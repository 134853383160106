import { __awaiter } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useStores } from "../../../utils/mobx";
import styles from "./style.scss";
const SuspendUserInnerModal = ({ form, onCancel, onSuccess, visible, userId, }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const { userReportStores, generalStore: { setErrorMessage, catchErrors, setSuccessMessage }, } = useStores();
    const handleClose = () => {
        onCancel();
    };
    const suspendUsers = (reason) => __awaiter(void 0, void 0, void 0, function* () {
        if (userId) {
            try {
                yield userReportStores.suspendUsers([
                    {
                        userId,
                        reason,
                    },
                ]);
                setSuccessMessage(t("users.suspendUserSuccessMessage"));
                onSuccess();
                form.resetFields();
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("users.defaultSuspendUserErrorMessage"));
                onCancel();
            }
        }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                suspendUsers(values.reason);
            }
        });
    };
    return (React.createElement(Modal, { className: styles.modal, visible: visible, title: t("suspendUserLabel"), onCancel: handleClose, onOk: handleSubmit, destroyOnClose: true, maskClosable: true, width: "40%", okText: t("confirmLabel") },
        React.createElement(Form, { layout: "vertical", hideRequiredMark: true },
            React.createElement(Row, { gutter: 20 },
                React.createElement(Form.Item, { "data-cy": "reason-wrapper", label: t("reasonLabel") }, getFieldDecorator("reason", {
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("reasonLabel") }),
                        },
                    ],
                })(React.createElement(TextArea, { style: { height: 120 }, placeholder: t("reasonPlaceholder"), allowClear: true })))))));
};
export const SuspendUserModal = Form.create({ name: "suspend_user_modal" })(SuspendUserInnerModal);
