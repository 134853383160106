import { __rest } from "tslib";
import React from "react";
import s from "./style.scss";
import { RightArrow } from "../../../shared/components/Icons/RightArrow";
export const SectionLink = (_a) => {
    var { title, description, info, onClick } = _a, rest = __rest(_a, ["title", "description", "info", "onClick"]);
    const handleEnterPress = (e) => {
        if (e.key === "Enter" && onClick) {
            onClick();
        }
    };
    return (React.createElement("a", Object.assign({ className: s.sectionLink, onClick: onClick || undefined, onKeyPress: handleEnterPress, tabIndex: 0 }, rest),
        React.createElement("div", { className: s.containerText },
            React.createElement("div", null,
                React.createElement("h3", { className: s.title }, title),
                info && React.createElement("p", { "data-cy": "info" }, info)),
            description && React.createElement("span", { className: s.description }, description)),
        React.createElement(RightArrow, null)));
};
