import { __rest } from "tslib";
import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { formatBankAccountNumber } from "../../../shared/utils/formatters";
const InputBankAccount = (_a) => {
    var { placeholder, defaultValue } = _a, rest = __rest(_a, ["placeholder", "defaultValue"]);
    const [value, setValue] = useState("");
    const handleSetValue = (accountNumber) => {
        const formatedValue = !accountNumber ? accountNumber : formatBankAccountNumber(accountNumber);
        setValue(formatedValue);
    };
    const handleChange = (e) => {
        const targetValue = e.target.value;
        handleSetValue(targetValue);
    };
    useEffect(() => {
        if (defaultValue) {
            handleSetValue(defaultValue.toString());
        }
    }, [defaultValue]);
    return (React.createElement(Input, Object.assign({ onChange: handleChange, value: value, allowClear: true, placeholder: "000000", maxLength: 13 }, rest)));
};
export default InputBankAccount;
