import React from "react";
import { SemanticButton } from "../../../shared/components/SemanticButton";
import { Revert } from "../../../shared/components/Icons/Revert";
const RevertButton = ({ disabled, handleClick }) => {
    return (React.createElement(SemanticButton, { disabled: disabled, onClick: (e) => {
            e.preventDefault();
            handleClick();
        } },
        React.createElement(Revert, null)));
};
export default RevertButton;
