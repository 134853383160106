import { __awaiter, __rest } from "tslib";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Form, Modal } from "antd";
import { CessionReportContext } from "../../containers/CessionReport/context";
import { cut, formatCurrency } from "../../../shared/utils/formatters";
import { CessionForm } from "../Contracts/Cession/CessionForm";
import { useStores } from "../../utils/mobx";
import { getToday } from "../../../shared/utils/date";
import styles from "./style.scss";
import BorderoNumberInput from "./BoderoNumberImput";
const ConfirmationCessionModal = (_a) => {
    var { form, visible, quantity, onCancel, confirmCession } = _a, rest = __rest(_a, ["form", "visible", "quantity", "onCancel", "confirmCession"]);
    const { validateFields } = form;
    const { selectedCessions } = React.useContext(CessionReportContext);
    const [cessionBalancies, setCessionBalancies] = useState([]);
    const { cessionReportStore: { calculateCessionValueByDate }, } = useStores();
    const { t } = useTranslation();
    useEffect(() => {
        setCessionBalancies(new Array(quantity).fill(0));
    }, [quantity]);
    useEffect(() => {
        calculateCessionValueByDateFromApi();
    }, [selectedCessions, visible]);
    const calculateCessionValueByDateFromApi = () => {
        selectedCessions.forEach((cession, index) => __awaiter(void 0, void 0, void 0, function* () {
            const cessionBalanceByDate = yield calculateCessionValueByDate(getToday(), cession.loanId);
            handleCessionFormDateChange(index, cessionBalanceByDate);
        }));
    };
    const handleCessionFormDateChange = (targetIndex, cessionBalance) => {
        setCessionBalancies((oldValue) => oldValue.map((currentValue, index) => index === targetIndex ? cessionBalance : currentValue));
    };
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                yield confirmCession(values);
            }
        }));
    });
    const renderInputs = (cessions) => {
        const formKey = "cessionsToConfirm";
        return (React.createElement(React.Fragment, null,
            React.createElement(BorderoNumberInput, { form: form }),
            cessions.map((cession, index) => {
                const inputName = `${formKey}[${index}]`;
                return (React.createElement(Collapse.Panel, { header: React.createElement("div", { className: styles.cessionCollapseTitle, "data-testid": "cessionConfirmInputGroup" },
                        React.createElement("div", null,
                            "CCB / ",
                            React.createElement("strong", null, cession.ccbNumber)),
                        React.createElement("div", { className: styles.userData },
                            cut(cession.userName),
                            " / ",
                            cut(cession.company),
                            " ",
                            "->",
                            " ",
                            cut(cession.assigneCompany)),
                        React.createElement("div", { "data-cy": "cession-balance-wrapper", className: styles.cessionBalance }, formatCurrency(cessionBalancies[index] / 100 || 0))), key: index },
                    React.createElement(CessionForm, { cession: cession, form: form, inputName: inputName, onDateChange: (newCession) => handleCessionFormDateChange(index, newCession) })));
            })));
    };
    return (React.createElement(Modal, { visible: visible, title: quantity && quantity > 1
            ? `${t("assignText")}  ${quantity}  ${t("contracts.contractsLabel")}`
            : t("assignText") + t("contracts.contractLabel"), onCancel: onCancel, onOk: handleSubmit, destroyOnClose: true, maskClosable: true, width: "65%" },
        React.createElement(Form, Object.assign({ className: styles.form, hideRequiredMark: true }, rest),
            React.createElement(Collapse, { defaultActiveKey: ["0"] }, renderInputs(selectedCessions)))));
};
export const ConfirmationCessionModalForm = Form.create({
    name: "cession_date_form",
})(ConfirmationCessionModal);
