/* eslint-disable */
import { SdkgenError, SdkgenErrorWithData, SdkgenHttpClient } from "@sdkgen/browser-runtime";
export class DBInvalidQuery extends SdkgenErrorWithData {
}
export class InvalidCredentials extends SdkgenErrorWithData {
}
export class InvalidCredentialsCode extends SdkgenErrorWithData {
}
export class InvalidParam extends SdkgenErrorWithData {
}
export class NotFoundCode extends SdkgenErrorWithData {
}
export class InvalidOperationCode extends SdkgenErrorWithData {
}
export class NotLoggedIn extends SdkgenErrorWithData {
}
export class NotExpectedOperation extends SdkgenErrorWithData {
}
export class CancelReservationError extends SdkgenErrorWithData {
}
export class ForbiddenAccessError extends SdkgenErrorWithData {
}
export class MetadadosRequestError extends SdkgenErrorWithData {
}
export class ConsultMarginError extends SdkgenErrorWithData {
}
export class ApiTimeoutError extends SdkgenErrorWithData {
}
export class CabineIntegrationError extends SdkgenErrorWithData {
}
export class CabineRunningInTestEnviromentError extends SdkgenErrorWithData {
}
export class IntegromatError extends SdkgenErrorWithData {
}
export class EmptyLog extends SdkgenErrorWithData {
}
export class Fatal extends SdkgenError {
}
export class ApiClient extends SdkgenHttpClient {
    constructor(baseUrl) {
        super(baseUrl, astJson, errClasses);
    }
    login(args) { return this.makeRequest("login", args || {}); }
    logout(args) { return this.makeRequest("logout", args || {}); }
    getCcbDataByLoanId(args) { return this.makeRequest("getCcbDataByLoanId", args || {}); }
    getCurrentAdmin(args) { return this.makeRequest("getCurrentAdmin", args || {}); }
    requestPasswordChange(args) { return this.makeRequest("requestPasswordChange", args || {}); }
    registerNewPassword(args) { return this.makeRequest("registerNewPassword", args || {}); }
    changePassword(args) { return this.makeRequest("changePassword", args || {}); }
    getEmailFromRequest(args) { return this.makeRequest("getEmailFromRequest", args || {}); }
    getOldestMonthWithActiveInstallment(args) { return this.makeRequest("getOldestMonthWithActiveInstallment", args || {}); }
    getChargeSummary(args) { return this.makeRequest("getChargeSummary", args || {}); }
    getChargeReport(args) { return this.makeRequest("getChargeReport", args || {}); }
    exportchargeReport(args) { return this.makeRequest("exportchargeReport", args || {}); }
    setChargeAsPaid(args) { return this.makeRequest("setChargeAsPaid", args || {}); }
    getChargeNote(args) { return this.makeRequest("getChargeNote", args || {}); }
    saveChargeNote(args) { return this.makeRequest("saveChargeNote", args || {}); }
    getHrEmployeesTotal(args) { return this.makeRequest("getHrEmployeesTotal", args || {}); }
    getAllHREmployees(args) { return this.makeRequest("getAllHREmployees", args || {}); }
    getHREmployeesByCompany(args) { return this.makeRequest("getHREmployeesByCompany", args || {}); }
    getHREmployeesByCompaniesGroup(args) { return this.makeRequest("getHREmployeesByCompaniesGroup", args || {}); }
    createHREmployee(args) { return this.makeRequest("createHREmployee", args || {}); }
    editHREmployee(args) { return this.makeRequest("editHREmployee", args || {}); }
    removeHREmployee(args) { return this.makeRequest("removeHREmployee", args || {}); }
    getAgreements(args) { return this.makeRequest("getAgreements", args || {}); }
    getAgreementById(args) { return this.makeRequest("getAgreementById", args || {}); }
    getAgreementFile(args) { return this.makeRequest("getAgreementFile", args || {}); }
    createAgreement(args) { return this.makeRequest("createAgreement", args || {}); }
    cancelAgreement(args) { return this.makeRequest("cancelAgreement", args || {}); }
    canCreateAgreement(args) { return this.makeRequest("canCreateAgreement", args || {}); }
    simulateAmortization(args) { return this.makeRequest("simulateAmortization", args || {}); }
    applyAmortization(args) { return this.makeRequest("applyAmortization", args || {}); }
    getDetailedCcbReport(args) { return this.makeRequest("getDetailedCcbReport", args || {}); }
    exportDetailedCcbReport(args) { return this.makeRequest("exportDetailedCcbReport", args || {}); }
    getApprovers(args) { return this.makeRequest("getApprovers", args || {}); }
    getMovers(args) { return this.makeRequest("getMovers", args || {}); }
    createEscrowMover(args) { return this.makeRequest("createEscrowMover", args || {}); }
    createEscrowApprover(args) { return this.makeRequest("createEscrowApprover", args || {}); }
    getCompaniesBasicInfoTotal(args) { return this.makeRequest("getCompaniesBasicInfoTotal", args || {}); }
    getCompaniesBasicInfo(args) { return this.makeRequest("getCompaniesBasicInfo", args || {}); }
    getCompanyInfo(args) { return this.makeRequest("getCompanyInfo", args || {}); }
    saveCompany(args) { return this.makeRequest("saveCompany", args || {}); }
    getActiveCompanies(args) { return this.makeRequest("getActiveCompanies", args || {}); }
    getLoanRequestTotal(args) { return this.makeRequest("getLoanRequestTotal", args || {}); }
    getLoanRequests(args) { return this.makeRequest("getLoanRequests", args || {}); }
    changeStatusFromLoanRequest(args) { return this.makeRequest("changeStatusFromLoanRequest", args || {}); }
    getContractTotal(args) { return this.makeRequest("getContractTotal", args || {}); }
    getContracts(args) { return this.makeRequest("getContracts", args || {}); }
    exportContracts(args) { return this.makeRequest("exportContracts", args || {}); }
    cancelCcbByAdmin(args) { return this.makeRequest("cancelCcbByAdmin", args || {}); }
    getActiveUsers(args) { return this.makeRequest("getActiveUsers", args || {}); }
    getUsersReportTotal(args) { return this.makeRequest("getUsersReportTotal", args || {}); }
    getUsersReport(args) { return this.makeRequest("getUsersReport", args || {}); }
    suspendUsers(args) { return this.makeRequest("suspendUsers", args || {}); }
    reactivateUser(args) { return this.makeRequest("reactivateUser", args || {}); }
    getUserSuspensionHistory(args) { return this.makeRequest("getUserSuspensionHistory", args || {}); }
    changeUserEmail(args) { return this.makeRequest("changeUserEmail", args || {}); }
    changeUserContact(args) { return this.makeRequest("changeUserContact", args || {}); }
    unblockUser(args) { return this.makeRequest("unblockUser", args || {}); }
    closeDigitalAccount(args) { return this.makeRequest("closeDigitalAccount", args || {}); }
    createLinkByCompanyId(args) { return this.makeRequest("createLinkByCompanyId", args || {}); }
    getLastLinkByCompanyId(args) { return this.makeRequest("getLastLinkByCompanyId", args || {}); }
    getProfiles(args) { return this.makeRequest("getProfiles", args || {}); }
    getProfilesTotal(args) { return this.makeRequest("getProfilesTotal", args || {}); }
    createProfile(args) { return this.makeRequest("createProfile", args || {}); }
    getAdminsByProfileId(args) { return this.makeRequest("getAdminsByProfileId", args || {}); }
    associateAdminsToProfile(args) { return this.makeRequest("associateAdminsToProfile", args || {}); }
    getRolesByProfileId(args) { return this.makeRequest("getRolesByProfileId", args || {}); }
    associateRolesToProfile(args) { return this.makeRequest("associateRolesToProfile", args || {}); }
    getCompaniesGroupsWithSecretAndAssocietedProfileRelationship(args) { return this.makeRequest("getCompaniesGroupsWithSecretAndAssocietedProfileRelationship", args || {}); }
    associateCompaniesGroupToExternalApiProfile(args) { return this.makeRequest("associateCompaniesGroupToExternalApiProfile", args || {}); }
    getProfileById(args) { return this.makeRequest("getProfileById", args || {}); }
    deleteProfile(args) { return this.makeRequest("deleteProfile", args || {}); }
    checkPermissionTest(args) { return this.makeRequest("checkPermissionTest", args || {}); }
    checkPermissionTest2(args) { return this.makeRequest("checkPermissionTest2", args || {}); }
    getAdminsTotal(args) { return this.makeRequest("getAdminsTotal", args || {}); }
    getAllAdmins(args) { return this.makeRequest("getAllAdmins", args || {}); }
    createAdmin(args) { return this.makeRequest("createAdmin", args || {}); }
    editAdmin(args) { return this.makeRequest("editAdmin", args || {}); }
    removeAdmin(args) { return this.makeRequest("removeAdmin", args || {}); }
    getLegalResponsibles(args) { return this.makeRequest("getLegalResponsibles", args || {}); }
    getSignatureCompanyInfo(args) { return this.makeRequest("getSignatureCompanyInfo", args || {}); }
    sendEmailToLegalResponsiblesWithSignatureLink(args) { return this.makeRequest("sendEmailToLegalResponsiblesWithSignatureLink", args || {}); }
    getCompaniesGroups(args) { return this.makeRequest("getCompaniesGroups", args || {}); }
    getCompaniesGroupsTotal(args) { return this.makeRequest("getCompaniesGroupsTotal", args || {}); }
    createCompaniesGroup(args) { return this.makeRequest("createCompaniesGroup", args || {}); }
    getCompaniesGroupById(args) { return this.makeRequest("getCompaniesGroupById", args || {}); }
    deleteCompaniesGroup(args) { return this.makeRequest("deleteCompaniesGroup", args || {}); }
    getFilteredCompaniesWithCompaniesGroupRelashionship(args) { return this.makeRequest("getFilteredCompaniesWithCompaniesGroupRelashionship", args || {}); }
    associateCompaniesGroupsCompanies(args) { return this.makeRequest("associateCompaniesGroupsCompanies", args || {}); }
    createCompaniesGroupApiSecret(args) { return this.makeRequest("createCompaniesGroupApiSecret", args || {}); }
    getCompaniesGroupApiSecret(args) { return this.makeRequest("getCompaniesGroupApiSecret", args || {}); }
    regenerateCompaniesGroupApiSecret(args) { return this.makeRequest("regenerateCompaniesGroupApiSecret", args || {}); }
    updateCompaniesGroupApiSecretProfile(args) { return this.makeRequest("updateCompaniesGroupApiSecretProfile", args || {}); }
    getCessionsTotal(args) { return this.makeRequest("getCessionsTotal", args || {}); }
    getCessions(args) { return this.makeRequest("getCessions", args || {}); }
    createCessions(args) { return this.makeRequest("createCessions", args || {}); }
    confirmCessions(args) { return this.makeRequest("confirmCessions", args || {}); }
    cancelCessions(args) { return this.makeRequest("cancelCessions", args || {}); }
    exportCessions(args) { return this.makeRequest("exportCessions", args || {}); }
    simulateInstallments(args) { return this.makeRequest("simulateInstallments", args || {}); }
    calculateCessionValueByDate(args) { return this.makeRequest("calculateCessionValueByDate", args || {}); }
    getOpenInstallmentsGivenByCession(args) { return this.makeRequest("getOpenInstallmentsGivenByCession", args || {}); }
    repurchaseGivenInstallments(args) { return this.makeRequest("repurchaseGivenInstallments", args || {}); }
    getLatestSourceCompany(args) { return this.makeRequest("getLatestSourceCompany", args || {}); }
    getUserActiveCompaniesByLoanId(args) { return this.makeRequest("getUserActiveCompaniesByLoanId", args || {}); }
    simulatePortability(args) { return this.makeRequest("simulatePortability", args || {}); }
    makeLoanPortability(args) { return this.makeRequest("makeLoanPortability", args || {}); }
    getPortabilityHistory(args) { return this.makeRequest("getPortabilityHistory", args || {}); }
    getFgtsParameters(args) { return this.makeRequest("getFgtsParameters", args || {}); }
    updateFgtsParameters(args) { return this.makeRequest("updateFgtsParameters", args || {}); }
    getBirthdayWithdrawalContractTotal(args) { return this.makeRequest("getBirthdayWithdrawalContractTotal", args || {}); }
    getBirthdayWithdrawalContracts(args) { return this.makeRequest("getBirthdayWithdrawalContracts", args || {}); }
    exportBirthdayWithdrawalContracts(args) { return this.makeRequest("exportBirthdayWithdrawalContracts", args || {}); }
    cancelFgtsBirthdayWithdrawals(args) { return this.makeRequest("cancelFgtsBirthdayWithdrawals", args || {}); }
    getInstallmentsWithPayments(args) { return this.makeRequest("getInstallmentsWithPayments", args || {}); }
    revertInstallmentPayment(args) { return this.makeRequest("revertInstallmentPayment", args || {}); }
    rescheduleInstallment(args) { return this.makeRequest("rescheduleInstallment", args || {}); }
    getCompanyDocuments(args) { return this.makeRequest("getCompanyDocuments", args || {}); }
    getCompanyDocumentFile(args) { return this.makeRequest("getCompanyDocumentFile", args || {}); }
    saveCcbSimulationPj(args) { return this.makeRequest("saveCcbSimulationPj", args || {}); }
    simulateCcbPj(args) { return this.makeRequest("simulateCcbPj", args || {}); }
    getSimulationPj(args) { return this.makeRequest("getSimulationPj", args || {}); }
    getCcbSimulationPjReportTotal(args) { return this.makeRequest("getCcbSimulationPjReportTotal", args || {}); }
    getCcbSimulationPjReport(args) { return this.makeRequest("getCcbSimulationPjReport", args || {}); }
    generateCcbPjPreview(args) { return this.makeRequest("generateCcbPjPreview", args || {}); }
    requestLoanPj(args) { return this.makeRequest("requestLoanPj", args || {}); }
    cancelLoanPj(args) { return this.makeRequest("cancelLoanPj", args || {}); }
    getLoanPjNegotiableCcbFile(args) { return this.makeRequest("getLoanPjNegotiableCcbFile", args || {}); }
    getLoanPjNonNegotiableCcbFile(args) { return this.makeRequest("getLoanPjNonNegotiableCcbFile", args || {}); }
    updateCompaniesPayrollViaMetadados(args) { return this.makeRequest("updateCompaniesPayrollViaMetadados", args || {}); }
    createExternalIntegrationUser(args) { return this.makeRequest("createExternalIntegrationUser", args || {}); }
    getBndesProposals(args) { return this.makeRequest("getBndesProposals", args || {}); }
    updateBndesProposal(args) { return this.makeRequest("updateBndesProposal", args || {}); }
    requestCnab(args) { return this.makeRequest("requestCnab", args || {}); }
    createMarsupioUsers(args) { return this.makeRequest("createMarsupioUsers", args || {}); }
    getAccountBalanceByPurpose(args) { return this.makeRequest("getAccountBalanceByPurpose", args || {}); }
    getPrecatoryAnticipationContractTotal(args) { return this.makeRequest("getPrecatoryAnticipationContractTotal", args || {}); }
    getPrecatoryAnticipationContracts(args) { return this.makeRequest("getPrecatoryAnticipationContracts", args || {}); }
    exportPrecatoryAnticipationContracts(args) { return this.makeRequest("exportPrecatoryAnticipationContracts", args || {}); }
    sendPushNotification(args) { return this.makeRequest("sendPushNotification", args || {}); }
}
const errClasses = {
    DBInvalidQuery,
    InvalidCredentials,
    InvalidCredentialsCode,
    InvalidParam,
    NotFoundCode,
    InvalidOperationCode,
    NotLoggedIn,
    NotExpectedOperation,
    CancelReservationError,
    ForbiddenAccessError,
    MetadadosRequestError,
    ConsultMarginError,
    ApiTimeoutError,
    CabineIntegrationError,
    CabineRunningInTestEnviromentError,
    IntegromatError,
    EmptyLog,
    Fatal
};
const astJson = {
    annotations: {
        "type.BankAccount.bankName": [
            {
                type: "description",
                value: "Nome do banco"
            }
        ],
        "type.BankAccount.bankNumber": [
            {
                type: "description",
                value: "Numero do banco"
            }
        ],
        "type.BankAccount.branchNumber": [
            {
                type: "description",
                value: "Agencia"
            }
        ],
        "type.BankAccount.accountNumber": [
            {
                type: "description",
                value: "Numero da conta"
            }
        ],
        "type.CcbDataPreviewDynamicDataCreditInfo.loanTotalValue": [
            {
                type: "description",
                value: "Valor de Presente"
            }
        ],
        "type.Ccb.draweeName": [
            {
                type: "description",
                value: "Nome do tomador / sacado: nome do funcionario que esta tomando o emprestimo (usuario)."
            }
        ],
        "type.Ccb.draweeCpf": [
            {
                type: "description",
                value: "CPF do tomador / sacado: CPF do funcionario que esta tomando o emprestimo (usuario)."
            }
        ],
        "type.Ccb.ccbValue": [
            {
                type: "description",
                value: "Valor do contrato"
            }
        ],
        "type.Ccb.numberOfInstallments": [
            {
                type: "description",
                value: "Quantidade de parcelas"
            }
        ],
        "type.Ccb.installmentValue": [
            {
                type: "description",
                value: "valor da parcela"
            }
        ],
        "type.Ccb.chargeValueForInstallment": [
            {
                type: "description",
                value: "valor da cobrança para a parcela"
            }
        ],
        "type.Ccb.draweeSector": [
            {
                type: "description",
                value: "setor do tomador"
            }
        ],
        "type.Ccb.extendedDueDate": [
            {
                type: "description",
                value: "data de vencimento da parcela da reprogramação"
            }
        ],
        "type.Ccb.extendedReason": [
            {
                type: "description",
                value: "motivo da reprogramação de parcela"
            }
        ],
        "type.UserLoanInfo.availableValue": [
            {
                type: "description",
                value: "Valor disponivel para pegar o emprestimo"
            }
        ],
        "type.UserLoanInfoForCompany.availableValue": [
            {
                type: "description",
                value: "Valor disponivel para pegar o emprestimo"
            }
        ],
        "type.UserLoanSummary.loanTotalValue": [
            {
                type: "description",
                value: "Valor Total do Emprestimo"
            }
        ],
        "type.UserLoanSummary.totalValue": [
            {
                type: "description",
                value: "Valor Total do Credito"
            }
        ],
        "type.UserLoanSummary.monthlyInterestRate": [
            {
                type: "description",
                value: "A taxa de juros efetiva a.m."
            }
        ],
        "type.UserLoanSummary.monthlyEffectiveCost": [
            {
                type: "description",
                value: "Custo Efetivo Total a.m."
            }
        ],
        "type.UserLoanSummary.balance": [
            {
                type: "description",
                value: "\"valor presente\" (saldo devedor teórico) em aberto"
            }
        ],
        "type.UserLoanSummary.structuringValue": [
            {
                type: "description",
                value: "Tarifa de cadastro"
            }
        ],
        "type.UserLoanSummary.thirdPartyValue": [
            {
                type: "description",
                value: "Valor do Serviço de Terceiros"
            }
        ],
        "type.AllInstallments.isOverdue": [
            {
                type: "description",
                value: "WHEN status = 'open', Verify if due_date < TodayLocalTimezone()"
            }
        ],
        "type.AllInstallments.dueDate": [
            {
                type: "description",
                value: "Needs to be string because the JSONB_BUILD_OBJECT() do bind only to string. @see user.loans.getUserLoanSummary()"
            }
        ],
        "type.AllInstallments.paidValue": [
            {
                type: "description",
                value: "WHEN status = 'paid'"
            }
        ],
        "type.AllInstallments.paidAt": [
            {
                type: "description",
                value: "WHEN status = 'paid'. Needs to be string because the JSONB_BUILD_OBJECT() do bind only to string. @see user.loans.getUserLoanSummary()"
            }
        ],
        "type.LoanRequestsCreditInfo.totalAmount": [
            {
                type: "description",
                value: "Valor total do crédito"
            }
        ],
        "type.LoanRequestsCreditInfo.ccbTotalValue": [
            {
                type: "description",
                value: "Valor Total do Emprestimo"
            }
        ],
        "type.InstallmentDetails.dctoId": [
            {
                type: "description",
                value: "dcto"
            }
        ],
        "type.InstallmentDetails.dueDate": [
            {
                type: "description",
                value: "vcto"
            }
        ],
        "type.InstallmentDetails.faceValue": [
            {
                type: "description",
                value: "vlrface"
            }
        ],
        "type.InstallmentDetails.optionalDiscount": [
            {
                type: "description",
                value: "desc"
            }
        ],
        "type.InstallmentDetails.limitDate": [
            {
                type: "description",
                value: "limit"
            }
        ],
        "type.InstallmentDetails.daysDue": [
            {
                type: "description",
                value: "pz: installment[n].dueDate - Today()"
            }
        ],
        "type.InstallmentDetails.discount": [
            {
                type: "description",
                value: "desagio: eh o desconto a ser aplicado na parcela futura, para chegar na parcela a Valor presente"
            }
        ],
        "type.InstallmentDetails.installmentPresentValue": [
            {
                type: "description",
                value: "liquido: eh o valor da parcela menos o desagio."
            }
        ],
        "type.InstallmentDetails.draweeName": [
            {
                type: "description",
                value: "sacado: nome do funcionario que esta tomando o emprestimo (usuario)."
            }
        ],
        "fn.getCcbDataByLoanId": [
            {
                type: "description",
                value: "get CCB after App loan request by user"
            },
            {
                type: "throws",
                value: "InvalidParam"
            },
            {
                type: "throws",
                value: "NotFoundCode"
            }
        ],
        "fn.getOldestMonthWithActiveInstallment": [
            {
                type: "description",
                value: "Get Oldest Month With Active Installment over all installments active or given"
            }
        ],
        "fn.getDetailedCcbReport": [
            {
                type: "description",
                value: "CCB details known as WBA Report"
            },
            {
                type: "throws",
                value: "InvalidOperationCode"
            }
        ],
        "fn.exportDetailedCcbReport": [
            {
                type: "description",
                value: "Export CCB details known as WBA Report"
            },
            {
                type: "throws",
                value: "InvalidOperationCode"
            }
        ],
        "fn.companyInfo": [
            {
                type: "hidden",
                value: null
            },
            {
                type: "rest",
                value: {
                    bodyVariable: "accountDigitalCompanyData",
                    headers: [
                        [
                            "authorization",
                            "token"
                        ]
                    ],
                    method: "PUT",
                    path: "/companyInfo",
                    pathVariables: [],
                    queryVariables: []
                }
            }
        ],
        "fn.company": [
            {
                type: "hidden",
                value: null
            },
            {
                type: "rest",
                value: {
                    bodyVariable: "companyRequestDto",
                    headers: [
                        [
                            "authorization",
                            "token"
                        ]
                    ],
                    method: "POST",
                    path: "/company",
                    pathVariables: [],
                    queryVariables: []
                }
            }
        ],
        "fn.getCompany": [
            {
                type: "hidden",
                value: null
            },
            {
                type: "rest",
                value: {
                    bodyVariable: null,
                    headers: [
                        [
                            "authorization",
                            "token"
                        ]
                    ],
                    method: "GET",
                    path: "/company/{cnpj}",
                    pathVariables: [
                        "cnpj"
                    ],
                    queryVariables: []
                }
            }
        ],
        "fn.getCompanyEmployeeInfo": [
            {
                type: "hidden",
                value: null
            },
            {
                type: "rest",
                value: {
                    bodyVariable: null,
                    headers: [
                        [
                            "authorization",
                            "token"
                        ]
                    ],
                    method: "GET",
                    path: "/company/employee/{cpf}",
                    pathVariables: [
                        "cpf"
                    ],
                    queryVariables: []
                }
            }
        ],
        "fn.getCompaniesBasicInfoTotal": [
            {
                type: "description",
                value: "How many companies exists by filter"
            }
        ],
        "fn.getActiveCompanies": [
            {
                type: "description",
                value: "get raw data from companies database table"
            }
        ],
        "fn.regenerateLoanCCBWithSeguroPrestamistaPolicy": [
            {
                type: "hidden",
                value: null
            },
            {
                type: "rest",
                value: {
                    bodyVariable: "regenerateLoanCCBParams",
                    headers: [
                        [
                            "authorization",
                            "token"
                        ]
                    ],
                    method: "POST",
                    path: "/regenerateLoanCcb",
                    pathVariables: [],
                    queryVariables: []
                }
            }
        ],
        "fn.getActiveUsers": [
            {
                type: "description",
                value: "get data from active users table in database"
            }
        ],
        "fn.getProfiles": [
            {
                type: "description",
                value: "get data from active profiles table"
            }
        ],
        "fn.getProfilesTotal": [
            {
                type: "description",
                value: "get data total from active profiles table"
            }
        ],
        "fn.createProfile": [
            {
                type: "description",
                value: "insert data in profiles table"
            }
        ],
        "fn.getCompaniesGroupsWithSecretAndAssocietedProfileRelationship": [
            {
                type: "description",
                value: "Returns companies groups that has apiSecrets"
            }
        ],
        "fn.getCompaniesGroups": [
            {
                type: "description",
                value: "get data from active companies_groups table"
            }
        ],
        "fn.getCompaniesGroupsTotal": [
            {
                type: "description",
                value: "get data total from active companies_groups table"
            }
        ],
        "fn.createCompaniesGroup": [
            {
                type: "description",
                value: "insert data in companies_groups table"
            }
        ],
        "fn.calculateCessionValueByDate.date": [
            {
                type: "description",
                value: "Somente data, no formato ISO 8601 (YYYY-MM-DD)"
            }
        ],
        "fn.calculateCessionValueByDate.loanId": [
            {
                type: "description",
                value: "id do empréstimo"
            }
        ],
        "fn.calculateCessionValueByDate": [
            {
                type: "description",
                value: "Calcula o valor da cessão do empréstimo pela data"
            },
            {
                type: "throws",
                value: "InvalidParam"
            }
        ],
        "fn.getBndesProposals": [
            {
                type: "description",
                value: "Return all proposals to backoffice"
            }
        ],
        "fn.updateBndesProposal": [
            {
                type: "description",
                value: "Update Bndes proposal"
            }
        ]
    },
    errors: [
        [
            "DBInvalidQuery",
            "DBInvalidQueryData"
        ],
        [
            "InvalidCredentials",
            "InvalidCredentialsData"
        ],
        [
            "InvalidCredentialsCode",
            "InvalidCredentialsCodeData"
        ],
        [
            "InvalidParam",
            "InvalidParamData"
        ],
        [
            "NotFoundCode",
            "NotFoundCodeData"
        ],
        [
            "InvalidOperationCode",
            "InvalidOperationCodeData"
        ],
        [
            "NotLoggedIn",
            "NotLoggedInData"
        ],
        [
            "NotExpectedOperation",
            "NotExpectedOperationData"
        ],
        [
            "CancelReservationError",
            "CancelReservationErrorData"
        ],
        [
            "ForbiddenAccessError",
            "ForbiddenAccessErrorData"
        ],
        [
            "MetadadosRequestError",
            "MetadadosRequestErrorData"
        ],
        [
            "ConsultMarginError",
            "ConsultMarginErrorData"
        ],
        [
            "ApiTimeoutError",
            "ApiTimeoutErrorData"
        ],
        [
            "CabineIntegrationError",
            "CabineIntegrationErrorData"
        ],
        [
            "CabineRunningInTestEnviromentError",
            "CabineRunningInTestEnviromentErrorData"
        ],
        [
            "IntegromatError",
            "IntegromatErrorData"
        ],
        [
            "EmptyLog",
            "EmptyLogData"
        ],
        "Fatal"
    ],
    functionTable: {
        login: {
            args: {
                email: "string",
                password: "string"
            },
            ret: "AdminWithAccessModules"
        },
        logout: {
            args: {},
            ret: "void"
        },
        getCcbDataByLoanId: {
            args: {
                loanId: "string"
            },
            ret: "CcbDataPreview"
        },
        getCurrentAdmin: {
            args: {},
            ret: "AdminWithAccessModules"
        },
        requestPasswordChange: {
            args: {
                email: "string"
            },
            ret: "void"
        },
        registerNewPassword: {
            args: {
                requestId: "string",
                password: "string",
                confirmPassword: "string"
            },
            ret: "void"
        },
        changePassword: {
            args: {
                currentPassword: "string",
                newPassword: "string",
                confirmNewPassword: "string"
            },
            ret: "void"
        },
        getEmailFromRequest: {
            args: {
                requestId: "string"
            },
            ret: "string"
        },
        getOldestMonthWithActiveInstallment: {
            args: {},
            ret: "MonthAndYear?"
        },
        getChargeSummary: {
            args: {
                month: "int",
                year: "int",
                chargeFilter: "ChargeFilter"
            },
            ret: "ChargeSummary"
        },
        getChargeReport: {
            args: {
                month: "int",
                year: "int",
                page: "int",
                rowsPerPage: "int",
                chargeFilter: "ChargeFilter"
            },
            ret: "ChargeReport"
        },
        exportchargeReport: {
            args: {
                month: "int",
                year: "int",
                chargeFilter: "ChargeFilter"
            },
            ret: "bytes"
        },
        setChargeAsPaid: {
            args: {
                month: "int",
                year: "int",
                paidDate: "datetime",
                chargeInfo: "ChargeInfo"
            },
            ret: "void"
        },
        getChargeNote: {
            args: {
                noteId: "string"
            },
            ret: "ChargeNote"
        },
        saveChargeNote: {
            args: {
                chargeNote: "ChargeNote"
            },
            ret: "ChargeNote"
        },
        getHrEmployeesTotal: {
            args: {
                filter: "HREmployeeFilter?"
            },
            ret: "int"
        },
        getAllHREmployees: {
            args: {
                filter: "HREmployeeFilter?",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "HrEmployeesPaginated"
        },
        getHREmployeesByCompany: {
            args: {
                companyId: "string"
            },
            ret: "HREmployee[]"
        },
        getHREmployeesByCompaniesGroup: {
            args: {
                companiesGroupId: "string"
            },
            ret: "HREmployee[]"
        },
        createHREmployee: {
            args: {
                newEmployee: "HREmployeeParams"
            },
            ret: "HREmployee"
        },
        editHREmployee: {
            args: {
                employee: "HREmployeeParams"
            },
            ret: "HREmployee"
        },
        removeHREmployee: {
            args: {
                employeeId: "string"
            },
            ret: "void"
        },
        getAgreements: {
            args: {
                companyId: "string"
            },
            ret: "Agreement[]"
        },
        getAgreementById: {
            args: {
                id: "string"
            },
            ret: "Agreement"
        },
        getAgreementFile: {
            args: {
                id: "string"
            },
            ret: "bytes"
        },
        createAgreement: {
            args: {
                companyId: "string",
                agreement: "Agreement",
                file: "bytes",
                solicitationDate: "datetime?"
            },
            ret: "Agreement"
        },
        cancelAgreement: {
            args: {
                id: "string"
            },
            ret: "void"
        },
        canCreateAgreement: {
            args: {
                companyId: "string"
            },
            ret: "void"
        },
        simulateAmortization: {
            args: {
                amortization: "Amortization",
                loanId: "string"
            },
            ret: "AmortizationSimulation"
        },
        applyAmortization: {
            args: {
                amortization: "Amortization",
                loanId: "string"
            },
            ret: "void"
        },
        getDetailedCcbReport: {
            args: {
                loanId: "string",
                solicitationDate: "datetime?",
                reportRequestDate: "datetime?"
            },
            ret: "DetailedCcbReport"
        },
        exportDetailedCcbReport: {
            args: {
                loanId: "string",
                solicitationDate: "datetime?",
                reportRequestDate: "datetime?"
            },
            ret: "bytes"
        },
        companyInfo: {
            args: {
                token: "string",
                accountDigitalCompanyData: "AccountDigitalCompanyData"
            },
            ret: "void"
        },
        company: {
            args: {
                token: "string",
                companyRequestDto: "CompanyRequestDto"
            },
            ret: "void"
        },
        getCompany: {
            args: {
                token: "string",
                cnpj: "string"
            },
            ret: "GetCompanyResponse?"
        },
        getCompanyEmployeeInfo: {
            args: {
                token: "string",
                cpf: "string"
            },
            ret: "GetCompanyEmployeeInfoResponse?"
        },
        getApprovers: {
            args: {
                companyQuery: "CompanyQuery?"
            },
            ret: "GetApproversResult[]"
        },
        getMovers: {
            args: {
                companyQuery: "CompanyQuery?"
            },
            ret: "GetMoversResult[]"
        },
        createEscrowMover: {
            args: {
                createMoverCompanyDto: "CreateMoverCompanyDto"
            },
            ret: "void"
        },
        createEscrowApprover: {
            args: {
                createApproverCompanyDto: "CreateApproverCompanyDto"
            },
            ret: "void"
        },
        getCompaniesBasicInfoTotal: {
            args: {
                nameToFilter: "string?"
            },
            ret: "int"
        },
        getCompaniesBasicInfo: {
            args: {
                nameToFilter: "string?",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "CompanyBasicInfoPaginated"
        },
        getCompanyInfo: {
            args: {
                id: "string"
            },
            ret: "CompanyInfo"
        },
        saveCompany: {
            args: {
                companyInfo: "CompanyInfo"
            },
            ret: "CompanyInfo"
        },
        getActiveCompanies: {
            args: {
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "CompanyRawData[]"
        },
        getLoanRequestTotal: {
            args: {
                filter: "LoanRequestFilter"
            },
            ret: "int"
        },
        getLoanRequests: {
            args: {
                filter: "LoanRequestFilter",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "LoanRequestsPaginated"
        },
        changeStatusFromLoanRequest: {
            args: {
                requestIds: "string[]",
                status: "ApprovalDecision",
                solicitationDate: "datetime?",
                transferAmount: "int?",
                blockTemporaryUser: "int?"
            },
            ret: "bool"
        },
        getContractTotal: {
            args: {
                filter: "ContractFilter"
            },
            ret: "uint"
        },
        getContracts: {
            args: {
                filter: "ContractFilter",
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "ContractReport"
        },
        exportContracts: {
            args: {
                filter: "ContractFilter"
            },
            ret: "bytes"
        },
        cancelCcbByAdmin: {
            args: {
                loanId: "string",
                solicitationDate: "datetime?"
            },
            ret: "void"
        },
        regenerateLoanCCBWithSeguroPrestamistaPolicy: {
            args: {
                token: "string",
                regenerateLoanCCBParams: "RegenerateLoanCCBParams"
            },
            ret: "void"
        },
        getActiveUsers: {
            args: {
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "User[]"
        },
        getUsersReportTotal: {
            args: {
                filter: "UserReportFilter"
            },
            ret: "uint"
        },
        getUsersReport: {
            args: {
                filter: "UserReportFilter",
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "UserReport"
        },
        suspendUsers: {
            args: {
                usersToSuspend: "SuspendUserParams[]"
            },
            ret: "void"
        },
        reactivateUser: {
            args: {
                userId: "string"
            },
            ret: "void"
        },
        getUserSuspensionHistory: {
            args: {
                userId: "string"
            },
            ret: "UserSuspension[]"
        },
        changeUserEmail: {
            args: {
                userId: "string",
                newEmail: "string"
            },
            ret: "void"
        },
        changeUserContact: {
            args: {
                userId: "string",
                contact: "ChangeUserContact"
            },
            ret: "void"
        },
        unblockUser: {
            args: {
                userId: "string"
            },
            ret: "void"
        },
        closeDigitalAccount: {
            args: {
                closeDigitalAccountParams: "CloseDigitalAccount"
            },
            ret: "string"
        },
        createLinkByCompanyId: {
            args: {
                companyId: "string"
            },
            ret: "LinkReturn"
        },
        getLastLinkByCompanyId: {
            args: {
                companyId: "string"
            },
            ret: "LinkReturn"
        },
        getProfiles: {
            args: {
                filter: "ProfileFilter?",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "ProfilePaginated"
        },
        getProfilesTotal: {
            args: {
                filter: "ProfileFilter?"
            },
            ret: "int"
        },
        createProfile: {
            args: {
                profileParams: "ProfileParams"
            },
            ret: "void"
        },
        getAdminsByProfileId: {
            args: {
                profileId: "uuid"
            },
            ret: "ProfileAdmins[]"
        },
        associateAdminsToProfile: {
            args: {
                associateAdminsProfileParams: "AssociateAdminsProfileParams"
            },
            ret: "void"
        },
        getRolesByProfileId: {
            args: {
                profileId: "uuid"
            },
            ret: "ProfileRoles[]"
        },
        associateRolesToProfile: {
            args: {
                associateRolesProfileParams: "AssociateRolesProfileParams"
            },
            ret: "void"
        },
        getCompaniesGroupsWithSecretAndAssocietedProfileRelationship: {
            args: {
                profileId: "uuid"
            },
            ret: "ProfileCompaniesGroup[]"
        },
        associateCompaniesGroupToExternalApiProfile: {
            args: {
                associateCompaniesGroupsProfileParams: "AssociateCompaniesGroupsProfileParams"
            },
            ret: "void"
        },
        getProfileById: {
            args: {
                profileId: "uuid"
            },
            ret: "Profile"
        },
        deleteProfile: {
            args: {
                profileId: "uuid"
            },
            ret: "void"
        },
        checkPermissionTest: {
            args: {},
            ret: "void"
        },
        checkPermissionTest2: {
            args: {},
            ret: "void"
        },
        getAdminsTotal: {
            args: {
                filter: "AdminFilter?"
            },
            ret: "int"
        },
        getAllAdmins: {
            args: {
                filter: "AdminFilter?",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "AdminPaginated"
        },
        createAdmin: {
            args: {
                admin: "AdminParams"
            },
            ret: "AdminWithProfile"
        },
        editAdmin: {
            args: {
                admin: "AdminWithProfile"
            },
            ret: "AdminWithProfile"
        },
        removeAdmin: {
            args: {
                adminId: "string"
            },
            ret: "void"
        },
        getLegalResponsibles: {
            args: {
                companyId: "uuid"
            },
            ret: "LegalResponsiblesWithSignatures[]"
        },
        getSignatureCompanyInfo: {
            args: {
                signatureId: "uuid"
            },
            ret: "CompanyInfoPj"
        },
        sendEmailToLegalResponsiblesWithSignatureLink: {
            args: {
                companyId: "uuid"
            },
            ret: "void"
        },
        getCompaniesGroups: {
            args: {
                filter: "CompaniesGroupFilter?",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "CompaniesGroupPaginated"
        },
        getCompaniesGroupsTotal: {
            args: {
                filter: "CompaniesGroupFilter?"
            },
            ret: "int"
        },
        createCompaniesGroup: {
            args: {
                companiesGroupParams: "CompaniesGroupParams"
            },
            ret: "CompaniesGroup"
        },
        getCompaniesGroupById: {
            args: {
                companiesGroupId: "uuid"
            },
            ret: "CompaniesGroup?"
        },
        deleteCompaniesGroup: {
            args: {
                companiesGroupId: "uuid"
            },
            ret: "void"
        },
        getFilteredCompaniesWithCompaniesGroupRelashionship: {
            args: {
                filter: "CompaniesWithCompaniesGroupRelashionshipFilter"
            },
            ret: "CompanyWithCompaniesGroupRelashionship[]"
        },
        associateCompaniesGroupsCompanies: {
            args: {
                associateCompaniesGroupsCompaniesParams: "AssociateCompaniesGroupsCompaniesParams"
            },
            ret: "void"
        },
        createCompaniesGroupApiSecret: {
            args: {
                params: "CompaniesGroupApiSecretParams"
            },
            ret: "ApiSecret"
        },
        getCompaniesGroupApiSecret: {
            args: {
                companiesGroupId: "uuid"
            },
            ret: "ApiSecret?"
        },
        regenerateCompaniesGroupApiSecret: {
            args: {
                companiesGroupId: "uuid"
            },
            ret: "ApiSecret"
        },
        updateCompaniesGroupApiSecretProfile: {
            args: {
                companiesGroupId: "uuid",
                profileId: "uuid?"
            },
            ret: "ApiSecret"
        },
        getCessionsTotal: {
            args: {
                filter: "CessionFilter"
            },
            ret: "int"
        },
        getCessions: {
            args: {
                filter: "CessionFilter",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "CessionPaginated"
        },
        createCessions: {
            args: {
                loansIds: "string[]",
                assignCompany: "string",
                installments: "string?",
                solicitationDate: "datetime?"
            },
            ret: "void"
        },
        confirmCessions: {
            args: {
                cessionsToConfirm: "CessionConfirm[]",
                borderoNumber: "string",
                solicitationDate: "datetime?"
            },
            ret: "void"
        },
        cancelCessions: {
            args: {
                cessionIds: "string[]"
            },
            ret: "void"
        },
        exportCessions: {
            args: {
                filter: "CessionFilter"
            },
            ret: "bytes"
        },
        simulateInstallments: {
            args: {
                loanValue: "uint",
                userId: "string",
                companyId: "string",
                solicitationDate: "datetime?"
            },
            ret: "SimulatedInstallments[]"
        },
        calculateCessionValueByDate: {
            args: {
                date: "string",
                loanId: "string"
            },
            ret: "int"
        },
        getOpenInstallmentsGivenByCession: {
            args: {
                cessionId: "string"
            },
            ret: "InstallmentFinancialInfo[]"
        },
        repurchaseGivenInstallments: {
            args: {
                cessionId: "string",
                repurchaseDate: "string",
                repurchaseValue: "int",
                installmentsDueDate: "string[]"
            },
            ret: "void"
        },
        getLatestSourceCompany: {
            args: {
                loanId: "uuid"
            },
            ret: "CompanyBasicInfo"
        },
        getUserActiveCompaniesByLoanId: {
            args: {
                loanId: "uuid"
            },
            ret: "CompanyBasicInfo[]"
        },
        simulatePortability: {
            args: {
                params: "SimulatePortabiltyParams"
            },
            ret: "InstallmentBasicInfo[]"
        },
        makeLoanPortability: {
            args: {
                params: "MakeLoanPortabilityParams"
            },
            ret: "void"
        },
        getPortabilityHistory: {
            args: {
                loanId: "string"
            },
            ret: "Portability[]"
        },
        getFgtsParameters: {
            args: {},
            ret: "FgtsParameters"
        },
        updateFgtsParameters: {
            args: {
                fgtsParameters: "FgtsParameters"
            },
            ret: "void"
        },
        getBirthdayWithdrawalContractTotal: {
            args: {
                filter: "BirthdayWithdrawalContractFilter"
            },
            ret: "uint"
        },
        getBirthdayWithdrawalContracts: {
            args: {
                filter: "BirthdayWithdrawalContractFilter",
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "BirthdayWithdrawalContractReport"
        },
        exportBirthdayWithdrawalContracts: {
            args: {
                filter: "BirthdayWithdrawalContractFilter"
            },
            ret: "bytes"
        },
        cancelFgtsBirthdayWithdrawals: {
            args: {
                fgtsWithdrawalsIds: "string[]"
            },
            ret: "void"
        },
        getInstallmentsWithPayments: {
            args: {
                loanId: "string"
            },
            ret: "InstallmentWithPayments[]"
        },
        revertInstallmentPayment: {
            args: {
                paymentId: "string"
            },
            ret: "void"
        },
        rescheduleInstallment: {
            args: {
                chargeInfo: "ChargeInfo",
                reason: "string"
            },
            ret: "void"
        },
        getCompanyDocuments: {
            args: {
                companyId: "uuid"
            },
            ret: "DocumentGroupList[]"
        },
        getCompanyDocumentFile: {
            args: {
                fileId: "uuid",
                companyId: "uuid"
            },
            ret: "bytes"
        },
        saveCcbSimulationPj: {
            args: {
                ccbSimulationPj: "CcbSimulationPj"
            },
            ret: "string"
        },
        simulateCcbPj: {
            args: {
                simulation: "SimulationRequestParamsPj"
            },
            ret: "SimulationPJ"
        },
        getSimulationPj: {
            args: {
                simulationId: "uuid"
            },
            ret: "CcbSimulationPj"
        },
        getCcbSimulationPjReportTotal: {
            args: {},
            ret: "uint"
        },
        getCcbSimulationPjReport: {
            args: {
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "CcbSimulationPjReport"
        },
        generateCcbPjPreview: {
            args: {
                ccbPjParams: "CcbPjParams"
            },
            ret: "bytes"
        },
        requestLoanPj: {
            args: {
                ccbPjParams: "CcbPjParams"
            },
            ret: "RequestLoanPj"
        },
        cancelLoanPj: {
            args: {
                ccbNumber: "string"
            },
            ret: "void"
        },
        getLoanPjNegotiableCcbFile: {
            args: {
                id: "uuid"
            },
            ret: "bytes"
        },
        getLoanPjNonNegotiableCcbFile: {
            args: {
                id: "uuid"
            },
            ret: "bytes"
        },
        updateCompaniesPayrollViaMetadados: {
            args: {
                companiesIds: "string[]?"
            },
            ret: "void"
        },
        createExternalIntegrationUser: {
            args: {
                username: "string",
                password: "string",
                email: "string"
            },
            ret: "string"
        },
        getBndesProposals: {
            args: {
                filter: "ProposalFilter?",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "ProposalsPaginated"
        },
        updateBndesProposal: {
            args: {
                proposal: "UpdateProposalRequestParams"
            },
            ret: "void"
        },
        requestCnab: {
            args: {
                targetDate: "string"
            },
            ret: "void"
        },
        createMarsupioUsers: {
            args: {
                marsupioUsersData: "MarsupioUserData[]"
            },
            ret: "void"
        },
        getAccountBalanceByPurpose: {
            args: {},
            ret: "AccountBalanceByPurposeResult"
        },
        getPrecatoryAnticipationContractTotal: {
            args: {
                filter: "PrecatoryAnticipationContractFilter"
            },
            ret: "uint"
        },
        getPrecatoryAnticipationContracts: {
            args: {
                filter: "PrecatoryAnticipationContractFilter",
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "PrecatoryAnticipationContractReport"
        },
        exportPrecatoryAnticipationContracts: {
            args: {
                filter: "PrecatoryAnticipationContractFilter"
            },
            ret: "bytes"
        },
        sendPushNotification: {
            args: {
                pushNotification: "PushNotificationRequestDto"
            },
            ret: "void"
        }
    },
    typeTable: {
        DBInvalidQueryData: {
            code: "string"
        },
        InvalidCredentialsData: {
            code: "string"
        },
        InvalidCredentialsCodeData: {
            code: "string"
        },
        InvalidParamData: {
            code: "string"
        },
        NotFoundCodeData: {
            code: "string"
        },
        InvalidOperationCodeData: {
            code: "string"
        },
        NotLoggedInData: {
            code: "string"
        },
        NotExpectedOperationData: {
            code: "string"
        },
        CancelReservationErrorData: {
            code: "string"
        },
        ForbiddenAccessErrorData: {
            code: "string"
        },
        MetadadosRequestErrorData: {
            code: "string"
        },
        ConsultMarginErrorData: {
            code: "string"
        },
        ApiTimeoutErrorData: {
            code: "string"
        },
        CabineIntegrationErrorData: {
            code: "string"
        },
        CabineRunningInTestEnviromentErrorData: {
            code: "string"
        },
        IntegromatErrorData: {
            code: "string",
            url: "string",
            contentBody: "json",
            responseData: "json"
        },
        EmptyLogData: {
            code: "string"
        },
        Address: {
            cep: "string",
            street: "string",
            number: "string",
            neighborhood: "string",
            city: "string",
            state: "string",
            complement: "string?"
        },
        BankAccount: {
            bankName: "string?",
            bankNumber: "string?",
            branchNumber: "string?",
            accountNumber: "string?",
            accountType: "AccountType?"
        },
        FinancialData: {
            userPaymentChoise: "UserPaymentChoise",
            bankAccount: "BankAccount?",
            pixKey: "string?"
        },
        DetailedInfo: {
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            cityOfBirth: "string",
            nationality: "string",
            civilState: "CivilState",
            gender: "Gender",
            address: "Address",
            residentialPhone: "string?",
            mobilePhone: "string",
            secondaryEmail: "string?",
            financialData: "FinancialData",
            pep: "bool?"
        },
        UserGeneral: {
            email: "string?",
            companyName: "string?",
            companyFantasyName: "string?"
        },
        User: {
            id: "string",
            codigoClienteOmie: "bigint?",
            name: "string",
            cpf: "string",
            salary: "int?",
            mobilePhone: "string?",
            companyId: "string?",
            dateOfBirth: "date?",
            admissionDate: "date?",
            companySector: "string?",
            companyPosition: "string?",
            detailedInfo: "DetailedInfo?",
            updatedAt: "datetime?",
            deleted: "bool?",
            addressStreet: "string?",
            addressNumber: "string?",
            addressCity: "string?",
            addressState: "string?",
            addressCep: "string?",
            acceptedTheTermsAt: "date?",
            grossSalary: "int?",
            externalId: "string?",
            availableMarginValue: "int?",
            usersCompaniesDeleted: "bool?",
            email: "string?",
            companyName: "string?",
            companyFantasyName: "string?"
        },
        UserInfo: {
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            cityOfBirth: "string",
            nationality: "string",
            civilState: "UserInfoCivilState",
            gender: "UserInfoGender",
            address: "Address",
            mobilePhone: "string",
            pep: "bool",
            email: "string",
            acceptedTheTermsAt: "datetime",
            externalId: "string",
            bankAccount: "BankAccount",
            dateOfBirth: "string"
        },
        PublicEmployee: {
            state: "string",
            city: "string?",
            modality: "Modality"
        },
        UserRegistrationInfo: {
            employeeType: "EmployeeType",
            cpf: "string",
            publicEmployee: "PublicEmployee?"
        },
        CompanyBasicInfo: {
            id: "string",
            name: "string",
            cnpj: "string"
        },
        CompaniesGroupBasicInfo: {
            id: "string",
            name: "string"
        },
        HREmployee: {
            id: "string",
            name: "string",
            email: "string",
            cpf: "string",
            companies: "CompanyBasicInfo[]?",
            companiesGroups: "CompaniesGroupBasicInfo[]?"
        },
        MonthAndYear: {
            month: "int",
            year: "int"
        },
        Definition: {
            id: "string",
            title: "string?",
            description: "string?"
        },
        Declaration: {
            id: "string",
            paragraphs: "string[]"
        },
        Clause: {
            id: "string",
            number: "int?",
            title: "string?",
            intro: "IntroClause",
            paragraphs: "string[]"
        },
        IntroClause: {
            title: "string?",
            subparagraphs: "string[]"
        },
        LoanRequest: {
            numberOfInstallments: "int",
            totalValue: "int",
            installmentValue: "int",
            reasonForLoanRequest: "string?"
        },
        SimulatedInstallments: {
            numberOfInstallments: "uint",
            value: "uint",
            highlight: "bool"
        },
        Creditor: {
            name: "string",
            email: "string",
            phone: "string",
            cnpj: "string",
            site: "string",
            address: "Address"
        },
        CreditorBankInfo: {
            bankNumber: "string?",
            bankName: "string?",
            agency: "string?",
            accountNumber: "string?",
            accountType: "string?"
        },
        StaticFields: {
            operationFinality: "string",
            additionalInformation: "string?",
            interestModality: "string",
            interestIndex: "string",
            interestCapitalizationFrequency: "string",
            formOfPayment: "string",
            yearBase: "string",
            orderCalculation: "string",
            resourcesReleaseForm: "string"
        },
        Individual: {
            name: "string",
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            companySector: "string",
            companyPosition: "string",
            birthDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            admissionDate: "string?",
            registration: "string",
            maritalStatus: "string",
            gender: "string",
            homePhone: "string",
            commercialPhone: "string",
            cellPhone: "string",
            address: "Address",
            email: "string"
        },
        BankReleaseInfo: {
            bankNumber: "string?",
            bankName: "string?",
            agency: "string?",
            accountNumber: "string?",
            accountType: "string?"
        },
        CreditInfoValue: {
            emittedDate: "string",
            creditDate: "string",
            paymentLocal: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            thirdPartyValue: "string",
            thirdPartyPercentage: "float",
            tedValue: "string"
        },
        CcbDataPreviewDynamicDataIndividualTimeOfService: {
            years: "int?",
            months: "int?"
        },
        CcbDataPreviewDynamicDataIndividual: {
            salary: "string",
            timeOfService: "CcbDataPreviewDynamicDataIndividualTimeOfService",
            name: "string",
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            companySector: "string",
            companyPosition: "string",
            birthDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            admissionDate: "string?",
            registration: "string",
            maritalStatus: "string",
            gender: "string",
            homePhone: "string",
            commercialPhone: "string",
            cellPhone: "string",
            address: "Address",
            email: "string"
        },
        CcbDataPreviewDynamicDataCompany: {
            socialName: "string",
            cnpj: "string",
            address: "Address",
            agreementNumber: "string"
        },
        CcbDataPreviewDynamicDataCreditInfo: {
            agreementId: "string",
            type: "string",
            structuringValue: "string",
            structuringRate: "string",
            insurance: "bool",
            insuranceType: "string",
            policyNumber: "string",
            loanTotalValue: "string",
            amountForEarlySettlementOtherFinancialInstitutions: "string",
            descriptionsOfPrepaidFinancialTransactions: "string",
            advanceSettlementPaymentMeansOther: "string",
            debtPortability: "string",
            originalCreditingInstitution: "string",
            originalContractNumber: "string",
            netValue: "string",
            numberOfInstallments: "string",
            installmentsValue: "string",
            lastInstallmentDue: "string",
            annualEffectiveCost: "string",
            prizePercentage: "int",
            custodyValue: "string",
            emittedDate: "string",
            creditDate: "string",
            paymentLocal: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            thirdPartyValue: "string",
            thirdPartyPercentage: "float",
            tedValue: "string"
        },
        CcbDataPreviewDynamicDataCreditReleaseInfo: {
            userPaymentChoise: "UserPaymentChoise",
            pixKey: "string?",
            favored: "string",
            favoredCPF: "string",
            bankNumber: "string?",
            bankName: "string?",
            agency: "string?",
            accountNumber: "string?",
            accountType: "string?"
        },
        CcbDataPreviewDynamicData: {
            staticFields: "StaticFields",
            ccbNumber: "string?",
            creditor: "Creditor",
            individual: "CcbDataPreviewDynamicDataIndividual",
            company: "CcbDataPreviewDynamicDataCompany",
            creditInfo: "CcbDataPreviewDynamicDataCreditInfo",
            creditReleaseInfo: "CcbDataPreviewDynamicDataCreditReleaseInfo"
        },
        CcbDataPreviewStaticData: {
            definitions: "Definition[]",
            clauses: "Clause[]"
        },
        CcbDataPreview: {
            dynamicData: "CcbDataPreviewDynamicData",
            staticData: "CcbDataPreviewStaticData"
        },
        Spouse: {
            spouseName: "string",
            spouseRg: "string",
            spouseCpf: "string"
        },
        PersonalGuaranteeAddress: {
            cep: "string",
            city: "string",
            address: "string",
            state: "string"
        },
        GeneralPersonalGuarantee: {
            name: "string",
            cpf: "string",
            personalDocumentType: "PersonalDocumentType",
            documentNumber: "string",
            dispatcher: "string",
            uf: "string",
            issuanceDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            address: "PersonalGuaranteeAddress",
            marriageRegime: "MarriageRegime?",
            spouse: "Spouse?",
            stableUnion: "bool?"
        },
        CcbPjParamsPersonalGuarantee: {
            civilState: "CivilState",
            name: "string",
            cpf: "string",
            personalDocumentType: "PersonalDocumentType",
            documentNumber: "string",
            dispatcher: "string",
            uf: "string",
            issuanceDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            address: "PersonalGuaranteeAddress",
            marriageRegime: "MarriageRegime?",
            spouse: "Spouse?",
            stableUnion: "bool?"
        },
        CcbDataPreviewPjDynamicDataPersonalGuarantee: {
            civilState: "string",
            name: "string",
            cpf: "string",
            personalDocumentType: "PersonalDocumentType",
            documentNumber: "string",
            dispatcher: "string",
            uf: "string",
            issuanceDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            address: "PersonalGuaranteeAddress",
            marriageRegime: "MarriageRegime?",
            spouse: "Spouse?",
            stableUnion: "bool?"
        },
        InstallmentPj: {
            number: "int",
            dueDate: "string",
            mainValue: "string",
            interestValue: "string",
            totalValue: "string",
            dueBalance: "string"
        },
        CcbDataPreviewPjDynamicDataStaticFields: {
            operationFinality: "string",
            additionalInformation: "string?",
            interestModality: "string",
            interestCapitalizationFrequency: "string",
            yearBase: "string",
            paymentLocal: "string"
        },
        CcbDataPreviewPjDynamicDataIssuer: {
            companyName: "string",
            cnpj: "string",
            address: "Address",
            bank: "BankReleaseInfo"
        },
        CcbDataPreviewPjDynamicDataOriginalCreditor: {
            name: "string",
            cnpj: "string",
            address: "Address"
        },
        CcbDataPreviewPjDynamicDataLoanData: {
            emittedDate: "string",
            creditDate: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            tedValue: "string",
            formOfPayment: "string",
            orderCalculation: "string",
            ccbNumber: "string?",
            resourcesReleaseForm: "string",
            amortizationTerm: "string",
            emissionDate: "string",
            registrationAndStructuringValue: "string",
            interestIndex: "string",
            installments: "InstallmentPj[]"
        },
        CcbDataPreviewPjDynamicData: {
            staticFields: "CcbDataPreviewPjDynamicDataStaticFields",
            issuer: "CcbDataPreviewPjDynamicDataIssuer",
            originalCreditor: "CcbDataPreviewPjDynamicDataOriginalCreditor",
            personalGuarantee: "CcbDataPreviewPjDynamicDataPersonalGuarantee[]",
            realGuaranteeNumber: "int",
            loanData: "CcbDataPreviewPjDynamicDataLoanData"
        },
        CcbDataPreviewPjStaticData: {
            declaration: "Declaration",
            clauses: "Clause[]"
        },
        CcbDataPreviewPj: {
            dynamicData: "CcbDataPreviewPjDynamicData",
            staticData: "CcbDataPreviewPjStaticData"
        },
        CcbPjParamsConditions: {
            resourcesReleaseForm: "string",
            formOfPayment: "PaymentMethod",
            orderCalculation: "OrderCalculationType",
            issuerBank: "BankReleaseInfo"
        },
        CcbPjParams: {
            simulationId: "uuid",
            emittedDate: "datetime",
            personalGuarantee: "CcbPjParamsPersonalGuarantee[]",
            realGuaranteeNumber: "int",
            conditions: "CcbPjParamsConditions",
            opinion: "string?"
        },
        TermsOfUseClauses: {
            id: "string?",
            section: "int?",
            title: "string?",
            paragraphs: "string[]?",
            level2: "Level2[]?"
        },
        TermsOfUse: {
            updatedAt: "string?",
            clauses: "TermsOfUseClauses[]?"
        },
        Level2: {
            subsection: "int?",
            title: "string?",
            level3: "Level3[]?"
        },
        Level3: {
            subsection: "int?",
            title: "string?",
            level4: "Level4[]?"
        },
        Level4: {
            subsection: "int?",
            title: "string?"
        },
        CompanyAddress: {
            cep: "string?",
            street: "string?",
            number: "string?",
            neighborhood: "string?",
            city: "string?",
            state: "string?",
            complement: "string?"
        },
        CompanySupplier: {
            id: "string?",
            name: "string?",
            phone: "string?"
        },
        CompanySupplierParams: {
            companyId: "uuid",
            id: "string?",
            name: "string?",
            phone: "string?"
        },
        CompanyFinancialInstitution: {
            id: "string?",
            name: "string?",
            phone: "string?",
            agency: "string?",
            account: "string?"
        },
        CompanyInfoGeneralGeneral: {
            cnpj: "string",
            stateSubscription: "string?",
            inscricaoMunicipal: "string?",
            socialName: "string",
            fantasyName: "string?"
        },
        CompanyInfoGeneralContact: {
            email: "string?",
            phone: "string?",
            otherContacts: "string?"
        },
        CompanyInfoGeneralChargeAddress: {
            sameAddress: "bool?",
            otherContact: "string?",
            address: "CompanyAddress?"
        },
        CompanyInfoGeneralPatrimonialInfo: {
            constitutionDate: "string?",
            lastStaturaryChangeDate: "string?",
            changeAmount: "int?",
            socialCapital: "bigint?",
            netWorth: "bigint?",
            netWorthReferenceDate: "string?",
            economicSector: "EconomicSector?",
            capitalOrigin: "CapitalOrigin?"
        },
        CompanyInfoGeneralFinancialData: {
            activity: "string?",
            employeesTotal: "int?",
            averageShoppingValue: "bigint?",
            averageRevenueValue: "bigint?",
            averageTimeToReceiveSales: "int?",
            utilizationOfInstalledCapacity: "int?",
            marketShare: "int?",
            hasBranches: "bool?",
            branchesAmount: "int?",
            branchesStates: "string?",
            hasExport: "bool?",
            hasImport: "bool?"
        },
        CompanyInfoGeneralDocumentLink: {
            token: "string?",
            dueDate: "string?",
            link: "string?"
        },
        CompanyInfoGeneral: {
            id: "string?",
            codigoClienteOmie: "bigint?",
            general: "CompanyInfoGeneralGeneral",
            address: "CompanyAddress",
            contact: "CompanyInfoGeneralContact",
            chargeAddress: "CompanyInfoGeneralChargeAddress",
            patrimonialInfo: "CompanyInfoGeneralPatrimonialInfo",
            financialData: "CompanyInfoGeneralFinancialData",
            additionalInfo: "string?",
            documentLink: "CompanyInfoGeneralDocumentLink?"
        },
        LegalResponsibleGeneral: {
            cpf: "string",
            companyId: "uuid?",
            name: "string?",
            rg: "string?",
            societyPercent: "int?",
            administrator: "bool?",
            isolated: "bool?",
            sendSignatureEmail: "bool?",
            createdAt: "string?",
            updatedAt: "string?",
            email: "string?"
        },
        LegalResponsible: {
            pep: "bool?",
            acceptedTheTermsAt: "string?",
            addressCep: "string?",
            addressStreet: "string?",
            addressNumber: "string?",
            addressNeighborhood: "string?",
            addressComplement: "string?",
            addressState: "string?",
            addressCity: "string?",
            ip: "string?",
            cpf: "string",
            companyId: "uuid?",
            name: "string?",
            rg: "string?",
            societyPercent: "int?",
            administrator: "bool?",
            isolated: "bool?",
            sendSignatureEmail: "bool?",
            createdAt: "string?",
            updatedAt: "string?",
            email: "string?"
        },
        CompanyInfoEmployees: {
            totalValuePayroll: "bigint?",
            payrollCloseDate: "int?",
            paymentDay: "int?",
            averageSalary: "int?",
            averagePermanenceTime: "int?",
            totalFiredEmployees: "int?"
        },
        CompanyInfoEmployessByAge: {
            between18And22: "int?",
            between23And30: "int?",
            between31And40: "int?",
            between41And50: "int?",
            between50AndPlus: "int?"
        },
        CompanyInfoEmployeesByTime: {
            untilOneYear: "int?",
            oneTwoYears: "int?",
            twoThreeYears: "int?",
            threePlusYears: "int?"
        },
        CompanyInfo: {
            employees: "CompanyInfoEmployees",
            employessByAge: "CompanyInfoEmployessByAge",
            employeesByTime: "CompanyInfoEmployeesByTime",
            suppliers: "CompanySupplier[]",
            financialInstitutions: "CompanyFinancialInstitution[]",
            legalResponsibles: "LegalResponsible[]",
            id: "string?",
            codigoClienteOmie: "bigint?",
            general: "CompanyInfoGeneralGeneral",
            address: "CompanyAddress",
            contact: "CompanyInfoGeneralContact",
            chargeAddress: "CompanyInfoGeneralChargeAddress",
            patrimonialInfo: "CompanyInfoGeneralPatrimonialInfo",
            financialData: "CompanyInfoGeneralFinancialData",
            additionalInfo: "string?",
            documentLink: "CompanyInfoGeneralDocumentLink?"
        },
        FinancialInstitution: {
            companyId: "uuid?",
            bankNumber: "string?",
            id: "string?",
            name: "string?",
            phone: "string?",
            agency: "string?",
            account: "string?"
        },
        CompanyInfoPj: {
            legalResponsibles: "LegalResponsible[]?",
            financialInstitutions: "FinancialInstitution[]?",
            suppliers: "CompanySupplierParams[]?",
            id: "string?",
            codigoClienteOmie: "bigint?",
            general: "CompanyInfoGeneralGeneral",
            address: "CompanyAddress",
            contact: "CompanyInfoGeneralContact",
            chargeAddress: "CompanyInfoGeneralChargeAddress",
            patrimonialInfo: "CompanyInfoGeneralPatrimonialInfo",
            financialData: "CompanyInfoGeneralFinancialData",
            additionalInfo: "string?",
            documentLink: "CompanyInfoGeneralDocumentLink?"
        },
        ApiSecret: {
            id: "uuid",
            apiKey: "string",
            apiSecret: "string",
            profileId: "uuid?",
            companiesGroupId: "uuid?"
        },
        FgtsParameters: {
            id: "uuid",
            interestRate: "int",
            structuringRate: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            antecipationPeriodsNumber: "int"
        },
        SimulatedLoanSummaryAdditionalInfo: {
            monthlyInterestRate: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            additionalSeguroPrestamistaValue: "string",
            additionalSeguroPrestamistaValuePerInstallment: "string",
            insuranceMonthlyGrossPrize: "string"
        },
        SimulatedLoanSummary: {
            totalValue: "string",
            loanTotalValue: "string",
            numberOfInstallments: "string",
            installmentsValue: "string",
            taxesAndRates: "string",
            additionalInfo: "SimulatedLoanSummaryAdditionalInfo"
        },
        BrokerInfo: {
            name: "string",
            cnpjCpf: "string"
        },
        Stipulant: {
            name: "string",
            cnpj: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataDataForInsurer: {
            groupAndBranch: "string",
            policyNumber: "string",
            subgroupCode: "string",
            branchNumber: "string",
            stipulant: "Stipulant",
            subStipulant: "Stipulant"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataCertificateData: {
            adhesionProposalNumber: "string?",
            emittedDatetime: "string",
            number: "string?",
            initialDate: "string",
            finalDate: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredUserData: {
            name: "string",
            birthDate: "string",
            cpf: "string",
            rgRne: "string",
            dispatcher: "string",
            rgIssuanceDate: "string",
            gender: "string",
            nationality: "string",
            phoneDDD: "string",
            phoneNumber: "string",
            pep: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredCapitalAndPrize: {
            obligationValue: "string",
            insuranceMonthlyNetPrize: "string",
            fixedIOF: "string",
            insuranceMonthlyGrossPrize: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataBrokerData: {
            broker: "BrokerInfo"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataStaticFields: {
            insuranceCovers: "string",
            capitalInsuredDescription: "string",
            insuredCosting: "string",
            pepDescription: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicData: {
            dataForInsurer: "SeguroPrestamistaPolicyDataPreviewDynamicDataDataForInsurer",
            certificateData: "SeguroPrestamistaPolicyDataPreviewDynamicDataCertificateData",
            insuredUserData: "SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredUserData",
            insuredCapitalAndPrize: "SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredCapitalAndPrize",
            brokerData: "SeguroPrestamistaPolicyDataPreviewDynamicDataBrokerData",
            staticFields: "SeguroPrestamistaPolicyDataPreviewDynamicDataStaticFields"
        },
        SeguroPrestamistaPolicyDataPreviewStaticData: {
            definitions: "Definition[]?",
            clauses: "Clause[]"
        },
        SeguroPrestamistaPolicyDataPreview: {
            dynamicData: "SeguroPrestamistaPolicyDataPreviewDynamicData",
            staticData: "SeguroPrestamistaPolicyDataPreviewStaticData"
        },
        Amortization: {
            type: "AmortizationType",
            date: "string",
            installments: "uint?",
            value: "uint?",
            order: "AmortizationOrder"
        },
        AmortizationSimulation: {
            balance: "uint",
            payValue: "uint",
            remainingValue: "uint",
            remainingInstallments: "uint?",
            nextExpirationDate: "date?",
            nextInstallmentValue: "uint?",
            lastExpirationDate: "date?",
            lastInstallmentValue: "uint?"
        },
        AssociatedLoanProposal: {
            id: "string",
            name: "string",
            companyName: "string",
            cpf: "string",
            status: "LoanProposalPFStatus",
            summary: "SimulatedLoanSummary",
            createdAt: "string",
            proposalNumber: "string",
            ccbNumber: "string?",
            corbanResponsible: "string",
            loanStatus: "string?",
            userCompanyId: "string"
        },
        BrazilState: {
            cCodigo: "string",
            cDescricao: "string",
            cSigla: "string"
        },
        Link: {
            id: "uuid",
            companyId: "uuid",
            legalResponsibleCompanyId: "uuid?",
            legalResponsibleCpf: "string?",
            token: "string",
            dueDate: "string"
        },
        LinkParams: {
            companyId: "uuid",
            legalResponsibleCompanyId: "uuid?",
            legalResponsibleCpf: "string?",
            token: "string"
        },
        LinkReturn: {
            token: "string",
            link: "string",
            dueDate: "string"
        },
        LoanFgtsRequest: {
            ccbValue: "int",
            antecipationPeriodsNumber: "int"
        },
        CcbFgtsDataPreviewDynamicDataStaticFields: {
            operationFinality: "string",
            additionalInformation: "string?",
            interestModality: "string",
            interestIndex: "string",
            interestCapitalizationFrequency: "string",
            formOfPayment: "string",
            yearBase: "string",
            orderCalculation: "string",
            resourcesReleaseForm: "string"
        },
        CcbFgtsDataPreviewDynamicDataCreditInfo: {
            interestRatePerDay: "string",
            registrationAndStructuringValue: "string",
            antecipationPeriodsNumber: "string",
            totalToBeBlockedFromFGTS: "string",
            emittedDate: "string",
            creditDate: "string",
            paymentLocal: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            thirdPartyValue: "string",
            thirdPartyPercentage: "float",
            tedValue: "string"
        },
        CcbFgtsDataPreviewDynamicDataPaymentsInfo: {
            installmentDate: "string",
            installmentValue: "string",
            installmentPresentValue: "string",
            installmentNumber: "int"
        },
        CcbFgtsDataPreviewDynamicData: {
            staticFields: "CcbFgtsDataPreviewDynamicDataStaticFields",
            ccbNumber: "string?",
            creditor: "Creditor",
            individual: "Individual",
            creditInfo: "CcbFgtsDataPreviewDynamicDataCreditInfo",
            creditReleaseInfo: "BankReleaseInfo",
            paymentsInfo: "CcbFgtsDataPreviewDynamicDataPaymentsInfo[]"
        },
        CcbFgtsDataPreviewStaticData: {
            definitions: "Definition[]",
            clauses: "Clause[]"
        },
        CcbFgtsDataPreview: {
            dynamicData: "CcbFgtsDataPreviewDynamicData",
            staticData: "CcbFgtsDataPreviewStaticData"
        },
        WithdrawalAntecipated: {
            date: "string",
            value: "int",
            presentValue: "int?"
        },
        BirthdayWithdrawalGeneral: {
            id: "uuid",
            firstAnticipationWithdrawalDate: "string",
            lastAnticipationWithdrawalDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedPeriodsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            totalBlockedFromFgts: "int",
            withdrawalsAntecipated: "WithdrawalAntecipated[]"
        },
        BirthdayWithdrawal: {
            interestRatePerDay: "float",
            interestRatePerYear: "float",
            financedIof: "int",
            fixedIof: "int",
            totalIof: "int",
            structuringRate: "int",
            structuringValue: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            creditDate: "string",
            status: "BirthdayWithdrawalStatus",
            id: "uuid",
            firstAnticipationWithdrawalDate: "string",
            lastAnticipationWithdrawalDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedPeriodsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            totalBlockedFromFgts: "int",
            withdrawalsAntecipated: "WithdrawalAntecipated[]"
        },
        File: {
            name: "string",
            url: "string",
            fileId: "string?"
        },
        FileWithoutId: {
            name: "string",
            url: "string"
        },
        DocumentFromDb: {
            id: "uuid",
            name: "string",
            description: "string",
            file: "FileWithoutId?"
        },
        Document: {
            id: "uuid",
            name: "string",
            description: "string",
            file: "File?"
        },
        DocumentGroup: {
            name: "string",
            entityId: "string",
            type: "DocumentGroupType",
            documents: "Document[]"
        },
        DocumentGroupList: {
            name: "string",
            type: "DocumentType",
            documentsGroups: "DocumentGroup[]"
        },
        ChargeFilter: {
            person: "ReportFilterPerson?",
            name: "string?",
            type: "ChargeFilterLoanType?",
            cpfCnpj: "string?",
            draweeSector: "string?",
            assigneCompanyFromCession: "string?",
            paid: "bool?"
        },
        ChargeInfo: {
            entityId: "string",
            name: "string",
            cpf: "string?",
            cnpj: "string?",
            type: "ChargeFilterLoanType",
            value: "int",
            noteId: "string?",
            noteContent: "string?",
            paid: "bool",
            dueDate: "string?",
            assigneCompanyFromCession: "string?",
            ccbs: "Ccb[]"
        },
        ChargeReport: {
            currentPage: "int",
            rowsPerPage: "int",
            chargeInfos: "ChargeInfo[]"
        },
        ChargeNote: {
            id: "string?",
            content: "string",
            entityId: "string",
            dueDate: "string",
            assigneCompany: "string"
        },
        ChargeSummary: {
            totalAmount: "int",
            totalItems: "int"
        },
        Ccb: {
            number: "string",
            loanId: "string?",
            draweeName: "string",
            draweeCpf: "string",
            ccbValue: "string",
            numberOfInstallments: "int",
            installmentValue: "string",
            chargeValueForInstallment: "int",
            draweeSector: "string?",
            extendedDueDate: "string?",
            extendedReason: "string?"
        },
        ProposalFilter: {
            status: "ProposalStatus?"
        },
        Proposals: {
            externalProposalId: "int",
            status: "ProposalStatus",
            createdAt: "string",
            cnpj: "string",
            postulantName: "string?",
            financingAmount: "int",
            billingAmount: "bigint",
            constitutionTimeInMonths: "int",
            taxRegime: "TaxRegime?",
            numberOfEmployees: "int?",
            offeredGuarantees: "OfferedGuarantees[]",
            mainCnae: "string",
            secundariesCnaes: "string[]",
            addressCep: "string",
            goal: "string",
            name: "string",
            phone: "string",
            email: "string",
            cpfIndividualEntrepreneur: "string?",
            dateOfBirth: "string?",
            meiProfileIdentificator: "bool",
            payload: "UpdateProposalRequestParams?",
            updatedAt: "string?"
        },
        ProposalsPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            proposals: "Proposals[]"
        },
        UpdateProposalRequestParams: {
            externalProposalId: "int",
            status: "ProposalStatus?",
            guaranteesType: "GuaranteesType?",
            financingAmount: "float?",
            interestRatePerMonth: "float?",
            discountRate: "float?",
            operationDeadline: "int?",
            advanceDeadline: "int?",
            contractDate: "datetime?",
            updatedAt: "datetime?",
            reasonProposalStatus: "ReasonProposalStatus?",
            acceptedGuarantees: "AcceptedGuarantees[]?"
        },
        PrecatoryAnticipationInstallmentAnticipated: {
            date: "string",
            value: "int",
            presentValue: "int",
            netValue: "int",
            discountRate: "float"
        },
        PrecatoryAnticipationInstallmentSummary: {
            date: "string",
            value: "string",
            presentValue: "string",
            netValue: "string",
            discountRate: "string"
        },
        PrecatoryAnticipationGeneral: {
            id: "uuid",
            firstAnticipationPeriodDate: "string",
            lastAnticipationPeriodDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedInstallmentsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            blockedTotalValue: "int",
            installmentsAnticipated: "PrecatoryAnticipationInstallmentAnticipated[]"
        },
        PrecatoryAnticipationInfo: {
            interestRatePerDay: "float",
            interestRatePerYear: "float",
            financedIof: "int",
            fixedIof: "int",
            totalIof: "int",
            structuringRate: "int",
            structuringValue: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            creditDate: "string",
            status: "PrecatoryAnticipationStatus",
            id: "uuid",
            firstAnticipationPeriodDate: "string",
            lastAnticipationPeriodDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedInstallmentsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            blockedTotalValue: "int",
            installmentsAnticipated: "PrecatoryAnticipationInstallmentAnticipated[]"
        },
        PrecatoryAnticipation: {
            interestRatePerDay: "float",
            interestRatePerYear: "float",
            financedIof: "int",
            fixedIof: "int",
            totalIof: "int",
            structuringRate: "int",
            structuringValue: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            creditDate: "string",
            status: "PrecatoryAnticipationStatus",
            id: "uuid",
            firstAnticipationPeriodDate: "string",
            lastAnticipationPeriodDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedInstallmentsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            blockedTotalValue: "int",
            installmentsAnticipated: "PrecatoryAnticipationInstallmentAnticipated[]"
        },
        Consideration: {
            id: "string",
            description: "string?"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicDataStaticFields: {
            operationFinality: "string",
            additionalInformation: "string?",
            interestModality: "string",
            interestIndex: "string",
            interestCapitalizationFrequency: "string",
            formOfPayment: "string",
            yearBase: "string",
            orderCalculation: "string",
            resourcesReleaseForm: "string"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicDataCreditor: {
            accountNumber: "string",
            agency: "string",
            accountType: "string",
            bankName: "string",
            bankNumber: "string",
            name: "string",
            email: "string",
            phone: "string",
            cnpj: "string",
            site: "string",
            address: "Address"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicDataIndividual: {
            stateRegistration: "string",
            name: "string",
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            companySector: "string",
            companyPosition: "string",
            birthDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            admissionDate: "string?",
            registration: "string",
            maritalStatus: "string",
            gender: "string",
            homePhone: "string",
            commercialPhone: "string",
            cellPhone: "string",
            address: "Address",
            email: "string"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicDataInstallmentsDiscountRate: {
            firstInstallmentDiscountRate: "string?",
            SecondInstallmentDiscountRate: "string?",
            thirdInstallmentDiscountRate: "string?"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicDataCreditInfo: {
            interestRatePerDay: "string",
            registrationAndStructuringValue: "string",
            anticipatedInstallmentsNumber: "string",
            blockedTotalValue: "string",
            emittedDate: "string",
            creditDate: "string",
            paymentLocal: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            thirdPartyValue: "string",
            thirdPartyPercentage: "float",
            tedValue: "string"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicDataPaymentsInfo: {
            installmentDate: "string",
            installmentValue: "string",
            installmentPresentValue: "string",
            installmentNetValue: "string",
            installmentNumber: "int",
            installmentDiscountRate: "float"
        },
        CcbPrecatoryAnticipationDataPreviewDynamicData: {
            staticFields: "CcbPrecatoryAnticipationDataPreviewDynamicDataStaticFields",
            ccbNumber: "string?",
            creditor: "CcbPrecatoryAnticipationDataPreviewDynamicDataCreditor",
            individual: "CcbPrecatoryAnticipationDataPreviewDynamicDataIndividual",
            installmentsDiscountRate: "CcbPrecatoryAnticipationDataPreviewDynamicDataInstallmentsDiscountRate",
            creditInfo: "CcbPrecatoryAnticipationDataPreviewDynamicDataCreditInfo",
            creditReleaseInfo: "BankReleaseInfo",
            paymentsInfo: "CcbPrecatoryAnticipationDataPreviewDynamicDataPaymentsInfo[]"
        },
        CcbPrecatoryAnticipationDataPreviewStaticData: {
            considerations: "Consideration[]",
            clauses: "Clause[]"
        },
        CcbPrecatoryAnticipationDataPreview: {
            dynamicData: "CcbPrecatoryAnticipationDataPreviewDynamicData",
            staticData: "CcbPrecatoryAnticipationDataPreviewStaticData"
        },
        PrecatoryAnticipationInstallment: {
            date: "string",
            value: "int"
        },
        UserLoanCessionStatus: {
            status: "GivenStatus?",
            assigneCompany: "string?"
        },
        UserLoanInfo: {
            isAllowedToMakeLoanRequest: "bool",
            notAllowedMessage: "string?",
            shouldDisplayWarningOnLoanRequest: "bool",
            loanRequestWarningMessage: "string?",
            userIsEmployed: "bool",
            isAllowedToSeguroPrestamistaRequest: "bool",
            availableValue: "uint?",
            minimumValueAllowedByAgreement: "uint?",
            loans: "UserLoanSummary[]",
            isAllowedSalaryAnticipation: "bool"
        },
        UserLoanInfoForCompany: {
            name: "string",
            userId: "string",
            isAllowedToMakeLoanRequest: "bool",
            notAllowedMessage: "string?",
            shouldDisplayWarningOnLoanRequest: "bool",
            loanRequestWarningMessage: "string?",
            userIsEmployed: "bool",
            isAllowedToSeguroPrestamistaRequest: "bool",
            availableValue: "uint?",
            minimumValueAllowedByAgreement: "uint?",
            loans: "UserLoanSummary[]",
            isAllowedSalaryAnticipation: "bool"
        },
        UserLoanSummary: {
            loanId: "string",
            companyName: "string",
            companyFantasyName: "string?",
            status: "LoanStatus?",
            statusV3: "LoanStatusV3?",
            statusV2: "LoanStatusV2?",
            ccbNumber: "string?",
            installmentValue: "uint",
            nextPayDate: "string",
            remainingInstallments: "uint",
            givenStatus: "GivenStatus?",
            assigneCompany: "string?",
            loanTotalValue: "uint",
            totalValue: "uint",
            monthlyInterestRate: "float",
            monthlyEffectiveCost: "float",
            balance: "uint?",
            totalIof: "float",
            structuringValue: "float",
            allInstallments: "AllInstallments[]?",
            thirdPartyValue: "int",
            responsible: "ResponsibleForPayment"
        },
        LatestCession: {
            status: "GivenStatus?",
            assigneCompany: "string?"
        },
        AllInstallments: {
            number: "int",
            status: "InstallmentStatus",
            statusV2: "InstallmentStatusV2",
            isOverdue: "bool?",
            dueDate: "string",
            paidValue: "uint?",
            paidAt: "string?"
        },
        AdminGeneral: {
            name: "string",
            email: "string",
            cpf: "string?"
        },
        AdminParams: {
            profileId: "uuid?",
            name: "string",
            email: "string",
            cpf: "string?"
        },
        Admin: {
            id: "string",
            profileId: "uuid?",
            name: "string",
            email: "string",
            cpf: "string?"
        },
        AdminWithAccessModules: {
            accessModules: "ProfileModule[]",
            id: "string",
            profileId: "uuid?",
            name: "string",
            email: "string",
            cpf: "string?"
        },
        SignatureGeneralParams: {
            email: "string",
            name: "string",
            cpf: "string?",
            changedAt: "datetime",
            ip: "string",
            hash: "string"
        },
        Signature: {
            status: "LoanStatusReport",
            email: "string",
            name: "string",
            cpf: "string?",
            changedAt: "datetime",
            ip: "string",
            hash: "string"
        },
        BirthdayWithdrawalSignature: {
            status: "LoanFgtsStatus",
            email: "string",
            name: "string",
            cpf: "string?",
            changedAt: "datetime",
            ip: "string",
            hash: "string"
        },
        ConflictSalaryChange: {
            from: "int",
            to: "int"
        },
        ConflictMonthlyPayrollDiscountChange: {
            from: "int",
            to: "int"
        },
        Conflict: {
            userId: "string?",
            userName: "string",
            userNewName: "string?",
            change: "ConflictStatus",
            salaryChange: "ConflictSalaryChange?",
            hasActiveLoan: "bool?",
            monthlyPayrollDiscountChange: "ConflictMonthlyPayrollDiscountChange?"
        },
        FiringsInfo: {
            userId: "string",
            resignationDate: "string?",
            rescissionValue: "int?",
            discountedValueOverride: "int?",
            rescissionPaymentDate: "string?"
        },
        FiringsInfoInDB: {
            discountedValue: "int?",
            userDueBalance: "int?",
            userId: "string",
            resignationDate: "string?",
            rescissionValue: "int?",
            discountedValueOverride: "int?",
            rescissionPaymentDate: "string?"
        },
        InssRemovalsInfo: {
            userId: "string",
            date: "string?"
        },
        EmployeeFiredDetails: {
            id: "string",
            name: "string",
            firstName: "string",
            cpf: "string",
            email: "string?",
            phoneNumber: "string?",
            activeLoans: "int",
            resignationDate: "string?",
            rescissionValue: "int?",
            rescissionPaymentDate: "string?",
            discountedValue: "int?",
            discountedValueOverride: "int?",
            userDueBalance: "int?",
            assigneCompanyFromCession: "string"
        },
        Agreement: {
            id: "string?",
            active: "bool",
            minWorkTime: "int",
            maxLoanInSalaries: "int",
            maxPercentAllowed: "int",
            maxInterestRate: "int",
            minInterestRate: "int",
            maximumInstallments: "int",
            minimumLoanAmount: "int",
            structuringRate: "int",
            dueDate: "string",
            filename: "string?",
            createdAt: "datetime?",
            tedValue: "int",
            custodyValue: "int",
            thirdPartyValue: "int",
            agreementNumber: "string?",
            minStructuringRate: "int?",
            denyParallelLoans: "bool",
            extendedMaximumInstallments: "int?",
            minWorkTimeForTermExtension: "int?",
            creditCalculationAlgorithm: "CreditCalculationAlgorithm"
        },
        LoanRequestsCreditInfo: {
            totalAmount: "int",
            ccbTotalValue: "string",
            emittedDate: "string",
            netValueCreditedToIssuer: "int",
            numberOfInstallments: "int",
            installmentsValue: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            monthlyEffectiveCost: "string",
            predictedValue: "int?",
            predictedInstallments: "int?",
            balance: "int?"
        },
        LoanRequests: {
            id: "string",
            companyName: "string",
            admissionDate: "string?",
            salary: "int",
            percentage: "float",
            creditInfo: "LoanRequestsCreditInfo",
            identityValidationReportInfo: "IdentityValidationReportInfo?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        LoanRequestsPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            requests: "LoanRequests[]"
        },
        LoanRequestFilter: {
            cpf: "string?",
            companyName: "string?"
        },
        NewHREmployee: {
            name: "string",
            cpf: "string",
            email: "string"
        },
        HrEmployeesPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            hrEmployees: "HREmployee[]?"
        },
        ContractReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            contracts: "Contract[]"
        },
        ContractCessionInfo: {
            assigneCompany: "string",
            installments: "date[]"
        },
        UserForReportGeneralData: {
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        Contract: {
            id: "string",
            companyName: "string",
            isOverdue: "bool?",
            inssAway: "bool?",
            userDeleted: "bool?",
            status: "ContractStatus",
            responsible: "ResponsibleForPayment",
            ccbId: "string?",
            ccbNumber: "string?",
            ccbLink: "string?",
            totalValue: "uint",
            totalInstallments: "uint",
            remainingValue: "uint?",
            paidValue: "uint?",
            remainingInstallments: "uint?",
            signDate: "date?",
            requestDate: "date",
            cessionInfo: "ContractCessionInfo?",
            overdueInstallments: "date[]?",
            remainingInstallmentsNumber: "int[]?",
            balance: "uint?",
            salary: "uint",
            percentage: "float",
            tedStatus: "ContractTedStatus?",
            seguroPrestamistaPolicyLink: "string?",
            companiesGroups: "CompaniesGroupBasicInfo[]?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        ContractFilterDate: {
            from: "date?",
            to: "date?"
        },
        ContractFilter: {
            name: "string?",
            cpf: "string?",
            companyName: "string?",
            isOverdue: "bool?",
            status: "string[]?",
            date: "ContractFilterDate",
            person: "ReportFilterPerson?",
            tedStatus: "ContractTedStatus?",
            ccbNumber: "string?",
            companyGroupName: "string?"
        },
        Cession: {
            id: "string",
            assigneCompany: "string",
            status: "CessionStatus",
            cessionDate: "string?",
            cpf: "string",
            userName: "string",
            company: "string",
            ccbNumber: "string",
            ccbId: "string",
            ccbLink: "string?",
            installments: "int[]",
            cessionValue: "int?",
            balance: "int",
            loanId: "string",
            repurchasedInstallments: "int[]",
            companiesGroups: "CompaniesGroupBasicInfo[]?"
        },
        CessionFilterDate: {
            from: "date?",
            to: "date?"
        },
        CessionFilter: {
            userName: "string?",
            cpf: "string?",
            assignCompany: "string?",
            status: "CessionStatus?",
            date: "CessionFilterDate",
            ccbNumber: "string?",
            company: "string?",
            companyGroupName: "string?"
        },
        CessionPaginated: {
            page: "int",
            rowsPerPage: "int",
            cessions: "Cession[]"
        },
        CessionConfirm: {
            cessionId: "string",
            cessionDate: "string",
            cessionValue: "int"
        },
        InstallmentDetails: {
            dctoId: "string",
            dueDate: "string",
            faceValue: "string",
            optionalDiscount: "string",
            limitDate: "string",
            daysDue: "int",
            discount: "string",
            installmentPresentValue: "string",
            draweeName: "string",
            operationType: "string",
            value: "string"
        },
        DetailedCcbReport: {
            installmentsDetails: "InstallmentDetails[]"
        },
        HREmployeeFilter: {
            name: "string?",
            companyName: "string?"
        },
        AssociateHrEmployeeCompaniesGroupParams: {
            companiesGroupId: "uuid",
            related: "bool"
        },
        HREmployeeParams: {
            id: "uuid?",
            name: "string",
            email: "string",
            cpf: "string",
            companiesIds: "string[]",
            companiesGroups: "AssociateHrEmployeeCompaniesGroupParams[]"
        },
        IdentityValidationReportInfoWithoutMatrizName: {
            reportLink: "string?",
            reportNumber: "string?"
        },
        IdentityValidationReportInfo: {
            reportMatriz: "string?",
            reportLink: "string?",
            reportNumber: "string?"
        },
        CompanyRequestDto: {
            cnpj: "string",
            socialReason: "string"
        },
        GetCompanyResponse: {
            cnpj: "string",
            name: "string"
        },
        GetCompanyEmployeeInfoResponse: {
            id: "uuid?",
            email: "string?",
            name: "string?",
            status: "UserStatus",
            hasExternalId: "bool?",
            salary: "int?"
        },
        AccountDigitalCompanyDataGeneral: {
            cnpj: "string",
            name: "string"
        },
        AccountDigitalCompanyDataContact: {
            email: "string?",
            phone: "string?"
        },
        AccountDigitalCompanyDataChargeAddress: {
            sameAddress: "bool?",
            otherContact: "string?",
            address: "CompanyAddress?"
        },
        AccountDigitalCompanyDataPatrimonialInfo: {
            lastStaturaryChangeDate: "date?",
            netWorth: "bigint",
            activity: "string"
        },
        AccountDigitalCompanyData: {
            general: "AccountDigitalCompanyDataGeneral",
            contact: "AccountDigitalCompanyDataContact",
            address: "CompanyAddress",
            chargeAddress: "AccountDigitalCompanyDataChargeAddress",
            patrimonialInfo: "AccountDigitalCompanyDataPatrimonialInfo",
            socialContract: "AccountFile?",
            annualBilling: "AccountFile?",
            conditionCertificateFileMEI: "AccountFile?",
            recordOfLastPollFile: "AccountFile?",
            docShortReportFile: "AccountFile?",
            cpfRgFile: "AccountFile",
            socialStatuteFileSA: "AccountFile?",
            representativeAddressFile: "AccountFile?",
            representativeProcurationFile: "AccountFile?",
            legalResponsible: "LegalResponsible"
        },
        AccountFile: {
            id: "string",
            name: "string"
        },
        RepresentativeData: {
            fullName: "string?",
            email: "string?",
            phone: "string?",
            birthDate: "string?",
            cpf: "string?",
            socialName: "string?"
        },
        ApproversSocialReasons: {
            id: "string?",
            name: "string?"
        },
        GetApproversResult: {
            id: "string",
            cnpj: "string",
            representativeData: "RepresentativeData?",
            socialReason: "string"
        },
        GetMoversResult: {
            id: "string",
            cnpj: "string",
            representativeData: "RepresentativeData?",
            socialReason: "string",
            approversSocialReasons: "ApproversSocialReasons[]?"
        },
        CompanyQuery: {
            cnpj: "string?",
            companyName: "string?"
        },
        IdList: {
            id: "string"
        },
        CreateMoverCompanyDto: {
            cnpj: "string",
            companyName: "string",
            accessPassword: "string",
            approverCompanies: "IdList[]",
            representative: "EscrowRepresentativeCompanyDto"
        },
        CreateApproverCompanyDto: {
            cnpj: "string",
            companyName: "string",
            accessPassword: "string",
            representative: "EscrowRepresentativeCompanyDto"
        },
        EscrowRepresentativeCompanyDto: {
            birthDate: "string",
            cpf: "string",
            email: "string",
            fullName: "string",
            phoneNumber: "string",
            transactionalPassword: "string",
            socialName: "string?"
        },
        CompanyBasicInfoPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            companies: "CompanyBasicInfo[]"
        },
        CompanyRawData: {
            id: "string",
            createdAt: "datetime",
            updatedAt: "datetime?",
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            addressCep: "string",
            addressStreet: "string",
            addressNumber: "string",
            addressNeighborhood: "string",
            addressCity: "string",
            addressState: "string",
            addressComplement: "string?",
            constitutionDate: "datetime?"
        },
        CompanyFilter: {
            name: "string?",
            fantasyName: "string?",
            cnpj: "string?"
        },
        RegenerateLoanCCBParams: {
            loanId: "string"
        },
        ReportUserGeneralData: {
            id: "string",
            companyName: "string?",
            hiringSituation: "HiringSituation",
            status: "UserStatus",
            suspensionReason: "string?",
            grossSalary: "int?",
            salary: "int?",
            admissionDate: "string?",
            monthlyPayrollDiscountWithOtherInstitutions: "int?",
            inssAway: "bool?",
            balance: "int?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        UserForReport: {
            identityValidationReportInfo: "IdentityValidationReportInfoWithoutMatrizName?",
            id: "string",
            companyName: "string?",
            hiringSituation: "HiringSituation",
            status: "UserStatus",
            suspensionReason: "string?",
            grossSalary: "int?",
            salary: "int?",
            admissionDate: "string?",
            monthlyPayrollDiscountWithOtherInstitutions: "int?",
            inssAway: "bool?",
            balance: "int?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        UserForReportDB: {
            reportNumber: "string?",
            id: "string",
            companyName: "string?",
            hiringSituation: "HiringSituation",
            status: "UserStatus",
            suspensionReason: "string?",
            grossSalary: "int?",
            salary: "int?",
            admissionDate: "string?",
            monthlyPayrollDiscountWithOtherInstitutions: "int?",
            inssAway: "bool?",
            balance: "int?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        UserReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            users: "UserForReport[]"
        },
        UserReportFilter: {
            name: "string?",
            cpf: "string?",
            companyName: "string?",
            hiringSituation: "HiringSituation?",
            status: "UserStatus[]?"
        },
        SuspendUserParams: {
            userId: "string",
            reason: "string"
        },
        UserSuspension: {
            suspensionDate: "datetime",
            reason: "string",
            reactivationDate: "datetime?"
        },
        ChangeUserContact: {
            email: "string?",
            mobilePhone: "string?"
        },
        UpdateUserContactRequestDto: {
            adminId: "string",
            userId: "string",
            cpf: "string",
            email: "string?",
            phone: "string?"
        },
        CloseDigitalAccount: {
            cpf: "string",
            requestId: "string",
            closeAccountReason: "string"
        },
        MessageStatusChange: {
            messageId: "string",
            status: "ContractTedStatus",
            requestIdentifier: "string"
        },
        ProfileParams: {
            title: "string",
            description: "string?",
            type: "ProfileType"
        },
        Profile: {
            id: "uuid",
            title: "string",
            description: "string?",
            type: "ProfileType"
        },
        ProfilePaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            profiles: "Profile[]"
        },
        ProfileAdmins: {
            activated: "bool",
            id: "string",
            profileId: "uuid?",
            name: "string",
            email: "string",
            cpf: "string?"
        },
        ProfileRoles: {
            activated: "bool",
            id: "uuid",
            title: "string",
            description: "string?"
        },
        AssociateProfile: {
            id: "uuid",
            activated: "bool?"
        },
        ProfileCompaniesGroup: {
            id: "uuid",
            name: "string",
            apiSecretId: "uuid",
            activated: "bool",
            companies: "CompanyBasicInfo[]"
        },
        AssociateAdminsProfileParams: {
            profileId: "uuid",
            admins: "AssociateProfile[]"
        },
        AssociateRolesProfileParams: {
            profileId: "uuid",
            roles: "AssociateProfile[]"
        },
        CompaniesGroupWithApiSecretProfileAssociable: {
            apiSecretId: "uuid",
            id: "uuid",
            activated: "bool?"
        },
        AssociateCompaniesGroupsProfileParams: {
            profileId: "uuid",
            companiesGroupProfileAssociable: "CompaniesGroupWithApiSecretProfileAssociable[]"
        },
        ProfileFilter: {
            name: "string?",
            type: "ProfileType?"
        },
        Roles: {
            id: "uuid",
            title: "string",
            description: "string?"
        },
        AdminWithProfile: {
            profile: "Profile?",
            id: "string",
            profileId: "uuid?",
            name: "string",
            email: "string",
            cpf: "string?"
        },
        AdminPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            admins: "AdminWithProfile[]?"
        },
        AdminFilter: {
            name: "string?",
            profileId: "string?"
        },
        LegalResponsibleSignature: {
            id: "uuid",
            status: "SignatureStatus?",
            pep: "bool?",
            acceptedTheTermsAt: "string?",
            createdAt: "string",
            updatedAt: "string?",
            token: "string?",
            expirationDate: "string?"
        },
        LegalResponsiblesWithSignatures: {
            name: "string",
            cpf: "string",
            email: "string",
            societyPercent: "int?",
            signatures: "LegalResponsibleSignature[]"
        },
        CompanyRepresentativeDto: {
            phoneNumber: "string",
            cpf: "string",
            email: "string",
            fullName: "string",
            documentNumber: "string",
            pep: "bool",
            address: "CompanyRepresentativeAddress",
            cnpj: "string"
        },
        CompanyRepresentativeAddress: {
            cep: "string",
            district: "string",
            street: "string",
            number: "string",
            state: "string",
            complement: "string?",
            cityCode: "string"
        },
        CompaniesGroupParams: {
            name: "string",
            description: "string"
        },
        CompaniesGroup: {
            id: "uuid",
            name: "string",
            description: "string"
        },
        CompaniesGroupPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            companiesGroups: "CompaniesGroup[]"
        },
        CompaniesGroupFilter: {
            name: "string?",
            companyName: "string?"
        },
        CompanyWithCompaniesGroupRelashionship: {
            related: "bool",
            id: "string",
            name: "string",
            cnpj: "string"
        },
        CompaniesWithCompaniesGroupRelashionshipFilter: {
            related: "bool?",
            companiesGroupId: "uuid",
            name: "string?",
            fantasyName: "string?",
            cnpj: "string?"
        },
        AssociateCompaniesGroupsCompaniesParamsCompanies: {
            companyId: "uuid",
            related: "bool"
        },
        AssociateCompaniesGroupsCompaniesParams: {
            companiesGroupId: "uuid",
            companies: "AssociateCompaniesGroupsCompaniesParamsCompanies[]"
        },
        CompaniesGroupApiSecretParams: {
            companiesGroupId: "uuid",
            profileId: "uuid?"
        },
        InstallmentBasicInfo: {
            dueDate: "string",
            number: "int",
            status: "InstallmentStatus",
            createdAt: "string",
            cessionId: "string?"
        },
        Portability: {
            transferredAt: "string",
            fromData: "CompanyBasicInfo",
            toData: "CompanyBasicInfo",
            installments: "int[]"
        },
        SimulatePortabiltyParams: {
            loanId: "uuid",
            transferDate: "string"
        },
        MakeLoanPortabilityParams: {
            sourceCompanyId: "uuid",
            destinyCompanyId: "uuid",
            loanId: "uuid",
            transferDate: "string"
        },
        BirthdayWithdrawalContract: {
            status: "LoanFgtsStatus",
            ccbLink: "string?",
            requestIdentifier: "string",
            reservationProtocolNumber: "string?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?",
            id: "uuid",
            firstAnticipationWithdrawalDate: "string",
            lastAnticipationWithdrawalDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedPeriodsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            totalBlockedFromFgts: "int",
            withdrawalsAntecipated: "WithdrawalAntecipated[]"
        },
        BirthdayWithdrawalContractWithCcbId: {
            ccbId: "string",
            status: "LoanFgtsStatus",
            ccbLink: "string?",
            requestIdentifier: "string",
            reservationProtocolNumber: "string?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?",
            id: "uuid",
            firstAnticipationWithdrawalDate: "string",
            lastAnticipationWithdrawalDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedPeriodsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            totalBlockedFromFgts: "int",
            withdrawalsAntecipated: "WithdrawalAntecipated[]"
        },
        BirthdayWithdrawalContractExport: {
            interestRatePerDay: "float",
            interestRatePerYear: "float",
            financedIof: "int",
            fixedIof: "int",
            totalIof: "int",
            structuringRate: "int",
            structuringValue: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            creditDate: "string",
            status: "LoanFgtsStatus",
            id: "uuid",
            firstAnticipationWithdrawalDate: "string",
            lastAnticipationWithdrawalDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedPeriodsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            totalBlockedFromFgts: "int",
            withdrawalsAntecipated: "WithdrawalAntecipated[]",
            ccbLink: "string?",
            requestIdentifier: "string",
            reservationProtocolNumber: "string?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        BirthdayWithdrawalContractFilterDate: {
            from: "date?",
            to: "date?"
        },
        BirthdayWithdrawalContractFilter: {
            name: "string?",
            cpf: "string?",
            status: "LoanFgtsStatus[]?",
            ccbNumber: "string?",
            date: "BirthdayWithdrawalContractFilterDate"
        },
        BirthdayWithdrawalContractReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            contracts: "BirthdayWithdrawalContract[]"
        },
        InstallmentFinancialInfo: {
            number: "int",
            dueDate: "string",
            value: "int",
            remainingValue: "int"
        },
        InstallmentPayment: {
            id: "string",
            createdAt: "datetime",
            paidDate: "string",
            paidByAmortization: "bool",
            grossValue: "int",
            paidValue: "int",
            reversed: "bool"
        },
        InstallmentWithPayments: {
            number: "int",
            dueDate: "string",
            status: "InstallmentStatus",
            value: "int",
            paidValue: "int",
            remainingValue: "int",
            payments: "InstallmentPayment[]"
        },
        SimulationPJ: {
            creditDate: "string",
            installmentReferenceDate: "string",
            interestRatePerDay: "float",
            interestRatePerYear: "float",
            totalEffectiveCostPerMonth: "float",
            totalEffectiveCostPerYear: "float",
            financedIOF: "int",
            fixedIOF: "int",
            totalIOF: "int",
            structuringValue: "int",
            ccbValue: "int",
            initalValue: "int",
            regularPmtValue: "int",
            seguroPrestamistaRate: "float?",
            additionalSeguroPrestamista: "int",
            simulationInstallmentsPj: "SimulationInstallmentsPjWithAdditionalValues[]",
            lastInstallmentDueDate: "string",
            dailyIOF: "float",
            maxIOFAfterYear: "float",
            fixedIOFRate: "float"
        },
        InstallmentsValuesParams: {
            number: "int",
            value: "int"
        },
        InstallmentsValues: {
            number: "int",
            value: "int"
        },
        InstallmentsDates: {
            number: "int",
            date: "string"
        },
        RatesParam: {
            interestRatePerMonth: "int",
            structuringRate: "int"
        },
        SimulationRequestParamsPj: {
            requestedAmountInCents: "int",
            numberOfInstallments: "int",
            creditDate: "string",
            installmentReferenceDate: "string",
            installmentsDates: "InstallmentsDates[]",
            installmentsValues: "InstallmentsValuesParams[]",
            rates: "RatesParam",
            aditionalValues: "AditionalValuesParam",
            seguroPrestamista: "bool"
        },
        SimulationParamsPjToSave: {
            requestedAmountInCents: "int",
            numberOfInstallments: "int",
            creditDate: "string",
            installmentReferenceDate: "string",
            simulationInstallmentsPj: "SimulationInstallmentsPj[]",
            rates: "RatesParam",
            ccbValue: "int",
            aditionalValues: "AditionalValuesParam",
            seguroPrestamista: "bool"
        },
        AditionalValuesParam: {
            custodyValue: "int",
            thirdPartyValue: "int",
            tedValue: "int"
        },
        CcbSimulationPj: {
            requestedAmountInCents: "int",
            numberOfInstallments: "int",
            creditDate: "string",
            installmentReferenceDate: "string",
            simulationInstallmentsPj: "SimulationInstallmentsPj[]",
            rates: "RatesParam",
            ccbValue: "int",
            aditionalValues: "AditionalValuesParam",
            seguroPrestamista: "bool",
            companyId: "uuid",
            simulationId: "uuid?"
        },
        SimulationInstallmentsPj: {
            number: "int",
            value: "int",
            date: "string",
            interestValue: "int",
            amortizationValue: "int"
        },
        SimulationInstallmentsPjWithAdditionalValues: {
            dueBalance: "int",
            number: "int",
            value: "int",
            date: "string",
            interestValue: "int",
            amortizationValue: "int"
        },
        AdditionalFields: {
            companyId: "uuid",
            simulationId: "uuid?"
        },
        CcbSimulationPjForReport: {
            id: "string",
            companyName: "string",
            cnpj: "string",
            requestedAmountInCents: "int",
            numberOfInstallments: "int",
            lastUpdate: "datetime"
        },
        CcbSimulationPjReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            simulations: "CcbSimulationPjForReport[]"
        },
        RequestLoanPj: {
            id: "string",
            ccbNumber: "string"
        },
        MarsupioUserData: {
            cpf: "string",
            password: "string",
            name: "string"
        },
        PaymenSlipAccountsBalanceResult: {
            accountId: "string",
            account: "string",
            branch: "string",
            bank: "string",
            status: "string",
            defaultAccount: "bool",
            balance: "uint",
            availableBalance: "uint",
            blockedAmount: "uint",
            accountPurpose: "AccountPurpose"
        },
        AccountBalanceByPurposeResult: {
            payPaymentSlip: "uint",
            paymentSlipRecipient: "uint",
            total: "uint"
        },
        PrecatoryAnticipationContract: {
            status: "PrecatoryStatus",
            ccbLink: "string?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?",
            id: "uuid",
            firstAnticipationPeriodDate: "string",
            lastAnticipationPeriodDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedInstallmentsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            blockedTotalValue: "int",
            installmentsAnticipated: "PrecatoryAnticipationInstallmentAnticipated[]"
        },
        PrecatoryAnticipationContractWithCcbId: {
            ccbId: "string",
            status: "PrecatoryStatus",
            ccbLink: "string?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?",
            id: "uuid",
            firstAnticipationPeriodDate: "string",
            lastAnticipationPeriodDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedInstallmentsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            blockedTotalValue: "int",
            installmentsAnticipated: "PrecatoryAnticipationInstallmentAnticipated[]"
        },
        PrecatoryAnticipationContractExport: {
            interestRatePerDay: "float",
            interestRatePerYear: "float",
            financedIof: "int",
            fixedIof: "int",
            totalIof: "int",
            structuringRate: "int",
            structuringValue: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            creditDate: "string",
            status: "PrecatoryStatus",
            id: "uuid",
            firstAnticipationPeriodDate: "string",
            lastAnticipationPeriodDate: "string",
            ccbNumber: "string",
            ccbValue: "int",
            emittedDate: "string",
            anticipatedInstallmentsNumber: "int",
            interestRatePerMonth: "int",
            totalEffectiveCostPerMonth: "float",
            transferAmount: "int",
            blockedTotalValue: "int",
            installmentsAnticipated: "PrecatoryAnticipationInstallmentAnticipated[]",
            ccbLink: "string?",
            userName: "string",
            cpf: "string",
            email: "string?",
            secondaryEmail: "string?",
            mobilePhone: "string?",
            residentialPhone: "string?",
            financialData: "FinancialData",
            address: "Address?",
            dateOfBirth: "string?"
        },
        PrecatoryAnticipationContractFilterDate: {
            from: "date?",
            to: "date?"
        },
        PrecatoryAnticipationContractFilter: {
            name: "string?",
            cpf: "string?",
            status: "PrecatoryStatus[]?",
            ccbNumber: "string?",
            date: "PrecatoryAnticipationContractFilterDate"
        },
        PrecatoryAnticipationContractReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            contracts: "PrecatoryAnticipationContract[]"
        },
        PushNotificationRequestDtoData: {
            id: "string?",
            path: "string"
        },
        PushNotificationRequestDto: {
            externalIds: "string[]",
            message: "string",
            title: "string",
            data: "PushNotificationRequestDtoData?"
        },
        CivilState: [
            "single",
            "married",
            "widow",
            "divorcee",
            "separate"
        ],
        Gender: [
            "male",
            "female",
            "other"
        ],
        AccountType: [
            "checking",
            "saving"
        ],
        UserPaymentChoise: [
            "byPix",
            "byBankAccount"
        ],
        UserStatus: [
            "registered",
            "pendingEmail",
            "pendingPassword",
            "inexistent",
            "blocked",
            "pendingTermsOfUse",
            "unhired",
            "suspended",
            "pendingIdentityValidations"
        ],
        UserInfoCivilState: [
            "single",
            "married",
            "divorced",
            "widowed"
        ],
        UserInfoGender: [
            "male",
            "female"
        ],
        EmployeeType: [
            "public",
            "private"
        ],
        Modality: [
            "state",
            "municipal",
            "federal"
        ],
        LoginOrigin: [
            "SPCRED",
            "CREDIFIT"
        ],
        PersonalDocumentType: [
            "RG",
            "CNH",
            "CRC",
            "OAB",
            "CRA",
            "CRM",
            "CRO"
        ],
        MarriageRegime: [
            "partialCommunion",
            "universalCommunion",
            "completeSeparation"
        ],
        PaymentMethod: [
            "bankSlip",
            "electronicAvailableTransfer",
            "accountDebit",
            "directDebit"
        ],
        OrderCalculationType: [
            "debtBalanceIncident",
            "installmentAmortizationIncident"
        ],
        EconomicSector: [
            "private",
            "municipal",
            "state",
            "federal"
        ],
        CapitalOrigin: [
            "national",
            "foreign",
            "mix",
            "other"
        ],
        ResponsibleForPayment: [
            "user",
            "company"
        ],
        ValidationReportResult: [
            "valid",
            "invalid",
            "processing"
        ],
        AmortizationOrder: [
            "ascending",
            "descending"
        ],
        AmortizationType: [
            "installments",
            "value"
        ],
        LoanProposalPFStatus: [
            "created",
            "pending_signature",
            "signed",
            "refused",
            "canceled_corban"
        ],
        BirthdayWithdrawalStatus: [
            "active",
            "pendingIdentityValidations"
        ],
        DocumentGroupType: [
            "company",
            "legalResponsible"
        ],
        DocumentType: [
            "cadastral",
            "financial",
            "personal"
        ],
        ChargeFilterLoanType: [
            "payday",
            "turning",
            "fgts"
        ],
        ReportFilterPerson: [
            "cpf",
            "cnpj"
        ],
        AcceptedGuarantees: [
            "bail",
            "realty",
            "vehicles",
            "receivables",
            "no_guarantees",
            "others"
        ],
        OfferedGuarantees: [
            "bail",
            "realty",
            "none",
            "others",
            "credit_card_receivables",
            "check_receivables",
            "contracts_receivables",
            "duplicate_receivables",
            "cell_phone",
            "vehicles"
        ],
        ProposalStatus: [
            "under_analysis",
            "beginning_of_formal_relationship",
            "contracted_with_own_lines",
            "refused",
            "canceled",
            "expired",
            "received"
        ],
        TaxRegime: [
            "simple_national",
            "presumed_profit",
            "real_profit"
        ],
        GuaranteesType: [
            "with_guarantees",
            "without_guarantees",
            "receivables_antecipation"
        ],
        ReasonProposalStatus: [
            "credit_denial",
            "lack_of_documentation_or_registration",
            "insufficient_guarantees",
            "others"
        ],
        PrecatoryAnticipationStatus: [
            "active",
            "pendingIdentityValidations"
        ],
        LoanStatusV2: [
            "pending_hr",
            "pending_credifit",
            "active"
        ],
        LoanStatusV3: [
            "pending_identity_validations",
            "pending_hr",
            "pending_credifit",
            "active"
        ],
        GivenStatus: [
            "given",
            "partial_given"
        ],
        LoanStatus: [
            "pending",
            "active"
        ],
        InstallmentStatus: [
            "open",
            "paid",
            "paidByAmortization"
        ],
        InstallmentStatusV2: [
            "open",
            "paid",
            "paidByAmortization",
            "processing"
        ],
        ProfileModule: [
            "simulator",
            "requests",
            "companies",
            "companiesGroups",
            "companyEmployees",
            "fgtsParameters",
            "contracts",
            "otherContracts",
            "birthdayWithdrawalContracts",
            "charges",
            "cessions",
            "users",
            "authentication",
            "external",
            "fullAccess",
            "cnab",
            "paymentSlipReports",
            "precatoryAnticipationContracts",
            "escrow"
        ],
        LoanStatusReport: [
            "pending_hr",
            "pending_credifit",
            "active",
            "denied_hr",
            "denied_credifit",
            "canceled_by_user",
            "given",
            "partial_given",
            "closed",
            "canceled_by_credifit",
            "pending_identity_validations"
        ],
        LoanFgtsStatus: [
            "processingCredifit",
            "active",
            "processingCaixa",
            "deniedCaixa",
            "failedCaixa",
            "canceledCredifit",
            "pendingIdentityValidations"
        ],
        ProposalStatusReport: [
            "created",
            "pending_signature",
            "signed",
            "refused",
            "canceled_corban"
        ],
        PrecatoryStatus: [
            "active",
            "pendingIdentityValidations",
            "failed"
        ],
        ConflictStatus: [
            "fired",
            "hired",
            "salaryUpgrade",
            "InssRemoval",
            "InssReturn",
            "nameChange",
            "monthlyPayrollDiscountChange"
        ],
        CreditCalculationAlgorithm: [
            "default",
            "machine_learning"
        ],
        ApprovalDecision: [
            "approved",
            "denied"
        ],
        ContractStatus: [
            "pendingHr",
            "pendingCredifit",
            "active",
            "deniedHr",
            "deniedCredifit",
            "closed",
            "given",
            "partialGiven",
            "pendingIdentityValidations",
            "canceledByUser",
            "pendingCession",
            "canceledByCredifit"
        ],
        ContractTedStatus: [
            "processing",
            "registerFailed",
            "registered"
        ],
        CessionStatus: [
            "given",
            "partialGiven",
            "pending"
        ],
        HiringSituation: [
            "hired",
            "unhired"
        ],
        ProfileType: [
            "portal_admin",
            "external_api"
        ],
        SignatureStatus: [
            "signed",
            "pending",
            "invalid",
            "linkExpired"
        ],
        AccountPurpose: [
            "PAY_PAYMENT_SLIP",
            "PAYMENT_SLIP_RECIPIENT"
        ]
    }
};
