import { __awaiter } from "tslib";
import React, { createContext, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../utils/mobx";
export const AdminContext = createContext({});
export const AdminProvider = observer(({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const { accessProfileStore: { getAllProfiles, getProfilesTotal }, generalStore, generalStore: { catchErrors }, adminStore: { getAllAdmins, adminToEdit, removeAdmin }, } = useStores();
    const fetchProfiles = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const profilesTotal = yield getProfilesTotal(null);
            const { profiles: fetchedProfiles } = yield getAllProfiles(null, 1, profilesTotal);
            setProfiles(fetchedProfiles);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    }), [getProfilesTotal, getAllProfiles, generalStore, catchErrors]);
    const fetchAdmins = useCallback((filter, page, rowsPerPage) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const { admins: fetchedAdmins } = yield getAllAdmins(filter, page, rowsPerPage);
            setAdmins(fetchedAdmins);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("companyEmployee.registerErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    }), [setLoading, setAdmins, generalStore, catchErrors, getAllAdmins]);
    const deleteAdmin = useCallback((admin) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield removeAdmin(admin.id);
            setAdmins((oldUsers) => oldUsers.filter(({ id }) => admin.id !== id));
            generalStore.setSuccessMessage(t("users.sucessfulRemovalMessage"));
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("users.removalApiErrorMessage"));
        }
    }), [removeAdmin, setAdmins, generalStore, catchErrors]);
    const contextValues = {
        loading,
        admins,
        profiles,
        fetchProfiles,
        fetchAdmins,
        editingAdmin: !!adminToEdit,
        deleteAdmin,
    };
    return React.createElement(AdminContext.Provider, { value: contextValues }, children);
});
