import { __awaiter, __decorate } from "tslib";
import { action } from "mobx";
import { api } from "../AppWrapper";
import { getDateIsoFormat, parseStringToMoment } from "../../shared/utils/date";
export class CcbPjStore {
    constructor() {
        this.simulateCcbPj = (args) => __awaiter(this, void 0, void 0, function* () {
            const priceSimulationPj = yield api.simulateCcbPj({
                simulation: this.mapCcbPjSimulationParamsToSimulationRequestParams(args),
            });
            return this.mapSimulationPjToPriceSimulationPjResult(priceSimulationPj);
        });
        this.saveCcbSimulationPj = (args) => __awaiter(this, void 0, void 0, function* () { return yield api.saveCcbSimulationPj({ ccbSimulationPj: this.mapSaveCcbSimulationPjParams(args) }); });
        this.getCcbSimulationPjReport = (page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () { return api.getCcbSimulationPjReport({ page, rowsPerPage }); });
        this.getCcbSimulationPjReportTotal = () => __awaiter(this, void 0, void 0, function* () { return api.getCcbSimulationPjReportTotal(); });
        this.getSimulationPj = (simulationId) => __awaiter(this, void 0, void 0, function* () {
            const simulationPj = yield api.getSimulationPj({ simulationId });
            return this.mapCcbSimulationPjParams(simulationPj);
        });
        this.generateCcbPjPreview = (ccbPjParams) => __awaiter(this, void 0, void 0, function* () { return api.generateCcbPjPreview({ ccbPjParams }); });
        this.requestLoanPj = (ccbPjParams) => __awaiter(this, void 0, void 0, function* () { return api.requestLoanPj({ ccbPjParams }); });
        this.getLoanPjNegotiableCcbFile = (id) => __awaiter(this, void 0, void 0, function* () { return api.getLoanPjNegotiableCcbFile({ id }); });
        this.getLoanPjNonNegotiableCcbFile = (id) => __awaiter(this, void 0, void 0, function* () { return api.getLoanPjNonNegotiableCcbFile({ id }); });
    }
    mapCcbPjSimulationParamsToSimulationRequestParams(args) {
        const { ccbPjSimulationParams } = args;
        return {
            aditionalValues: {
                custodyValue: 0,
                tedValue: ccbPjSimulationParams.tedValue,
                thirdPartyValue: ccbPjSimulationParams.thirdPartyValue,
            },
            creditDate: getDateIsoFormat(ccbPjSimulationParams.valueCreditDate),
            installmentReferenceDate: getDateIsoFormat(ccbPjSimulationParams.installmentReferenceDate),
            seguroPrestamista: ccbPjSimulationParams.joinSeguroPrestamista,
            installmentsDates: args.installmentsDates,
            installmentsValues: args.installmentsValues,
            numberOfInstallments: ccbPjSimulationParams.numberOfInstallments,
            rates: {
                interestRatePerMonth: ccbPjSimulationParams.interestRatePerMonth,
                structuringRate: ccbPjSimulationParams.structuringRate,
            },
            requestedAmountInCents: ccbPjSimulationParams.requestedAmount,
        };
    }
    mapSimulationPjToPriceSimulationPjResult(priceSimulationPj) {
        return Object.assign(Object.assign({}, priceSimulationPj), { lastInstallmentDate: priceSimulationPj.lastInstallmentDueDate, installments: priceSimulationPj.simulationInstallmentsPj });
    }
    mapSaveCcbSimulationPjParams(args) {
        const { companyId, priceSimulationPj, simulationFormArgs, simulationId } = args;
        return Object.assign(Object.assign(Object.assign({}, simulationFormArgs), priceSimulationPj), { requestedAmountInCents: simulationFormArgs.requestedAmount, rates: Object.assign({}, simulationFormArgs), aditionalValues: Object.assign(Object.assign({}, simulationFormArgs), { custodyValue: 0 }), seguroPrestamista: simulationFormArgs.joinSeguroPrestamista, companyId, creditDate: getDateIsoFormat(simulationFormArgs.valueCreditDate), installmentReferenceDate: getDateIsoFormat(simulationFormArgs.installmentReferenceDate), simulationInstallmentsPj: priceSimulationPj.installments, simulationId: simulationId || null });
    }
    mapCcbSimulationPjParams(args) {
        return Object.assign(Object.assign({}, args), { requestedAmount: args.requestedAmountInCents, valueCreditDate: parseStringToMoment(args.creditDate), installmentReferenceDate: parseStringToMoment(args.installmentReferenceDate), interestRatePerMonth: args.rates.interestRatePerMonth, structuringRate: args.rates.structuringRate, joinSeguroPrestamista: args.seguroPrestamista, thirdPartyValue: args.aditionalValues.thirdPartyValue, tedValue: args.aditionalValues.tedValue, installments: args.simulationInstallmentsPj });
    }
}
__decorate([
    action
], CcbPjStore.prototype, "simulateCcbPj", void 0);
__decorate([
    action
], CcbPjStore.prototype, "saveCcbSimulationPj", void 0);
__decorate([
    action
], CcbPjStore.prototype, "requestLoanPj", void 0);
__decorate([
    action
], CcbPjStore.prototype, "getLoanPjNegotiableCcbFile", void 0);
__decorate([
    action
], CcbPjStore.prototype, "getLoanPjNonNegotiableCcbFile", void 0);
