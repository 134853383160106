import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Input, Row, Col, InputNumber as AntdInputNumber, Radio } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import InputCurrency from "../../../../../shared/components/InputCurrency";
import { formatCurrencyNoPrefix, toInt } from "../../../../../shared/utils/formatters";
import { useStores } from "../../../../utils/mobx";
import InputNumber from "../../../../../shared/components/InputNumber";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const FinancialDataForm = observer(({ form, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { getFieldDecorator } = form;
    const [hasBranches, setHasBranches] = React.useState(false);
    const [branchesAmount, setBranchesAmount] = React.useState("");
    const [branchesState, setBranchesState] = React.useState("");
    const formKey = "financialData";
    const { t } = useTranslation();
    React.useEffect(() => {
        if (initialValues) {
            if (initialValues.hasBranches !== null) {
                setHasBranches(initialValues.hasBranches);
                setBranchesAmount(initialValues.branchesAmount ? initialValues.branchesAmount.toString() : "");
                setBranchesState(initialValues.branchesStates || "");
            }
        }
    }, [initialValues]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.activityAndFinancialDataTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 16 },
                React.createElement(Form.Item, { label: t("companyForm.activityLabel") }, getFieldDecorator(`${formKey}.activity`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.activity }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { allowClear: true, maxLength: 255, placeholder: t("companyForm.activityLabel"), onFocus: () => setSelectedItem(`${formKey}.activity`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: `${t("companyForm.hasImportLabel")}?` }, getFieldDecorator(`${formKey}.hasImport`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.hasImport,
                })(React.createElement(Radio.Group, null,
                    React.createElement(Radio, { value: true }, t("yesLabel")),
                    React.createElement(Radio, { value: false }, t("noLabel")))))),
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: `${t("companyForm.hasExportLabel")}?` }, getFieldDecorator(`${formKey}.hasExport`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.hasExport,
                })(React.createElement(Radio.Group, null,
                    React.createElement(Radio, { value: true }, t("yesLabel")),
                    React.createElement(Radio, { value: false }, t("noLabel"))))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.shoppingValueLabel"), help: t("companyForm.average12MonthsLabel") }, getFieldDecorator(`${formKey}.averageShoppingValue`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.averageShoppingValue, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(InputCurrency, { min: 0, onFocus: () => setSelectedItem(`${formKey}.averageShoppingValue`) })))),
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: t("companyForm.averageRevenueLabel"), help: t("companyForm.average12MonthsLabel") }, getFieldDecorator(`${formKey}.averageRevenueValue`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.averageRevenueValue, normalize: (value) => toInt(value), rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("companyForm.averageRevenueLabel") }),
                        },
                    ] }, decoratorConfigDefaultOnBlur))(React.createElement(InputCurrency, { min: 0, onFocus: () => setSelectedItem(`${formKey}.averageRevenueValue`) })))),
            React.createElement(Col, { span: 9 },
                React.createElement(Form.Item, { label: t("companyForm.averageTimeToSalesLabel"), help: t("companyForm.inMonthLabel") }, getFieldDecorator(`${formKey}.averageTimeToReceiveSales`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.averageTimeToReceiveSales, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(AntdInputNumber, { min: 0, placeholder: "12", onFocus: () => setSelectedItem(`${formKey}.averageTimeToReceiveSales`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 10 },
                React.createElement(Form.Item, { label: t("companyForm.utilizationOfCapacityLabel") }, getFieldDecorator(`${formKey}.utilizationOfInstalledCapacity`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.utilizationOfInstalledCapacity, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(AntdInputNumber, { formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, max: 10000, onFocus: () => setSelectedItem(`${formKey}.utilizationOfInstalledCapacity`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("companyForm.marketShareLabel") }, getFieldDecorator(`${formKey}.marketShare`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.marketShare, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(AntdInputNumber, { formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, max: 10000, onFocus: () => setSelectedItem(`${formKey}.marketShare`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 3 },
                React.createElement(Form.Item, { label: `${t("companyForm.hasBranchesLabel")}?` }, getFieldDecorator(`${formKey}.hasBranches`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.hasBranches,
                })(React.createElement(Radio.Group, { onChange: (e) => {
                        const value = e.target.value;
                        if (value === false) {
                            setBranchesAmount("");
                            setBranchesState("");
                            form.setFieldsValue({
                                financialData: {
                                    branchesAmount: "",
                                    branchesStates: "",
                                },
                            });
                        }
                        setHasBranches(value);
                    } },
                    React.createElement(Radio, { value: true }, t("yesLabel")),
                    React.createElement(Radio, { value: false }, t("noLabel")))))),
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: t("companyForm.branchesAmountLabel") }, getFieldDecorator(`${formKey}.branchesAmount`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.branchesAmount, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(InputNumber, { disabled: !hasBranches, min: 0, maxLength: 5, placeholder: "2", onFocus: () => setSelectedItem(`${formKey}.branchesAmount`), value: branchesAmount, onChange: (e) => setBranchesAmount(e.target.value) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("companyForm.branchesStateLabel") }, getFieldDecorator(`${formKey}.branchesStates`, Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.branchesStates }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { disabled: !hasBranches, maxLength: 255, placeholder: "Bahia, Sergipe, S\u00E3o Paulo", onFocus: () => setSelectedItem(`${formKey}.branchesStates`), value: branchesState, onChange: (e) => setBranchesState(e.target.value) })))))));
});
export default FinancialDataForm;
