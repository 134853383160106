import { getDateIsoFormat } from "../../../../shared/utils/date";
import { parseToString } from "../../../../shared/utils/formatters";
function mapGuarantorToPersonalGuarantee(guarantor) {
    return {
        address: {
            cep: guarantor.cep,
            city: guarantor.addressCity,
            address: guarantor.address,
            state: guarantor.addressState,
        },
        cityOfBirth: guarantor.placeOfBirth,
        civilState: guarantor.civilStatus,
        cpf: guarantor.cpf,
        dispatcher: guarantor.dispatcher,
        documentNumber: guarantor.documentNumber,
        issuanceDate: getDateIsoFormat(guarantor.documentEmissionDate),
        marriageRegime: guarantor.marriageRegime ? guarantor.marriageRegime : null,
        name: guarantor.name,
        nationality: guarantor.nationality,
        personalDocumentType: guarantor.documentType,
        stableUnion: guarantor.stableUnion,
        spouse: {
            spouseCpf: parseToString(guarantor.spouseCpf),
            spouseName: parseToString(guarantor.spouseName),
            spouseRg: parseToString(guarantor.spouseRg),
        },
        uf: guarantor.documentState,
    };
}
export function mapGenerateCcbPjPreviewParams(fields, simulationId) {
    return {
        conditions: {
            formOfPayment: fields.installmentsPaymentWay,
            issuerBank: {
                accountNumber: fields.bankAccount,
                accountType: fields.bankAccountType,
                agency: String(fields.bankBranch),
                bankName: fields.bankName,
                bankNumber: fields.bankCode,
            },
            orderCalculation: fields.chargesCalculation,
            resourcesReleaseForm: fields.releaseWay,
        },
        emittedDate: new Date(fields.ccbEmitDate),
        opinion: fields.opinion,
        personalGuarantee: fields.guarantors
            ? fields.guarantors.map((guarantor) => mapGuarantorToPersonalGuarantee(guarantor))
            : [],
        realGuaranteeNumber: fields.qteGuarantees,
        simulationId,
    };
}
