import { __awaiter, __decorate } from "tslib";
import { computed } from "mobx";
import { api } from "../AppWrapper";
export class ContractStore {
    constructor() {
        this.getContracts = (filter, page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () { return api.getContracts({ filter, page, rowsPerPage }); });
        this.getContractsTotal = (filter) => __awaiter(this, void 0, void 0, function* () { return api.getContractTotal({ filter }); });
        this.simulateAmortizing = (amortization, loanId) => __awaiter(this, void 0, void 0, function* () { return yield api.simulateAmortization({ amortization, loanId }); });
        this.applyAmortizing = (amortization, loanId) => __awaiter(this, void 0, void 0, function* () { return api.applyAmortization({ amortization, loanId }); });
        this.exportContracts = (filter) => __awaiter(this, void 0, void 0, function* () { return api.exportContracts({ filter }); });
        /**
         * getDetailedCcbReport
         * Return Discount report (WBA) data
         */
        this.getDetailedCcbReport = (loanId, reportRequestDate, solicitationDate) => __awaiter(this, void 0, void 0, function* () { return api.getDetailedCcbReport({ loanId, reportRequestDate, solicitationDate }); });
        /**
         * exportDetailedCcbReport
         * Export Discount report (WBA) as XLSX
         */
        this.exportDetailedCcbReport = (loanId, reportRequestDate, solicitationDate) => __awaiter(this, void 0, void 0, function* () { return api.exportDetailedCcbReport({ loanId, reportRequestDate, solicitationDate }); });
        /**
         * getCcbDataByLoanId
         * Get dynamic infos
         */
        this.getCcbDataByLoanId = (loanId) => __awaiter(this, void 0, void 0, function* () { return api.getCcbDataByLoanId({ loanId }); });
        this.setTourDone = () => localStorage.setItem("tourDone", "1");
        this.simulatePortability = (transferDate, loanId) => __awaiter(this, void 0, void 0, function* () { return api.simulatePortability({ params: { loanId, transferDate } }); });
        this.getLatestSourceCompany = (loanId) => __awaiter(this, void 0, void 0, function* () { return api.getLatestSourceCompany({ loanId }); });
        this.getUserActiveCompaniesByLoanId = (loanId) => __awaiter(this, void 0, void 0, function* () { return api.getUserActiveCompaniesByLoanId({ loanId }); });
        this.makeLoanPortability = (sourceCompanyId, destinyCompanyId, loanId, transferDate) => __awaiter(this, void 0, void 0, function* () {
            return api.makeLoanPortability({
                params: {
                    sourceCompanyId,
                    destinyCompanyId,
                    loanId,
                    transferDate,
                },
            });
        });
        this.portabilityHistory = (loanId) => __awaiter(this, void 0, void 0, function* () { return api.getPortabilityHistory({ loanId }); });
        this.cancelCcbByAdmin = (loanId) => __awaiter(this, void 0, void 0, function* () { return api.cancelCcbByAdmin({ loanId }); });
    }
    get tourDone() {
        return !!localStorage.getItem("tourDone");
    }
}
__decorate([
    computed
], ContractStore.prototype, "tourDone", null);
