import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { FINANCIAL_INSTITUITIONS_STEP_INDEX, COMPANY_DATA_STEP_INDEX, FINANCIAL_DATA_STEP_INDEX, LEGAL_RESPONSIBLE_STEP_INDEX, ADITIONAL_INFORMATIONS_STEP_INDEX, SUPPLIERS_STEP_INDEX, } from "../../../Company/Forms/Steps";
import { CompanyDataFields } from "../CompanyDataFields";
import { FinancialDataFields } from "../FinancialDataFields";
import { LegalResponsibleFields } from "../LegalResponsibleFields";
import { FinancialInstitutionsFields } from "../FinancialInstitutionsFields";
import { SupplierFields } from "../SupplierFields";
import { AditionalInformationsFields } from "../AditionalInformationsFields";
import { FormTitleH3 } from "../../../../../shared/components/FormTitleH3";
const Comp = ({ form, currentStep, companyInfo, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const hiddenIfStepIsNotActive = React.useCallback((stepIndex) => {
        const display = currentStep === stepIndex ? "block" : "none";
        return { style: { display } };
    }, [currentStep]);
    useEffect(() => {
        if (companyInfo) {
            form.resetFields();
        }
    }, [companyInfo]);
    if (!companyInfo) {
        return null;
    }
    return (React.createElement(Form, null,
        React.createElement("div", Object.assign({}, hiddenIfStepIsNotActive(COMPANY_DATA_STEP_INDEX)),
            React.createElement(CompanyDataFields, { form: form, "data-cy": "company-data-form", companyInfo: companyInfo, readOnly: true })),
        React.createElement("div", Object.assign({}, hiddenIfStepIsNotActive(FINANCIAL_DATA_STEP_INDEX)),
            React.createElement(FinancialDataFields, { form: form, "data-cy": "financial-data-form", companyInfo: companyInfo, readOnly: true })),
        React.createElement("div", Object.assign({}, hiddenIfStepIsNotActive(LEGAL_RESPONSIBLE_STEP_INDEX)),
            React.createElement(FormTitleH3, { title: t("portalPj.partnersAndLegalGuardians") }),
            React.createElement(LegalResponsibleFields, { form: form, "data-cy": "legal-responsible-form", companyId: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.id, readOnly: true, legalResponsibles: companyInfo.legalResponsibles, hasOnlyOneLegalResponsible: ((_a = companyInfo.legalResponsibles) === null || _a === void 0 ? void 0 : _a.length) === 1 })),
        React.createElement("div", Object.assign({}, hiddenIfStepIsNotActive(FINANCIAL_INSTITUITIONS_STEP_INDEX)),
            React.createElement(FinancialInstitutionsFields, { form: form, "data-cy": "financial-institution-form", companyInfo: companyInfo, readOnly: true, financialInstitutions: companyInfo.financialInstitutions, hasOnlyOneFinancialInstitution: ((_b = companyInfo.financialInstitutions) === null || _b === void 0 ? void 0 : _b.length) === 1 })),
        React.createElement("div", Object.assign({}, hiddenIfStepIsNotActive(SUPPLIERS_STEP_INDEX)),
            React.createElement(SupplierFields, { form: form, "data-cy": "supplier-form", companyInfo: companyInfo, readOnly: true, suppliers: companyInfo.suppliers, hasOnlyOneSupplier: ((_c = companyInfo.suppliers) === null || _c === void 0 ? void 0 : _c.length) === 1 })),
        React.createElement("div", Object.assign({}, hiddenIfStepIsNotActive(ADITIONAL_INFORMATIONS_STEP_INDEX)),
            React.createElement(AditionalInformationsFields, { form: form, "data-cy": "aditional-info-form", readOnly: true, companyInfo: companyInfo }))));
};
export const ReadOnlyForm = Form.create({
    name: "company_form",
})(Comp);
