import { __awaiter } from "tslib";
import React, { useEffect } from "react";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { PasswordConfirmForm } from "../../../../shared/components/PasswordConfirmForm";
import { useStores } from "../../../utils/mobx";
import styles from "./style.scss";
export const Register = observer(() => {
    const { authStore, generalStore: { catchErrors }, routerStore, } = useStores();
    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [passwordWasRegisterd, setPasswordWasRegisterd] = React.useState(false);
    const { match } = useReactRouter();
    const { t } = useTranslation();
    const emailFromRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        const requestId = match.params.id;
        setErrorMessage("");
        try {
            const emailFromId = yield authStore.emailFromRequestId(requestId);
            setEmail(emailFromId);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("emailFromRequestIdFail"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        emailFromRequest();
    }, []);
    const register = (password, passwordConfirm) => __awaiter(void 0, void 0, void 0, function* () {
        const id = match.params.id;
        setErrorMessage("");
        setLoading(true);
        try {
            yield authStore.createPassword(id, password, passwordConfirm);
            setPasswordWasRegisterd(true);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("createPasswordFail"));
        }
        finally {
            setLoading(false);
        }
    });
    const handleVistLoginPage = () => {
        routerStore.push("/login");
    };
    return (React.createElement(React.Fragment, null, passwordWasRegisterd ? (React.createElement("div", null,
        React.createElement("h1", null, "Senha cadastrada com sucessso! Navegue at\u00E9 a p\u00E1gina de login e realize o acesso."),
        React.createElement("div", { className: styles.buttonWrapper },
            React.createElement(Button, { type: "primary", size: "large", onClick: handleVistLoginPage }, "Fazer Login")))) : (React.createElement(PasswordConfirmForm, { email: email, loading: loading, handleSubmit: register, error: errorMessage }))));
});
