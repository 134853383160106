import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, Input, List, Row, Tooltip } from "antd";
import Tag from "../Tag";
import styles from "./style.scss";
const InnerForm = observer(({ visible, initialValues, entities, onChange, inputProps, formatters, disabled }) => {
    const [entitiesList, setEntitiesList] = useState([]);
    const [showEntities, setShowEntities] = useState(false);
    const [associatedEntities, setAssociatedEntities] = useState([]);
    const associatedEntitiesIds = React.useMemo(() => {
        return associatedEntities === null || associatedEntities === void 0 ? void 0 : associatedEntities.map(({ id }) => id);
    }, [associatedEntities]);
    useEffect(() => {
        setEntitiesList(entities);
        setAssociatedEntities(initialValues);
    }, [visible]);
    useEffect(() => {
        onChange(associatedEntities);
    }, [associatedEntities]);
    const handleChange = (e) => {
        setShowEntities(true);
        const filterTerm = e.target.value;
        const comps = entities.filter((entity) => entity.name.toLowerCase().includes(filterTerm.toLowerCase()));
        setEntitiesList(comps);
    };
    const handleClickedEntityItem = (entity) => {
        let entityAssociated = false;
        if (associatedEntities && associatedEntities.length) {
            entityAssociated = associatedEntities.some((c) => c.id === entity.id);
        }
        const addEntity = () => {
            let entitiesGroup = null;
            associatedEntities
                ? (entitiesGroup = [...associatedEntities, entity])
                : (entitiesGroup = [entity]);
            setAssociatedEntities(entitiesGroup);
        };
        if (!entityAssociated) {
            addEntity();
        }
    };
    const removeAllAssociatedEntities = () => {
        setAssociatedEntities([]);
    };
    const removeSingleEntity = (entity) => {
        const hasAssociated = associatedEntities.some((item) => item.id === entity.id);
        const newAssociatedEntities = () => {
            const assocComps = associatedEntities.filter((item) => item.id !== entity.id);
            return assocComps;
        };
        return hasAssociated && setAssociatedEntities(newAssociatedEntities);
    };
    const formatDescription = (description) => {
        if ((formatters === null || formatters === void 0 ? void 0 : formatters.description) && description) {
            return formatters.description(description);
        }
        return description !== null && description !== void 0 ? description : "";
    };
    return (React.createElement(Row, { gutter: 20 },
        React.createElement(Col, null,
            React.createElement(Form.Item, { className: styles.addCompanyInput, label: inputProps === null || inputProps === void 0 ? void 0 : inputProps.label },
                React.createElement(Input, { id: inputProps === null || inputProps === void 0 ? void 0 : inputProps.id, placeholder: inputProps === null || inputProps === void 0 ? void 0 : inputProps.placeholder, onFocus: () => setShowEntities(true), onBlur: () => setTimeout(() => {
                        setShowEntities(false);
                    }, 300), onChange: handleChange, allowClear: true, autoComplete: "none", disabled: disabled })),
            React.createElement("div", { className: `${styles.selectCompanies} ${!showEntities ? styles.hidden : null}` },
                React.createElement(List, { itemLayout: "horizontal", dataSource: entitiesList, renderItem: (item) => {
                        const selected = associatedEntitiesIds
                            ? associatedEntitiesIds.includes(item.id)
                            : false;
                        return (React.createElement(List.Item, { id: item.id, className: selected ? styles.selected : "", onClick: () => {
                                handleClickedEntityItem(item);
                            } },
                            React.createElement(List.Item.Meta, { title: `${item.name} ${selected ? "(Selecionada)" : ""}`, description: formatDescription(item.description) })));
                    }, "data-cy": inputProps === null || inputProps === void 0 ? void 0 : inputProps.autoCompleteDataCy })),
            associatedEntities && associatedEntities !== null && associatedEntities.length > 0 && (React.createElement("div", { className: styles.companyArea },
                React.createElement("div", { className: styles.companies }, associatedEntities.map((entity) => (React.createElement(Tag, { key: entity.id, onRemove: (entityToRemove) => {
                        removeSingleEntity(entityToRemove);
                    }, item: Object.assign(Object.assign({}, entity), { description: formatDescription(entity.description) }) })))),
                React.createElement("div", { className: styles.removeAll },
                    React.createElement(Tooltip, { placement: "bottom", title: "Excluir todas" },
                        React.createElement("span", { "data-cy": "remove-all-entities-button", onClick: removeAllAssociatedEntities }, "X"))))))));
});
export const EntitiesSelector = Form.create({
    name: "entities_selector",
})(InnerForm);
