import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, InputNumber } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import { useStores } from "../../../../utils/mobx";
import { toInt } from "../../../../../shared/utils/formatters";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const EmployeeByAgeForm = observer(({ form, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { getFieldDecorator } = form;
    const formKey = "employessByAge";
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.employeeAmountByAgeTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: "18 a 22" }, getFieldDecorator(`${formKey}.between18And22`, Object.assign(Object.assign({ initialValue: initialValues.between18And22 }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.between18And22`) })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: "23 a 30" }, getFieldDecorator(`${formKey}.between23And30`, Object.assign(Object.assign({ initialValue: initialValues.between23And30 }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.between23And30`) })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: "31 a 41" }, getFieldDecorator(`${formKey}.between31And40`, Object.assign(Object.assign({ initialValue: initialValues.between31And40 }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.between31And40`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: "41 a 50" }, getFieldDecorator(`${formKey}.between41And50`, Object.assign(Object.assign({ initialValue: initialValues.between41And50 }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.between41And50`) })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: "50+" }, getFieldDecorator(`${formKey}.between50AndPlus`, Object.assign(Object.assign({ initialValue: initialValues.between50AndPlus }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.between50AndPlus`) })))))));
});
export default EmployeeByAgeForm;
