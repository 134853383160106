import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { useStores } from "../../utils/mobx";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { AccountsBalanceChart } from "./AccountsBalanceChart";
export const PaymentSlipReports = () => {
    const { generalStore: { setErrorMessage, catchErrors }, paymentSlipReportsStore, } = useStores();
    const { t } = useTranslation();
    const [accountsBalance, setAccountsBalance] = useState();
    const fetchBalanceFromPaymenSlipAccounts = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const accountsBalance = yield paymentSlipReportsStore.getAccountBalanceByPurpose();
            setAccountsBalance(accountsBalance);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
    });
    useEffect(() => {
        fetchBalanceFromPaymenSlipAccounts();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("paymentSlipReportsTitle") }),
        React.createElement(AccountsBalanceChart, { balance: accountsBalance })));
};
