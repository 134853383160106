import React from "react";
import { Input, Select } from "antd";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormTitleH3 } from "../../../FormTitleH3";
import { getFormItemName } from "../../../../utils/form.utils";
import { DeletableCard } from "../../../DeletableCard";
import styles from "./style.scss";
const { Option } = Select;
export const FinancialInstitutionsFields = ({ form, companyInfo, readOnly, financialInstitutions, formKey = "financialInstitutions", onDeleteClick, hasOnlyOneFinancialInstitution, banks, }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitleH3, { title: t("portalPj.financialInstitutionsTitle") }), financialInstitutions === null || financialInstitutions === void 0 ? void 0 :
        financialInstitutions.map((financialInstitution, index) => {
            const fieldName = getFormItemName(formKey, index);
            return (React.createElement(DeletableCard, { key: fieldName, deleteButtonProps: {
                    onClick: () => {
                        if (onDeleteClick) {
                            onDeleteClick(index);
                        }
                    },
                    disabled: !!financialInstitution.id || hasOnlyOneFinancialInstitution,
                }, hideDeleteButton: readOnly, "data-cy": "financial-institution-form-item" },
                !readOnly && (React.createElement(React.Fragment, null,
                    React.createElement(Form.Item, { label: "id", style: { display: "none" } },
                        getFieldDecorator(`${fieldName}.id`, {
                            initialValue: financialInstitution.id,
                        }),
                        React.createElement(Input, { disabled: readOnly })),
                    React.createElement(Form.Item, { label: "companyId", style: { display: "none" } },
                        getFieldDecorator(`${fieldName}.companyId`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.id,
                        }),
                        React.createElement(Input, { disabled: readOnly })))),
                React.createElement("div", { className: styles.firstRow },
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: t("companyForm.institutionLabel"), "data-cy": "financial-institution-name-input-wrapper" }, getFieldDecorator(`${fieldName}.name`, {
                            initialValue: financialInstitution.name,
                        })(React.createElement(Select, { showSearch: true, placeholder: "Selecionar institui\u00E7\u00E3o financeira", optionFilterProp: "children", disabled: readOnly }, banks === null || banks === void 0 ? void 0 : banks.map((bank) => (React.createElement(Option, { key: bank.name, value: bank.name },
                            bank.name,
                            " (",
                            bank.code,
                            ")"))))))),
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: t("phone"), "data-cy": "financial-institution-phone-input-wrapper" }, getFieldDecorator(`${fieldName}.phone`, {
                            initialValue: financialInstitution.phone,
                        })(React.createElement(Input, { disabled: readOnly }))))),
                React.createElement("div", { className: styles.secondRow },
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: t("financialInstitution.agency"), "data-cy": "financial-institution-agency-input-wrapper" }, getFieldDecorator(`${fieldName}.agency`, {
                            initialValue: financialInstitution.agency,
                        })(React.createElement(Input, { disabled: readOnly })))),
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: t("financialInstitution.checkingAccount"), "data-cy": "financial-institution-checking-account-input-wrapper" }, getFieldDecorator(`${fieldName}.account`, {
                            initialValue: financialInstitution.account,
                        })(React.createElement(Input, { disabled: readOnly })))))));
        })));
};
