import React from "react";
import s from "./style.scss";
const Table = ({ columns, data, className }) => {
    return (React.createElement("div", { className: `${s.containerTable} ${className}` },
        React.createElement("table", { className: s.table },
            React.createElement("thead", null,
                React.createElement("tr", null, columns.map((column) => (React.createElement("th", { key: column.key }, column.title))))),
            React.createElement("tbody", null, data.map((item, globalIndex) => (React.createElement("tr", { key: "" + globalIndex }, columns.map((column, index) => (React.createElement("td", { key: "" + globalIndex + index + column.dataIndex }, column.render ? column.render(item[column.dataIndex]) : item[column.dataIndex]))))))))));
};
export default Table;
