exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container___1bX3V {\n  display: flex;\n  flex-wrap: wrap; }\n\n.container___1bX3V > * {\n  flex: 0 0 30%;\n  max-width: 33.3333%;\n  margin: 12px; }\n\n@media (max-width: 768px) {\n  .container___1bX3V > * {\n    flex: 0 0 50%;\n    max-width: 50%; } }\n\n.documentsCounter___2msHr {\n  margin-top: -5px;\n  margin-bottom: 5px;\n  font-weight: 500; }\n\n.buttonCardEnabled___LkA34 {\n  border: 1px solid;\n  border-radius: 50%;\n  background-color: #484848;\n  border: none;\n  width: 1.8em;\n  height: 1.8em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer; }\n  .buttonCardEnabled___LkA34 svg {\n    transform: scale(1.25); }\n\n.buttonCardDisabled___3PTCu {\n  display: none; }\n", ""]);

// Exports
exports.locals = {
	"container": "container___1bX3V",
	"documentsCounter": "documentsCounter___2msHr",
	"buttonCardEnabled": "buttonCardEnabled___LkA34",
	"buttonCardDisabled": "buttonCardDisabled___3PTCu"
};