exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.header___1RMsM {\n  margin-bottom: 20px; }\n  .header___1RMsM .subtitleGrid___xlHh_ {\n    font-weight: bold;\n    font-size: 16px;\n    color: #01929f; }\n  .header___1RMsM .titleGrid___1liHd {\n    font-weight: bold;\n    color: #4D4D50; }\n\n.content___H4o7J {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%;\n  height: 60px;\n  margin-bottom: 25px;\n  gap: 20px; }\n\n.box___2OoUM {\n  width: 50%;\n  margin-bottom: 20px; }\n\n.inputTittle___2UP0I {\n  font-size: 13px;\n  font-weight: 700;\n  color: rgba(0, 0, 0, 0.85); }\n", ""]);

// Exports
exports.locals = {
	"header": "header___1RMsM",
	"subtitleGrid": "subtitleGrid___xlHh_",
	"titleGrid": "titleGrid___1liHd",
	"content": "content___H4o7J",
	"box": "box___2OoUM",
	"inputTittle": "inputTittle___2UP0I"
};