import { __awaiter } from "tslib";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import ActionTable from "../../../shared/components/ActionTable";
import { useStores } from "../../utils/mobx";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { columns } from "./column";
import { Pagination } from "../../../shared/components/Pagination";
import { setContextMenuDataHandler } from "../../utils/table.utils";
import { defaultContextMenuData } from "../Contracts";
import { Button } from "antd";
import { ShowIcon } from "../../../shared/components/Icons/Show";
import { NavLink } from "react-router-dom";
import { AdminRoutes } from "../../constants/routes.constants";
import styles from "./style.scss";
const SimulationsReport = () => {
    const { t } = useTranslation();
    const [simulations, setSimulations] = useState([]);
    const [totalSimulations, setTotalSimulations] = useState(0);
    const [loadingSimulations, setLoadingSimulations] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [contextMenuData, setContextMenuData] = useState(defaultContextMenuData);
    const { generalStore: { setErrorMessage, catchErrors }, ccbPjStore, } = useStores();
    const fetchSimulations = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingSimulations(true);
        try {
            const responseTotal = yield ccbPjStore.getCcbSimulationPjReportTotal();
            setTotalSimulations(responseTotal);
            const response = yield ccbPjStore.getCcbSimulationPjReport(currentPage, rowsPerPage);
            setSimulations(response.simulations);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("simulationsApiErrorMessage"));
        }
        finally {
            setLoadingSimulations(false);
        }
    });
    useEffect(() => {
        fetchSimulations();
    }, [currentPage, rowsPerPage]);
    const hasSimulations = simulations.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("simulationsLabel") }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                React.createElement(ActionTable, { className: styles.table, columns: columns, scroll: { y: 480 }, onRow: (simulation) => ({
                        onContextMenu: setContextMenuDataHandler(contextMenuData, setContextMenuData, simulation),
                    }), loading: loadingSimulations, dataSource: simulations.map((simulation) => (Object.assign(Object.assign({}, simulation), { key: simulation.id, actions: (React.createElement(NavLink, { to: `${AdminRoutes.Simulator.Simulation}/${simulation.id}` },
                            React.createElement(Button, { type: "link", className: styles.actionsSection },
                                t("showSimulationLabel"),
                                " ",
                                React.createElement(ShowIcon, null)))) }))) }))),
        React.createElement("div", { className: styles.newSimulationButtonWrapper },
            React.createElement(NavLink, { to: AdminRoutes.Simulator.Simulation },
                React.createElement(Button, { type: "primary", "data-cy": "new-simulation-data-cy", className: styles.newSimulation }, t("newSimulationLabel")))),
        React.createElement(SectionFooter, null, hasSimulations && (React.createElement(Pagination, { current: currentPage, total: totalSimulations, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
};
export default SimulationsReport;
