import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, InputNumber } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import { useStores } from "../../../../utils/mobx";
import { toInt } from "../../../../../shared/utils/formatters";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const EmployeeByTimeForm = observer(({ form, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { getFieldDecorator } = form;
    const formKey = "employeesByTime";
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.employeeAmountByWorkTimeTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.firstYearLabel") }, getFieldDecorator(`${formKey}.untilOneYear`, Object.assign(Object.assign({ initialValue: initialValues.untilOneYear }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.untilOneYear`) })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.betweenOneAndTwoLabel") }, getFieldDecorator(`${formKey}.oneTwoYears`, Object.assign(Object.assign({ initialValue: initialValues.oneTwoYears }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.oneTwoYears`) })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.betweenTwoAndThreeLabel") }, getFieldDecorator(`${formKey}.twoThreeYears`, Object.assign(Object.assign({ initialValue: initialValues.twoThreeYears }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.twoThreeYears`) })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.moreThanThreeLabel") }, getFieldDecorator(`${formKey}.threePlusYears`, Object.assign(Object.assign({ initialValue: initialValues.untilOneYear }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, placeholder: "14", onFocus: () => setSelectedItem(`${formKey}.threePlusYears`) })))))));
});
export default EmployeeByTimeForm;
