import { api } from "../AppWrapper";
import { DateFormat, getDateLocalTimezoneFormat } from "../../shared/utils/date";
export class CessionReportStore {
    constructor() {
        this.createCessions = (loansIds, assigneCompany, installments) => api.createCessions({ loansIds, assignCompany: assigneCompany, installments });
        this.getCessions = (filter, page, rowsPerPage) => api.getCessions({ filter, page, rowsPerPage });
        this.getCessionsTotal = (filter) => api.getCessionsTotal({ filter });
        this.confirmCessions = (cessionsToConfirm, borderoNumber) => api.confirmCessions({ cessionsToConfirm, borderoNumber });
        this.cancelCessions = (cessionsIds) => api.cancelCessions({ cessionIds: cessionsIds });
        this.exportCessions = (filter) => api.exportCessions({ filter });
        this.calculateCessionValueByDate = (dateMoment, loanId) => api.calculateCessionValueByDate({
            date: getDateLocalTimezoneFormat(dateMoment, DateFormat.ISO),
            loanId,
        });
        this.getOpenInstallmentsGivenByCession = (cessionId) => api.getOpenInstallmentsGivenByCession({ cessionId });
        this.repurchaseGivenInstallments = (cessionId, repurchaseValue, repurchaseDate, installmentsDueDate) => api.repurchaseGivenInstallments({
            cessionId,
            repurchaseValue,
            repurchaseDate,
            installmentsDueDate,
        });
    }
}
