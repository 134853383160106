exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.cardWrapper___1fV15,\n.cardAttentionWrapper___3KCX6 {\n  display: grid;\n  grid-template-columns: auto 25px;\n  border: 1px solid #e5e5e5;\n  border-radius: 8px;\n  padding: 15px;\n  margin-bottom: 30px;\n  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.13);\n  padding-bottom: 20px; }\n\n.cardAttentionWrapper___3KCX6 {\n  border-radius: 0px;\n  background-color: #fffaeb;\n  box-shadow: none;\n  border: 1px solid #ffe9a7;\n  border-radius: 4px;\n  color: #8f8f8f; }\n\n.deleteButtonWrapper___Aa5QP,\n.deleteButtonAttentionWrapper___3XC5c {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end; }\n  .deleteButtonWrapper___Aa5QP:hover path,\n  .deleteButtonAttentionWrapper___3XC5c:hover path {\n    fill: #ff3737; }\n\n.deleteButtonAttentionWrapper___3XC5c button {\n  height: 20px; }\n\n.deleteButtonAttentionWrapper___3XC5c path {\n  fill: #8f8f8f; }\n", ""]);

// Exports
exports.locals = {
	"cardWrapper": "cardWrapper___1fV15",
	"cardAttentionWrapper": "cardAttentionWrapper___3KCX6",
	"deleteButtonWrapper": "deleteButtonWrapper___Aa5QP",
	"deleteButtonAttentionWrapper": "deleteButtonAttentionWrapper___3XC5c"
};