import { __awaiter, __rest } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
import { observer } from "mobx-react-lite";
import Form from "antd/lib/form";
import { Title } from "../../../../shared/components/Title";
import CreditPolicyForm from "./CreditPolicyForm";
import SignedAgreementForm from "./SignedAgreementForm";
import { Button, Row, Col } from "antd";
import { DateFormat, getDateLocalTimezoneFormat } from "../../../../shared/utils/date";
const AgreementInnerForm = observer((_a) => {
    var { form, initialValues, disableFields, companyId, downloadAgreementFile, openConfirmCancel, setShouldShowMinWorkTimeForTermExtension, setShouldShowInterestRateRange } = _a, props = __rest(_a, ["form", "initialValues", "disableFields", "companyId", "downloadAgreementFile", "openConfirmCancel", "setShouldShowMinWorkTimeForTermExtension", "setShouldShowInterestRateRange"]);
    const { validateFields } = form;
    const { t } = useTranslation();
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                const { file } = values.file;
                const reader = new FileReader();
                reader.onload = () => __awaiter(void 0, void 0, void 0, function* () {
                    const buffer = Buffer.from(reader.result.toString().split(",")[1], "base64");
                    const agreement = Object.assign(Object.assign({}, values), { filename: file.name, dueDate: values.dueDate
                            ? getDateLocalTimezoneFormat(values.dueDate, DateFormat.ISO)
                            : null });
                    props.handleSubmit(agreement, buffer);
                });
                reader.readAsDataURL(file);
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.formContainer },
            React.createElement(Title, { title: t("agreement.agreementLabel") }),
            initialValues && (React.createElement("span", null,
                React.createElement("strong", null, initialValues.active ? t("agreement.activeLabel") : t("agreement.cancelledLabel")))),
            React.createElement(Form, { className: s.form, layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit, "data-cy": "agreement-form-data-cy" },
                React.createElement(SignedAgreementForm, { disabled: disableFields, form: form, filename: initialValues ? initialValues.filename : null, downloadAgreementFile: downloadAgreementFile }),
                React.createElement(CreditPolicyForm, { disabled: disableFields, initialValues: initialValues ? Object.assign({}, initialValues) : null, form: form, setShouldShowMinWorkTimeForTermExtension: setShouldShowMinWorkTimeForTermExtension, setShouldShowInterestRateRange: setShouldShowInterestRateRange }),
                React.createElement(Row, { gutter: 20, justify: "end" },
                    React.createElement(Col, { span: 16 },
                        React.createElement("div", { className: s.buttonsRow }, disableFields ? (React.createElement(Button, { type: "primary", onClick: openConfirmCancel, disabled: initialValues ? initialValues.active === false : false }, t("agreement.cancelAgreementLabel"))) : (React.createElement(Button, { type: "primary", htmlType: "submit" }, t("agreement.createAgreementLabel"))))))))));
});
export const AgreementForm = Form.create({
    name: "agreement_form",
})(AgreementInnerForm);
