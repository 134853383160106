import React, { useCallback, useContext, useMemo } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import ActionTable from "../../../../shared/components/ActionTable";
import { formatCpf } from "../../../../shared/utils/formatters";
import { useStores } from "../../../utils/mobx";
import { defaultConfirmProps } from "../../../../shared/utils";
import { AdminContext } from "../../../contexts/AdminContext";
import s from "./style.scss";
const confirm = Modal.confirm;
const AdminTable = ({}) => {
    const { t } = useTranslation();
    const { admins, deleteAdmin } = useContext(AdminContext);
    const { adminStore: { setAdminToEdit }, } = useStores();
    const tableColumns = useMemo(() => [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            width: 300,
            fixed: "left",
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: t("companyEmployee.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Perfil",
            dataIndex: "profile",
            key: "profile",
            render: (profile) => profile === null || profile === void 0 ? void 0 : profile.title,
        },
        {
            title: "",
            key: "actions",
            dataIndex: "actions",
        },
    ], []);
    const handleDelete = useCallback((user) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: t("users.deleteUserLabel"), content: (React.createElement(React.Fragment, null,
                React.createElement("p", { style: { marginTop: 10 } }, t("users.removalMessage", { userName: user.name })))), width: 600, okText: t("removeLabel"), onOk: () => {
                deleteAdmin(user);
            } }));
    }, [deleteAdmin]);
    const tableDataSource = useMemo(() => admins.map((admin) => (Object.assign(Object.assign({}, admin), { key: admin.id, cpf: formatCpf(admin.cpf), actions: (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "link", onClick: () => {
                    setAdminToEdit(admin);
                } }, t("editUpperLabel")),
            " | ",
            React.createElement(Button, { type: "link", onClick: () => handleDelete(admin) }, t("removeUpperLabel")))) }))), [admins]);
    return (React.createElement(ActionTable, { className: s.table, columns: tableColumns, loading: false, scroll: { x: 1500 }, dataSource: tableDataSource }));
};
export default AdminTable;
