import React from "react";
import styles from "./style.scss";
export const ConcealableTableHeaderRow = ({ columns, selectedItems, totalOfItems, onToogleClick, show = false, hideToogleButton = false, action, children, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", null,
            children[0],
            columns.map((_, idx) => {
                return children[idx + 1];
            })),
        React.createElement("tr", { className: styles.tableHeaderRowWrapper },
            React.createElement("td", { className: "ant-table-selection-column" }),
            React.createElement("td", null,
                React.createElement("div", { className: styles.tableHeaderRow },
                    React.createElement("span", { className: styles.counter }, `${action} (${selectedItems} de ${totalOfItems} serviços)`),
                    !hideToogleButton && (React.createElement("a", { onClick: onToogleClick, "data-cy": "hide-show-table-items-button" },
                        show ? (React.createElement("span", { className: "show-active" }, "Esconder")) : (React.createElement("span", { className: "hide-active" }, "Exibir")),
                        ` ${totalOfItems - selectedItems} restantes`)))),
            React.createElement("td", { className: "ant-table-row-cell-last" }))));
};
