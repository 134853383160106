import React, { useState } from "react";
import { Form, Steps } from "antd";
import { ContractDataForm } from "./ContractDataForm";
import { contractCcbPjSteps } from "./ContractStepsForm/Steps/steps";
import { cut } from "../../../shared/utils/formatters";
import { StepsPages } from "./ContractStepsForm/StepsPages";
import styles from "./style.scss";
const { Step } = Steps;
const BaseSimulatorRequest = ({ form }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const navigateToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const navigateToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Steps, { size: "small", direction: "horizontal", progressDot: true, current: currentStep, className: styles.stepsWrapper }, contractCcbPjSteps.map((step) => (React.createElement(Step, { key: step.title, title: cut(step.title, 25), "data-cy": step.dataCy, className: styles.stepWrapper })))),
        React.createElement("div", { className: styles.container },
            React.createElement(ContractDataForm, { currentStep: currentStep, form: form }),
            React.createElement("div", { className: styles.contentSection },
                React.createElement(StepsPages, { currentStep: currentStep, navigateToNextStep: navigateToNextStep, navigateToPreviousStep: navigateToPreviousStep, form: form })))));
};
export const SimulatorRequest = Form.create({
    name: "simulator_request_form",
})(BaseSimulatorRequest);
