import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Input, Row, Col, Radio } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import AddressForm from "../../../../components/AdressForm";
import { useStores } from "../../../../utils/mobx";
import s from "../style.scss";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const ChargeAddressForm = observer(({ form, initialValues }) => {
    const { getFieldDecorator } = form;
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const [sameAddress, setSameAddress] = React.useState(true);
    React.useEffect(() => {
        if (initialValues.sameAddress !== null) {
            setSameAddress(initialValues.sameAddress);
        }
    }, []);
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.chargeAddressTitle") }),
        React.createElement(Form.Item, null, getFieldDecorator("chargeAddress.sameAddress", {
            initialValue: initialValues ? initialValues.sameAddress : true,
        })(React.createElement(Radio.Group, { className: s.horizontalRadio, onChange: (e) => setSameAddress(e.target.value) },
            React.createElement(Radio, { value: true, checked: true }, t("companyForm.sameAddressLabel")),
            React.createElement(Radio, { value: false }, t("companyForm.otherAddressLabel"))))),
        React.createElement(AddressForm, { form: form, formKey: "chargeAddress.address", readOnly: sameAddress, initialValues: sameAddress ? form.getFieldValue("address") : initialValues.address }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 16 },
                React.createElement(Form.Item, { label: t("companyForm.otherContactsLabel") }, getFieldDecorator("chargeAddress.otherContact", Object.assign({ initialValue: initialValues.otherContact }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { allowClear: true, placeholder: t("companyForm.lobbyTelephoneLabel") + " (71) 9999-9999", disabled: sameAddress, onFocus: () => setSelectedItem(`chargeAddress.otherContact`), maxLength: 500 })))))));
});
export default ChargeAddressForm;
