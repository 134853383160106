import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useStores } from "../../utils/mobx";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SubSectionHeader } from "../../../shared/components/SubSectionHeader";
import { TableOfContents } from "../../components/TableOfContents";
import styles from "./style.scss";
import { fgtsParametersFields } from "./types";
import { FgtsParametersForm } from "./FgtsParametersForm";
const FgtsParameters = () => {
    const { t } = useTranslation();
    const [fgtsParameters, setFgtsParameters] = useState();
    const [loading, setLoading] = useState(false);
    const { generalStore: { catchErrors, setSuccessMessage }, generalStore, fgtsParametersStore, } = useStores();
    const fetchFgtsParameters = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const fetchedFgtsParameters = yield fgtsParametersStore.getFgtsParameters();
            setFgtsParameters(fetchedFgtsParameters);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("fgtsParameters.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        fetchFgtsParameters();
    }, []);
    const setFocus = (id) => {
        const element = document.getElementById(`fgts_parameters_form_${id}`) ||
            document.querySelector(`input[id~="${id}"]`);
        if (element) {
            element.focus();
        }
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (fgtsParameters) {
            try {
                setLoading(true);
                yield fgtsParametersStore.updateFgtsParameters(Object.assign(Object.assign({}, values), { id: fgtsParameters.id }));
                setSuccessMessage(t("fgtsParameters.defaultSuccessMessage"));
            }
            catch (error) {
                catchErrors(error, generalStore.setErrorMessage, t("fgtsParameters.defaultErrorMessage"));
            }
            finally {
                setLoading(false);
            }
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("fgtsParameters.title"), description: t("fgtsParameters.subtitle") }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(Spin, { spinning: loading },
                React.createElement(SubSectionHeader, { title: t("fgtsParameters.parameters").toUpperCase() }),
                React.createElement("div", { className: styles.rowContainer },
                    React.createElement("div", { className: styles.side },
                        React.createElement(TableOfContents, { title: t("fgtsParameters.parametersLabel"), items: fgtsParametersFields, handleSelectItem: setFocus })),
                    React.createElement(FgtsParametersForm, { initialValues: fgtsParameters, onSubmit: handleSubmit }))))));
};
export default FgtsParameters;
