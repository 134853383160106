import { __awaiter } from "tslib";
import React, { useState } from "react";
import isValidCnpj from "@brazilian-utils/is-valid-cnpj";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Input, Row, Col, Radio, Spin } from "antd";
import onlyNumbers from "@brazilian-utils/helper-only-numbers";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import { useStores } from "../../../../utils/mobx";
import { checkCNPJ } from "../../../../../shared/utils/formValidator";
import InputNumber from "../../../../../shared/components/InputNumber";
import s from "../style.scss";
import { getCompanyInfoByCnpj } from "../../../../../shared/services/cnpj";
import { CompanyFormContext } from "../context";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const GeneralDataFields = observer(({ form, initialValues, disabled, companyId }) => {
    const { getFieldDecorator } = form;
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { companyInfo, setCompanyInfo, handleChangeCompanyInfo, handleChangeAddress } = React.useContext(CompanyFormContext);
    const [loading, setLoading] = React.useState(false);
    const formKey = "general";
    const [stateSubscription, setStateSubscription] = useState("");
    const { t } = useTranslation();
    const decorator = formKey || "general";
    React.useEffect(() => {
        if (initialValues) {
            setCompanyInfo(initialValues);
        }
    }, []);
    const setFormState = React.useCallback(() => {
        form.setFieldsValue({
            [decorator]: Object.assign({}, companyInfo),
        });
    }, [companyInfo]);
    const getInitialValueHasExemption = () => {
        if (companyId) {
            if (initialValues.stateSubscription) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    };
    function findCompanyInfo(cnpj) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isValidCnpj(cnpj)) {
                return;
            }
            try {
                setLoading(true);
                const fetchedCompanyInfo = yield getCompanyInfoByCnpj(cnpj);
                setCompanyInfo(Object.assign(Object.assign({}, companyInfo), { socialName: fetchedCompanyInfo.socialName, fantasyName: fetchedCompanyInfo.fantasyName }));
                handleChangeAddress("cep", fetchedCompanyInfo.cep);
                form.setFieldsValue({
                    [decorator]: Object.assign(Object.assign({}, companyInfo), fetchedCompanyInfo),
                });
            }
            catch (e) {
                const error = e;
                console.error(error.message);
            }
            finally {
                setLoading(false);
            }
        });
    }
    const handleStateSubscriptionChange = (event) => {
        setStateSubscription(event.target.value);
        handleChangeCompanyInfo("stateSubscription", event.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.generalInfoTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: "CNPJ" }, getFieldDecorator(`${formKey}.cnpj`, Object.assign({ initialValue: initialValues.cnpj, rules: [
                        {
                            validator: checkCNPJ,
                        },
                    ], normalize: (value) => onlyNumbers(value) }, decoratorConfigDefaultOnBlur))(React.createElement(MaskedInput, { autoFocus: true, allowClear: !disabled, mask: "11.111.111/1111-11", onFocus: () => setSelectedItem(`${formKey}.cnpj`), disabled: disabled, onBlur: (e) => {
                        findCompanyInfo(e.target.value);
                    }, onChange: (e) => handleChangeCompanyInfo("cnpj", e.target.value) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 2 },
                React.createElement(Form.Item, { label: t("companyForm.hasExemptionLabel") }, getFieldDecorator(`${formKey}.hasExemption`, {
                    initialValue: getInitialValueHasExemption(),
                })(React.createElement(Radio.Group, { disabled: disabled, className: s.horizontalRadio, onChange: (e) => {
                        if (e.target.value) {
                            setStateSubscription("");
                            form.setFields({
                                general: {
                                    stateSubscription: {
                                        value: "",
                                        errors: null,
                                    },
                                },
                            });
                        }
                    } },
                    React.createElement(Radio, { value: true }, t("yesLabel")),
                    React.createElement(Radio, { value: false }, t("noLabel")))))),
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.stateSubscriptionLabel") }, getFieldDecorator(`${formKey}.stateSubscription`, Object.assign({ initialValue: initialValues.stateSubscription || stateSubscription, rules: [
                        {
                            required: !form.getFieldValue("general.hasExemption"),
                            message: t("companyForm.stateSubscriptionMessage"),
                        },
                    ], normalize: (value) => value.replace(/\D/g, "") }, decoratorConfigDefaultOnBlur))(React.createElement(InputNumber, { disabled: form.getFieldValue("general.hasExemption") || disabled, allowClear: !disabled, onFocus: () => setSelectedItem(`${formKey}.stateSubscription`), value: stateSubscription || initialValues.stateSubscription || "", onChange: handleStateSubscriptionChange, maxLength: 40 }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 16 },
                React.createElement(Spin, { spinning: loading },
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 24 },
                            React.createElement(Form.Item, { label: t("companyForm.socialNameLabel") }, getFieldDecorator(`${formKey}.socialName`, Object.assign({ initialValue: initialValues.socialName, rules: [
                                    {
                                        required: true,
                                        message: t("companyForm.socialNameMessage"),
                                    },
                                ] }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { placeholder: t("companyForm.socialNamePlaceholder"), allowClear: true, onFocus: () => setSelectedItem(`${formKey}.socialName`), value: companyInfo.socialName, onChange: (e) => handleChangeCompanyInfo("socialName", e.target.value), onBlur: setFormState, maxLength: 500 }))))),
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 24 },
                            React.createElement(Form.Item, { label: t("companyForm.fantasyNameLabel") }, getFieldDecorator(`${formKey}.fantasyName`, Object.assign({ initialValue: initialValues.fantasyName }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { maxLength: 500, placeholder: t("companyForm.fantasyNamePlaceholder"), allowClear: true, onFocus: () => setSelectedItem(`${formKey}.fantasyName`), value: companyInfo.fantasyName || "", onChange: (e) => handleChangeCompanyInfo("fantasyName", e.target.value), onBlur: setFormState }))))))))));
});
export default GeneralDataFields;
