import i18n from "../../i18n";
export const fgtsParametersFields = [
    {
        label: i18n.t("fgtsParameters.generalInfoTitle"),
        id: "fgtsGeneralParameters",
        subItems: [
            {
                label: i18n.t("fgtsParameters.interestRate"),
                id: "interestRate",
            },
            {
                label: i18n.t("fgtsParameters.structuringRate"),
                id: "structuringRate",
            },
            {
                label: i18n.t("fgtsParameters.tedValue"),
                id: "tedValue",
            },
            {
                label: i18n.t("fgtsParameters.thirdPartyValue"),
                id: "thirdPartyValue",
            },
            {
                label: i18n.t("fgtsParameters.antecipationPeriodsNumber"),
                id: "antecipationPeriodsNumber",
            },
        ],
    },
];
