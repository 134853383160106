import { __awaiter } from "tslib";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { TableWithFilter } from "../../../../shared/components/TableWithFilter";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import { mergeArrayOfObjects, sortItemsByName } from "../../../../shared/utils/colection";
const mapCompaniesGroupToTableDataSource = (companies) => companies === null || companies === void 0 ? void 0 : companies.map((company) => (Object.assign(Object.assign({}, company), { key: company.id, cnpj: formatCnpj(company.cnpj) })));
const CompaniesTable = observer(({}) => {
    const { t } = useTranslation();
    const [total, setTotal] = useState(0);
    const { companyStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const { companies, companiesToAdd, filters, setFilter, selectedCompaniesIds, toogleRelatedFilter, addCompany, removeCompany, } = useContext(CompaniesGroupContext);
    const getCompaniesTotal = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const fetchedTotal = yield companyStore.getCompaniesTotal(null);
            setTotal(fetchedTotal);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    useEffect(() => {
        getCompaniesTotal();
    }, []);
    const columns = [
        { title: t("cnpjLabel"), dataIndex: "cnpj", key: "cnpj", width: "30%" },
        { title: t("razaoSocialLabel"), dataIndex: "name", key: "name" },
    ];
    const usersTableSource = useMemo(() => {
        let companiesToShow = companies;
        const isFilteringByRelated = filters.related;
        if (isFilteringByRelated) {
            const companiesMergedWithoutDuplication = mergeArrayOfObjects(companies, companiesToAdd, "id");
            const companiesSelected = companiesMergedWithoutDuplication.filter(({ id }) => selectedCompaniesIds.includes(id));
            const companiesSelectedSortedByName = sortItemsByName(companiesSelected);
            companiesToShow = companiesSelectedSortedByName;
        }
        const companiesFilteredMapedToDataSource = mapCompaniesGroupToTableDataSource(companiesToShow);
        return companiesFilteredMapedToDataSource;
    }, [companiesToAdd, companies, filters.related]);
    const isHidingUnrelated = !!(filters === null || filters === void 0 ? void 0 : filters.related);
    const filterItems = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("companiesGroups.razaoSocialAndFantasyNamePlaceholder"),
        },
        {
            key: "cnpj",
            name: "cnpj",
            type: "input",
            placeholder: t("cnpjLabel"),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(TableWithFilter, { columns: columns, dataSource: usersTableSource, selectedRowKeys: selectedCompaniesIds, filterProps: { items: filterItems }, onFilterChange: (values) => {
                setFilter(values.name, values.cnpj);
            }, table: {
                headerProps: {
                    action: t("associate"),
                    hideToogleButton: isHidingUnrelated,
                    selectedItems: Number(selectedCompaniesIds === null || selectedCompaniesIds === void 0 ? void 0 : selectedCompaniesIds.length),
                    totalOfItems: total,
                    show: !isHidingUnrelated,
                    onToogleClick: () => toogleRelatedFilter(),
                },
                rowSelection: {
                    onSelect: (company, selected) => selected ? addCompany(company) : removeCompany(company),
                },
            } })));
});
export default CompaniesTable;
