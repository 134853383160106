import { __awaiter } from "tslib";
import { api } from "../AppWrapper";
export class LoanApplicationStore {
    constructor() {
        this.getLoanRequests = (filter, page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () { return api.getLoanRequests({ filter, page, rowsPerPage }); });
        this.changeStatusFromLoanRequest = (status, requestIds, transferAmount, blockTemporaryUser) => __awaiter(this, void 0, void 0, function* () {
            if (requestIds) {
                return api.changeStatusFromLoanRequest({
                    requestIds,
                    status,
                    transferAmount,
                    blockTemporaryUser,
                });
            }
        });
        this.getLoanRequestTotal = (filter) => __awaiter(this, void 0, void 0, function* () { return api.getLoanRequestTotal({ filter }); });
    }
}
