exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".date___1ohlZ {\n  width: 100%; }\n\n.form___gUI7n div[class$=\"ant-form-explain\"] {\n  font-size: 12px; }\n\n.form___gUI7n div[class$=\"ant-modal-body\"] {\n  padding: 10px 24px 0px 24px; }\n\n.form___gUI7n div[class$=\"ant-form\"] label {\n  font-size: 14px; }\n\ndiv[class*=\"ant-modal-body\"] {\n  padding: 10px 25px 0px 25px; }\n\n.cessionInputItem___3mg_t {\n  padding: 0 15px; }\n\n.cessionCollapseTitle___2xnc3 {\n  display: flex;\n  justify-content: space-between; }\n\n.userData___2__HY {\n  font-size: 11px;\n  font-weight: bold;\n  color: #ff7600; }\n\n.cessionBalance___rJiNQ {\n  color: green;\n  font-weight: bold; }\n", ""]);

// Exports
exports.locals = {
	"date": "date___1ohlZ",
	"form": "form___gUI7n",
	"cessionInputItem": "cessionInputItem___3mg_t",
	"cessionCollapseTitle": "cessionCollapseTitle___2xnc3",
	"userData": "userData___2__HY",
	"cessionBalance": "cessionBalance___rJiNQ"
};