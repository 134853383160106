import React from "react";
import { Col, Input, InputNumber, InputNumber as AntdInputNumber, Radio, Row } from "antd";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormTitleH3 } from "../../../FormTitleH3";
import { Card } from "../../../CustomCard";
import { formatCurrencyNoPrefix, parseBigIntToString, toInt } from "../../../../utils/formatters";
import InputCurrency from "../../../InputCurrency";
export const FinancialDataFields = ({ form, companyInfo, readOnly, formKey = "financialData", onBranchesRadioChange }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitleH3, { title: t("companyForm.activityAndFinancialDataTitle") }),
        React.createElement(Card, null,
            React.createElement(Row, { gutter: 20 },
                React.createElement(Col, { span: 16 },
                    React.createElement(Form.Item, { label: t("companyForm.activityLabel"), "data-cy": "activity-input-wrapper" }, getFieldDecorator(`${formKey}.activity`, {
                        initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.activity,
                    })(React.createElement(Input, { allowClear: true, maxLength: 255, placeholder: readOnly ? "" : t("companyForm.activityLabel"), disabled: readOnly }))))),
            React.createElement(Row, { gutter: 20 },
                React.createElement(Col, { span: 8 },
                    React.createElement(Form.Item, { label: t("companyForm.employeeNumberLabel"), "data-cy": "employees-total-input-wrapper" }, getFieldDecorator(`${formKey}.employeesTotal`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.employeesTotal),
                        normalize: (value) => toInt(value),
                    })(React.createElement(AntdInputNumber, { min: 1, placeholder: readOnly ? "" : "6000", disabled: readOnly })))),
                React.createElement(Col, { span: 5 },
                    React.createElement(Form.Item, { label: t("companyForm.hasImportLabel"), "data-cy": "has-import-input-wrapper" }, getFieldDecorator(`${formKey}.hasImport`, {
                        initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.hasImport,
                    })(React.createElement(Radio.Group, { disabled: readOnly },
                        React.createElement(Radio, { value: true }, t("yesLabel")),
                        React.createElement(Radio, { value: false }, t("noLabel")))))),
                React.createElement(Col, { span: 5 },
                    React.createElement(Form.Item, { label: t("companyForm.hasExportLabel"), "data-cy": "has-export-input-wrapper" }, getFieldDecorator(`${formKey}.hasExport`, {
                        initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.hasExport,
                    })(React.createElement(Radio.Group, { disabled: readOnly },
                        React.createElement(Radio, { value: true }, t("yesLabel")),
                        React.createElement(Radio, { value: false }, t("noLabel"))))))),
            React.createElement(Row, { gutter: 20 },
                React.createElement(Col, { span: 4, "data-cy": "average-shoppingvalue-input-wrapper" },
                    React.createElement(Form.Item, { label: t("companyForm.shoppingValueLabel"), help: t("companyForm.average12MonthsLabel") }, getFieldDecorator(`${formKey}.averageShoppingValue`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.averageShoppingValue),
                        normalize: (value) => toInt(value),
                    })(React.createElement(InputCurrency, { min: 0, disabled: readOnly })))),
                React.createElement(Col, { span: 5 },
                    React.createElement(Form.Item, { label: t("companyForm.averageRevenueLabel"), help: t("companyForm.average12MonthsLabel") }, getFieldDecorator(`${formKey}.averageRevenueValue`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.averageRevenueValue),
                        normalize: (value) => toInt(value),
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("companyForm.averageRevenueLabel") }),
                            },
                        ],
                    })(React.createElement(InputCurrency, { min: 0, disabled: readOnly })))),
                React.createElement(Col, { span: 9 },
                    React.createElement(Form.Item, { label: t("companyForm.averageTimeToSalesLabel"), help: t("companyForm.inMonthLabel") }, getFieldDecorator(`${formKey}.averageTimeToReceiveSales`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.averageTimeToReceiveSales),
                        normalize: (value) => toInt(value),
                    })(React.createElement(AntdInputNumber, { min: 0, placeholder: readOnly ? "" : "12", disabled: readOnly }))))),
            React.createElement(Row, { gutter: 20 },
                React.createElement(Col, { span: 10 },
                    React.createElement(Form.Item, { label: t("companyForm.utilizationOfCapacityLabel") }, getFieldDecorator(`${formKey}.utilizationOfInstalledCapacity`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.utilizationOfInstalledCapacity),
                        normalize: (value) => toInt(value),
                    })(React.createElement(AntdInputNumber, { formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, max: 10000, disabled: readOnly })))),
                React.createElement(Col, { span: 8 },
                    React.createElement(Form.Item, { label: t("companyForm.marketShareLabel") }, getFieldDecorator(`${formKey}.marketShare`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.marketShare),
                        normalize: (value) => toInt(value),
                    })(React.createElement(AntdInputNumber, { formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, max: 10000, disabled: readOnly }))))),
            React.createElement(Row, { gutter: 20 },
                React.createElement(Col, { span: 3 },
                    React.createElement(Form.Item, { label: t("companyForm.hasBranchesLabel"), "data-cy": "has-branches-radio-group" }, getFieldDecorator(`${formKey}.hasBranches`, {
                        initialValue: (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.hasBranches) || false,
                    })(React.createElement(Radio.Group, { onChange: onBranchesRadioChange, disabled: readOnly },
                        React.createElement(Radio, { value: true }, t("yesLabel")),
                        React.createElement(Radio, { value: false }, t("noLabel")))))),
                React.createElement(Col, { span: 5 },
                    React.createElement(Form.Item, { label: t("companyForm.branchesAmountLabel"), "data-cy": "branches-amount-input" }, getFieldDecorator(`${formKey}.branchesAmount`, {
                        initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.branchesAmount),
                        normalize: (value) => toInt(value),
                    })(React.createElement(InputNumber, { min: 0, maxLength: 5, placeholder: readOnly ? "" : "2", disabled: !form.getFieldValue(`${formKey}.hasBranches`) || readOnly })))),
                React.createElement(Col, { span: 8 },
                    React.createElement(Form.Item, { label: t("companyForm.branchesStateLabel"), "data-cy": "branches-states-input" }, getFieldDecorator(`${formKey}.branchesStates`, {
                        initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.financialData.branchesStates,
                    })(React.createElement(Input, { maxLength: 255, placeholder: readOnly ? "" : "Bahia, Sergipe, São Paulo", disabled: !form.getFieldValue(`${formKey}.hasBranches`) || readOnly }))))))));
};
