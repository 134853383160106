import { __awaiter } from "tslib";
import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { useStores } from "../../../../utils/mobx";
import { useTranslation } from "react-i18next";
const CancelationModal = ({ visible, onCancel, contract, onSuccess, }) => {
    const { t } = useTranslation();
    const { contractStore: { cancelCcbByAdmin }, generalStore: { catchErrors, setSuccessMessage }, generalStore, } = useStores();
    const [loading, setLoading] = useState(false);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (contract) {
            yield cancelCcb(contract.id);
        }
    });
    const cancelCcb = (contractId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield cancelCcbByAdmin(contractId);
            setSuccessMessage(t("cancelation.cancelationSuccessMessage"));
            onSuccess();
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("cancelation.defaultErrorMessage"));
        }
        finally {
            setLoading(false);
            onCancel();
        }
    });
    return (React.createElement(Modal, { title: t("cancelation.cancelCcbTitle", { ccbNumber: contract === null || contract === void 0 ? void 0 : contract.ccbNumber }), visible: visible, okText: t("confirmLabel"), onCancel: onCancel, onOk: handleSubmit, maskClosable: true, destroyOnClose: true, width: "30%" },
        React.createElement(Spin, { spinning: loading }, t("cancelation.cancelCcbText"))));
};
export default CancelationModal;
