import { __awaiter } from "tslib";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useReactRouter from "use-react-router";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import CompaniesTable from "../../../components/CompaniesGroup/CompaniesTable";
import { Button, Spin } from "antd";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompaniesGroupCompanies = observer(() => {
    const { match: { params: { id }, }, } = useReactRouter();
    const { t } = useTranslation();
    const { associateCompaniesGroupsCompanies, selectedCompaniesGroup, loading, companiesGroupId, setCompaniesGroupId, fetchFilterdCompanies, filters, } = useContext(CompaniesGroupContext);
    const handleUpdateCompaniesGroup = () => __awaiter(void 0, void 0, void 0, function* () {
        yield associateCompaniesGroupsCompanies();
    });
    const fetchCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        yield fetchFilterdCompanies();
    });
    useEffect(() => {
        setCompaniesGroupId(id);
    }, [id]);
    useEffect(() => {
        fetchCompanies();
    }, [filters, companiesGroupId]);
    return (React.createElement("div", null,
        React.createElement(SectionHeader, { key: selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.name, hasBackButton: true, linkToBackButton: `${AdminRoutes.CompaniesGroups.Actions}/${companiesGroupId}`, title: t("companiesGroups.companies.title"), description: t("companiesGroups.companies.detail", {
                companiesGroupName: selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.name,
            }) }),
        React.createElement(Spin, { spinning: loading, "data-cy": "companies-table-wrapper" },
            React.createElement(CompaniesTable, null)),
        React.createElement("div", { className: styles.actionsWrapper },
            React.createElement(Button, { type: "primary", size: "large", style: { width: 150 }, onClick: handleUpdateCompaniesGroup, "data-cy": "update-companies-group-button-data-cy" }, t("saveLabel")))));
});
export default CompaniesGroupCompanies;
