import { __awaiter, __decorate } from "tslib";
import { computed, action, observable } from "mobx";
import { api } from "../AppWrapper";
import { AdminRoutes } from "../constants/routes.constants";
import { findRouteByModule, getAdminNavbarRoutes } from "../utils/routes.utils";
export class AuthStore {
    constructor(rootStore) {
        this.updateUserFromLocalStorage = () => {
            const user = localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user"))
                : null;
            this.user = user;
        };
        this.updateUserFromApi = () => __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.user) {
                    this.user = yield api.getCurrentAdmin();
                    this.setLocalStorageAdminUser();
                }
            }
            catch (err) {
                yield this.logout();
            }
        });
        this.login = (email, password) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.user = yield api.login({ email, password });
                if (this.user) {
                    this.setLocalStorageAdminUser();
                    this.redirectToAdminInittialRoute();
                }
            }
            catch (err) {
                throw err;
            }
        });
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            yield api.logout();
            this.localLogout();
        });
        this.changePassword = (currentPassword, newPassword, passwordConfirm) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.changePassword({
                    currentPassword,
                    newPassword,
                    confirmNewPassword: passwordConfirm,
                });
                this.redirectToRoute(AdminRoutes.LoansRequests);
            }
            catch (err) {
                throw err;
            }
        });
        this.localLogout = () => {
            localStorage.removeItem("user");
            this.user = null;
            this.redirectToRoute("/login");
        };
        this.createPassword = (requestId, password, confirmPassword) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.registerNewPassword({ requestId, password, confirmPassword });
            }
            catch (err) {
                throw err;
            }
        });
        this.registerNewPassword = (requestId, password, confirmPassword) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.registerNewPassword({ requestId, password, confirmPassword });
                this.redirectToRoute("/login");
            }
            catch (err) {
                throw err;
            }
        });
        this.emailFromRequestId = (requestId) => __awaiter(this, void 0, void 0, function* () {
            try {
                return yield api.getEmailFromRequest({ requestId });
            }
            catch (err) {
                throw err;
            }
        });
        this.requestPasswordChange = (email) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.requestPasswordChange({ email });
            }
            catch (err) {
                throw err;
            }
        });
        this.rootStore = rootStore;
        this.updateUserFromLocalStorage();
    }
    get isLoggedIn() {
        return !!this.user;
    }
    setLocalStorageAdminUser() {
        localStorage.setItem("user", JSON.stringify(this.user));
    }
    redirectToRoute(route) {
        return this.rootStore.routerStore.push(route);
    }
    redirectToAdminInittialRoute() {
        var _a;
        const adminNavbarRoutes = getAdminNavbarRoutes((_a = this.user) === null || _a === void 0 ? void 0 : _a.accessModules);
        const requestsRoute = findRouteByModule("requests", adminNavbarRoutes);
        if (requestsRoute) {
            this.redirectToRoute(requestsRoute.to);
        }
        else {
            const [firstNavbarRoute] = adminNavbarRoutes;
            this.redirectToRoute(firstNavbarRoute.items ? firstNavbarRoute.items[0].to : firstNavbarRoute.to);
        }
    }
}
__decorate([
    observable
], AuthStore.prototype, "user", void 0);
__decorate([
    computed
], AuthStore.prototype, "isLoggedIn", null);
__decorate([
    action
], AuthStore.prototype, "updateUserFromLocalStorage", void 0);
__decorate([
    action
], AuthStore.prototype, "updateUserFromApi", void 0);
__decorate([
    action
], AuthStore.prototype, "login", void 0);
__decorate([
    action
], AuthStore.prototype, "logout", void 0);
__decorate([
    action
], AuthStore.prototype, "changePassword", void 0);
__decorate([
    action
], AuthStore.prototype, "localLogout", void 0);
__decorate([
    action
], AuthStore.prototype, "createPassword", void 0);
__decorate([
    action
], AuthStore.prototype, "registerNewPassword", void 0);
__decorate([
    action
], AuthStore.prototype, "emailFromRequestId", void 0);
export default AuthStore;
