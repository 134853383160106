import { Icon, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
export const IdentityValidationReportInfo = observer(({ reportLink, id, reportNumber, textContent }) => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { "data-cy": `reporLink-data-cy-${id}`, className: styles.link, href: `${reportLink}`, target: "_blank" }, textContent),
        React.createElement(Tooltip, { mouseLeaveDelay: 0.2, trigger: "hover", placement: "topRight", title: React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement("span", null, t("loanApplication.reportNumberToolTipTitle")),
                    React.createElement("p", null, reportNumber))) },
            React.createElement(Icon, { "data-cy": `tooltip-data-cy-${id}`, className: styles.icon, type: "info-circle" }))));
});
