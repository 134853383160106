import React from "react";
import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../../shared/utils/formatters";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { TableGray } from "../../../shared/components/TableGray";
import styles from "./style.scss";
export const InstallmentsTable = ({ expandedRowRender, installments, }) => {
    const { t } = useTranslation();
    const tableColumns = [
        {
            title: t("numberLabel"),
            dataIndex: "number",
            key: "number",
            width: 100,
        },
        {
            title: t("dueLabel"),
            dataIndex: "dueDate",
            key: "dueDate",
            render: (dueDate) => getDateLocalTimezoneFormat(dueDate),
        },
        {
            title: t("statusLabel"),
            dataIndex: "status",
            key: "status",
            width: 180,
            render: (status) => t(`payments.${status}`),
        },
        {
            title: t("originalValue"),
            dataIndex: "value",
            key: "value",
            render: (value) => formatStringCurrency(value),
        },
        {
            title: t("paidValueLabel"),
            dataIndex: "paidValue",
            key: "paidValue",
            render: (paidValue) => formatStringCurrency(paidValue),
        },
        {
            title: t("remainingAmountLabel"),
            dataIndex: "remainingValue",
            key: "remainingValue",
            render: (remainingValue) => formatStringCurrency(remainingValue),
        },
    ];
    return (React.createElement("div", { className: styles.tableWrapper, "data-cy": "installments-table-data-cy" },
        React.createElement(TableGray, { className: styles.installmentsTable, columns: tableColumns, dataSource: installments, scroll: { y: 250 }, pagination: false, expandedRowRender: expandedRowRender })));
};
