import { __awaiter } from "tslib";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Form, Input, Modal } from "antd";
import { useStores } from "../../../utils/mobx";
import styles from "./style.scss";
import InputPhone from "../../../../shared/components/InputPhone";
import { ContentCard, DataCard } from "../../DataCard";
import { decoratorConfigDefaultOnBlur } from "../../../../admin/utils/decoratorsConfig";
import { formatPhoneNumberWithDDI, clearSymbols, addPhoneNumberDDI, } from "../../../../shared/utils/formatters";
const EditUserModal = ({ form, onCancel, onSuccess, visible, user }) => {
    var _a, _b;
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    React.useEffect(() => {
        const editUserModalHasBeenOpened = user && visible;
        if (editUserModalHasBeenOpened) {
            setErrorMessage("");
        }
    }, [user, visible]);
    const { userReportStores, generalStore: { catchErrors, setSuccessMessage }, } = useStores();
    const changeUserContact = (newEmail, newMobilePhone) => __awaiter(void 0, void 0, void 0, function* () {
        if (user) {
            try {
                yield userReportStores.changeUserContact(user.id, newEmail, newMobilePhone);
                setSuccessMessage(t("users.changeUserContactSuccessMessage"));
                onSuccess();
                form.resetFields();
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("users.defaultChangeUserContactErrorMessage"));
            }
        }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                yield changeUserContact(values.newEmail, getPhoneWithDDI(values.newMobilePhone));
            }
        }));
    };
    const getUserMobilePhoneFormatted = () => {
        var _a;
        return formatPhoneNumberWithDDI((_a = user === null || user === void 0 ? void 0 : user.mobilePhone) !== null && _a !== void 0 ? _a : "");
    };
    const getPhoneWithDDI = (phone) => {
        const phoneWhitoutSymbols = clearSymbols(phone);
        return addPhoneNumberDDI(phoneWhitoutSymbols);
    };
    return (React.createElement(Modal, { visible: visible, title: t("users.editUser"), onCancel: onCancel, onOk: handleSubmit, destroyOnClose: true, maskClosable: true, width: "45%", okText: t("saveLabel") },
        React.createElement(Form, { layout: "vertical", hideRequiredMark: true },
            React.createElement("div", { className: styles.header },
                React.createElement(DataCard, { data: [
                        {
                            title: t("nameLabel"),
                            content: user === null || user === void 0 ? void 0 : user.userName,
                        },
                    ] })),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.box },
                    React.createElement("span", { className: styles.inputTittle }, "E-mail"),
                    React.createElement(ContentCard, { content: (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "-- --" })),
                React.createElement("div", { className: styles.box },
                    React.createElement("span", { className: styles.inputTittle }, t("defineNewEmailLabel")),
                    React.createElement(Form.Item, { "data-cy": "new-email-wrapper" }, getFieldDecorator("newEmail", Object.assign({ rules: [
                            {
                                required: false,
                                message: t("mandatoryField", { field: t("newEmailLabel") }),
                            },
                        ] }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { "data-cy": "new-email-data-cy", placeholder: t("Digite o novo e-mail") }))))),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.box },
                    React.createElement("span", { className: styles.inputTittle }, "Telefone"),
                    React.createElement(ContentCard, { content: (_b = getUserMobilePhoneFormatted()) !== null && _b !== void 0 ? _b : "-- --" })),
                React.createElement("div", { className: styles.box },
                    React.createElement("span", { className: styles.inputTittle }, t("defineNewMobilePhoneLabel")),
                    React.createElement(Form.Item, { "data-cy": "new-mobile-phone-wrapper" }, getFieldDecorator("newMobilePhone", Object.assign({ rules: [
                            {
                                required: false,
                                message: t("mandatoryField", { field: t("newMobilePhoneLabel") }),
                            },
                        ] }, decoratorConfigDefaultOnBlur))(React.createElement(InputPhone, { "data-cy": "new-mobile-phone-data-cy", placeholder: "(XX) XXXXX-XXXX", allowClear: true }))))),
            React.createElement("div", null, errorMessage && React.createElement(Alert, { type: "error", closable: true, showIcon: true, message: errorMessage })))));
};
export const EditUserModalForm = Form.create({
    name: "edit_user_modal",
})(EditUserModal);
