import { __awaiter } from "tslib";
import React, { useState } from "react";
import { BaseAccessProfileForm } from "../BaseAccessProfileForm";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../utils/mobx";
export const NewAccessProfileModal = ({ open, onClose }) => {
    const { generalStore, generalStore: { catchErrors }, accessProfileStore: { saveProfile }, } = useStores();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const handleCloseModal = (profileWasCreated) => {
        if (onClose) {
            onClose(profileWasCreated);
        }
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield saveProfile(Object.assign({}, values));
            generalStore.setSuccessMessage(t("accessProfile.registerSuccessMessage"));
            handleCloseModal(true);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("saveNewProfileFail"));
            handleCloseModal(false);
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(BaseAccessProfileForm, { open: open, title: t("accessProfile.registerNewAccessProfileLabel"), onClose: () => handleCloseModal(false), onSubmit: handleSubmit, loading: loading }));
};
