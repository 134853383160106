import { __rest } from "tslib";
import React, { useMemo } from "react";
import classNames from "classnames";
import { Button } from "antd";
import { TrashOutline } from "../Icons/TrashOutline";
import { Close } from "../Icons/Close";
import styles from "./style.scss";
export const DeletableCard = (_a) => {
    var { children, deleteButtonProps, variant, className, hideDeleteButton } = _a, props = __rest(_a, ["children", "deleteButtonProps", "variant", "className", "hideDeleteButton"]);
    const cardWrapperClass = useMemo(() => {
        switch (variant) {
            case "atention":
                return styles.cardAttentionWrapper;
            default:
                return styles.cardWrapper;
        }
    }, [variant]);
    const deleteButtonWrapper = useMemo(() => {
        switch (variant) {
            case "atention":
                return styles.deleteButtonAttentionWrapper;
            default:
                return styles.deleteButtonWrapper;
        }
    }, [variant]);
    const buttonIcon = () => {
        switch (variant) {
            case "atention":
                return React.createElement(Close, null);
            default:
                return React.createElement(TrashOutline, null);
        }
    };
    return (React.createElement("div", Object.assign({ className: classNames([cardWrapperClass, className, "card-base"]) }, props),
        React.createElement("div", null, children),
        React.createElement("div", { className: deleteButtonWrapper },
            React.createElement(Button, Object.assign({ type: "link" }, deleteButtonProps, { "data-cy": "remove-card-button", style: { display: hideDeleteButton ? "none" : "block" } }), buttonIcon()))));
};
