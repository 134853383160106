import React from "react";
import { Typograph } from "../../../../shared/components/Typograph";
import StepContentCard from "../../../components/Simulator/StepContentCard";
import styles from "./styles.scss";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { InputNumber } from "antd";
export const GuaranteesForm = ({ form, guarantees }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContentCard, { title: React.createElement(Typograph, { variant: "modalTitle" }, t("guarenteesStep.guaranteesFormTitle")) },
            React.createElement("div", { className: styles.qteGuarantees },
                React.createElement("span", { className: styles.qteGuaranteesTitle }, t("guarenteesStep.qteGuaranteesTitle")),
                React.createElement("span", { className: styles.qteGuaranteesSubtitle }, t("guarenteesStep.qteGuaranteesSubtitle")),
                React.createElement("div", { className: styles.qteGuaranteesWrapper },
                    React.createElement(Form.Item, null, getFieldDecorator("qteGuarantees", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", {
                                    field: t("guarenteesStep.qteGuaranteesTitle"),
                                }),
                            },
                        ],
                        initialValue: guarantees,
                    })(React.createElement(InputNumber, { min: 0, placeholder: "0", className: styles.qteGauranteesinput, "data-cy": "qteGaurantees-data-cy", "data-testid": "Gaurantees" }))))))));
};
