import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Spin, Alert } from "antd";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SubSectionHeader } from "../../../../shared/components/SubSectionHeader";
import { CompanyForm } from "../CompanyForm";
import { TableOfContents } from "../../../components/TableOfContents";
import { fields, setFocus } from "./helper";
import { useStores } from "../../../utils/mobx";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompanyRegister = observer(() => {
    const { companyStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = React.useState(false);
    const [company, setCompany] = React.useState(null);
    const [message, setMessage] = React.useState("");
    const [messageType, setMessageType] = React.useState("success");
    const [disableGeneralFields, setDisableGeneralFields] = React.useState(false);
    const { t } = useTranslation();
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setMessage("");
            setLoading(true);
            values.id = company ? company.id : null;
            const savedCompany = yield companyStore.saveCompany(values);
            setCompany(savedCompany);
            setDisableGeneralFields(true);
            setMessage(`${t("companyLabel")} ${values.id ? t("edited") : t("registered")} ${t("confirmationSuccessfullyText")}`);
            setMessageType("success");
        }
        catch (error) {
            setMessageType("error");
            catchErrors(error, setMessage, t("company.saveErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("company.registerTitle"), hasBackButton: true, linkToBackButton: AdminRoutes.Company.Report, description: t("company.registerDescription") }),
        message && (React.createElement(Alert, { className: styles.fixedMessage, message: message, type: messageType, showIcon: true, closable: true, onClose: () => setMessage("") })),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(Spin, { spinning: loading },
                React.createElement(SubSectionHeader, { title: t("company.registerHeaderTitle") }),
                React.createElement("div", { className: styles.rowContainer },
                    React.createElement("div", { className: styles.side },
                        React.createElement(TableOfContents, { title: t("company.companyInfoLabel"), items: fields, handleSelectItem: setFocus })),
                    React.createElement(CompanyForm, { handleSubmit: handleSubmit, initialValues: company, disableGeneral: disableGeneralFields, disableSectionLink: !(company === null || company === void 0 ? void 0 : company.id), companyId: (company === null || company === void 0 ? void 0 : company.id) || "" }))))));
});
export default CompanyRegister;
