import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { Spin, Button, Modal } from "antd";
import { useStores } from "../../../utils/mobx";
import ActionTable from "../../../../shared/components/ActionTable";
import { formatCpf } from "../../../../shared/utils/formatters";
import { defaultConfirmProps } from "../../../../shared/utils";
import { HrEmployeeForm } from "../HrEmployeeForm";
import s from "./style.scss";
import { getCompaniesIds } from "../../../utils/company.utils";
const confirm = Modal.confirm;
const emptyEmployeeValues = {
    id: "",
    name: "",
    cpf: "",
    email: "",
    companies: [],
    companiesGroups: [],
};
const HrEmployeesShortTable = observer(({ initialEmployees, initialLoading, onRemove, removeModalProps, sectionProps }) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t("companyEmployee.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: t("companyEmployee.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: "",
            key: "actions",
            dataIndex: "actions",
            align: "right",
        },
    ];
    const { companyStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(Object.assign({}, emptyEmployeeValues));
    const [errorMessageForm, setErrorMessageForm] = useState("");
    const [showModalForm, setShowModalForm] = useState(false);
    useEffect(() => {
        setEmployees(initialEmployees);
    }, [initialEmployees]);
    useEffect(() => {
        setLoading(initialLoading);
    }, [initialLoading]);
    const handleCancelForm = () => {
        setShowModalForm(false);
    };
    const handleSubmitForm = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingForm(true);
            let savedEmployee = null;
            const companiesIds = getCompaniesIds(selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.companies);
            if (selectedEmployee.id) {
                savedEmployee = yield companyStore.editHREmployee(Object.assign(Object.assign({ id: selectedEmployee.id }, values), { companiesIds, companiesGroups: [] }));
                if (savedEmployee) {
                    const newEmployees = employees.map((e) => savedEmployee && e.id === savedEmployee.id ? savedEmployee : e);
                    setEmployees(newEmployees);
                    generalStore.setSuccessMessage(t("companyEmployee.successfulEditMessage"));
                }
            }
            setShowModalForm(false);
        }
        catch (error) {
            catchErrors(error, setErrorMessageForm, t("companyEmployee.registerErrorMessage"));
            return error;
        }
        finally {
            setLoadingForm(false);
        }
    });
    const handleEdit = (employee) => {
        setErrorMessageForm("");
        setSelectedEmployee(employee);
        setShowModalForm(true);
    };
    const handleDelete = (employee) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: t("companyEmployee.deleteEmployeeLabel"), content: (React.createElement("p", null,
                t("companyEmployee.removalConfirmationMessage"),
                " ",
                React.createElement("strong", null, employee.name),
                " ",
                removeModalProps.removeConfirmationMessage,
                "?")), width: 600, okText: React.createElement("div", { "data-cy": "remove-contributor-modal-button" },
                " ",
                t("removeLabel")), onOk: () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    setLoading(true);
                    yield onRemove(employee);
                    const newEmployees = employees.filter((e) => e.id !== employee.id);
                    setEmployees(newEmployees);
                    generalStore.setSuccessMessage(t("companyEmployee.successfulRemovalFromCompany"));
                }
                catch (error) {
                    catchErrors(error, generalStore.setErrorMessage, t("companyEmployee.removalApiErrorMessage"));
                    setShowModalForm(false);
                }
                finally {
                    setLoading(false);
                }
            }) }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionContainer, { className: s.container },
            React.createElement(Spin, { spinning: loading }, employees.length === 0 && !loading ? (React.createElement("h1", null, sectionProps.noEmployeeMessage)) : (React.createElement(ActionTable, { className: s.table, columns: columns, loading: loading, dataSource: employees.map((employee) => (Object.assign(Object.assign({}, employee), { key: employee.id, cpf: formatCpf(employee.cpf), actions: (React.createElement(React.Fragment, null,
                        React.createElement(Button, { type: "link", onClick: () => handleEdit(employee) }, t("editUpperLabel")),
                        " | ",
                        React.createElement(Button, { type: "link", onClick: () => handleDelete(employee) }, t("removeUpperLabel")))) }))) })))),
        React.createElement(HrEmployeeForm, { visible: showModalForm, title: `${selectedEmployee.id ? t("editLabel") : t("newLabel")} ${t("companyEmployee.label")}`, onCancel: handleCancelForm, onCreate: handleSubmitForm, onCloseAlert: () => setErrorMessageForm(""), okText: selectedEmployee.id ? t("saveLabel") : t("registerLabel"), initialValues: selectedEmployee, loading: loadingForm, error: errorMessageForm })));
});
export default HrEmployeesShortTable;
