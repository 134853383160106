import { __decorate } from "tslib";
import { action, computed, observable } from "mobx";
import { api } from "../AppWrapper";
export class LegalResponsibleStore {
    constructor() {
        this.signatureIdValue = "";
        this.setSignatureId = (signatureId) => {
            this.signatureIdValue = signatureId;
        };
        this.getLegalResponsibles = (companyId) => api.getLegalResponsibles({ companyId });
        this.getCompanyInfo = () => api.getSignatureCompanyInfo({ signatureId: this.signatureIdValue });
        this.sendEmailToLegalResponsiblesWithSignatureLink = (companyId) => api.sendEmailToLegalResponsiblesWithSignatureLink({ companyId });
    }
    get signatureId() {
        return this.signatureIdValue;
    }
}
__decorate([
    observable
], LegalResponsibleStore.prototype, "signatureIdValue", void 0);
__decorate([
    computed
], LegalResponsibleStore.prototype, "signatureId", null);
__decorate([
    action
], LegalResponsibleStore.prototype, "setSignatureId", void 0);
__decorate([
    action
], LegalResponsibleStore.prototype, "getLegalResponsibles", void 0);
__decorate([
    action
], LegalResponsibleStore.prototype, "getCompanyInfo", void 0);
__decorate([
    action
], LegalResponsibleStore.prototype, "sendEmailToLegalResponsiblesWithSignatureLink", void 0);
