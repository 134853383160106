import { __awaiter } from "tslib";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../../shared/components/SectionFooter";
import { Pagination } from "../../../../shared/components/Pagination";
import { SectionLink } from "../../../components/SectionLink";
import { SimpleFieldSearch } from "../../../components/SimpleFieldSearch";
import { Spin } from "antd";
import { useStores } from "../../../utils/mobx";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompanyList = observer(() => {
    const { companyStore, routerStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = React.useState(null);
    const [companies, setCompanies] = useState([]);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const { t } = useTranslation();
    const applySearchTerm = (value) => {
        setSearchTerm(value);
        setCurrentPage(1);
        setRowsPerPage(10);
    };
    React.useEffect(() => {
        getAllCompanies();
    }, [currentPage, rowsPerPage, searchTerm]);
    const getAllCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const total = yield companyStore.getCompaniesTotal(searchTerm);
            const result = yield companyStore.getAllCompanies(searchTerm, currentPage, rowsPerPage);
            setCompanies(result.companies);
            setCurrentPage(result.currentPage);
            setRowsPerPage(result.rowsPerPage);
            setTotalCompanies(total);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const hasCompanies = loading === false && companies.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("company.title"), description: t("company.description"), actions: [
                {
                    label: t("company.registerNewCompanyLabel"),
                    handleAction: () => {
                        routerStore.push(AdminRoutes.Company.Register);
                    },
                },
            ] }),
        React.createElement(SimpleFieldSearch, { placeholder: t("company.searchPlaceholder"), handleSubmit: applySearchTerm }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(Spin, { spinning: loading }, companies.length === 0 && !loading ? (React.createElement("h1", null,
                t("company.companyNotFoundMessage"),
                searchTerm && `${t("company.withNameMessage")}"${searchTerm}"`,
                ".")) : (companies.map((company) => (React.createElement(SectionLink, { key: company.id, title: company.name, info: formatCnpj(company.cnpj), onClick: () => {
                    companyStore.setSelectedCompany(company);
                    routerStore.push(AdminRoutes.Company.Actions);
                } })))))),
        React.createElement(SectionFooter, null, hasCompanies && (React.createElement(Pagination, { current: currentPage, total: totalCompanies, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
});
export default CompanyList;
