import React from "react";
import { Input, Radio } from "antd";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { checkCPF, checkEmail, checkRG } from "../../../../utils/formValidator";
import { getFormItemName } from "../../../../utils/form.utils";
import { DeletableCard } from "../../../DeletableCard";
import { toInt } from "../../../../utils/formatters";
import { compareStrings } from "../../../../utils/colection";
import InputCurrency from "../../../InputCurrency";
import s from "./style.scss";
export const LegalResponsibleFields = ({ form, companyId, readOnly, legalResponsibles, formKey = "legalResponsibles", onDeleteClick, hasOnlyOneLegalResponsible, deletableCardProps, }) => {
    const { getFieldDecorator } = form;
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null, legalResponsibles === null || legalResponsibles === void 0 ? void 0 : legalResponsibles.sort((first, second) => compareStrings(first === null || first === void 0 ? void 0 : first.name, second === null || second === void 0 ? void 0 : second.name)).map((legalResponsible, index) => {
        const fieldName = getFormItemName(formKey, index);
        return (React.createElement(DeletableCard, { key: fieldName, deleteButtonProps: {
                onClick: () => {
                    if (onDeleteClick) {
                        onDeleteClick(index);
                    }
                },
                disabled: !!legalResponsible.createdAt || hasOnlyOneLegalResponsible,
            }, "data-cy": "legal-responsible-form-item", hideDeleteButton: (deletableCardProps === null || deletableCardProps === void 0 ? void 0 : deletableCardProps.hideDeleteButton) ||
                readOnly ||
                !!legalResponsible.createdAt ||
                hasOnlyOneLegalResponsible },
            !readOnly && (React.createElement(React.Fragment, null,
                React.createElement(Form.Item, { label: "companyId", style: { display: "none" } },
                    getFieldDecorator(`${fieldName}.companyId`, {
                        initialValue: companyId,
                    }),
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: "deleted", style: { display: "none" } },
                    getFieldDecorator(`${fieldName}.deleted`, {
                        initialValue: false,
                    }),
                    React.createElement(Input, { disabled: readOnly })))),
            React.createElement("div", { className: s.firstRow },
                React.createElement("div", null,
                    React.createElement(Form.Item, { label: t("person.name"), "data-cy": "name-input-wrapper" }, getFieldDecorator(`${fieldName}.name`, {
                        initialValue: legalResponsible.name,
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("person.name") }),
                            },
                        ],
                    })(React.createElement(Input, { disabled: readOnly })))),
                React.createElement("div", null,
                    React.createElement(Form.Item, { label: "RG", "data-cy": "rg-input-wrapper" }, getFieldDecorator(`${fieldName}.rg`, {
                        initialValue: legalResponsible.rg,
                        rules: [
                            {
                                required: true,
                                validator: checkRG,
                            },
                        ],
                    })(React.createElement(Input, { disabled: readOnly }))))),
            React.createElement("div", null,
                React.createElement(Form.Item, { label: t("companyForm.email"), "data-cy": "email-input-wrapper" }, getFieldDecorator(`${fieldName}.email`, {
                    initialValue: legalResponsible.email,
                    rules: [
                        {
                            required: true,
                            validator: checkEmail,
                        },
                    ],
                })(React.createElement(Input, { "data-cy": "email-input", disabled: readOnly })))),
            React.createElement("div", { className: s.secondRow },
                React.createElement("div", null,
                    React.createElement(Form.Item, { label: t("person.cpf"), "data-cy": "cpf-input-wrapper" }, getFieldDecorator(`${fieldName}.cpf`, {
                        initialValue: legalResponsible.cpf,
                        rules: [
                            {
                                required: true,
                                validator: checkCPF,
                            },
                        ],
                    })(React.createElement(Input, { "data-cy": "cpf-input", disabled: !!legalResponsible.createdAt || readOnly })))),
                React.createElement("div", null,
                    React.createElement(Form.Item, { label: `${t("person.societyPercent")} (%)`, "data-cy": "society-input-wrapper" }, getFieldDecorator(`${fieldName}.societyPercent`, {
                        initialValue: legalResponsible.societyPercent,
                        normalize: (value) => toInt(value),
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("person.societyPercent") }),
                            },
                        ],
                    })(React.createElement(InputCurrency, { "data-cy": "society-percent-input", min: 0, max: 10000, disabled: readOnly }))))),
            React.createElement("div", { className: s.footerWrapper },
                React.createElement("div", { className: s.selectionGroupsWrapper },
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: `${t("person.admin")}?`, "data-cy": "admin-radio-input-wrapper" }, getFieldDecorator(`${fieldName}.administrator`, {
                            initialValue: legalResponsible.administrator,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", { field: t("person.admin") }),
                                },
                            ],
                        })(React.createElement(Radio.Group, { disabled: readOnly },
                            React.createElement(Radio, { value: true }, t("yesLabel")),
                            React.createElement(Radio, { value: false }, t("noLabel")))))),
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: `${t("person.admin")} ${t("person.isolated")}?`, "data-cy": "isolated-radio-input-wrapper" }, getFieldDecorator(`${fieldName}.isolated`, {
                            initialValue: legalResponsible.isolated,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", { field: t("person.isolated") }),
                                },
                            ],
                        })(React.createElement(Radio.Group, { disabled: readOnly },
                            React.createElement(Radio, { value: true }, t("yesLabel")),
                            React.createElement(Radio, { value: false }, t("noLabel")))))),
                    React.createElement("div", { style: { width: "205px" } },
                        React.createElement(Form.Item, { style: { width: "205px" }, label: t("legalResponsibles.sendSignatureEmail"), "data-cy": "sendSignatureEmail-radio-input-wrapper" }, getFieldDecorator(`${fieldName}.sendSignatureEmail`, {
                            initialValue: legalResponsible.sendSignatureEmail,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", { field: t("legalResponsibles.sendSignatureEmail") }),
                                },
                            ],
                        })(React.createElement(Radio.Group, { disabled: readOnly },
                            React.createElement(Radio, { value: true }, t("yesLabel")),
                            React.createElement(Radio, { value: false }, t("noLabel"))))))))));
    })));
};
