import { __awaiter } from "tslib";
import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import s from "./style.scss";
import { useStores } from "../../utils/mobx";
import { Tooltip, Modal } from "antd";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { Icon } from "antd";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import ActionTable from "../../../shared/components/ActionTable";
import { FilterForm } from "../../../shared/components/FilterForm";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { Pagination } from "../../../shared/components/Pagination";
import { formatCpf, formatCurrency } from "../../../shared/utils/formatters";
import ActionSelect from "../../../shared/components/ActionSelect";
import { openFileInNewTab, downloadFileFromBuffer } from "../../../shared/utils/file";
import { ConfirmationCessionModalForm } from "../../components/ConfirmationCessionModalForm";
import CCBButton from "../../components/CCBButton";
import { DateFormat, getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { CessionReportContext } from "./context";
import { nullIfEmpty } from "../../../shared/utils/filter";
import { ConfirmationRepurchaseModalForm } from "../../components/RepurchaseCessionModal";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
const handleClick = (ccbLink, e) => {
    e.stopPropagation();
    if (ccbLink !== null && typeof ccbLink === "string") {
        openFileInNewTab(ccbLink);
    }
};
const confirm = Modal.confirm;
const CessionReport = observer(() => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t("nameLabel"),
            dataIndex: "userName",
            key: "userName",
            fixed: true,
            width: 250,
            render: (value) => React.createElement("span", { "data-cy": "userName" }, value),
        },
        {
            title: t("ccbNumberLabel"),
            dataIndex: "ccbNumber",
            key: "ccbNumber",
            width: 150,
            render: (value) => React.createElement("span", { "data-cy": "ccbNumber" }, value),
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
            width: 180,
            render: (value) => React.createElement("span", { "data-cy": "cpf" }, value),
        },
        {
            title: t("companyLabel"),
            dataIndex: "companyName",
            key: "companyName",
            render: (value) => React.createElement("span", { "data-cy": "companyName" }, value),
        },
        {
            title: t("cession.assigneeCompanyLabel"),
            dataIndex: "assigneCompany",
            key: "assigneCompany",
            render: (value) => React.createElement("span", { "data-cy": "assigneCompany" }, value),
        },
        {
            title: "Status",
            dataIndex: "statusDisplay",
            key: "statusDisplay",
            render: (value) => React.createElement("span", { "data-cy": "statusDisplay" }, value),
        },
        {
            title: "CCB",
            dataIndex: "ccb",
            key: "ccb",
            render: (value) => React.createElement("span", { "data-cy": "ccb" }, value),
        },
        {
            title: "Valor da cessão (R$)",
            dataIndex: "cessionValue",
            key: "cessionValue",
            render: (value) => React.createElement("span", { "data-cy": "cessionValue" }, value),
        },
        {
            title: t("cession.givenInstallmentsLabel"),
            dataIndex: "givenInstallments",
            key: "givenInstallments",
            render: (value) => React.createElement("span", { "data-cy": "givenInstallments" }, value),
        },
        {
            title: t("cession.dateLabel"),
            dataIndex: "cessionDate",
            key: "cessionDate",
            render: (value) => React.createElement("span", { "data-cy": "cessionDate" }, value),
        },
    ];
    const { generalStore: { setErrorMessage, setSuccessMessage, clearMessage, catchErrors }, cessionReportStore, } = useStores();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [cessions, setCessions] = useState([]);
    const [loadingCessions, setLoadingCessions] = useState(false);
    const [totalCessions, setTotalCessions] = useState(0);
    const [selectedCessionsKeys, setSelectedCessionsKeys] = useState([]);
    const selectedCessionsKeysRef = useRef();
    selectedCessionsKeysRef.current = selectedCessionsKeys;
    const [selectedCessions, setSelectedCessions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showRepurchaseModal, setShowRepurchaseModal] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({
        userName: null,
        cpf: null,
        assignCompany: null,
        status: null,
        date: {
            from: null,
            to: null,
        },
        ccbNumber: null,
        company: null,
        companyGroupName: null,
    });
    const statusLabels = {
        pending: t("cession.pendingLabel"),
        given: t("cession.given"),
        partialGiven: t("cession.partialGiven"),
    };
    const items = [
        {
            key: "userName",
            name: "userName",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: "CPF",
        },
        {
            key: "assignCompany",
            name: "assignCompany",
            type: "input",
            placeholder: t("cession.assigneeCompanyLabel"),
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: "Status",
            selectDefaultValue: "",
            selectOptions: [
                {
                    value: "given",
                    label: t("cession.given"),
                },
                {
                    value: "partialGiven",
                    label: t("cession.partialGiven"),
                },
                {
                    value: "pending",
                    label: t("cession.pendingLabel"),
                },
                {
                    value: "",
                    label: "Status",
                },
            ],
        },
        {
            key: "data",
            name: "data",
            type: "dateRange",
        },
        {
            key: "companyName",
            name: "company",
            type: "input",
            placeholder: t("companyLabel"),
        },
        {
            key: "companyGroupName",
            name: "companyGroupName",
            type: "input",
            placeholder: t("companyGroupLabel"),
        }
    ];
    const changeSelectedCessions = (cessionsId) => {
        setSelectedCessionsKeys(cessionsId);
        setSelectedCessions([...cessions.filter((cession) => cessionsId.includes(cession.id))]);
    };
    const applyFilter = (values) => {
        changeSelectedCessions([]);
        const filter = {
            userName: values.userName,
            cpf: !values.cpf ? null : values.cpf.replace(/\D/g, ""),
            assignCompany: nullIfEmpty(values.assignCompany),
            status: nullIfEmpty(values.status),
            date: {
                from: values.dataInitial ? values.dataInitial.toDate() : null,
                to: values.dataFinal ? values.dataFinal.toDate() : null,
            },
            ccbNumber: null,
            company: values.company,
            companyGroupName: values.companyGroupName
        };
        setCurrentPage(1);
        setRowsPerPage(10);
        setCurrentFilter(filter);
    };
    const getCessions = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingCessions(true);
        try {
            const response = yield cessionReportStore.getCessions(filter, currentPage, rowsPerPage);
            const cessionsTotal = yield cessionReportStore.getCessionsTotal(filter);
            setTotalCessions(cessionsTotal);
            setCessions(response.cessions);
            setRowsPerPage(response.rowsPerPage);
            setCurrentPage(response.page);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("cession.errorMessage"));
        }
        finally {
            setLoadingCessions(false);
        }
    });
    const handleOpenConfirm = (action, newSelectedCessions) => {
        clearMessage();
        const totalSelectedCessions = newSelectedCessions ? newSelectedCessions.length : 0;
        if (newSelectedCessions === undefined || totalSelectedCessions === 0) {
            setErrorMessage(t("cession.selectContractMessage"));
            return;
        }
        if (action === "confirm") {
            setShowModal(true);
        }
        else if (action === "cancel") {
            const length = newSelectedCessions.length;
            const title = length > 1 ? "cessões" : "cessão";
            confirm({
                title: `Cancelar ${length} ${title}`,
                content: (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "Tem certeza que deseja cancelar ",
                        length > 1 ? "as" : "a",
                        " ",
                        title,
                        "?"))),
                okButtonProps: {
                    type: "primary",
                },
                okText: "Confirmar",
                cancelText: "Fechar",
                onOk: cancelCessions,
                maskClosable: true,
                icon: null,
            });
        }
        else if (action === "repurchase") {
            if (totalSelectedCessions > 1) {
                setErrorMessage(t("cession.repurchaseCessionSelectedErroMessage"));
                return;
            }
            else {
                setShowRepurchaseModal(true);
            }
        }
    };
    const actions = [
        {
            value: "confirm",
            label: t("cession.confirmCessionLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("confirm", selectedKeys);
            },
        },
        {
            value: "cancel",
            label: t("cession.cancelCessionLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("cancel", selectedKeys);
            },
        },
        {
            value: "repurchase",
            label: t("cession.repurchaseCessionLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("repurchase", selectedKeys);
            },
        },
    ];
    React.useEffect(() => {
        getCessions(currentFilter);
    }, [currentFilter, currentPage, rowsPerPage]);
    const hasCessions = !loadingCessions && cessions.length > 0;
    const exportCessionReport = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const buffer = yield cessionReportStore.exportCessions(filter);
            downloadFileFromBuffer(buffer, "Relatório de Cessões.xlsx");
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("exportApiErrorMessage"));
        }
    });
    const confirmCession = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingCessions(true);
        setShowModal(false);
        try {
            yield cessionReportStore.confirmCessions(values.cessionsToConfirm.map((cessionToConfirm) => (Object.assign(Object.assign({}, cessionToConfirm), { cessionDate: getDateLocalTimezoneFormat(cessionToConfirm.cessionDate, DateFormat.ISO) }))), String(values.borderoNumber));
            yield getCessions(currentFilter);
            const message = selectedCessionsKeys.length > 1
                ? t("cession.confirmCessionsMessage")
                : t("cession.confirmCessionMessage");
            changeSelectedCessions([]);
            setSuccessMessage(message);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("cession.confirmCessionErrorMessage"));
        }
        finally {
            setLoadingCessions(false);
        }
    });
    const handleCancelCessionsSuccess = () => {
        const message = selectedCessionsKeys.length > 1
            ? t("cession.cancelCessionsMessage")
            : t("cession.cancelCessionMessage");
        changeSelectedCessions([]);
        setSuccessMessage(message);
        setSelectedCessionsKeys([]);
    };
    const cancelCessions = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingCessions(true);
        setShowModal(false);
        try {
            yield cessionReportStore.cancelCessions(selectedCessionsKeysRef.current || []);
            yield getCessions(currentFilter);
            handleCancelCessionsSuccess();
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("cession.cancelCessionErrorMessage"));
        }
        finally {
            setLoadingCessions(false);
        }
    });
    const handleSelectedChange = (selected, _) => {
        changeSelectedCessions(selected);
    };
    const onSuccessRepurchase = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowRepurchaseModal(false);
        yield getCessions(currentFilter);
        changeSelectedCessions([]);
    });
    const formatInstallments = (installments, id) => {
        return installments
            .sort((a, b) => a - b)
            .map((inst, index) => (React.createElement("span", { key: `${id}-${inst}` },
            inst,
            index <= installments.length - 2 ? "," : " ",
            index % 12 === 11 ? React.createElement("br", null) : "",
            " ")));
    };
    return (React.createElement(CessionReportContext.Provider, { value: { selectedCessions } },
        React.createElement(SectionHeader, { title: t("cession.cessionReportTitle"), actions: [
                {
                    label: t("exportReportLabel"),
                    handleAction: () => exportCessionReport(currentFilter),
                    icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                    disabled: cessions.length === 0,
                },
            ] }),
        React.createElement(FilterForm, { className: s.chargeForm, items: items, handleSubmit: applyFilter }),
        React.createElement(ConfirmationCessionModalForm, { visible: showModal, onCancel: () => setShowModal(false), quantity: selectedCessionsKeys.length, confirmCession: confirmCession }),
        React.createElement(ConfirmationRepurchaseModalForm, { cession: selectedCessions[0], visible: showRepurchaseModal, onCancel: () => setShowRepurchaseModal(false), onSuccess: onSuccessRepurchase }),
        React.createElement(SectionContainer, { className: s.container },
            React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                React.createElement(ActionTable, { className: s.cessionsTable, columns: columns, loading: loadingCessions, scroll: { x: 1800 }, rowSelection: {
                        selectedRowKeys: selectedCessionsKeys,
                        onChange: handleSelectedChange,
                        getCheckboxProps: (cession) => ({
                            "data-cy": `select-cession-${cession.id}`,
                        }),
                    }, dataSource: cessions.map((cession) => (Object.assign(Object.assign({}, cession), { key: cession.id, name: cession.userName, cpf: formatCpf(cession.cpf), companyName: cession.company, statusDisplay: (React.createElement("div", { className: classNames({
                                [s.pending]: cession.status === "pending",
                            }) }, cession.status !== null ? statusLabels[cession.status] : "--")), ccb: cession.ccbId === null ? (React.createElement(Tooltip, { mouseLeaveDelay: 0, trigger: "hover", placement: "topRight", title: t("ccbTooltipLabel") },
                            React.createElement("div", { className: s.tooltipContainer },
                                React.createElement(CCBButton, { ccb: cession.ccbLink, handleClick: handleClick })))) : (React.createElement(React.Fragment, null, React.createElement(CCBButton, { ccb: cession.ccbLink, handleClick: handleClick }))), ccbNumber: cession.ccbNumber || "--", givenInstallments: (React.createElement("div", { className: "givenInstallments" },
                            cession.installments.length > 0
                                ? formatInstallments(cession.installments, cession.id)
                                : "--",
                            cession.repurchasedInstallments.length > 0 && (React.createElement(TooltipDisplay, { type: "info-circle", "data-cy": "repurchedToolTip-data-cy", title: React.createElement(React.Fragment, null,
                                    React.createElement("span", { className: s.Tooltip },
                                        " ",
                                        React.createElement("span", null, t("repurchaseToolTipLabel")),
                                        React.createElement("p", null, formatInstallments(cession.repurchasedInstallments, cession.id)))) })))), cessionDate: getDateLocalTimezoneFormat(cession.cessionDate), cessionValue: !!cession.cessionValue
                            ? formatCurrency(cession.cessionValue / 100 || 0)
                            : "--" }))) }))),
        React.createElement(ActionSelect, { actions: actions, currentPage: currentPage || 1, selectedKeys: selectedCessionsKeys ? selectedCessionsKeys : [] }),
        React.createElement(SectionFooter, null, hasCessions && (React.createElement(Pagination, { current: currentPage, total: totalCessions, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
});
export default CessionReport;
