import { __awaiter } from "tslib";
import React from "react";
import { Modal, Form, Input, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
const { TextArea } = Input;
const { Option } = Select;
const BaseAccessProfile = ({ open, title, onClose, onSubmit, form, loading, }) => {
    const { getFieldDecorator, validateFields, setFieldsValue } = form;
    const inputName = "accessProfile";
    const { t } = useTranslation();
    const clearFields = () => {
        setFieldsValue({
            [inputName]: {
                title: undefined,
                description: undefined,
                type: undefined,
            },
        });
    };
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        clearFields();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                if (onSubmit) {
                    yield onSubmit(values.accessProfile);
                }
                clearFields();
            }
        }));
    };
    const typeOptions = [
        {
            value: "portal_admin",
            label: t("accessProfile.adminPortal"),
        },
        {
            value: "external_api",
            label: t("accessProfile.externalApi"),
        },
    ];
    return (React.createElement("div", { className: styles.modalWrapper },
        React.createElement(Modal, { title: React.createElement("div", null, title || t("accessProfile.registerNewAccessProfileLabel")), visible: open, onOk: handleSubmit, onCancel: handleClose, width: 700, okText: React.createElement("span", { "data-cy": "access-profile-submit-button" }, t("confirmLabel")) },
            React.createElement("div", { className: styles.bodyWrapper },
                React.createElement(Spin, { spinning: loading },
                    React.createElement(Form.Item, { label: t("accessProfile.name"), "data-cy": "title-input-wrapper" }, getFieldDecorator(`${inputName}.title`, {
                        rules: [
                            {
                                required: true,
                                message: t("accessProfile.titleErrorMessage"),
                            },
                        ],
                    })(React.createElement(Input, { "data-cy": "title-input-data-cy", placeholder: t("accessProfile.titlePlaceholder") }))),
                    React.createElement(Form.Item, { label: t("accessProfile.type"), "data-cy": "type-select-wrapper" }, getFieldDecorator(`${inputName}.type`, {
                        rules: [
                            {
                                required: true,
                                message: t("accessProfile.typeErrorMessage"),
                            },
                        ],
                    })(React.createElement(Select, { "data-cy": "type-select-data-cy", className: styles.select, showSearch: true, placeholder: t("accessProfile.typePlaceholder") }, typeOptions.map((option) => (React.createElement(Option, { key: option.value, value: option.value }, option.label)))))),
                    React.createElement(Form.Item, { label: t("accessProfile.descriptionField"), "data-cy": "description-input-wrapper" }, getFieldDecorator(`${inputName}.description`, {
                        rules: [
                            {
                                required: true,
                                message: t("accessProfile.descriptionErrorMessage"),
                            },
                        ],
                    })(React.createElement(TextArea, { "data-cy": "description-input-data-cy", rows: 3, placeholder: t("accessProfile.descriptionPlaceholder") }))))))));
};
export const BaseAccessProfileForm = Form.create({
    name: "access_profile_form",
})(BaseAccessProfile);
