import i18n from "../../i18n";
import { getTodayDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { formatTedStatus } from "../../utils/contracts.utils";
export const defaultColumns = [
    {
        title: i18n.t("contracts.overdueLabel"),
        dataIndex: "adimplencia",
        key: "adimplencia",
    },
    {
        title: "Status",
        dataIndex: "statusDisplay",
        key: "statusDisplay",
    },
    {
        title: i18n.t("contracts.personLabel"),
        dataIndex: "documentType",
        key: "documentType",
    },
    {
        title: "CCB",
        dataIndex: "ccb",
        key: "ccb",
        align: "center",
        width: 80,
    },
    {
        title: i18n.t("contracts.totalValueLabel"),
        dataIndex: "totalValue",
        key: "totalValue",
    },
    {
        title: i18n.t("contracts.tedStatus.title"),
        dataIndex: "tedStatus",
        key: "tedStatus",
        render: formatTedStatus,
    },
    {
        title: i18n.t("contracts.totalInstallmentsLabel"),
        dataIndex: "installments",
        key: "installments",
    },
    {
        title: i18n.t("contracts.remainingInstallmentsLabel"),
        dataIndex: "remainingInstallmentsDisplay",
        key: "remainingInstallmentsDisplay",
    },
    {
        title: `${i18n.t("contracts.remainingValueLabel")} ${getTodayDateLocalTimezoneFormat()}`,
        dataIndex: "remainingValue",
        key: "remainingValue",
    },
    {
        title: i18n.t("contracts.paidValueLabel"),
        dataIndex: "paidValue",
        key: "paidValue",
    },
    {
        title: i18n.t("contracts.contractDateLabel"),
        dataIndex: "contractSignDate",
        key: "contractSignDate",
    },
    {
        title: i18n.t("contracts.requestDateLabel"),
        dataIndex: "requestDate",
        key: "requestDate",
    },
    {
        title: "Seguro Prestamista",
        dataIndex: "policy",
        key: "policy",
        align: "center",
        width: 145,
    },
];
