import { __rest } from "tslib";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { LegalResponsibleFields } from "../../../../shared/components/Company/Forms/LegalResponsibleFields";
import { FormTitle } from "../../../../shared/components/FormTitle";
import s from "./style.scss";
const emptyLegalResponsible = {
    administrator: null,
    isolated: null,
    name: null,
    rg: null,
    societyPercent: null,
    companyId: "",
    cpf: "",
    createdAt: null,
    updatedAt: null,
    email: null,
    sendSignatureEmail: null,
};
export const LegalResponsibleForm = observer((_a) => {
    var { form, readOnly, companyLegalResponsibles, companyId } = _a, rest = __rest(_a, ["form", "readOnly", "companyLegalResponsibles", "companyId"]);
    const formKey = "legalResponsibles";
    const { t } = useTranslation();
    const [legalResponsibles, setLegalResponsibles] = useState([]);
    const legalResponsiblesRef = useRef();
    const hasOnlyOneLegalResponsible = legalResponsibles.length === 1;
    legalResponsiblesRef.current = legalResponsibles;
    useEffect(() => {
        if (companyLegalResponsibles && companyLegalResponsibles.length > 0) {
            const persistedLegalResponsibles = companyLegalResponsibles;
            setLegalResponsibles(persistedLegalResponsibles);
        }
    }, [companyLegalResponsibles]);
    const hancleClickMore = () => {
        setLegalResponsibles((oldValue) => [...oldValue, emptyLegalResponsible]);
    };
    const handleDeleteButtonClick = () => {
        setLegalResponsibles((oldValue) => {
            return oldValue.filter((_, i) => {
                return i !== oldValue.length - 1;
            });
        });
    };
    return (React.createElement("div", Object.assign({ className: s.cardWrapper }, rest),
        React.createElement(FormTitle, { title: t("portalPj.partnersAndLegalGuardians") }),
        React.createElement(LegalResponsibleFields, { form: form, formKey: formKey, hasOnlyOneLegalResponsible: hasOnlyOneLegalResponsible, legalResponsibles: legalResponsibles, readOnly: readOnly, companyId: companyId, deletableCardProps: { hideDeleteButton: true } }),
        React.createElement("div", { className: s.buttonsWrapper },
            React.createElement(Button, { type: "link", onClick: hancleClickMore, className: s.addButton, "data-cy": "add-legal-responsible-button" },
                "+",
                React.createElement("div", null, t("companyForm.addPartner"))),
            legalResponsibles.filter((legalResponsible) => !legalResponsible.createdAt).length > 0 && (React.createElement(Button, { type: "link", onClick: handleDeleteButtonClick, className: s.addButton, "data-cy": "remove-legal-responsible-button" },
                React.createElement("div", null, "- Remover s\u00F3cio"))))));
});
