import { __awaiter } from "tslib";
import React, { useState } from "react";
import { BaseCompaniesGroupForm } from "../BaseCompaniesGroupForm";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../utils/mobx";
export const NewCompaniesGroupModal = ({ open, onClose }) => {
    const { companiesGroupStore: { createCompaniesGroup }, generalStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const handleCloseModal = (groupWasCreated) => {
        if (onClose) {
            onClose(groupWasCreated);
        }
    };
    const handleSubmit = (companiesGroupParams) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield createCompaniesGroup(companiesGroupParams);
            generalStore.setSuccessMessage(t("companiesGroups.registerSuccessMessage"));
            handleCloseModal(true);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("companiesGroups.registerErrorMessage"));
            handleCloseModal(false);
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(BaseCompaniesGroupForm, { open: open, title: t("companiesGroups.newGroup"), onClose: () => handleCloseModal(false), onSubmit: handleSubmit, loading: loading }));
};
