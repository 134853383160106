import React from "react";
import Table from "../../../../../shared/components/Table";
import styles from "./style.scss";
import i18n from "../../../../i18n";
import { getDateLocalTimezoneFormat } from "../../../../../shared/utils/date";
const PortabilityTable = ({ portabilityHistoryData }) => {
    const { t } = i18n;
    const portabilityTableColumns = [
        {
            title: t("dateLabel"),
            dataIndex: "transferredAt",
            key: "transferredAt",
            render: (transferredAt) => getDateLocalTimezoneFormat(transferredAt),
        },
        {
            title: t("originLabel"),
            dataIndex: "fromData",
            key: "fromData",
            render: (fromData) => fromData.name,
        },
        {
            title: t("destinyLabel"),
            dataIndex: "toData",
            key: "toData",
            render: (toData) => toData.name,
        },
        {
            title: t("installmentsLowerLabel"),
            dataIndex: "installments",
            key: "installments",
            render: (installments) => {
                const lastInstallmentIndex = installments.length - 1;
                const firstInstallment = installments[0];
                const lastInstallment = installments[lastInstallmentIndex];
                return `${firstInstallment} a ${lastInstallment}`;
            },
        },
    ];
    return (React.createElement(Table, { columns: portabilityTableColumns, data: portabilityHistoryData, className: styles.table }));
};
export default PortabilityTable;
