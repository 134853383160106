import { __rest } from "tslib";
import React from "react";
import { DownloadIcon } from "../../../../shared/components/Icons/Download";
import { DocumentCard } from "../../../../shared/components/DocumentCard";
import styles from "./style.scss";
export const DocumentDownloadCard = (_a) => {
    var { onDownloadClick, file } = _a, props = __rest(_a, ["onDownloadClick", "file"]);
    const renderDownloadButton = (fileToDownload) => {
        return (React.createElement("button", { className: fileToDownload ? styles.buttonCardEnabled : styles.buttonCardDisabled, onClick: () => onDownloadClick(fileToDownload), "data-cy": `${fileToDownload === null || fileToDownload === void 0 ? void 0 : fileToDownload.fileId}-data-cy` }, fileToDownload && React.createElement(DownloadIcon, null)));
    };
    return (React.createElement(DocumentCard, Object.assign({ className: styles.downloadCard }, props, { renderRightButton: () => renderDownloadButton(file) })));
};
