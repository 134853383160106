import { __awaiter } from "tslib";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../../shared/components/SectionFooter";
import { Pagination } from "../../../../shared/components/Pagination";
import { SectionLink } from "../../../components/SectionLink";
import { Spin } from "antd";
import { useStores } from "../../../utils/mobx";
import { NewAccessProfileModal } from "../../../components/AccessProfile/Forms/NewAccessProfileModal";
import { FilterForm } from "../../../../shared/components/FilterForm";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const AccessProfileList = observer(() => {
    const { accessProfileStore, generalStore, generalStore: { catchErrors }, routerStore, } = useStores();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [profileType, setProfileType] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [accessProfiles, setAccessProfiles] = useState([]);
    const [newProfileModalIsOpen, setNewProfileModalIsOpen] = useState(false);
    const fetchProfiles = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const { profiles: fetchedProfiles } = yield accessProfileStore.getAllProfiles({
                name: searchTerm,
                type: profileType,
            }, currentPage, rowsPerPage);
            setAccessProfiles(fetchedProfiles);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setIsLoading(false);
        }
    });
    const fetchProfilesTotal = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const totalFetched = yield accessProfileStore.getProfilesTotal({
                name: searchTerm,
                type: profileType,
            });
            setTotal(totalFetched);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    useEffect(() => {
        fetchProfiles();
        fetchProfilesTotal();
    }, [searchTerm, profileType, currentPage, rowsPerPage]);
    const handleOpenNewAccessProfileModal = () => {
        setNewProfileModalIsOpen(true);
    };
    const handleCloseNewAccessProfileModal = (profileWasCreated) => {
        if (profileWasCreated) {
            fetchProfiles();
        }
        setNewProfileModalIsOpen(false);
    };
    const resetPagination = () => {
        setCurrentPage(1);
        setRowsPerPage(10);
    };
    const handleSearchSubmit = (accessNameFilter, profileTypeFilter) => {
        setSearchTerm(accessNameFilter);
        setProfileType(profileTypeFilter);
        resetPagination();
    };
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("accessProfile.name"),
        },
        {
            key: "type",
            name: "type",
            type: "select",
            placeholder: t("accessProfile.type"),
            selectOptions: [
                {
                    value: null,
                    label: t("accessProfile.type"),
                },
                {
                    value: "portal_admin",
                    label: t("accessProfile.adminPortal"),
                },
                {
                    value: "external_api",
                    label: t("accessProfile.externalApi"),
                },
            ],
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(NewAccessProfileModal, { open: newProfileModalIsOpen, onClose: handleCloseNewAccessProfileModal }),
        React.createElement(SectionHeader, { title: t("accessProfile.title"), description: t("accessProfile.description"), actions: [
                {
                    label: t("accessProfile.registerNewAccessProfileLabel"),
                    handleAction: handleOpenNewAccessProfileModal,
                },
            ] }),
        React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: (values) => {
                handleSearchSubmit(values.name, values.type);
            } }),
        React.createElement(SectionContainer, { className: styles.container, "data-cy": "profiles-wrapper-data-cy" },
            React.createElement(Spin, { spinning: isLoading }, isEmpty(accessProfiles) ? (React.createElement("h1", null,
                `${t("accessProfile.accessProfileNotFoundMessage")} `,
                searchTerm && `${t("company.withNameMessage")} "${searchTerm}"`,
                ".")) : (accessProfiles.map((profile) => (React.createElement(SectionLink, { key: profile.id, title: profile.title, info: String(profile.description), onClick: () => {
                    routerStore.push(`${AdminRoutes.Admin.ProfileEdit}/${profile.id}`);
                }, "data-cy": "access-profile-section-link" })))))),
        React.createElement(SectionFooter, null, true && (React.createElement(Pagination, { current: currentPage, total: total, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
});
export default AccessProfileList;
