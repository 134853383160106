import React from "react";
import { useTranslation } from "react-i18next";
import { DateFormat, getDateLocalTimezoneFormat } from "../../../../../shared/utils/date";
import { formatCurrency } from "../../../../../shared/utils/formatters";
import { goBackTwoDecimalPlaces } from "../../../../../shared/utils/numbers";
import styles from "./style.scss";
export const SimulationDownloadInstallmentsList = ({ installments }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.installmentsWrapper },
        React.createElement("ul", null, installments === null || installments === void 0 ? void 0 : installments.map((installment) => (React.createElement("li", { key: `${installment.number}-${installment.value}-${installment.date}` },
            React.createElement("div", { className: styles.installmentContentTop },
                React.createElement("span", { className: styles.installmentNumber }, `${t("installmentLabel")} ${installment.number}`),
                React.createElement("span", { className: styles.installmentDate }, getDateLocalTimezoneFormat(installment.date, DateFormat.BR_SHORT_YEAR))),
            React.createElement("span", { className: styles.installmentValue }, formatCurrency(goBackTwoDecimalPlaces(installment.value)))))))));
};
