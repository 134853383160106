import { isEmpty, cloneDeep } from "lodash";
import { routes } from "../constants/routes.constants";
export function getAdminNavbarRoutes(accessModules) {
    if (adminHasFullAccess(accessModules))
        return routes;
    return cloneDeep(routes).filter((route) => {
        var _a;
        if (!route.items) {
            return adminHasAccessModule(route.module, accessModules);
        }
        route.items = (_a = route.items) === null || _a === void 0 ? void 0 : _a.filter((route) => adminHasAccessModule(route.module, accessModules));
        return !isEmpty(route.items);
    });
}
function adminHasFullAccess(accessModules) {
    return Boolean(accessModules === null || accessModules === void 0 ? void 0 : accessModules.includes("fullAccess"));
}
function adminHasAccessModule(module, accessModules) {
    return module ? Boolean(accessModules === null || accessModules === void 0 ? void 0 : accessModules.includes(module)) : true;
}
export function findRouteByModule(module, routes) {
    return routes.find((route) => route.module === module);
}
