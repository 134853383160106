import React from "react";
import cln from "classnames";
import css from "./style.scss";
export function DataCard({ data, vertical, className }) {
    return (React.createElement("div", { className: cln(vertical ? css.verticalContainer : css.horizontalContainer, className) }, data.map((item, index) => (React.createElement("div", { className: vertical ? css.verticalOrientation : css.horizontalOrientation, key: index },
        React.createElement("div", { className: css.titleGrid }, item.title),
        React.createElement("div", { className: css.subtitleGrid }, item.content))))));
}
export function ContentCard({ content }) {
    return (React.createElement("div", { className: css.contentContainer },
        React.createElement("div", { className: css.subtitleGrid }, content)));
}
