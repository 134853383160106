import i18n from "../../../i18n";
export const setFocus = (id) => {
    const element = document.getElementById(`company_form_${id}`) || document.querySelector(`input[id~="${id}"]`);
    if (element) {
        if (element.classList.contains("ant-calendar-picker")) {
            const input = element.querySelector("input");
            input.focus();
            input.click();
        }
        else {
            element.focus();
        }
    }
};
export const fields = [
    {
        label: i18n.t("companyForm.generalInfoTitle"),
        id: "general",
        subItems: [
            {
                label: i18n.t("cnpjLabel"),
                id: "general.cnpj",
            },
            {
                label: i18n.t("companyForm.stateSubscriptionLabel"),
                id: "general.stateSubscription",
            },
            {
                label: i18n.t("companyForm.socialNameLabel"),
                id: "general.socialName",
            },
            {
                label: i18n.t("companyForm.fantasyNameLabel"),
                id: "general.fantasyName",
            },
        ],
    },
    {
        label: i18n.t("addressLabel"),
        id: "address",
        subItems: [
            {
                label: i18n.t("cepLabel"),
                id: "address.cep",
            },
            {
                label: i18n.t("streetLabel"),
                id: "address.street",
            },
            {
                label: i18n.t("numberLabel"),
                id: "address.number",
            },
            {
                label: i18n.t("neighborhoodLabel"),
                id: "address.neighborhood",
            },
            {
                label: i18n.t("cityLabel"),
                id: "address.city",
            },
            {
                label: i18n.t("stateLabel"),
                id: "address.state",
            },
            {
                label: i18n.t("complementLabel"),
                id: "address.complement",
            },
        ],
    },
    {
        label: i18n.t("companyForm.contactTitle"),
        id: "contact",
        subItems: [
            {
                label: i18n.t("emailLabel"),
                id: "contact.email",
            },
            {
                label: i18n.t("companyForm.telephoneLabel"),
                id: "contact.phone",
            },
            {
                label: i18n.t("companyForm.otherContactsLabel"),
                id: "contact.otherContacts",
            },
        ],
    },
    {
        label: i18n.t("companyForm.chargeAddressTitle"),
        id: "chargeAddress",
        subItems: [
            {
                label: i18n.t("cepLabel"),
                id: "chargeAddress.address.cep",
            },
            {
                label: i18n.t("streetLabel"),
                id: "chargeAddress.address.street",
            },
            {
                label: i18n.t("numberLabel"),
                id: "chargeAddress.address.number",
            },
            {
                label: i18n.t("neighborhoodLabel"),
                id: "chargeAddress.address.neighborhood",
            },
            {
                label: i18n.t("cityLabel"),
                id: "chargeAddress.address.city",
            },
            {
                label: i18n.t("stateLabel"),
                id: "chargeAddress.address.state",
            },
            {
                label: i18n.t("complementLabel"),
                id: "chargeAddress.address.complement",
            },
            {
                label: i18n.t("companyForm.otherContactsLabel"),
                id: "chargeAddress.otherContact",
            },
        ],
    },
    {
        label: i18n.t("companyForm.patrimonialInfoTitle"),
        id: "patrimonialInfo",
        subItems: [
            {
                label: i18n.t("companyForm.constitutionDateLabel"),
                id: "patrimonialInfo.constitutionDate",
            },
            {
                label: i18n.t("companyForm.lastStaturaryChangeDateLabel"),
                id: "patrimonialInfo.lastStaturaryChangeDate",
            },
            {
                label: i18n.t("companyForm.changeAmountLabel"),
                id: "patrimonialInfo.changeAmount",
            },
            {
                label: i18n.t("companyForm.socialCapitalLabel"),
                id: "patrimonialInfo.socialCapital",
            },
            {
                label: i18n.t("companyForm.netWorthLabel"),
                id: "patrimonialInfo.netWorth",
            },
            {
                label: i18n.t("companyForm.netWorthReferenceDateLabel"),
                id: "patrimonialInfo.netWorthReferenceDate",
            },
        ],
    },
    {
        label: i18n.t("companyForm.employeesTitle"),
        id: "employees",
        subItems: [
            {
                label: i18n.t("companyForm.totalValuePayrollLabel"),
                id: "employees.totalValuePayroll",
            },
            {
                label: i18n.t("companyForm.payrollDayLabel"),
                id: "employees.payrollCloseDate",
            },
            {
                label: i18n.t("companyForm.paydayLabel"),
                id: "employees.paymentDay",
            },
            {
                label: i18n.t("companyForm.employeeNumberLabel"),
                id: "employees.totalEmployees",
            },
            {
                label: i18n.t("companyForm.averageSalaryLabel"),
                id: "employees.averageSalary",
            },
            {
                label: i18n.t("companyForm.averagePermanenceTimeLabel"),
                id: "employees.averagePermanenceTime",
            },
            {
                label: i18n.t("companyForm.totalFiredEmployeesLabel"),
                id: "employees.totalFiredEmployees",
            },
        ],
    },
    {
        label: i18n.t("companyForm.employeeAmountByAgeTitle"),
        id: "employessByAge",
        subItems: [
            {
                label: i18n.t("companyForm.between18And22Label"),
                id: "employessByAge.between18And22",
            },
            {
                label: i18n.t("companyForm.between23And30Label"),
                id: "employessByAge.between23And30",
            },
            {
                label: i18n.t("companyForm.between31And40Label"),
                id: "employessByAge.between31And40",
            },
            {
                label: i18n.t("companyForm.between41And50Label"),
                id: "employessByAge.between41And50",
            },
            {
                label: i18n.t("companyForm.over50Label"),
                id: "employessByAge.between50AndPlus",
            },
        ],
    },
    {
        label: i18n.t("companyForm.employeeAmountByWorkTimeTitle"),
        id: "employeesByTime",
        subItems: [
            {
                label: i18n.t("companyForm.firstYearLabel"),
                id: "employeesByTime.untilOneYear",
            },
            {
                label: i18n.t("companyForm.betweenOneAndTwoLabel"),
                id: "employeesByTime.oneTwoYears",
            },
            {
                label: i18n.t("companyForm.betweenTwoAndThreeLabel"),
                id: "employeesByTime.twoThreeYears",
            },
            {
                label: i18n.t("companyForm.moreThanThreeLabel"),
                id: "employeesByTime.threePlusYears",
            },
        ],
    },
    {
        label: i18n.t("companyForm.activityAndFinancialDataTitle"),
        id: "financialData",
        subItems: [
            {
                label: i18n.t("companyForm.activityLabel"),
                id: "financialData.activity",
            },
            {
                label: i18n.t("companyForm.employeeNumberLabel"),
                id: "financialData.employeesTotal",
            },
            {
                label: i18n.t("companyForm.shoppingValueLabel"),
                id: "financialData.averageShoppingValue",
            },
            {
                label: i18n.t("companyForm.averageRevenueLabel"),
                id: "financialData.averageRevenueValue",
            },
            {
                label: i18n.t("companyForm.averageTimeToSalesLabel"),
                id: "financialData.averageTimeToReceiveSales",
            },
            {
                label: i18n.t("companyForm.utilizationOfCapacityLabel"),
                id: "financialData.utilizationOfInstalledCapacity",
            },
            {
                label: i18n.t("companyForm.marketShareLabel"),
                id: "financialData.marketShare",
            },
            {
                label: i18n.t("companyForm.branchesAmountLabel"),
                id: "financialData.branchesAmount",
            },
            {
                label: i18n.t("companyForm.branchesStateLabel"),
                id: "financialData.branchesStates",
            },
        ],
    },
    {
        label: i18n.t("companyForm.mainProvidersTitle"),
        id: "suppliers",
        subItems: [
            {
                label: i18n.t("companyForm.providerLabel"),
                id: "company_form_suppliers[0].name",
            },
            {
                label: i18n.t("companyForm.telephoneLabel"),
                id: "company_form_suppliers[0].phone",
            },
        ],
    },
    {
        label: i18n.t("companyForm.financialFormTitle"),
        id: "financialInstitutions",
        subItems: [
            {
                label: i18n.t("companyForm.institutionLabel"),
                id: "company_form_financialInstitutions[0].name",
            },
            {
                label: i18n.t("companyForm.agencyLabel"),
                id: "company_form_financialInstitutions[0].agency",
            },
            {
                label: i18n.t("companyForm.accountLabel"),
                id: "company_form_financialInstitutions[0].account",
            },
        ],
    },
    {
        label: i18n.t("companyForm.additionalInfoTitle"),
        id: "additionalInfo",
        subItems: [
            {
                label: i18n.t("companyForm.additionalInfoLabel"),
                id: "additionalInfo",
            },
        ],
    },
];
