exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.installmentsWrapper___2IdVj {\n  display: flex;\n  flex-direction: column;\n  align-self: center;\n  width: 100%;\n  flex-grow: 1;\n  margin-top: 12px;\n  overflow: hidden;\n  overflow-y: scroll;\n  height: 442px; }\n  .installmentsWrapper___2IdVj ul {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: start;\n    padding: 0px;\n    margin: 0px;\n    column-gap: 8px;\n    row-gap: 8px; }\n    .installmentsWrapper___2IdVj ul li {\n      height: 58px;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n      border-radius: 8px;\n      flex-basis: 13.6%;\n      border: 1px solid #dfdfdf;\n      padding: 8px; }\n      .installmentsWrapper___2IdVj ul li .installmentContentTop___fhU7L {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between; }\n      .installmentsWrapper___2IdVj ul li .installmentNumber___2oNlk,\n      .installmentsWrapper___2IdVj ul li .installmentValue___2OvOJ {\n        font-weight: bold;\n        font-size: 13px;\n        color: #4D4D50; }\n      .installmentsWrapper___2IdVj ul li .installmentDate___RAfi- {\n        font-size: 11px;\n        color: #6F6F6F; }\n", ""]);

// Exports
exports.locals = {
	"installmentsWrapper": "installmentsWrapper___2IdVj",
	"installmentContentTop": "installmentContentTop___fhU7L",
	"installmentNumber": "installmentNumber___2oNlk",
	"installmentValue": "installmentValue___2OvOJ",
	"installmentDate": "installmentDate___RAfi-"
};