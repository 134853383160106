import { __rest } from "tslib";
import React from "react";
import { Table } from "antd";
import styles from "./style.scss";
import classNames from "classnames";
export const TableGray = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement("div", { className: styles.tableWrapper },
        React.createElement(Table, Object.assign({}, props, { className: classNames(styles.installmentsTable, props.className) }), children)));
};
