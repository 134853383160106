import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import s from "./style.scss";
import { Spin, Alert } from "antd";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SubSectionHeader } from "../../../../shared/components/SubSectionHeader";
import { CompanyForm } from "../CompanyForm";
import { TableOfContents } from "../../../components/TableOfContents";
import { fields, setFocus } from "../CompanyRegister/helper";
import { useStores } from "../../../utils/mobx";
import { AdminRoutes } from "../../../constants/routes.constants";
const CompanyEdit = observer(() => {
    const { companyStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = React.useState(false);
    const { match } = useReactRouter();
    const { t } = useTranslation();
    const [company, setCompany] = React.useState(null);
    const [message, setMessage] = React.useState("");
    const [messageType, setMessageType] = React.useState("success");
    React.useEffect(() => {
        const companyId = match.params.id;
        if (companyId) {
            getCompanyById(companyId);
        }
    }, []);
    const getCompanyById = (id) => __awaiter(void 0, void 0, void 0, function* () {
        let company = null;
        try {
            setLoading(true);
            company = yield companyStore.getCompanyById(id);
            setCompany(company);
        }
        catch (error) {
            setMessageType("error");
            catchErrors(error, setMessage, "");
        }
        finally {
            setLoading(false);
        }
    });
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setMessage("");
            setLoading(true);
            values.id = company ? company.id : null;
            const savedCompany = yield companyStore.saveCompany(values);
            setCompany(savedCompany);
            setMessageType("success");
            setMessage(`${t("company.editSucessMessage")}`);
        }
        catch (error) {
            catchErrors(error, setMessage, t("company.apiSaveErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        company && (React.createElement(SectionHeader, { title: company.general.socialName, description: t("company.editDescriptionText"), hasBackButton: true, linkToBackButton: AdminRoutes.Company.Actions })),
        message && (React.createElement(Alert, { className: s.fixedMessage, message: message, type: messageType, showIcon: true, closable: true, onClose: () => setMessage("") })),
        React.createElement(SectionContainer, { className: s.container },
            React.createElement(Spin, { spinning: loading },
                React.createElement(SubSectionHeader, { title: t("company.registerFormTitle") }),
                React.createElement("div", { className: s.rowContainer },
                    React.createElement("div", { className: s.side },
                        React.createElement(TableOfContents, { title: t("company.companyInfoLabel"), items: fields, handleSelectItem: setFocus })),
                    company && (React.createElement(CompanyForm, { handleSubmit: handleSubmit, initialValues: company, disableGeneral: true, companyId: company.id || "" })))))));
});
export default CompanyEdit;
