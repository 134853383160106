import { __awaiter } from "tslib";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../utils/mobx";
import { AdminForm } from "../AdminForm";
import { AdminContext } from "../../../../contexts/AdminContext";
export const EditAdminForm = observer(({ open }) => {
    const { t } = useTranslation();
    const { profiles } = useContext(AdminContext);
    const [loading, setLoading] = useState(false);
    const { adminStore: { editAdmin, setAdminToEdit, adminToEdit }, generalStore, generalStore: { catchErrors }, } = useStores();
    const handleCloseModal = () => {
        setAdminToEdit(undefined);
    };
    const handleUpdateUser = useCallback((user, setErrorMessage) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield editAdmin(user);
            handleCloseModal();
            generalStore.setSuccessMessage("Usuário atualizado com sucesso!");
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("users.registerErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    }), [editAdmin, generalStore, catchErrors, setAdminToEdit, handleCloseModal]);
    return (React.createElement(AdminForm, { onCancel: handleCloseModal, onCreate: handleUpdateUser, profiles: profiles, title: t("users.editUser"), visible: open, loading: loading, initialValues: adminToEdit, okText: t("saveLabel"), className: "edit-admin-modal" }));
});
