import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, Spin } from "antd";
import InputPhone from "../../../../../shared/components/InputPhone";
import DynamicForm from "../../../../components/DynamicForm";
import InputBankAccount from "../../../../components/InputBankAccount";
import InputNumber from "../../../../../shared/components/InputNumber";
import { getAllBanks } from "../../../../../shared/services/banks";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const FinancialInstitutionForm = observer(({ form, initialValues }) => {
    const { t } = useTranslation();
    const [banks, setBanks] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { Option } = Select;
    const getBanks = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const fetchedBanks = yield getAllBanks();
            setBanks(fetchedBanks);
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }), []);
    React.useEffect(() => {
        getBanks();
    }, []);
    const renderItem = (getFieldDecorator, itemName, initialValues) => (React.createElement(Spin, { spinning: loading },
        React.createElement(Row, { gutter: 20, key: itemName },
            React.createElement(Col, { span: 10 },
                React.createElement(Form.Item, { label: t("companyForm.institutionLabel") }, getFieldDecorator(`${itemName}.name`, Object.assign({ initialValue: initialValues ? initialValues.name : null }, decoratorConfigDefaultOnBlur))(React.createElement(Select, { showSearch: true, placeholder: "Selecionar institui\u00E7\u00E3o financeira", optionFilterProp: "children" }, banks.map((bank) => (React.createElement(Option, { value: bank.name },
                    bank.name,
                    " (",
                    bank.code,
                    ")"))))))),
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.telephoneLabel"), help: t("companyForm.telephoneHelp") }, getFieldDecorator(`${itemName}.phone`, Object.assign({ initialValue: initialValues ? initialValues.phone : null }, decoratorConfigDefaultOnBlur))(React.createElement(InputPhone, { allowClear: true }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.agencyLabel") }, getFieldDecorator(`${itemName}.agency`, Object.assign({ initialValue: initialValues ? initialValues.agency : "", normalize: (value) => `${value}` }, decoratorConfigDefaultOnBlur))(React.createElement(InputNumber, { placeholder: "0000", maxLength: 5, allowClear: true })))),
            React.createElement(Col, { span: 4 },
                React.createElement(Form.Item, { label: t("companyForm.accountLabel") }, getFieldDecorator(`${itemName}.account`, Object.assign({ initialValue: initialValues ? initialValues.account : "" }, decoratorConfigDefaultOnBlur))(React.createElement(InputBankAccount, { allowClear: true })))))));
    return (React.createElement(DynamicForm, { title: t("companyForm.financialFormTitle"), addItemButtonText: t("companyForm.financialFormButtonText"), defaultEmptyValue: {
            name: null,
            phone: null,
            account: null,
            agency: null,
        }, formKey: "financialInstitutions", renderItem: renderItem, form: form, initialValues: initialValues }));
});
export default FinancialInstitutionForm;
