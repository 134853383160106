import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Input, Row, Col } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import { useStores } from "../../../../utils/mobx";
import InputPhone from "../../../../../shared/components/InputPhone";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const ContactForm = observer(({ form, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { getFieldDecorator } = form;
    const formKey = "contact";
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.contactTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("emailLabel") }, getFieldDecorator(`${formKey}.email`, Object.assign({ initialValue: initialValues.email, rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("emailLabel") }),
                        },
                    ] }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { allowClear: true, placeholder: t("companyForm.emailPlaceholder"), onFocus: () => setSelectedItem(`${formKey}.email`), maxLength: 255 })))),
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.telephoneLabel"), help: t("companyForm.telephoneHelp") }, getFieldDecorator(`${formKey}.phone`, Object.assign({ initialValue: initialValues.phone, rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("companyForm.telephoneLabel") }),
                        },
                    ] }, decoratorConfigDefaultOnBlur))(React.createElement(InputPhone, { onFocus: () => setSelectedItem(`${formKey}.phone`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: t("companyForm.otherContactsLabel") }, getFieldDecorator(`${formKey}.otherContacts`, Object.assign({ initialValue: initialValues.otherContacts }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { allowClear: true, placeholder: t("companyForm.lobbyTelephoneLabel") + " (71) 9999-9999", onFocus: () => setSelectedItem(`${formKey}.otherContacts`), maxLength: 500 })))))));
});
export default ContactForm;
