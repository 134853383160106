import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useStores } from "../../utils/mobx";
import s from "./style.scss";
const TocItem = observer(({ item, isSelectedItem, handleItemClick }) => (React.createElement("li", { className: classNames(s.tocListItem, s.truncate, {
        [s.selectedListItem]: isSelectedItem(item),
    }), onClick: () => handleItemClick(item) }, item.label)));
export const TableOfContents = observer(({ title, items, handleSelectItem }) => {
    const { tableOfContentStore: { setSelectedItem, isSelectedItem }, } = useStores();
    const handleItemClick = (item) => {
        setSelectedItem(item.id);
        handleSelectItem(item.id);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.tocTitle }, title),
        React.createElement("ul", { className: s.tocList }, items.map((item) => item.subItems && item.subItems.length > 0 ? (React.createElement("div", { key: `toc-${item.id}` },
            React.createElement("li", { className: classNames(s.truncate, s.tocListItem, s.tocListItemTitle) }, item.label),
            item.subItems.map((sub) => (React.createElement(TocItem, { key: `toc-sub-${sub.id}`, item: sub, handleItemClick: handleItemClick, isSelectedItem: () => isSelectedItem(sub) }))))) : (React.createElement(TocItem, { key: `toc-${item.id}`, item: item, handleItemClick: handleItemClick, isSelectedItem: () => isSelectedItem(item) }))))));
});
