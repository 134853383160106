import * as ReactDOM from "react-dom";
import React from "react";
/**
 * This import should occurs before App import
 */
import { AppWrapper } from "./AppWrapper";
import { App } from "./containers/App";
import { ClearCacheProvider } from "react-clear-cache";
ReactDOM.render(React.createElement(ClearCacheProvider, { duration: 5000 },
    React.createElement(AppWrapper, null,
        React.createElement(App, null))), document.getElementById("root"));
