import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
const BorderoNumberInput = ({ form }) => {
    const { getFieldDecorator } = form;
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.borderoNumberInput },
        React.createElement(Form.Item, { label: t("cession.borderoNumber") }, getFieldDecorator(`borderoNumber`, {
            rules: [
                {
                    required: true,
                    message: t("mandatoryField", {
                        field: t("cession.borderoNumber"),
                    }),
                },
            ],
        })(React.createElement(InputNumber, { "data-cy": "bordero-number-input", min: 0 })))));
};
export default BorderoNumberInput;
