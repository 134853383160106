import { __awaiter } from "tslib";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { ProfileSelect } from "../../CustomSelects/ProfileSelect";
import { useStores } from "../../../utils/mobx";
import { observer } from "mobx-react-lite";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import styles from "./style.scss";
export const ExternalApi = observer(({}) => {
    const { t } = useTranslation();
    const [profiles, setProfiles] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState();
    const { accessProfileStore: { getAllProfiles, getProfilesTotal }, generalStore, generalStore: { catchErrors }, apiSecretStore: { apiSecret, createCompanyApiSecret, regenerateCompanyApiSecret, updateCompanyApiSecretProfile, }, } = useStores();
    const { selectedCompaniesGroup } = useContext(CompaniesGroupContext);
    const fetchProfiles = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const profilesTotal = yield getProfilesTotal({ name: null, type: "external_api" });
            const { profiles: fetchedProfiles } = yield getAllProfiles({ name: null, type: "external_api" }, 1, profilesTotal);
            setProfiles(fetchedProfiles);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    useEffect(() => {
        fetchProfiles();
    }, []);
    useEffect(() => {
        setSelectedProfileId((apiSecret === null || apiSecret === void 0 ? void 0 : apiSecret.profileId) || "");
    }, [apiSecret]);
    const copySecret = () => {
        navigator.clipboard.writeText(apiSecret === null || apiSecret === void 0 ? void 0 : apiSecret.apiSecret);
        generalStore.setSuccessMessage(t("externalApi.secretCopySuccessMessage"));
    };
    const hasSecret = apiSecret && apiSecret.id !== "";
    const createApiSecret = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield createCompanyApiSecret({
                companiesGroupId: selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.id,
                profileId: selectedProfileId,
            });
            generalStore.setSuccessMessage(t("externalApi.secretCreationSuccessMessage"));
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    const regenerateApiSecret = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield regenerateCompanyApiSecret(selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.id);
            generalStore.setSuccessMessage(t("externalApi.secretRegenerationSuccessMessage"));
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    const updateApiSecret = (profileId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield updateCompanyApiSecretProfile(selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.id, profileId);
            generalStore.setSuccessMessage(t("externalApi.profileUpdateSuccessMessage"));
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    const handleProfileChange = (newSelectedProfileId) => {
        setSelectedProfileId(newSelectedProfileId);
        if (hasSecret) {
            updateApiSecret(newSelectedProfileId);
        }
    };
    return (React.createElement("div", { className: styles.bodyWrapper },
        React.createElement("div", { className: styles.firstRow },
            React.createElement(Form.Item, { className: styles.apiKeyField, label: t("externalApi.apiKey"), "data-cy": "apiKey-input-wrapper" },
                React.createElement(Input, { value: (apiSecret === null || apiSecret === void 0 ? void 0 : apiSecret.apiKey) || String(t("defaultNullPlaceholder")), "data-cy": "apiKey-input-data-cy", placeholder: String(t("defaultNullPlaceholder")) })),
            React.createElement(Form.Item, { label: t("externalApi.profile"), "data-cy": "profile-input-wrapper", className: styles.select },
                React.createElement(ProfileSelect, { profiles: profiles, onProfileChange: handleProfileChange, initialValue: selectedProfileId }))),
        React.createElement("div", { className: styles.secondRow },
            React.createElement(Form.Item, { label: t("externalApi.apiSecret"), "data-cy": "apiSecret-input-wrapper" },
                React.createElement(Input.Password, { "data-cy": "apiSecret-input-data-cy", value: apiSecret === null || apiSecret === void 0 ? void 0 : apiSecret.apiSecret, placeholder: String(t("defaultNullPlaceholder")) })),
            React.createElement("div", { className: styles.buttonsWrapper },
                React.createElement(Button, { onClick: copySecret, type: "ghost", disabled: !hasSecret, "data-cy": "copy-button-data-cy" }, "Copiar"),
                React.createElement(Button, { onClick: () => {
                        if (hasSecret) {
                            regenerateApiSecret();
                        }
                        else {
                            createApiSecret();
                        }
                    }, type: "primary", style: { marginLeft: 8 } },
                    hasSecret ? t("externalApi.regenerate") : t("externalApi.generate"),
                    " API Secret")))));
});
export const ExternalApiForm = Form.create({
    name: "external_api_form",
})(ExternalApi);
