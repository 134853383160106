import { __awaiter } from "tslib";
import { api } from "../AppWrapper";
export class AgreementStore {
    constructor() {
        this.getAgreementById = (id) => api.getAgreementById({ id });
        this.createAgreement = (companyId, agreement, file) => api.createAgreement({ companyId, agreement, file });
        this.canCreateAgreement = (companyId) => api.canCreateAgreement({ companyId });
        this.getAllCompanyAgreements = (companyId) => api.getAgreements({ companyId });
        this.cancelAgreement = (id) => api.cancelAgreement({ id });
        this.getAgreementFile = (id) => __awaiter(this, void 0, void 0, function* () { return yield api.getAgreementFile({ id }); });
    }
}
