import { __awaiter } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, InputNumber, Row } from "antd";
import Form from "antd/lib/form";
import { useStores } from "../../../utils/mobx";
import { observer } from "mobx-react-lite";
import { Title } from "../../../../shared/components/Title";
import { FormTitle } from "../../../../shared/components/FormTitle";
import { formatCurrencyNoPrefix, parseToString, toInt } from "../../../../shared/utils/formatters";
import InputCurrency from "../../../../shared/components/InputCurrency";
import styles from "./style.scss";
const FgtsParametersInnerForm = observer(({ initialValues, onSubmit, form }) => {
    const { t } = useTranslation();
    const { getFieldDecorator, validateFields } = form;
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                onSubmit(values);
            }
        }));
    });
    const buildMessageToRequiredLabel = (fieldLabel) => {
        return `${fieldLabel} ${t("isRequiredLabel")}`;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.formContainer },
            React.createElement(Title, { title: t("fgtsParameters.parametersLabel") }),
            React.createElement(Form, { className: styles.form, layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit, "data-cy": "fgts-parameters-form-data-cy" },
                React.createElement(FormTitle, { title: t("fgtsParameters.generalInfoTitle") }),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: `${t("fgtsParameters.interestRate")} (%)` }, getFieldDecorator("interestRate", {
                            initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.interestRate,
                            normalize: (value) => toInt(value),
                            rules: [
                                {
                                    required: true,
                                    message: buildMessageToRequiredLabel(t("fgtsParameters.interestRate")),
                                },
                            ],
                        })(React.createElement(InputNumber, { placeholder: "0,00", formatter: (value) => formatCurrencyNoPrefix(parseToString(value)), min: 0, onFocus: () => setSelectedItem(`interestRate`) })))),
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: `${t("fgtsParameters.structuringRate")} (%)` }, getFieldDecorator("structuringRate", {
                            initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.structuringRate,
                            normalize: (value) => toInt(value),
                            rules: [
                                {
                                    required: true,
                                    message: buildMessageToRequiredLabel(t("fgtsParameters.structuringRate")),
                                },
                            ],
                        })(React.createElement(InputNumber, { placeholder: "0,00", formatter: (value) => formatCurrencyNoPrefix(parseToString(value)), min: 0, onFocus: () => setSelectedItem(`structuringRate`) }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: `${t("fgtsParameters.tedValue")} (R$)` }, getFieldDecorator("tedValue", {
                            initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.tedValue,
                            normalize: (value) => toInt(value),
                            rules: [
                                {
                                    required: true,
                                    message: buildMessageToRequiredLabel(t("fgtsParameters.tedValue")),
                                },
                            ],
                        })(React.createElement(InputCurrency, { placeholder: "0,00", min: 0, formatter: (value) => formatCurrencyNoPrefix(parseToString(value)), onFocus: () => setSelectedItem(`tedValue`) })))),
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: `${t("fgtsParameters.thirdPartyValue")} (R$)` }, getFieldDecorator("thirdPartyValue", {
                            initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.thirdPartyValue,
                            normalize: (value) => toInt(value),
                            rules: [
                                {
                                    required: true,
                                    message: buildMessageToRequiredLabel(t("fgtsParameters.thirdPartyValue")),
                                },
                            ],
                        })(React.createElement(InputCurrency, { placeholder: "0,00", min: 0, formatter: (value) => formatCurrencyNoPrefix(parseToString(value)), onFocus: () => setSelectedItem(`thirdPartyValue`) }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: t("fgtsParameters.antecipationPeriodsNumber") }, getFieldDecorator("antecipationPeriodsNumber", {
                            initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.antecipationPeriodsNumber,
                            normalize: (value) => toInt(value),
                            rules: [
                                {
                                    required: true,
                                    message: buildMessageToRequiredLabel(t("fgtsParameters.antecipationPeriodsNumber")),
                                },
                            ],
                        })(React.createElement(InputNumber, { placeholder: "5", min: 0, onFocus: () => setSelectedItem(`antecipationPeriodsNumber`) }))))),
                React.createElement(Row, { gutter: 20, justify: "end" },
                    React.createElement(Col, { span: 16 },
                        React.createElement("div", { className: styles.buttonsRow },
                            React.createElement(Button, { type: "primary", htmlType: "submit", "data-cy": "fgts-parameters-save-button" }, t("companyForm.saveEditLabel")))))))));
});
export const FgtsParametersForm = Form.create({
    name: "fgts_parameters_form",
})(FgtsParametersInnerForm);
