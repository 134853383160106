import { __rest } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Button } from "antd";
import { Title } from "../../../../shared/components/Title";
import GeneralDataFields from "./GeneralDataFields";
import ContactForm from "./ContactForm";
import AddressForm from "../../../components/AdressForm";
import RequiredCategorizationForm from "./RequiredCategorizationForm";
import ChargeAddressForm from "./ChargeAddressForm";
import EmployeeForm from "./EmployeeForm";
import EmployeeByAgeForm from "./EmployeeByAgeForm";
import EmployeeByTimeForm from "./EmployeeByTimeForm";
import FinancialDataForm from "./FinancialDataForm";
import AdditionalInfoForm from "./AdditionalInfoForm";
import SupplierForm from "./SupplierForm";
import FinancialInstitutionForm from "./FinancialInstitutionForm";
import { ButtonGroup } from "../../../../shared/components/ButtonGroup";
import { IconButton } from "../../../../shared/components/IconButton";
import { ArrowUp } from "../../../../shared/components/Icons/ArrowUp";
import { CompanyFormContext, addressInitialState, companyInfoInitialState, } from "./context";
import { LinkForm } from "./LinkForm";
import { parseMomentToString } from "../../../../shared/utils/date";
import { LegalResponsibleForm } from "../../../components/Company/LegalResponsibleForm";
import s from "./style.scss";
const InnerForm = observer((_a) => {
    var { form, initialValues, disableGeneral, companyId, disableSectionLink } = _a, props = __rest(_a, ["form", "initialValues", "disableGeneral", "companyId", "disableSectionLink"]);
    const { validateFieldsAndScroll } = form;
    const [companyInfo, setCompanyInfo] = React.useState(companyInfoInitialState);
    const [address, setAddress] = React.useState(addressInitialState);
    const handleChangeAddress = (key, value) => {
        setAddress(Object.assign(Object.assign({}, address), { [key]: value }));
    };
    const handleChangeCompanyInfo = (key, value) => {
        setCompanyInfo(Object.assign(Object.assign({}, companyInfo), { [key]: value }));
    };
    const { t } = useTranslation();
    const removeEmptyFromArray = (array) => {
        if (array) {
            return array.filter((element) => element.name !== null && element.name !== "") || [];
        }
        return [];
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFieldsAndScroll(["general"], (errors) => {
            if (!errors) {
                const values = form.getFieldsValue();
                values.patrimonialInfo.constitutionDate = parseMomentToString(values.patrimonialInfo.constitutionDate);
                values.patrimonialInfo.netWorthReferenceDate = parseMomentToString(values.patrimonialInfo.netWorthReferenceDate);
                values.patrimonialInfo.lastStaturaryChangeDate = parseMomentToString(values.patrimonialInfo.lastStaturaryChangeDate);
                if (values.suppliers) {
                    if (initialValues && initialValues.suppliers) {
                        initialValues.suppliers.map((supplier, index) => (values.suppliers[index].id = supplier.id));
                    }
                    values.suppliers = removeEmptyFromArray(values.suppliers);
                }
                if (values.financialInstitutions) {
                    if (initialValues && initialValues.financialInstitutions) {
                        initialValues.financialInstitutions.map((financialInstitution, index) => (values.financialInstitutions[index].id = financialInstitution.id));
                    }
                    values.financialInstitutions = removeEmptyFromArray(values.financialInstitutions);
                }
                values.patrimonialInfo.netWorth = BigInt(values.patrimonialInfo.netWorth || 0);
                values.patrimonialInfo.socialCapital = BigInt(values.patrimonialInfo.socialCapital || 0);
                values.employees.totalValuePayroll = BigInt(values.employees.totalValuePayroll || 0);
                values.financialData.averageShoppingValue = BigInt(values.financialData.averageShoppingValue || 0);
                values.financialData.averageRevenueValue = BigInt(values.financialData.averageRevenueValue || 0);
                values.legalResponsibles = values.legalResponsibles || [];
                props.handleSubmit(values);
            }
        });
    };
    const goToTop = () => window.scrollTo(0, 0);
    return (React.createElement(CompanyFormContext.Provider, { value: {
            companyInfo,
            address,
            handleChangeAddress,
            handleChangeCompanyInfo,
            setAddress,
            setCompanyInfo,
        } },
        React.createElement("div", { className: s.formContainer },
            !disableSectionLink && (React.createElement(LinkForm, { form: form, companyId: companyId, initialValues: {
                    subscriptionLink: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.documentLink) || {
                        token: null,
                        dueDate: null,
                        link: null,
                    },
                } })),
            React.createElement(Title, { title: t("company.companyInfoLabel") }),
            React.createElement(Form, { layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit },
                React.createElement(GeneralDataFields, { form: form, initialValues: initialValues && initialValues.general
                        ? initialValues.general
                        : companyInfoInitialState, disabled: !!disableGeneral, companyId: companyId }),
                React.createElement(LegalResponsibleForm, { form: form, "data-cy": "legal-responsible-form", companyId: initialValues === null || initialValues === void 0 ? void 0 : initialValues.id, companyLegalResponsibles: initialValues === null || initialValues === void 0 ? void 0 : initialValues.legalResponsibles }),
                React.createElement(AddressForm, { form: form, title: t("addressLabel"), initialValues: initialValues && initialValues.address ? initialValues.address : addressInitialState }),
                React.createElement(ContactForm, { form: form, initialValues: initialValues && initialValues.contact
                        ? initialValues.contact
                        : {
                            email: null,
                            phone: null,
                            otherContacts: null,
                        } }),
                React.createElement(ChargeAddressForm, { form: form, initialValues: initialValues && initialValues.chargeAddress
                        ? initialValues.chargeAddress
                        : {
                            sameAddress: true,
                            otherContact: null,
                            address: null,
                        } }),
                React.createElement(RequiredCategorizationForm, { form: form, initialValues: initialValues && initialValues.patrimonialInfo
                        ? initialValues.patrimonialInfo
                        : {
                            constitutionDate: null,
                            lastStaturaryChangeDate: null,
                            changeAmount: null,
                            socialCapital: null,
                            netWorth: null,
                            economicSector: null,
                            capitalOrigin: null,
                            netWorthReferenceDate: null,
                        } }),
                React.createElement(EmployeeForm, { form: form, initialValues: initialValues && Object.assign(Object.assign({}, initialValues.employees), { employeesTotal: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.financialData.employeesTotal) || null }) }),
                React.createElement(EmployeeByAgeForm, { form: form, initialValues: initialValues && initialValues.employessByAge
                        ? initialValues.employessByAge
                        : {
                            between18And22: null,
                            between23And30: null,
                            between31And40: null,
                            between41And50: null,
                            between50AndPlus: null,
                        } }),
                React.createElement(EmployeeByTimeForm, { form: form, initialValues: initialValues && initialValues.employeesByTime
                        ? initialValues.employeesByTime
                        : {
                            untilOneYear: null,
                            oneTwoYears: null,
                            twoThreeYears: null,
                            threePlusYears: null,
                        } }),
                React.createElement(FinancialDataForm, { form: form, initialValues: initialValues && initialValues.financialData }),
                React.createElement(SupplierForm, { form: form, initialValues: initialValues && initialValues.suppliers ? initialValues.suppliers || [] : null }),
                React.createElement(FinancialInstitutionForm, { form: form, initialValues: initialValues && initialValues.financialInstitutions
                        ? initialValues.financialInstitutions || []
                        : null }),
                React.createElement(AdditionalInfoForm, { form: form, initialValue: initialValues && initialValues.additionalInfo ? initialValues.additionalInfo : null }),
                React.createElement("div", { className: s.buttonsRow },
                    React.createElement(ButtonGroup, { className: s.buttons },
                        React.createElement(IconButton, { buttonIcon: ArrowUp, onClick: goToTop }, "Ir para o topo"),
                        React.createElement(Button, { type: "primary", htmlType: "submit" }, initialValues && initialValues.id
                            ? t("companyForm.saveEditLabel")
                            : t("companyForm.addCompanyLabel"))))))));
});
export const CompanyForm = Form.create({
    name: "company_form",
})(InnerForm);
