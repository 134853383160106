import { __awaiter } from "tslib";
import { api } from "../AppWrapper";
export class UserReportStore {
    constructor() {
        this.getUsersReport = (filter, page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () { return api.getUsersReport({ filter, page, rowsPerPage }); });
        this.getUsersReportTotal = (filter) => __awaiter(this, void 0, void 0, function* () { return api.getUsersReportTotal({ filter }); });
        this.suspendUsers = (usersToSuspend) => __awaiter(this, void 0, void 0, function* () { return api.suspendUsers({ usersToSuspend }); });
        this.getUserSuspensionHistory = (userId) => __awaiter(this, void 0, void 0, function* () { return api.getUserSuspensionHistory({ userId }); });
        this.reactivateUser = (userId) => __awaiter(this, void 0, void 0, function* () { return api.reactivateUser({ userId }); });
        this.changeUserEmail = (userId, newEmail) => __awaiter(this, void 0, void 0, function* () { return api.changeUserEmail({ userId, newEmail }); });
        this.changeUserContact = (userId, email, mobilePhone) => __awaiter(this, void 0, void 0, function* () { return api.changeUserContact({ userId, contact: { email, mobilePhone } }); });
        this.unblockUser = (userId) => __awaiter(this, void 0, void 0, function* () { return api.unblockUser({ userId }); });
        this.closeDigitalAccount = (closeDigitalAccountParams) => __awaiter(this, void 0, void 0, function* () { return api.closeDigitalAccount({ closeDigitalAccountParams }); });
    }
}
