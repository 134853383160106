import i18n from "../../i18n";
import { formatCnpj, formatStringCurrency } from "../../../shared/utils/formatters";
import { formatFullDateTime } from "../../../shared/utils/date";
export const columns = [
    {
        title: i18n.t("companyLabel"),
        dataIndex: "companyName",
        key: "companyName",
        width: 400,
    },
    {
        title: i18n.t("cnpjLabel"),
        dataIndex: "cnpj",
        key: "cnpj",
        render: (cnpj) => formatCnpj(cnpj),
    },
    {
        title: i18n.t("simulatedValueLabel"),
        dataIndex: "requestedAmountInCents",
        key: "requestedAmountInCents",
        render: (requestedAmountInCents) => formatStringCurrency(requestedAmountInCents),
        width: 250,
    },
    {
        title: i18n.t("installmentsLabel"),
        dataIndex: "numberOfInstallments",
        key: "numberOfInstallments",
        width: 150,
    },
    {
        title: i18n.t("lastUpdateLabel"),
        dataIndex: "lastUpdate",
        key: "lastUpdate",
        render: (lastUpdate) => formatFullDateTime(lastUpdate),
    },
    {
        title: "",
        key: "actions",
        dataIndex: "actions",
        width: 230,
    },
];
