import { __awaiter, __decorate } from "tslib";
import { action, computed, observable } from "mobx";
import { api } from "../AppWrapper";
export class ApiSecretStore {
    constructor() {
        this.createCompanyApiSecret = (params) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.loadingValue = true;
                this.apiSecretValue = yield api.createCompaniesGroupApiSecret({
                    params: Object.assign(Object.assign({}, params), { profileId: params.profileId ? params.profileId : null }),
                });
            }
            catch (error) {
                throw error;
            }
            finally {
                this.loadingValue = false;
            }
        });
        this.getCompanyApiSecret = (companiesGroupId) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.loadingValue = true;
                this.apiSecretValue = null;
                this.apiSecretValue = yield api.getCompaniesGroupApiSecret({ companiesGroupId });
            }
            catch (error) {
                throw error;
            }
            finally {
                this.loadingValue = false;
            }
        });
        this.regenerateCompanyApiSecret = (companiesGroupId) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.loadingValue = true;
                this.apiSecretValue = yield api.regenerateCompaniesGroupApiSecret({ companiesGroupId });
            }
            catch (error) {
                throw error;
            }
            finally {
                this.loadingValue = false;
            }
        });
        this.updateCompanyApiSecretProfile = (companiesGroupId, profileId) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.loadingValue = true;
                this.apiSecretValue = yield api.updateCompaniesGroupApiSecretProfile({
                    companiesGroupId,
                    profileId: profileId ? profileId : null,
                });
            }
            catch (error) {
                throw error;
            }
            finally {
                this.loadingValue = false;
            }
        });
    }
    get apiSecret() {
        return this.apiSecretValue;
    }
    get loading() {
        return this.loadingValue;
    }
}
__decorate([
    observable
], ApiSecretStore.prototype, "apiSecretValue", void 0);
__decorate([
    observable
], ApiSecretStore.prototype, "loadingValue", void 0);
__decorate([
    computed
], ApiSecretStore.prototype, "apiSecret", null);
__decorate([
    computed
], ApiSecretStore.prototype, "loading", null);
__decorate([
    action
], ApiSecretStore.prototype, "createCompanyApiSecret", void 0);
__decorate([
    action
], ApiSecretStore.prototype, "getCompanyApiSecret", void 0);
__decorate([
    action
], ApiSecretStore.prototype, "regenerateCompanyApiSecret", void 0);
__decorate([
    action
], ApiSecretStore.prototype, "updateCompanyApiSecretProfile", void 0);
