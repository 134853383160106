import React from "react";
import { Tooltip } from "antd";
import { SemanticButton } from "../../../shared/components/SemanticButton";
import { CircledDownloadIcon } from "../../../shared/components/Icons/CircledDownload";
import { openFileInNewTab } from "../../../shared/utils/file";
import styles from "./style.scss";
const handleClick = (link, e) => {
    e.stopPropagation();
    if (link !== null) {
        openFileInNewTab(link);
    }
};
const Button = (link) => {
    return (React.createElement(SemanticButton, { disabled: link === null, onClick: (e) => {
            handleClick(link, e);
        } },
        React.createElement(CircledDownloadIcon, null)));
};
const DownloadTooltip = ({ link, tooltipLabel }) => {
    return link === null ? (React.createElement(Tooltip, { mouseLeaveDelay: 0, trigger: "hover", placement: "topRight", title: tooltipLabel },
        React.createElement("div", { className: styles.tooltipContainer }, Button(link)))) : (React.createElement(React.Fragment, null, Button(link)));
};
export default DownloadTooltip;
