import { __awaiter } from "tslib";
import { Spin } from "antd";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../utils/mobx";
import { downloadFileFromBuffer, getDocumentsWithFileCount, } from "../../../../../shared/utils/file";
import { FormTitle as FormSectionTitle } from "../../../../../shared/components/FormTitle";
import styles from "./style.scss";
import { DocumentDownloadCard } from "../../DocumentDownloadCard";
const CompanyDocumentsFiles = observer(({ documentsGroups }) => {
    const { t } = useTranslation();
    const { companyStore: { selectedCompany, getCompanyDocumentFile }, generalStore: { setErrorMessage, clearMessage, catchErrors }, } = useStores();
    const [loading, setLoading] = useState(false);
    const downloadCompanyFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            clearMessage();
            if ((file === null || file === void 0 ? void 0 : file.fileId) && selectedCompany) {
                const downloadedFile = yield getCompanyDocumentFile(selectedCompany.id, file.fileId);
                if (downloadedFile) {
                    downloadFileFromBuffer(downloadedFile, file.name);
                }
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("getCompanyDocumentFileFail"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { spinning: loading, style: { minHeight: "25em" } }, !isEmpty(documentsGroups) ? (documentsGroups.map((category) => (React.createElement("div", { key: category.name },
            React.createElement(FormSectionTitle, { title: category.name }),
            React.createElement("div", { className: styles.documentsCounter }, `${getDocumentsWithFileCount(category.documents)}/${category.documents.length}`),
            React.createElement("div", { className: styles.container }, category.documents.map((document, index) => (React.createElement("div", { key: document.id, "data-cy": "document-card-data-cy" },
                React.createElement(DocumentDownloadCard, { isChecked: !!document.file, attachment: document.file, sequence: index + 1, title: document.name, file: document.file, onDownloadClick: () => downloadCompanyFile(document.file), disabled: !document.file }))))))))) : (React.createElement("span", { "data-cy": "empty-file-description-data-cy" }, t("companyDocument.emptyDocumentsListsDescription"))))));
});
export default CompanyDocumentsFiles;
