import React from "react";
import classNames from "classnames";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Title } from "../Title";
import s from "./style.scss";
import { LeftArrow } from "../Icons/LeftArrow";
import { useStores } from "../../../admin/utils/mobx";
export const SectionHeader = ({ title, description, actions, className, hasBackButton, linkToBackButton, info, }) => {
    const { routerStore } = useStores();
    const { t } = useTranslation();
    return (React.createElement("div", { className: classNames(s.sectionHeader, className) },
        React.createElement("div", null,
            hasBackButton && (React.createElement("div", { "data-cy": "back-button", className: s.backButton, onClick: () => {
                    if (linkToBackButton) {
                        routerStore.push(linkToBackButton);
                    }
                    else {
                        routerStore.goBack();
                    }
                } },
                React.createElement(LeftArrow, null),
                " ",
                t("backLabel"))),
            React.createElement(Title, { title: title }),
            info && React.createElement("div", { className: s.info }, info),
            description && React.createElement("div", { className: s.description }, description)),
        actions &&
            actions.map((action) => (React.createElement(Button, { className: s.iconButton, key: action.label, type: "primary", size: "large", onClick: action.handleAction, disabled: action === null || action === void 0 ? void 0 : action.disabled, style: action.style, "data-cy": action.dataCy },
                action.icon && action.icon,
                action.label)))));
};
