import React from "react";
import { useTranslation } from "react-i18next";
import { FilterForm } from "../../../../shared/components/FilterForm";
import styles from "./style.scss";
const PrecatoryAnticipationContractsFilterForm = ({ applyFilter }) => {
    const { t } = useTranslation();
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: t("cpfLabel"),
            inputMask: "111.111.111-11",
        },
        {
            key: "ccbNumber",
            name: "ccbNumber",
            type: "input",
            placeholder: t("contractNumber"),
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: t("statusLabel"),
            mode: "multiple",
            selectOptions: [
                {
                    value: "active",
                    label: t("precatoryAnticipation.contractStatus.active"),
                },
                {
                    value: "pendingIdentityValidations",
                    label: t("precatoryAnticipation.contractStatus.pendingIdentityValidations"),
                },
                {
                    value: "failed",
                    label: t("precatoryAnticipation.contractStatus.failed"),
                },
            ],
        },
        {
            key: "date",
            name: "data",
            type: "dateRange",
        },
    ];
    return React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: applyFilter });
};
export default PrecatoryAnticipationContractsFilterForm;
