import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ButtonWithPreventDefault } from "../../../../shared/components/ButtonWithPreventDefault";
import styles from "./style.scss";
const CcbPreviewBottomActions = ({ onBackClick, onEditDataClick, onGenerateCcbClick, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.bottomNavigatorWrapper },
        React.createElement(ButtonWithPreventDefault, { type: "default", handleClick: onBackClick, "data-testid": "back-button" }, t("backLabel")),
        React.createElement("div", { className: styles.rightBottomNavigatorWrapper },
            React.createElement(ButtonWithPreventDefault, { type: "primary", className: classNames(styles.button, styles.editButton), handleClick: onEditDataClick, "data-testid": "editData-button" }, t("ccbPreviewStep.editDataButtonLabel")),
            React.createElement(ButtonWithPreventDefault, { type: "primary", className: classNames(styles.blueButton, styles.button, styles.generateButton), handleClick: onGenerateCcbClick, "data-testid": "generateCcb-button", "data-cy": "generate-ccb-button" }, t("ccbPreviewStep.generateCcbButtonLabel")))));
};
export default CcbPreviewBottomActions;
