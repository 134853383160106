import { __awaiter } from "tslib";
import React, { useState } from "react";
import { Input, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { AdminRoutes } from "../../../constants/routes.constants";
export const DeleteProfileModal = ({ id, onCancel, open }) => {
    const [profileName, setProfileName] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const { accessProfileStore, generalStore, generalStore: { catchErrors }, routerStore, } = useStores();
    const { selectedProfile } = accessProfileStore;
    const { t } = useTranslation();
    const shouldDeleteProfile = () => profileName === (selectedProfile === null || selectedProfile === void 0 ? void 0 : selectedProfile.title);
    const handleDeleteProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setisLoading(true);
            yield accessProfileStore.deleteProfile(id);
            routerStore.push(AdminRoutes.Admin.Profiles);
            generalStore.setSuccessMessage("Perfil removido com sucesso!");
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setisLoading(false);
        }
    });
    const handleModalClose = () => {
        if (onCancel) {
            onCancel();
        }
        setProfileName("");
    };
    return (React.createElement(Modal, { title: React.createElement("div", null, t("accessProfile.deleteUserLabel")), okText: t("accessProfile.deleteUserLabel"), okButtonProps: {
            disabled: !shouldDeleteProfile,
        }, onOk: handleDeleteProfile, visible: open, onCancel: handleModalClose, width: 600 },
        React.createElement(Spin, { spinning: isLoading },
            React.createElement("p", null,
                t("accessProfile.removalMessage", { profileName: selectedProfile === null || selectedProfile === void 0 ? void 0 : selectedProfile.title }),
                " ",
                React.createElement("strong", null, t("accessProfile.removalImpacts"))),
            React.createElement("p", null,
                t("accessProfile.pleaseWrite"),
                " ",
                React.createElement("strong", null,
                    " ", selectedProfile === null || selectedProfile === void 0 ? void 0 :
                    selectedProfile.title),
                " ",
                t("accessProfile.toConfirm"),
                "."),
            React.createElement("p", null,
                React.createElement(Input, { value: profileName, type: "text", onChange: (e) => setProfileName(e.target.value), autoFocus: true, allowClear: true, "data-cy": "profile-to-delete-name-input" })))));
};
