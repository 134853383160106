import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithPreventDefault } from "../../../../shared/components/ButtonWithPreventDefault";
import styles from "./style.scss";
const RequestBottomActions = ({ onBackClick, onNextClick, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.bottomNavigatorWrapper },
        React.createElement(ButtonWithPreventDefault, { type: "default", "data-cy": "backStep-data-cy", handleClick: onBackClick }, t("backLabel")),
        React.createElement(ButtonWithPreventDefault, { type: "primary", className: styles.nextButton, "data-cy": "nextStep-data-cy", handleClick: onNextClick }, t("next"))));
};
export default RequestBottomActions;
