import { Input, InputNumber, Radio, Select } from "antd";
import Form from "antd/lib/form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typograph } from "../../../../shared/components/Typograph";
import StepContentCard from "../StepContentCard";
import styles from "./styles.scss";
import InputBankAccount from "../../InputBankAccount";
import { decoratorConfigDefaultOnBlur } from "../../../../admin/utils/decoratorsConfig";
import { CustomRadio } from "../../../../shared/components/CustomRadio";
import classNames from "classnames";
import { chargesCalculations, installmentPaymentWays } from "./radioUtils/radioValue";
const { Option } = Select;
export const ConditionsForm = ({ form, banks, conditions }) => {
    var _a;
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    const [bankCode, setBankCode] = useState((_a = conditions === null || conditions === void 0 ? void 0 : conditions.bankCode) !== null && _a !== void 0 ? _a : "");
    const handleSetBankCode = (bankName) => {
        const bank = banks === null || banks === void 0 ? void 0 : banks.find((bankInstituition) => bankInstituition.name === bankName);
        setBankCode(String(bank === null || bank === void 0 ? void 0 : bank.code));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContentCard, { title: React.createElement(Typograph, { variant: "modalTitle" }, t("conditionsStep.conditionsFormTitle")), subTitle: React.createElement(Typograph, { variant: "subtitle" }, t("conditionsStep.conditionsFormSubTitle")) },
            React.createElement("div", null,
                React.createElement("div", { className: styles.conditionsCard },
                    React.createElement(Form.Item, { label: t("conditionsStep.releaseWay"), className: classNames([styles.midFields, styles.releaseWay]) }, getFieldDecorator("releaseWay", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", {
                                    field: t("conditionsStep.releaseWay"),
                                }),
                            },
                        ],
                        initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.releaseWay,
                    })(React.createElement(Input, { "data-testid": "releaseWay", "data-cy": "releaseWay-data-cy", placeholder: "Transfer\u00EAncia banc\u00E1ria" })))),
                React.createElement("div", { className: styles.conditionsCard },
                    React.createElement(Form.Item, { label: t("conditionsStep.installmentsPaymentWay"), className: styles.midFields }, getFieldDecorator("installmentsPaymentWay", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", {
                                    field: t("conditionsStep.installmentsPaymentWay"),
                                }),
                            },
                        ],
                        initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.installmentsPaymentWay,
                    })(React.createElement(CustomRadio, null, installmentPaymentWays.map((option, index) => (React.createElement(Radio, { key: index, "data-cy": option.dataCy, "data-testid": option.dataTestId, value: option.value },
                        React.createElement("span", { className: styles.radioLabel },
                            " ",
                            option.label))))))),
                    React.createElement(Form.Item, { label: t("conditionsStep.chargesCalculation"), className: styles.midFields }, getFieldDecorator("chargesCalculation", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", {
                                    field: t("conditionsStep.chargesCalculation"),
                                }),
                            },
                        ],
                        initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.chargesCalculation,
                    })(React.createElement(CustomRadio, null, chargesCalculations.map((option, index) => (React.createElement(Radio, { key: index, "data-cy": option.dataCy, "data-testid": option.dataTestId, value: option.value },
                        React.createElement("span", { className: styles.radioLabel },
                            " ",
                            option.label)))))))),
                React.createElement("div", null,
                    React.createElement("span", { className: styles.bankDetailsTitle }, t("conditionsStep.bankDetails")),
                    React.createElement("div", { className: styles.conditionsCard },
                        React.createElement(Form.Item, { label: t("conditionsStep.bankName"), className: styles.midFields }, getFieldDecorator("bankName", {
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("conditionsStep.bankName"),
                                    }),
                                },
                            ],
                            initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.bankName,
                        })(React.createElement(Select, { showSearch: true, "data-cy": "bankName-data-cy", "data-testid": "bankName", optionFilterProp: "children", placeholder: t("conditionsStep.selectBankInstitution"), onChange: (value) => handleSetBankCode(value) }, banks === null || banks === void 0 ? void 0 : banks.map((bank) => (React.createElement(Option, { key: bank.name, value: bank.name },
                            bank.name,
                            " (",
                            bank.code,
                            ")")))))),
                        React.createElement(Form.Item, { style: { display: "none" } }, getFieldDecorator("bankCode", {
                            initialValue: bankCode,
                        })(React.createElement(Input, { "data-testid": "bankCode", type: "hidden" }))),
                        React.createElement(Form.Item, { label: t("conditionsStep.bankAccountType"), className: styles.midFields }, getFieldDecorator("bankAccountType", {
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("conditionsStep.bankAccountType"),
                                    }),
                                },
                            ],
                            initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.bankAccountType,
                        })(React.createElement(Input, { "data-cy": "bankAccountType-data-cy", "data-testid": "bankAccountType", allowClear: true, placeholder: "Conta corrente" }))),
                        React.createElement(Form.Item, { label: t("conditionsStep.bankBranch"), className: styles.midFields }, getFieldDecorator("bankBranch", {
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("conditionsStep.bankBranch"),
                                    }),
                                },
                            ],
                            initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.bankBranch,
                        })(React.createElement(InputNumber, { placeholder: "0000", "data-cy": "bankBranch-data-cy", "data-testid": "bankBranch", maxLength: 5 }))),
                        React.createElement(Form.Item, { label: t("conditionsStep.bankAccount"), className: styles.midFields }, getFieldDecorator("bankAccount", Object.assign({ rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("conditionsStep.bankAccount"),
                                    }),
                                },
                            ], initialValue: conditions === null || conditions === void 0 ? void 0 : conditions.bankAccount }, decoratorConfigDefaultOnBlur))(React.createElement(InputBankAccount, { allowClear: true, "data-cy": "bankAccount", "data-testid": "bankAccount" })))))))));
};
