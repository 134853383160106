import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { useStores } from "../../../utils/mobx";
import { useTranslation } from "react-i18next";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { Spin, Tabs } from "antd";
import CompanyDocumentsFiles from "./CompanyDocumentsFiles";
import { AdminRoutes } from "../../../constants/routes.constants";
const { TabPane } = Tabs;
const CompanyDocument = observer(() => {
    const { t } = useTranslation();
    const { companyStore: { selectedCompany, getCompanyDocuments }, generalStore: { setErrorMessage }, } = useStores();
    const [documentGroupLists, setDocumentGroupLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchDocumentGroupList = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedCompany) {
            return;
        }
        try {
            setLoading(true);
            const documentGroupListFeched = yield getCompanyDocuments(selectedCompany.id);
            setDocumentGroupLists(documentGroupListFeched);
        }
        catch (error) {
            setErrorMessage(error.message);
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        fetchDocumentGroupList();
    }, [selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id]);
    return (selectedCompany && (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("companyDocument.title"), info: React.createElement(React.Fragment, null,
                React.createElement("b", { "data-cy": `companyName-data-cy` }, selectedCompany.name),
                " ",
                React.createElement("span", { "data-cy": `companyCnpj-data-cy` },
                    "CNPJ: ",
                    formatCnpj(selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.cnpj))), hasBackButton: true, linkToBackButton: AdminRoutes.Company.Actions, description: React.createElement("span", { "data-cy": `companyDocumentDescription-data-cy` },
                t("companyDocument.description"),
                ".") }),
        React.createElement(Spin, { spinning: loading, style: { minHeight: "25em" } },
            React.createElement(Tabs, { type: "card" }, documentGroupLists === null || documentGroupLists === void 0 ? void 0 : documentGroupLists.map((documentGroupList) => (React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": `${documentGroupList.type}-data-cy` }, documentGroupList.name), key: documentGroupList.type },
                React.createElement(CompanyDocumentsFiles, { documentsGroups: documentGroupList.documentsGroups })))))))));
});
export default CompanyDocument;
