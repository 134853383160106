import React from "react";
import classNames from "classnames";
import { DocumentIcon } from "../Icons/Document";
import { ClipboardIcon } from "../Icons/Clipboard";
import { Close } from "../Icons/Close";
import styles from "./style.scss";
export const DocumentCard = ({ isChecked, attachment, sequence, title, onRemoveAttachmentClick, onClick, renderRightButton, isSelected, disabled = false, className, }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(isSelected);
        }
    };
    const handleRemoveAttachmentClick = (e) => {
        e.stopPropagation();
        if (onRemoveAttachmentClick && attachment) {
            onRemoveAttachmentClick(attachment.url);
        }
    };
    return (React.createElement("div", { className: classNames([
            styles.cardWrapper,
            isSelected ? styles.selectedCard : "",
            isChecked ? styles.checkedCard : "",
            disabled ? styles.disabledCard : "",
            className,
        ]), onClick: handleClick },
        React.createElement("div", { className: styles.documentIconWrapper },
            React.createElement(DocumentIcon, null)),
        React.createElement("div", { className: styles.cardContent },
            React.createElement("div", null,
                React.createElement("div", { className: disabled ? styles.cardTitleDisabled : styles.cardTitle }, `${sequence} - ${title}`),
                React.createElement("div", { className: styles.attachmentWrapper }, attachment && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.clipboardWrapper },
                        React.createElement(ClipboardIcon, null)),
                    React.createElement("div", null, attachment.name),
                    onRemoveAttachmentClick && (React.createElement("button", { onClick: handleRemoveAttachmentClick, className: classNames([styles.trashWrapper, "document-card-close-button"]) },
                        React.createElement(Close, null))))))),
            renderRightButton && renderRightButton())));
};
