import { __awaiter, __decorate } from "tslib";
import { observable, action } from "mobx";
import { api } from "../AppWrapper";
export class CompanyStore {
    constructor() {
        this.selectedCompany = null;
        this.setSelectedCompany = (company) => {
            this.selectedCompany = company;
            localStorage.setItem("selectedCompany", JSON.stringify(company));
        };
        this.getAllCompanies = (filter, page, rowsPerPage) => api.getCompaniesBasicInfo({ nameToFilter: filter, page, rowsPerPage });
        this.getCompaniesTotal = (filter) => api.getCompaniesBasicInfoTotal({ nameToFilter: filter });
        this.getCompanyById = (id) => api.getCompanyInfo({ id });
        this.saveCompany = (company) => __awaiter(this, void 0, void 0, function* () { return api.saveCompany({ companyInfo: company }); });
        this.getHREmployees = (id) => api.getHREmployeesByCompany({ companyId: id });
        this.editHREmployee = (employee) => api.editHREmployee({ employee });
        // TO DO: Remove this line
        this.removeHREmployee = (id) => api.removeHREmployee({ employeeId: id });
        this.createPJSubscriptionLinkByCompanyId = (companyId) => api.createLinkByCompanyId({ companyId });
        this.getCompanyDocuments = (id) => api.getCompanyDocuments({ companyId: id });
        this.getCompanyDocumentFile = (id, fileId) => __awaiter(this, void 0, void 0, function* () { return yield api.getCompanyDocumentFile({ companyId: id, fileId }); });
        const company = localStorage.getItem("selectedCompany")
            ? JSON.parse(localStorage.getItem("selectedCompany"))
            : null;
        this.selectedCompany = company;
    }
}
__decorate([
    observable
], CompanyStore.prototype, "selectedCompany", void 0);
__decorate([
    action
], CompanyStore.prototype, "setSelectedCompany", void 0);
