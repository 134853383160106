import { __awaiter } from "tslib";
import React, { useContext, useEffect } from "react";
import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { useStores } from "../../../utils/mobx";
import { ActionCard } from "../../../../shared/components/ActionCard";
import { ExternalApiForm } from "../../../components/ExternalApi/ExternalApiForm";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import useReactRouter from "use-react-router";
import { AdminRoutes } from "../../../constants/routes.constants";
const CompaniesGroupExternalApi = observer(() => {
    const { match: { params: { id }, }, } = useReactRouter();
    const { t } = useTranslation();
    const { apiSecretStore: { getCompanyApiSecret, loading }, generalStore: { catchErrors }, generalStore, } = useStores();
    const { selectedCompaniesGroup, setCompaniesGroupId, companiesGroupId } = useContext(CompaniesGroupContext);
    const fetchApiSecret = (companyId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield getCompanyApiSecret(companyId);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    useEffect(() => {
        setCompaniesGroupId(id);
    }, [id]);
    useEffect(() => {
        if (selectedCompaniesGroup) {
            fetchApiSecret(selectedCompaniesGroup.id);
        }
    }, [selectedCompaniesGroup]);
    return (selectedCompaniesGroup && (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: `${AdminRoutes.CompaniesGroups.Actions}/${companiesGroupId}`, title: t("externalApi.title"), description: t("externalApi.detailPageDescription") }),
        React.createElement(Spin, { spinning: loading },
            React.createElement(ActionCard, { title: t("externalApi.keyOfCompanyText", { companyName: selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.name }) },
                React.createElement(ExternalApiForm, null))))));
});
export default CompaniesGroupExternalApi;
