import { Spin } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typograph } from "../../../../shared/components/Typograph";
import StepContentCard from "../StepContentCard";
import styles from "./styles.scss";
export const CcbPreviewForm = ({ ccbPreviewUrl, PdfViewer }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    return (React.createElement(StepContentCard, { title: React.createElement(Typograph, { variant: "modalTitle" }, t("ccbPreviewStep.ccbPreviewTitle")), subTitle: React.createElement(Typograph, { variant: "subtitle" }, t("ccbPreviewStep.ccbPreviewSubTitle")) },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: styles.mainContainer, "data-testid": "ccbPreviewPdf-wrapper" },
                React.createElement(PdfViewer, { setLoading: setLoading, fileUrl: ccbPreviewUrl })))));
};
