export const getChargeReportColumns = (t) => [
    {
        title: t("personOrCompanyLabel"),
        dataIndex: "name",
        key: "name",
        width: "250px",
    },
    {
        title: t("cpfCnpjLabel"),
        dataIndex: "cpfCnpj",
        key: "cpfCnpj",
        width: "200px",
    },
    {
        title: t("charge.typeLabel"),
        dataIndex: "type",
        key: "type",
        width: "200px",
    },
    {
        title: t("charge.assigneCompanyFromCession"),
        dataIndex: "assigneCompanyFromCession",
        key: "assigneCompanyFromCession",
        width: "150px",
    },
    {
        title: t("charge.valueLabel"),
        dataIndex: "value",
        key: "value",
        align: "right",
        width: "150px",
    },
    {
        title: t("charge.dueDateLabel"),
        dataIndex: "dueDate",
        key: "dueDate",
        align: "right",
        width: "150px",
    },
    {
        title: t("statusLabel"),
        dataIndex: "status",
        key: "status",
        align: "center",
        width: "200px",
    },
];
export const getChargeReportColumnsCcbs = (t) => [
    {
        title: t("ccbNumberLabel"),
        dataIndex: "number",
        key: "number",
        className: "ccbNumberChargeReport",
    },
    {
        title: t("chargeReport.draweeName"),
        dataIndex: "draweeName",
        key: "draweeName",
        className: "draweeNameChargeReport",
    },
    {
        title: t("chargeReport.draweeCpf"),
        dataIndex: "draweeCpf",
        key: "draweeCpf",
        className: "draweeCpfChargeReport",
        width: 200,
    },
    {
        title: t("chargeReport.sector"),
        dataIndex: "draweeSector",
        key: "draweeSector",
        className: "draweeSectorChargeReport",
    },
    {
        title: t("chargeReport.ccbValue"),
        dataIndex: "ccbValue",
        key: "ccbValue",
        className: "ccbValueChargeReport",
    },
    {
        title: t("chargeReport.numberOfInstallments"),
        dataIndex: "numberOfInstallments",
        key: "numberOfInstallments",
        className: "numberOfInstallmentsChargeReport",
    },
    {
        title: t("chargeReport.installmentValue"),
        dataIndex: "installmentValue",
        key: "installmentValue",
        className: "installmentValueChargeReport",
    },
    {
        title: t("chargeReport.chargeValueForInstallment"),
        dataIndex: "chargeValueForInstallment",
        key: "chargeValueForInstallment",
        className: "chargeValueForInstallmentChargeReport",
    },
];
