import { __awaiter } from "tslib";
import { brasilAPI } from "./brasilAPI";
import { removeSpecialCharacters } from "../utils/formatters";
const CNPJ_API_VERSION = "v1";
export function getCompanyInfoByCnpj(cnpj) {
    return __awaiter(this, void 0, void 0, function* () {
        const cnpjFormated = removeSpecialCharacters(cnpj);
        try {
            const { data: companyInfo } = yield brasilAPI.get(`/cnpj/${CNPJ_API_VERSION}/${cnpjFormated}`);
            return {
                socialName: companyInfo.razao_social,
                fantasyName: companyInfo.nome_fantasia,
                cep: companyInfo.cep,
            };
        }
        catch (error) {
            const e = error;
            throw new Error(e.message);
        }
    });
}
