import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, InputNumber } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import InputCurrency from "../../../../../shared/components/InputCurrency";
import { useStores } from "../../../../utils/mobx";
import { toInt } from "../../../../../shared/utils/formatters";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const EmployeeForm = observer(({ form, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { getFieldDecorator } = form;
    const employeesDataFormKey = "employees";
    const financialDataFormKey = "financialData";
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.employeesTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: t("companyForm.totalValuePayrollLabel") }, getFieldDecorator(`${employeesDataFormKey}.totalValuePayroll`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.totalValuePayroll }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputCurrency, { min: 0, onFocus: () => setSelectedItem(`${employeesDataFormKey}.totalValuePayroll`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.payrollDayLabel") }, getFieldDecorator(`${employeesDataFormKey}.payrollCloseDate`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.payrollCloseDate }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 1, max: 28, placeholder: "28", onFocus: () => setSelectedItem(`${employeesDataFormKey}.payrollCloseDate`) })))),
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.paydayLabel") }, getFieldDecorator(`${employeesDataFormKey}.paymentDay`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.paymentDay }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 1, max: 31, placeholder: "05", onFocus: () => setSelectedItem(`${employeesDataFormKey}.paymentDay`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { "data-cy": "employeesTotal-value-input", label: t("companyForm.employeeNumberLabel") }, getFieldDecorator(`${financialDataFormKey}.employeesTotal`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.employeesTotal }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 1, placeholder: "82", onFocus: () => setSelectedItem(`${financialDataFormKey}.employeesTotal`) })))),
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.averageSalaryLabel") }, getFieldDecorator(`${employeesDataFormKey}.averageSalary`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.averageSalary }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputCurrency, { min: 0, onFocus: () => setSelectedItem(`${employeesDataFormKey}.averageSalary`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("companyForm.averagePermanenceTimeLabel"), help: t("companyForm.inMonthLabel") }, getFieldDecorator(`${employeesDataFormKey}.averagePermanenceTime`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.averagePermanenceTime }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 1, placeholder: "13", onFocus: () => setSelectedItem(`${employeesDataFormKey}.averagePermanenceTime`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.totalFiredEmployeesLabel"), help: t("companyForm.lastYearMonthLabel") }, getFieldDecorator(`${employeesDataFormKey}.totalFiredEmployees`, Object.assign(Object.assign({ initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.totalFiredEmployees }, decoratorConfigDefaultOnBlur), { normalize: (value) => toInt(value) }))(React.createElement(InputNumber, { min: 0, max: form.getFieldValue("totalEmployees"), placeholder: "7", onFocus: () => setSelectedItem(`${employeesDataFormKey}.totalFiredEmployees`) })))))));
});
export default EmployeeForm;
