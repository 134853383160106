import i18n from "../i18n";
export const formatTedStatus = (status) => {
    switch (status) {
        case "processing":
            return i18n.t("contracts.tedStatus.processing");
        case "registerFailed":
            return i18n.t("contracts.tedStatus.registerFailed");
        case "registered":
            return i18n.t("contracts.tedStatus.registered");
        default:
            return i18n.t("defaultNullFieldPlaceholder");
    }
};
export const formatBirthdayWithdrawalStatus = (status) => {
    switch (status) {
        case "active":
        case "deniedCaixa":
        case "failedCaixa":
        case "processingCaixa":
        case "processingCredifit":
        case "canceledCredifit":
        case "pendingIdentityValidations":
            return i18n.t(`birthdayWithdrawalContracts.${status}Label`);
        default:
            return i18n.t("defaultNullFieldPlaceholder");
    }
};
export const formatPrecatoryAnticipationStatus = (status) => {
    switch (status) {
        case "active":
            return i18n.t("precatoryAnticipation.contractStatus.active");
        case "pendingIdentityValidations":
            return i18n.t("precatoryAnticipation.contractStatus.pendingIdentityValidations");
        case "failed":
            return i18n.t("precatoryAnticipation.contractStatus.failed");
        default:
            return i18n.t("defaultNullFieldPlaceholder");
    }
};
