import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { TableWithFilter } from "../../../../shared/components/TableWithFilter";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { filterActivatedItemsByName, filterItemsByName } from "../../../../shared/utils/colection";
import { isEmpty } from "lodash";
import styles from "./style.scss";
const mapCompaniesToTableDataSource = (companiesGroups) => companiesGroups === null || companiesGroups === void 0 ? void 0 : companiesGroups.map((companiesGroup) => (Object.assign(Object.assign({}, companiesGroup), { key: companiesGroup.id })));
export const CompaniesGroupTable = observer(({}) => {
    const { t } = useTranslation();
    const [showAllCompaniesGroups, setShowAllCompaniesGroups] = useState(false);
    const { accessProfileStore, accessProfileStore: { addCompaniesGroup, allCompaniesGroups, isFilteringUsers, companyNameFilter, removeCompaniesGroup, }, } = useStores();
    const columns = [
        { title: t("companiesGroups.title"), dataIndex: "name", key: "name", width: "30%" },
        {
            title: t("companiesLabel"),
            dataIndex: "companies",
            key: "companies",
            render: (companies) => {
                var _a, _b;
                return (React.createElement("span", null,
                    isEmpty(companies) ? "--" : `${(_a = companies[0]) === null || _a === void 0 ? void 0 : _a.name} (${formatCnpj((_b = companies[0]) === null || _b === void 0 ? void 0 : _b.cnpj)})`,
                    companies.length > 1 && (React.createElement("span", { className: styles.companyBadge },
                        "+",
                        companies.length - 1))));
            },
        },
    ];
    const selectedRowKeys = useMemo(() => allCompaniesGroups === null || allCompaniesGroups === void 0 ? void 0 : allCompaniesGroups.filter(({ activated }) => activated).map(({ id }) => id), [allCompaniesGroups]);
    const usersTableSource = useMemo(() => {
        let companiesFiltered;
        if (showAllCompaniesGroups) {
            companiesFiltered = filterItemsByName(allCompaniesGroups, companyNameFilter);
        }
        else {
            companiesFiltered = filterActivatedItemsByName(allCompaniesGroups, companyNameFilter);
        }
        const companiesFilteredMapedToDataSource = mapCompaniesToTableDataSource(companiesFiltered);
        return companiesFilteredMapedToDataSource;
    }, [showAllCompaniesGroups, allCompaniesGroups, selectedRowKeys, companyNameFilter]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableWithFilter, { columns: columns, dataSource: usersTableSource, selectedRowKeys: selectedRowKeys, onFilterChange: (values) => {
                accessProfileStore.setCompanyNameFilter(values.name);
            }, table: {
                headerProps: {
                    action: t("associate"),
                    hideToogleButton: isFilteringUsers,
                    selectedItems: Number(selectedRowKeys === null || selectedRowKeys === void 0 ? void 0 : selectedRowKeys.length),
                    totalOfItems: Number(allCompaniesGroups === null || allCompaniesGroups === void 0 ? void 0 : allCompaniesGroups.length),
                    show: showAllCompaniesGroups,
                    onToogleClick: () => setShowAllCompaniesGroups((oldValue) => !oldValue),
                },
                rowSelection: {
                    onSelect: (company, selected) => selected ? addCompaniesGroup(company) : removeCompaniesGroup(company),
                },
            } })));
});
