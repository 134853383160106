exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container___20B1a > div {\n  display: flex;\n  flex-direction: column; }\n  .container___20B1a > div div[class$=\"ant-table-scroll\"] {\n    max-width: 95vw;\n    overflow-x: scroll; }\n\n.pending___1My4A {\n  color: #ff7600; }\n\n.container___20B1a {\n  max-height: 851px;\n  min-height: 851px;\n  overflow-y: scroll; }\n\n.toolTip___3lOGv {\n  word-wrap: break-word;\n  display: flex; }\n\n.cessionsTable___2U37e div[class$=\"ant-table-selection\"] {\n  visibility: visible; }\n", ""]);

// Exports
exports.locals = {
	"container": "container___20B1a",
	"pending": "pending___1My4A",
	"toolTip": "toolTip___3lOGv",
	"cessionsTable": "cessionsTable___2U37e"
};