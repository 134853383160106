import * as stores from "./";
import { createContext } from "react";
import { syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
export class RootStore {
    constructor() {
        this.routerStore = new stores.RouterStore();
        this.history = syncHistoryWithStore(createBrowserHistory(), this.routerStore);
        this.authStore = new stores.AuthStore(this);
        this.generalStore = new stores.GeneralStore(this);
        this.loanApplicationStore = new stores.LoanApplicationStore();
        this.contractStore = new stores.ContractStore();
        this.companyStore = new stores.CompanyStore();
        this.tableOfContentStore = new stores.TableOfContentStore();
        this.agreementStore = new stores.AgreementStore();
        this.chargeReportStore = new stores.ChargeReportStore();
        this.cessionReportStore = new stores.CessionReportStore();
        this.contributorsStore = new stores.ContributorsStore();
        this.accessProfileStore = new stores.AccessProfileStore();
        this.adminStore = new stores.AdminStore();
        this.legalResponsibleStore = new stores.LegalResponsibleStore();
        this.apiSecretStore = new stores.ApiSecretStore();
        this.companiesGroupStore = new stores.CompaniesGroupStore();
        this.fgtsParametersStore = new stores.FgtsParametersStore();
        this.birthdayWithdrawalContractStore = new stores.BirthdayWithdrawalContractStore();
        this.userReportStores = new stores.UserReportStore();
        this.installmentsStore = new stores.InstallmentsStore();
        this.ccbPjStore = new stores.CcbPjStore();
        this.cnabStore = new stores.CnabStore();
        this.paymentSlipReportsStore = new stores.PaymentSlipReportsStore();
        this.precatoryAnticipationContractStore = new stores.PrecatoryAnticipationContractStore();
        this.escrowStore = new stores.EscrowStore();
    }
}
export const rootStore = new RootStore();
export default createContext(rootStore);
