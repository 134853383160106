import { __awaiter } from "tslib";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import HrEmployeesShortTable from "../../../components/HrEmployee/HrEmployeeShortTable";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { getCompaniesIds } from "../../../utils/company.utils";
import { AdminRoutes } from "../../../constants/routes.constants";
const CompanyEmployeesList = observer(() => {
    const { t } = useTranslation();
    const { companyStore: { selectedCompany }, companyStore, generalStore, generalStore: { catchErrors }, routerStore, } = useStores();
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    React.useEffect(() => {
        if (selectedCompany) {
            getAllCompanyEmployees(selectedCompany.id);
        }
        else {
            routerStore.push(AdminRoutes.Company.Report);
        }
    }, []);
    const getAllCompanyEmployees = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const employeesFetched = yield companyStore.getHREmployees(id);
            setEmployees(employeesFetched);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("companyEmployee.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const handleRemove = (employee) => __awaiter(void 0, void 0, void 0, function* () {
        const companiesIds = getCompaniesIds(employee === null || employee === void 0 ? void 0 : employee.companies);
        yield companyStore.editHREmployee(Object.assign(Object.assign({}, employee), { companiesIds: (companiesIds === null || companiesIds === void 0 ? void 0 : companiesIds.filter((id) => (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id) !== id)) || [], companiesGroups: [] }));
    });
    return (selectedCompany && (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("companyEmployee.title"), hasBackButton: true, linkToBackButton: AdminRoutes.Company.Actions, description: ` ${t("companyEmployee.description")} ${selectedCompany.name}.` }),
        React.createElement(HrEmployeesShortTable, { initialEmployees: employees, initialLoading: loading, onRemove: handleRemove, removeModalProps: {
                removeConfirmationMessage: (React.createElement(React.Fragment, null,
                    t("companyEmployee.nextRemovalConfirmationMessage"),
                    " ",
                    React.createElement("strong", null, selectedCompany ? selectedCompany.name : ""))),
            }, sectionProps: {
                noEmployeeMessage: (React.createElement(React.Fragment, null,
                    t("companyEmployee.noEmployeeMessage"),
                    " ",
                    selectedCompany.name,
                    ".")),
            } }))));
});
export default CompanyEmployeesList;
