import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { Alert, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { defaultConfirmProps } from "../../../shared/utils";
import ContractModalHeader from "../Contracts/ContractModalHeader";
import { PaymentsTable } from "../PaymentsTable";
import { InstallmentsTable } from "../InstallmentsTable";
import { useStores } from "../../../admin/utils/mobx";
import styles from "./style.scss";
const confirm = Modal.confirm;
export const InstallmentsModal = ({ onClose, contract }) => {
    const { t } = useTranslation();
    const { generalStore: { catchErrors }, installmentsStore, } = useStores();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [installmentsWithPayments, setInstallmetnsWithPayments] = useState([]);
    useEffect(() => {
        if (contract) {
            fetchContractInstallments(contract.id);
        }
        else {
            setInstallmetnsWithPayments([]);
        }
    }, [contract]);
    const fetchContractInstallments = (loanId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const installments = yield installmentsStore.getInstallmentsWithPayments(loanId);
            setInstallmetnsWithPayments(installments);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("payments.defaultFetchErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const setErrorMessage = (message) => {
        setMessage({
            type: "error",
            message,
        });
    };
    const setSuccessMessage = (message) => {
        setMessage({
            type: "success",
            message,
        });
    };
    const handleRevertPayment = (payment) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: t("payments.confirmReversion"), content: (React.createElement(React.Fragment, null,
                React.createElement("p", { style: { marginTop: 10 } }, t("payments.confirmReversionText")))), okText: t("revertLabel"), onOk: () => {
                revertPayment(payment.id);
            } }));
    };
    const revertPayment = (paymentId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield installmentsStore.revertInstallmentPayment(paymentId);
            fetchContractInstallments(contract.id);
            setSuccessMessage(t("payments.revertSuccessMessage"));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("payments.defaultRevertErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(Modal, { className: styles.modal, title: React.createElement(ContractModalHeader, { identifier: (contract === null || contract === void 0 ? void 0 : contract.ccbNumber) || "", title: (contract === null || contract === void 0 ? void 0 : contract.userName) || "", subtitle: (contract === null || contract === void 0 ? void 0 : contract.companyName) || "" }), visible: Boolean(contract), okText: t("confirmLabel"), cancelText: t("closeText"), maskClosable: true, destroyOnClose: true, okButtonProps: { style: { display: "none" } }, width: 1000, closable: false, onCancel: onClose },
        React.createElement(Spin, { spinning: loading, className: styles.contentWrapper },
            React.createElement("div", { className: styles.title }, t("payments.installmentsList")),
            React.createElement(InstallmentsTable, { installments: installmentsWithPayments, expandedRowRender: (installment) => (React.createElement(PaymentsTable, { payments: installment.payments, handleRevertPayment: handleRevertPayment })) }),
            message && (React.createElement(Alert, { className: styles.errorAlert, type: message.type, closable: true, showIcon: true, message: message.message, onClose: () => setMessage(null) })))));
};
