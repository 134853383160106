import { List } from "antd";
import React from "react";
import styles from "./style.scss";
const FloatingList = ({ contextMenuData, items }) => {
    return (React.createElement(List, { className: styles.contextMenu, style: {
            display: contextMenuData.visible ? "inline-block" : "none",
            position: "absolute",
            top: `${contextMenuData.y}px`,
            left: `${contextMenuData.x}px`,
        } }, items.map((item) => (React.createElement(List.Item, { onClick: item.onClick, key: item.title },
        React.createElement(List.Item.Meta, { title: item.title, avatar: item.avatar }))))));
};
export default FloatingList;
