import { __awaiter } from "tslib";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CcbPjStep } from "../../Steps/steps";
import StepContent from "../../../../../components/Simulator/StepContent";
import RequestBottomActions from "../../../../../components/Simulator/RequestBottomActions";
import { ConditionsForm } from "../../../../../../admin/components/Simulator/ConditionsForm";
import { hasErrors } from "../../../../../../shared/utils/form.utils";
import { observer } from "mobx-react-lite";
import { getAllBanks } from "../../../../../../shared/services/banks";
import { SimulatorContext } from "../../../../../../admin/contexts/SimulatorContext";
const fieldsToValidate = [
    "releaseWay",
    "installmentsPaymentWay",
    "chargesCalculation",
    "bankName",
    "bankAccountType",
    "bankBranch",
    "bankAccount",
    "bankCode",
];
export const Conditions = observer(({ currentStep, navigateToNextStep, navigateToPreviousStep, form }) => {
    const { validateFieldsAndScroll, getFieldsValue } = form;
    const { setConditionsData, conditions, contractDataValues } = useContext(SimulatorContext);
    const [banks, setBanks] = useState([]);
    const getBanks = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const fetchedBanks = yield getAllBanks();
            setBanks(fetchedBanks);
        }
        catch (error) {
            console.log(error);
        }
    }), []);
    useEffect(() => {
        getBanks();
    }, []);
    const handleNextStep = () => {
        validateFieldsAndScroll(fieldsToValidate, (errors) => {
            if (hasErrors(errors)) {
                return;
            }
            const conditionsData = getFieldsValue(fieldsToValidate);
            setConditionsData({
                conditionsData,
                simulationId: contractDataValues.simulationId,
            });
            navigateToNextStep();
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContent, { hided: currentStep === CcbPjStep.CONDITIONS, dataCy: "conditions-data-cy" },
            React.createElement(ConditionsForm, { form: form, conditions: conditions, banks: banks }),
            React.createElement(RequestBottomActions, { onBackClick: navigateToPreviousStep, onNextClick: handleNextStep }))));
});
