import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { TableWithFilter } from "../../../../shared/components/TableWithFilter";
import { filterActivatedItemsByTitle, filterItemsByTitle, } from "../../../../shared/utils/colection";
const mapPermissionsToTableDataSource = (permissions) => permissions === null || permissions === void 0 ? void 0 : permissions.map((permissionRole) => (Object.assign(Object.assign({}, permissionRole), { description: permissionRole.description, key: permissionRole.id })));
const PermissionsTable = observer(({}) => {
    const { t } = useTranslation();
    const [showAllPermissions, setShowAllPermissions] = useState(false);
    const { accessProfileStore, accessProfileStore: { addProfileRole, allProfilesRoles, isFilteringPermission, permissionNameFilter, removeProfileRole, }, } = useStores();
    const columns = [
        { title: t("nameLabel"), dataIndex: "title", key: "title", width: "30%" },
        { title: t("descriptionLabel"), dataIndex: "description", key: "description" },
    ];
    const selectedRowKeys = useMemo(() => allProfilesRoles === null || allProfilesRoles === void 0 ? void 0 : allProfilesRoles.filter(({ activated }) => activated).map(({ id }) => id), [allProfilesRoles]);
    const permissionsTableSource = useMemo(() => {
        let permissionsFiltered;
        if (showAllPermissions) {
            permissionsFiltered = filterItemsByTitle(allProfilesRoles, permissionNameFilter);
        }
        else {
            permissionsFiltered = filterActivatedItemsByTitle(allProfilesRoles, permissionNameFilter);
        }
        const permissionsFilteredMapedToDataSource = mapPermissionsToTableDataSource(permissionsFiltered);
        return permissionsFilteredMapedToDataSource;
    }, [showAllPermissions, allProfilesRoles, permissionNameFilter]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableWithFilter, { columns: columns, dataSource: permissionsTableSource, selectedRowKeys: selectedRowKeys, onFilterChange: (values) => {
                accessProfileStore.setPermissionNameFilter(values.name);
            }, table: {
                headerProps: {
                    hideToogleButton: isFilteringPermission,
                    selectedItems: Number(selectedRowKeys === null || selectedRowKeys === void 0 ? void 0 : selectedRowKeys.length),
                    totalOfItems: Number(allProfilesRoles === null || allProfilesRoles === void 0 ? void 0 : allProfilesRoles.length),
                    show: showAllPermissions,
                    onToogleClick: () => setShowAllPermissions((oldValue) => !oldValue),
                    action: "Permitir",
                },
                rowSelection: {
                    onSelect: (profileRole, selected) => selected ? addProfileRole(profileRole) : removeProfileRole(profileRole),
                },
            } })));
});
export default PermissionsTable;
