import { __awaiter } from "tslib";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import ActionTable from "../../../shared/components/ActionTable";
import { useStores } from "../../utils/mobx";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { FileText } from "../../../shared/components/Icons/FileText";
import { Pagination } from "../../../shared/components/Pagination";
import { defaultContextMenuData, formatDateFilter } from "../Contracts";
import PrecatoryAnticipationContractsFilterForm from "../../components/precatoryAnticipation/BirthdayWithdrawalContractsFilterForm/index";
import { columns } from "./column";
import { setContextMenuDataHandler } from "../../utils/table.utils";
import FloatingList from "../../components/FloatingList";
import { Icon, Modal, Tabs } from "antd";
import { defaultConfirmProps } from "../../../shared/utils";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { formatCpf, formatStringCurrencyNoPrefix, } from "../../../shared/utils/formatters";
import { getDateLocalTimezoneFormat, getYearFromDateString } from "../../../shared/utils/date";
import { ContractDataTabCard } from "../../components/Contracts/ContractDataTabCard";
import ContractModalHeader from "../../components/Contracts/ContractModalHeader";
import { downloadFileFromBuffer } from "../../../shared/utils/file";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import WithdrawalsAntecipatedTable from "../../components/Fgts/WithdrawalsAntecipatedTable";
import styles from "./style.scss";
const { TabPane } = Tabs;
const confirm = Modal.confirm;
const defaultFilter = {
    ccbNumber: null,
    cpf: null,
    date: {
        from: null,
        to: null,
    },
    name: null,
    status: null,
};
const formatInstallmentAnticpatedYear = (installmentsAnticipated) => {
    const lastInstallmentIndex = installmentsAnticipated.length - 1;
    const { 0: firstInstallment, [lastInstallmentIndex]: lastInstallment } = installmentsAnticipated;
    return `${getYearFromDateString(firstInstallment.date)} - ${getYearFromDateString(lastInstallment.date)}`;
};
const PrecatoryAnticipationContracts = () => {
    const { t } = useTranslation();
    const [contracts, setContracts] = useState([]);
    const [totalContracts, setTotalContracts] = useState(0);
    const [loadingContracts, setLoadingContracts] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState(defaultFilter);
    const [contextMenuData, setContextMenuData] = useState(defaultContextMenuData);
    const [selectedContractsKeys, setSelectedContractsKeys] = useState([]);
    const { generalStore: { setErrorMessage, catchErrors }, precatoryAnticipationContractStore, } = useStores();
    const fetchContracts = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingContracts(true);
        try {
            const responseTotal = yield precatoryAnticipationContractStore.getContractsTotal(filter);
            setTotalContracts(responseTotal);
            const response = yield precatoryAnticipationContractStore.getContracts(filter, currentPage, rowsPerPage);
            setContracts(response.contracts);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contracts.contractsApiErrorMessage"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    useEffect(() => {
        fetchContracts();
    }, [filter, currentPage, rowsPerPage]);
    const applyFilter = (values) => {
        setCurrentPage(1);
        setFilter(Object.assign(Object.assign({}, values), { date: formatDateFilter(values) }));
    };
    const hasContracts = contracts.length > 0;
    const exportContractsReport = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const buffer = yield precatoryAnticipationContractStore.exportContracts(filter);
            downloadFileFromBuffer(buffer, t("contracts.fileName"));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("exportApiErrorMessage"));
        }
    });
    const showPrecatoryAnticipationContractDetails = (contract) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: React.createElement(ContractModalHeader, { identifier: contract.ccbNumber, title: contract.userName }), content: (React.createElement("div", { className: styles.modal },
                    React.createElement("div", { className: styles.modalBodyDialog },
                        React.createElement(ListColumnInfo, { labels: [
                                i18n.t("cpfLabel"),
                                i18n.t("precatoryAnticipation.antecipatedPeriod"),
                                `${i18n.t("loanApplication.totalAmount")} (R$)`,
                                i18n.t("contractsLabels.antecipatedPeriod"),
                                i18n.t("contractsLabels.requestIdentifier"),
                            ], values: [
                                formatCpf(contract.cpf),
                                contract.anticipatedInstallmentsNumber,
                                formatStringCurrencyNoPrefix(contract.ccbValue),
                                formatInstallmentAnticpatedYear(contract.installmentsAnticipated),
                                contract.id,
                            ] }),
                        React.createElement("div", { className: styles.separation }),
                        React.createElement(ListColumnInfo, { labels: [
                                i18n.t("contractsLabels.emitDate"),
                                i18n.t("contractsLabels.totalLendValue"),
                                i18n.t("contractsLabels.liquidValue"),
                                i18n.t("contractsLabels.blockedValue"),
                                i18n.t("contractsLabels.firstInstallmentDate"),
                                i18n.t("contractsLabels.lastInstallmentDate"),
                                i18n.t("contractsLabels.effectiveMonthlyInterestRate"),
                                i18n.t("contractsLabels.effectiveMonthlyCost"),
                            ], values: [
                                getDateLocalTimezoneFormat(contract.emittedDate),
                                formatStringCurrencyNoPrefix(contract.ccbValue),
                                formatStringCurrencyNoPrefix(contract.transferAmount),
                                formatStringCurrencyNoPrefix(contract.blockedTotalValue),
                                getDateLocalTimezoneFormat(contract.firstAnticipationPeriodDate),
                                getDateLocalTimezoneFormat(contract.lastAnticipationPeriodDate),
                                formatStringCurrencyNoPrefix(contract.interestRatePerMonth),
                                contract.totalEffectiveCostPerMonth,
                            ] }),
                        React.createElement(ContractDataTabCard, { generalData: contract },
                            React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "portability-tab-button" }, "Parcelas Antecipadas"), key: "withdrawalsAntecipated" },
                                React.createElement(WithdrawalsAntecipatedTable, { withdrawalsAntecipated: contract.installmentsAnticipated })))))), className: styles.containerDialog, width: "50%", maskClosable: true, onOk: () => handleSelectChange([...selectedContractsKeys, contract.id]), okText: i18n.t("selectLabel"), okButtonProps: {
                    icon: "check",
                    type: "primary",
                    disabled: selectedContractsKeys.includes(contract.id),
                }, cancelText: i18n.t("closeText") }));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contracts.contractsApiErrorMessage"));
        }
    });
    const handleSelectChange = (selectedRowKeys) => {
        setSelectedContractsKeys(selectedRowKeys);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("precatoryAnticipation.title"), actions: [
                {
                    label: t("exportSheetLabel"),
                    handleAction: () => exportContractsReport(),
                    icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                },
            ] }),
        React.createElement(PrecatoryAnticipationContractsFilterForm, { applyFilter: applyFilter }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                React.createElement(ActionTable, { locale: {
                        emptyText: t("precatoryAnticipation.emptyPrecatoryAnticipationContractsText"),
                    }, className: styles.table, loading: loadingContracts, rowClassName: () => {
                        if (styles.rowClick) {
                            return styles.rowClick;
                        }
                        return "";
                    }, onRow: (contract) => ({
                        onContextMenu: setContextMenuDataHandler(contextMenuData, setContextMenuData, contract),
                    }), rowSelection: {
                        selectedRowKeys: selectedContractsKeys,
                        onChange: handleSelectChange,
                    }, dataSource: contracts.map((contract) => (Object.assign(Object.assign({}, contract), { key: contract.id }))), columns: columns, scroll: { y: 600, x: 1800 } }))),
        React.createElement(FloatingList, { contextMenuData: contextMenuData, items: [
                {
                    avatar: React.createElement(FileText, { fill: "#ff7600" }),
                    onClick: () => {
                        showPrecatoryAnticipationContractDetails(contextMenuData.currentContract);
                    },
                    title: t("contracts.ccbDetailLabel"),
                },
            ] }),
        React.createElement(SectionFooter, null, hasContracts && (React.createElement(Pagination, { current: currentPage, total: totalContracts, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
};
export default PrecatoryAnticipationContracts;
