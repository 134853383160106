exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.signaturesTable___1kec5 {\n  min-height: auto !important;\n  margin: 0 0 0 0 !important; }\n  .signaturesTable___1kec5 div[class$=\"ant-table-body\"] {\n    min-height: 150px !important; }\n\n.status___oJk_m,\n.linkButton___KLvGV {\n  font-weight: bold;\n  font-size: 12px; }\n\n.linkButton___KLvGV {\n  color: #01929f; }\n  .linkButton___KLvGV:hover {\n    color: #08828d; }\n\n.tooltipContainer___FatjS {\n  max-width: 150px;\n  margin-left: 5px; }\n", ""]);

// Exports
exports.locals = {
	"signaturesTable": "signaturesTable___1kec5",
	"status": "status___oJk_m",
	"linkButton": "linkButton___KLvGV",
	"tooltipContainer": "tooltipContainer___FatjS"
};