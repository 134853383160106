import { __awaiter } from "tslib";
import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "../../styles/antd.less";
import { useStores } from "../../utils/mobx";
import { AgreementList } from "../Agreement/AgreementList";
import AgreementRegister from "../Agreement/AgreementRegister";
import { Authentication } from "../Authentication";
import CessionReport from "../CessionReport";
import ChangePassword from "../Authentication/ChangePassword";
import ChargeReport from "../ChargeReport";
import CompanyEdit from "../Company/CompanyEdit";
import CompanyEmployeesList from "../CompanyEmployees/CompanyEmployeesList";
import CompanyList from "../Company/CompanyList";
import CompanyRegister from "../Company/CompanyRegister";
import CompanyDetail from "../Company/CompanyDetail";
import { ContractsCredifit } from "../Contracts";
import ContributorsList from "../Contributors/ContributorsList";
import i18n from "../../i18n";
import { I18nextProvider } from "react-i18next";
import LoanApplication from "../LoanApplications";
import { Main } from "../Main";
import { AdminNotFound } from "../404";
import { useClearCacheCtx } from "react-clear-cache";
import { ClearCache } from "../../../shared/components/ClearCache";
import AccessProfileList from "../AccessProfile/AccessProfileList";
import AccessProfileDetail from "../AccessProfile/AccessProfileDetail";
import AdminList from "../Admin/AdminList";
import { AdminProvider } from "../../contexts/AdminContext";
import { CompaniesGroupProvider } from "../../contexts/CompaniesGroupContext";
import CompanyLegalResponsible from "../Company/CompanyLegalResponsible";
import CompaniesGroupExternalApi from "../CompaniesGroups/CompaniesGroupExternalApi";
import CompaniesGroupList from "../CompaniesGroups/CompaniesGroupList";
import CompaniesGroupDetail from "../CompaniesGroups/CompaniesGroupDetail";
import CompaniesGroupCompanies from "../CompaniesGroups/CompaniesGroupCompanies";
import CompaniesGroupEmployees from "../CompaniesGroups/CompaniesGroupEmployees";
import FgtsParameters from "../FgtsParameters";
import FgtsContracts from "../FgtsContracts";
import UserReport from "../UserReport";
import CompanyDocument from "../Company/Documents";
import SimulatorReport from "../SimulatorReport";
import { Simulator } from "../Simulator";
import { SimulatorRequest } from "../SimulatorRequest";
import { SimulatorProvider } from "../../contexts/SimulatorContext";
import { AdminRoutes } from "../../constants/routes.constants";
import { Cnab } from "../Cnab";
import { PaymentSlipReports } from "../paymentSlipReports";
import PrecatoryAnticipationContracts from "../PrecatoryAnticipation";
import { Escrow } from "../Escrow";
export const AppComp = () => {
    const { authStore } = useStores();
    const { isLatestVersion } = useClearCacheCtx();
    if (!isLatestVersion) {
        return React.createElement(ClearCache, null);
    }
    if (!authStore.isLoggedIn) {
        return (React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(Authentication, null)));
    }
    const reauthenticate = () => __awaiter(void 0, void 0, void 0, function* () {
        yield authStore.updateUserFromApi();
    });
    useEffect(() => {
        reauthenticate();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(AdminProvider, null,
                React.createElement(CompaniesGroupProvider, null,
                    React.createElement(SimulatorProvider, null,
                        React.createElement(Main, null,
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: AdminRoutes.LoansRequests, component: LoanApplication, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.Report, component: CompanyList, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.Register, component: CompanyRegister, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.Company.Edit}/:id`, component: CompanyEdit, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.Actions, component: CompanyDetail, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.LegalResponsibles, component: CompanyLegalResponsible, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.Documents, component: CompanyDocument, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.Employees, component: CompanyEmployeesList, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.Agreements, component: AgreementList, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Company.AgreementRegister, component: AgreementRegister, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.Company.Agreements}/:id`, component: AgreementRegister }),
                                React.createElement(Route, { path: AdminRoutes.Company.Contracts, render: () => React.createElement(ContractsCredifit, { isPayroll: true }), exact: true }),
                                React.createElement(Route, { path: AdminRoutes.CompaniesGroups.Report, component: CompaniesGroupList, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.CompaniesGroups.Actions}/:id`, component: CompaniesGroupDetail, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.CompaniesGroups.Actions}/:id/empresas`, component: CompaniesGroupCompanies, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.CompaniesGroups.Actions}/:id/api-externa`, component: CompaniesGroupExternalApi, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.CompaniesGroups.Actions}/:id/colaboradores`, component: CompaniesGroupEmployees, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Contributors, render: () => React.createElement(ContributorsList, null), exact: true }),
                                React.createElement(Route, { path: AdminRoutes.FgtsParameters, component: FgtsParameters, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.ChangePassword, component: ChangePassword, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.ChargeReport, component: ChargeReport, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Contracts, exact: true, render: () => React.createElement(ContractsCredifit, { isPayroll: true }) }),
                                React.createElement(Route, { path: AdminRoutes.OtherContracts, render: () => React.createElement(ContractsCredifit, { isPayroll: false }), exact: true }),
                                React.createElement(Route, { path: AdminRoutes.FgtsContracts, exact: true, component: FgtsContracts }),
                                React.createElement(Route, { path: AdminRoutes.PrecatoryAnticipationContracts, exact: true, component: PrecatoryAnticipationContracts }),
                                React.createElement(Route, { path: AdminRoutes.UserReport, exact: true, component: UserReport }),
                                React.createElement(Route, { path: AdminRoutes.Admin.Profiles, component: AccessProfileList, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.Admin.ProfileEdit}/:id`, component: AccessProfileDetail, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.CessionReport, component: CessionReport, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Admin.Users, component: AdminList, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Simulator.Report, component: SimulatorReport, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.Simulator.Simulation}/:simulationId?`, component: Simulator, exact: true }),
                                React.createElement(Route, { path: `${AdminRoutes.Simulator.Request}/:simulationId`, component: SimulatorRequest, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Cnab, component: Cnab, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.paymentSlipReports, component: PaymentSlipReports, exact: true }),
                                React.createElement(Route, { path: AdminRoutes.Escrow, component: Escrow, exact: true }),
                                React.createElement(Route, { component: AdminNotFound })))))))));
};
export const App = withRouter(AppComp);
