import React from "react";
import { LocaleProvider } from "antd";
import { Provider } from "mobx-react";
import { rootStore } from "./stores/RootStore";
import { Router } from "react-router";
import ptBR from "antd/lib/locale-provider/pt_BR";
import "firebase/analytics";
import { ApiClient } from "../api/adminApi";
import { ApiFactory } from "../api/apiFactory";
import { FIREBASE_CONFIG_ADMIN } from "../constants/firebase.constant";
import { initFirebaseAnalytics } from "../shared/utils/firebase";
initFirebaseAnalytics(FIREBASE_CONFIG_ADMIN);
export const api = new ApiFactory().create(ApiClient, "admin");
rootStore.history.listen(() => {
    rootStore.authStore.updateUserFromLocalStorage();
    rootStore.generalStore.clearMessage();
});
export const AppWrapper = ({ children }) => {
    return (React.createElement(Provider, Object.assign({}, rootStore),
        React.createElement(Router, { history: rootStore.history },
            React.createElement(LocaleProvider, { locale: ptBR }, children))));
};
