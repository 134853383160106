import { __decorate } from "tslib";
import { action, computed, observable } from "mobx";
import { api } from "../AppWrapper";
export class AdminStore {
    constructor() {
        this.setAdminToEdit = (admin) => {
            this.adminToEditValue = admin;
        };
        this.getAdminsTotal = (filter) => api.getAdminsTotal({ filter });
        this.getAllAdmins = (filter, page, rowsPerPage) => api.getAllAdmins({ filter, page, rowsPerPage });
        this.createAdmin = (admin) => {
            return api.createAdmin({
                admin,
            });
        };
        this.editAdmin = (admin) => {
            return api.editAdmin({ admin });
        };
        this.removeAdmin = (adminId) => {
            api.removeAdmin({ adminId });
        };
    }
    get adminToEdit() {
        return this.adminToEditValue;
    }
}
__decorate([
    observable
], AdminStore.prototype, "adminToEditValue", void 0);
__decorate([
    computed
], AdminStore.prototype, "adminToEdit", null);
__decorate([
    action
], AdminStore.prototype, "setAdminToEdit", void 0);
__decorate([
    action
], AdminStore.prototype, "getAllAdmins", void 0);
__decorate([
    action
], AdminStore.prototype, "createAdmin", void 0);
__decorate([
    action
], AdminStore.prototype, "editAdmin", void 0);
__decorate([
    action
], AdminStore.prototype, "removeAdmin", void 0);
