import React from "react";
import styles from "./style.scss";
export const List = () => (React.createElement("span", { className: styles.listIcon },
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "39", height: "38", viewBox: "0 0 39 38" },
        React.createElement("g", { id: "list", transform: "translate(0 -0.887)" },
            React.createElement("rect", { id: "Ret\u00E2ngulo_4367", "data-name": "Ret\u00E2ngulo 4367", width: "39", height: "38", transform: "translate(0 0.887)", fill: "none" }),
            React.createElement("line", { id: "Linha_42", "data-name": "Linha 42", x2: "14", transform: "translate(19 19.887)", fill: "none", stroke: "#ed7f46", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
            React.createElement("line", { id: "Linha_43", "data-name": "Linha 43", x2: "14", transform: "translate(19 10.887)", fill: "none", stroke: "#ed7f46", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
            React.createElement("line", { id: "Linha_44", "data-name": "Linha 44", x2: "14", transform: "translate(19 29.887)", fill: "none", stroke: "#ed7f46", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
            React.createElement("path", { id: "Caminho_4602", "data-name": "Caminho 4602", d: "M47.9,48,42.63,52.864,40,50.432", transform: "translate(-33.92 -40.731)", fill: "none", stroke: "#ed7f46", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
            React.createElement("path", { id: "Caminho_4603", "data-name": "Caminho 4603", d: "M47.9,112l-5.275,4.864L40,114.432", transform: "translate(-33.92 -95.002)", fill: "none", stroke: "#ed7f46", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
            React.createElement("path", { id: "Caminho_4604", "data-name": "Caminho 4604", d: "M47.9,176l-5.275,4.864L40,178.432", transform: "translate(-33.92 -149.274)", fill: "none", stroke: "#ed7f46", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" })))));
