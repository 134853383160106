import { __decorate } from "tslib";
import { observable, action } from "mobx";
export class TableOfContentStore {
    constructor() {
        this.selectedItem = "";
        this.setSelectedItem = (id) => {
            this.selectedItem = id;
        };
        this.isSelectedItem = (item) => {
            return item.id === this.selectedItem;
        };
    }
}
__decorate([
    observable
], TableOfContentStore.prototype, "selectedItem", void 0);
__decorate([
    action
], TableOfContentStore.prototype, "setSelectedItem", void 0);
