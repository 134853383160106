import { __awaiter } from "tslib";
import React, { useContext, useState } from "react";
import useReactRouter from "use-react-router";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../admin/utils/mobx";
import { CcbPjStep } from "../../Steps/steps";
import StepContent from "../../../../../components/Simulator/StepContent";
import { CcbPreviewForm } from "../../../../../../admin/components/Simulator/CcbPreviewForm";
import CcbPreviewBottomActions from "../../../../../../admin/components/Simulator/CcpPreviewStepBottomActions";
import { SimulatorContext } from "../../../../../../admin/contexts/SimulatorContext";
import { mapGenerateCcbPjPreviewParams } from "../../../Utils/generateCcb";
import { downloadFileFromBuffer } from "../../../../../../shared/utils/file";
import { AdminRoutes } from "../../../../../constants/routes.constants";
export const CcbPreview = ({ currentStep, navigateToPreviousStep, onEditDataClick, form, PdfViewer, }) => {
    const { match: { params: { simulationId }, }, } = useReactRouter();
    const { ccbPreviewUrl, deleteSimulationInLocalStorage } = useContext(SimulatorContext);
    const { t } = useTranslation();
    const { history, ccbPjStore, generalStore: { catchErrors, setErrorMessage, setSuccessMessage }, } = useStores();
    const [loading, setLoading] = useState(false);
    const handleGenerateCcbClick = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const fields = form.getFieldsValue();
            const ccbPjParams = mapGenerateCcbPjPreviewParams(fields, simulationId);
            const { id: loanPjId, ccbNumber } = yield ccbPjStore.requestLoanPj(ccbPjParams);
            const negotiableCcbFile = yield ccbPjStore.getLoanPjNegotiableCcbFile(loanPjId);
            const nonNegotiableCcbFile = yield ccbPjStore.getLoanPjNonNegotiableCcbFile(loanPjId);
            downloadFileFromBuffer(negotiableCcbFile, `CCB_${ccbNumber} (VIA NEGOCIAVEL).pdf`);
            downloadFileFromBuffer(nonNegotiableCcbFile, `CCB_${ccbNumber} (VIA NAO NEGOCIAVEL).pdf`);
            navigateWhenGenerateCcb();
            setSuccessMessage(t("simulator.generateCcbSuccessMessage"));
            deleteSimulationInLocalStorage(simulationId);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("simulator.generateCcbErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const navigateWhenGenerateCcb = () => {
        history.push(AdminRoutes.Simulator.Report);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { spinning: loading },
            React.createElement(StepContent, { hided: currentStep === CcbPjStep.CCB_PREVIEW, dataCy: "ccb-preview-data-cy" },
                React.createElement(CcbPreviewForm, { PdfViewer: PdfViewer, ccbPreviewUrl: ccbPreviewUrl, form: form }),
                React.createElement(CcbPreviewBottomActions, { onBackClick: navigateToPreviousStep, onEditDataClick: onEditDataClick, onGenerateCcbClick: handleGenerateCcbClick })))));
};
