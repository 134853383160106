exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.firstRow___1P6UC {\n  display: grid;\n  grid-template-columns: 60% auto;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.secondRow___1mSBq {\n  display: grid;\n  grid-template-columns: 150px 300px;\n  width: 100%;\n  grid-column-gap: 10px; }\n  @media (max-width: 960px) {\n    .secondRow___1mSBq {\n      grid-template-columns: auto auto; } }\n\n.footerWrapper___3tBJ2 {\n  display: flex; }\n\n.addButton___snFQi {\n  display: flex;\n  align-items: center; }\n  .addButton___snFQi svg {\n    margin-right: 5px; }\n  .addButton___snFQi:hover {\n    color: #0bc2d2; }\n    .addButton___snFQi:hover path {\n      fill: #0bc2d2; }\n", ""]);

// Exports
exports.locals = {
	"firstRow": "firstRow___1P6UC",
	"secondRow": "secondRow___1mSBq",
	"footerWrapper": "footerWrapper___3tBJ2",
	"addButton": "addButton___snFQi"
};