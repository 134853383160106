import { __decorate } from "tslib";
import { observable } from "mobx";
import i18n from "../i18n";
import { sendFirebaseAnalyticsEvent } from "../../shared/utils/firebase";
export class GeneralStore {
    constructor(rootStore) {
        this.message = "";
        this.messageType = "success";
        this.setErrorMessage = (message) => {
            var _a, _b;
            sendFirebaseAnalyticsEvent({
                message: "Erorr",
                event: {
                    errorMessage: message,
                    url: window.location.href,
                    user: {
                        name: (_a = this.rootStore.authStore.user) === null || _a === void 0 ? void 0 : _a.name,
                        email: (_b = this.rootStore.authStore.user) === null || _b === void 0 ? void 0 : _b.email,
                    },
                },
            });
            this.setMessage(message, "error");
        };
        this.catchErrors = (error, callback, message) => {
            var _a;
            if (error.type === "Fatal" ||
                error.type === "Connection" ||
                error.type === "BadFormattedResponse") {
                callback ? callback(i18n.t("unexpectedErrorMessage")) : this.setErrorMessage(message);
            }
            else if (error.type === "NotLoggedIn") {
                this.rootStore.authStore.localLogout();
            }
            else {
                callback(((_a = error.data) === null || _a === void 0 ? void 0 : _a.code) ? error.message : message);
            }
        };
        this.setSuccessMessage = (message) => {
            this.setMessage(message, "success");
        };
        this.setMessage = (message, type) => {
            this.message = message;
            this.messageType = type;
            this.scrollToTop();
        };
        this.scrollToTop = () => window.scrollTo(0, 0);
        this.clearMessage = () => (this.message = "");
        this.rootStore = rootStore;
    }
}
__decorate([
    observable
], GeneralStore.prototype, "message", void 0);
__decorate([
    observable
], GeneralStore.prototype, "messageType", void 0);
