import { __awaiter, __decorate } from "tslib";
import { action } from "mobx";
import { api } from "../AppWrapper";
export class CompaniesGroupStore {
    constructor() {
        this.fetchCompaniesGroups = (filter, currentPage, rowsPerPage) => __awaiter(this, void 0, void 0, function* () {
            return api.getCompaniesGroups({
                filter,
                page: currentPage,
                rowsPerPage,
            });
        });
        this.fetchCompaniesGroupsTotal = (filter) => __awaiter(this, void 0, void 0, function* () {
            return api.getCompaniesGroupsTotal({
                filter,
            });
        });
        this.createCompaniesGroup = (companiesGroupParams) => __awaiter(this, void 0, void 0, function* () { return yield api.createCompaniesGroup({ companiesGroupParams }); });
        this.fetchCompaniesGroupById = (companiesGroupId) => __awaiter(this, void 0, void 0, function* () {
            return api.getCompaniesGroupById({
                companiesGroupId,
            });
        });
        this.fetchFilterdCompanies = (filter) => __awaiter(this, void 0, void 0, function* () {
            return api.getFilteredCompaniesWithCompaniesGroupRelashionship({
                filter,
            });
        });
        this.associateCompaniesGroupsCompanies = (companiesGroupId, companies) => __awaiter(this, void 0, void 0, function* () {
            return api.associateCompaniesGroupsCompanies({
                associateCompaniesGroupsCompaniesParams: {
                    companiesGroupId,
                    companies,
                },
            });
        });
        this.fetchHrEmployesByCompaniesGroupId = (companiesGroupId) => __awaiter(this, void 0, void 0, function* () {
            return api.getHREmployeesByCompaniesGroup({
                companiesGroupId,
            });
        });
    }
}
__decorate([
    action
], CompaniesGroupStore.prototype, "fetchCompaniesGroups", void 0);
__decorate([
    action
], CompaniesGroupStore.prototype, "fetchCompaniesGroupsTotal", void 0);
__decorate([
    action
], CompaniesGroupStore.prototype, "createCompaniesGroup", void 0);
__decorate([
    action
], CompaniesGroupStore.prototype, "fetchCompaniesGroupById", void 0);
__decorate([
    action
], CompaniesGroupStore.prototype, "fetchFilterdCompanies", void 0);
__decorate([
    action
], CompaniesGroupStore.prototype, "associateCompaniesGroupsCompanies", void 0);
__decorate([
    action
], CompaniesGroupStore.prototype, "fetchHrEmployesByCompaniesGroupId", void 0);
