import React from "react";
import Table from "../../../../shared/components/Table";
import i18n from "../../../i18n";
import { getDateLocalTimezoneFormat } from "../../../../shared/utils/date";
import styles from "./style.scss";
const UserSuspensionTable = ({ userSuspensionHistoryData }) => {
    const { t } = i18n;
    const userSuspensionTableColumns = [
        {
            title: t("suspensionDateLabel"),
            dataIndex: "suspensionDate",
            key: "suspensionDate",
            render: (suspensionDate) => getDateLocalTimezoneFormat(suspensionDate),
        },
        {
            title: t("reasonLabel"),
            dataIndex: "reason",
            key: "reason",
            render: (reason) => React.createElement("div", { className: styles.reason }, reason),
        },
        {
            title: t("reactivationDateLabel"),
            dataIndex: "reactivationDate",
            key: "reactivationDate",
            render: (reactivationDate) => getDateLocalTimezoneFormat(reactivationDate),
        },
    ];
    return (React.createElement(Table, { columns: userSuspensionTableColumns, data: userSuspensionHistoryData, className: styles.table }));
};
export default UserSuspensionTable;
