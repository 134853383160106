import React from "react";
import Table from "../../../../../shared/components/Table";
import styles from "./style.scss";
import { useTranslation } from "react-i18next";
import { getDateLocalTimezoneFormat } from "../../../../../shared/utils/date";
const PortabilityInstallmentsTable = ({ installments }) => {
    const { t } = useTranslation();
    const portabilityInstallmentsColumns = [
        {
            title: t("installmentsLowerLabel"),
            dataIndex: "number",
            key: "number",
        },
        {
            title: t("dueLabel"),
            dataIndex: "dueDate",
            key: "dueDate",
            render: (dueDateIso) => getDateLocalTimezoneFormat(dueDateIso),
        },
    ];
    return (React.createElement(Table, { columns: portabilityInstallmentsColumns, data: installments, className: styles.table }));
};
export default PortabilityInstallmentsTable;
