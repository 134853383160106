import { __awaiter } from "tslib";
import { brasilAPI } from "./brasilAPI";
import { removeSpecialCharacters } from "../utils/formatters";
const CEP_API_VERSION = "v1";
export function getAddressByCep(cep) {
    return __awaiter(this, void 0, void 0, function* () {
        const cepFormated = removeSpecialCharacters(cep);
        try {
            const { data: addressInfo } = yield brasilAPI.get(`/cep/${CEP_API_VERSION}/${cepFormated}`);
            return addressInfo;
        }
        catch (error) {
            const e = error;
            throw new Error(e.message);
        }
    });
}
