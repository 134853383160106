import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Button, Tabs, Spin } from "antd";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { useStores } from "../../../utils/mobx";
import PermissionsTable from "../../../components/AccessProfile/PermissionsTable";
import UsersTable from "../../../components/AccessProfile/UsersTable";
import { CompaniesGroupTable } from "../../../components/AccessProfile/CompaniesGroupTable";
import { DeleteProfileModal } from "../../../components/AccessProfile/DeleteProfileModal";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const { TabPane } = Tabs;
const AccessProfileDetail = observer(() => {
    const { match: { params: { id }, }, } = useReactRouter();
    const { accessProfileStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const [isLoading, setisLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { t } = useTranslation();
    const { selectedProfile } = accessProfileStore;
    const isPortalAdminProfile = (selectedProfile === null || selectedProfile === void 0 ? void 0 : selectedProfile.type) === "portal_admin";
    const fetchProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setisLoading(true);
            accessProfileStore.clearFilters();
            yield Promise.all([
                accessProfileStore.getAccessProfileById(id),
                accessProfileStore.fecthAllPermissions(id),
            ]);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setisLoading(false);
        }
    });
    const fetchProfileContent = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isPortalAdminProfile) {
            accessProfileStore.fecthAllUsers(id);
        }
        else {
            accessProfileStore.fecthAllCompaniesGroups(id);
        }
    });
    useEffect(() => {
        fetchProfile();
    }, [id]);
    useEffect(() => {
        fetchProfileContent();
    }, [isPortalAdminProfile]);
    const handleUpdateProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setisLoading(true);
            yield Promise.all([
                accessProfileStore.updateRolesByProfileId(id),
                isPortalAdminProfile
                    ? accessProfileStore.updateAdminsByProfileId(id)
                    : accessProfileStore.updateCompaniesGroupsByProfileId(id),
            ]);
            generalStore.setSuccessMessage("Perfil atualizado com sucesso!");
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setisLoading(false);
        }
    });
    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };
    return (React.createElement("div", null, selectedProfile && (React.createElement(React.Fragment, null,
        React.createElement(DeleteProfileModal, { id: id, onCancel: handleCloseDeleteModal, open: openDeleteModal }),
        React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: AdminRoutes.Admin.Profiles, title: selectedProfile.title, description: selectedProfile.description, actions: [
                {
                    handleAction: handleOpenDeleteModal,
                    label: "Excluir Perfil",
                    dataCy: "delete-button",
                    style: { backgroundColor: "#3A3A3A", borderColor: "#3A3A3A" },
                },
            ] }),
        React.createElement(Spin, { spinning: isLoading },
            React.createElement("div", { className: styles.tabsWrapper },
                React.createElement(Tabs, { type: "card" },
                    React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "permissions-tab-button" }, "Permiss\u00F5es"), key: "permissions" },
                        React.createElement("div", { "data-cy": "permission-table-wrapper" },
                            React.createElement(PermissionsTable, null))),
                    selectedProfile.type === "portal_admin" ? (React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "users-tab-button" }, "Usu\u00E1rios"), key: "users" },
                        React.createElement("div", { "data-cy": "users-table-wrapper" },
                            React.createElement(UsersTable, null)))) : (React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "companies-groups-tab-button" }, "Grupos Empresariais"), key: "companies-groups" },
                        React.createElement("div", { "data-cy": "companies-table-wrapper" },
                            React.createElement(CompaniesGroupTable, null))))))),
        React.createElement("div", { className: styles.actionsWrapper },
            React.createElement(Button, { type: "primary", size: "large", style: { width: 150 }, onClick: handleUpdateProfile, "data-cy": "update-profile-button-data-cy" }, "Salvar"))))));
});
export default AccessProfileDetail;
