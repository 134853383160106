import React from "react";
import { Close } from "../../../shared/components/Icons/Close";
import s from "./style.scss";
const Tag = ({ onRemove, item }) => {
    return (React.createElement("div", { className: s.tagItem, key: item.id },
        React.createElement("p", null,
            item.name,
            React.createElement("small", { className: s.description }, item.description)),
        React.createElement("span", { "data-cy": "remove-company-button", "data-testid": "remove-button", className: s.removeTag, onClick: () => onRemove(item) },
            React.createElement(Close, null))));
};
export default Tag;
