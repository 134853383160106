import React, { useState } from "react";
import { RightArrow } from "../Icons/RightArrow";
import styles from "./style.scss";
export const HideableContainer = ({ children, title, className, buttonProps, hideDefault, onTooglehide, hide, }) => {
    const [hideData, setShowOther] = useState(hideDefault !== null && hideDefault !== void 0 ? hideDefault : true);
    const handleClick = () => setShowOther((oldValue) => {
        if (onTooglehide) {
            onTooglehide(oldValue);
        }
        return !oldValue;
    });
    const isHide = hide !== null && hide !== void 0 ? hide : !hideData;
    return (React.createElement("div", { className: className },
        React.createElement("button", { className: isHide ? styles.button : styles.buttonClicked, onClick: handleClick, "data-cy": "hideable-container-button-data-cy", "data-testid": "hideable-container-button", type: "button" },
            title,
            React.createElement("div", { className: buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.arrowClassName },
                React.createElement(RightArrow, null))),
        React.createElement("div", { "data-testid": "hideable-container-content", style: { display: isHide ? "block" : "none" } }, children)));
};
