import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Input, Row, Col, Spin } from "antd";
import { useStores } from "../../utils/mobx";
import { FormTitle } from "../../../shared/components/FormTitle";
import s from "./style.scss";
import ReactInputMask from "react-input-mask";
import { getAddressByCep } from "../../../shared/services/cep";
import { addressInitialState, CompanyFormContext, } from "../../containers/Company/CompanyForm/context";
import { removeSpecialCharacters } from "../../../shared/utils/formatters";
const AddressForm = observer(({ form, formKey, readOnly, title, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [disabledFields, setDisabledFields] = React.useState(true);
    const { handleChangeAddress, address, setAddress } = React.useContext(CompanyFormContext);
    const [requesting, setRequesting] = React.useState(false);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (initialValues && initialValues.cep) {
            setAddress(initialValues);
            setDisabledFields(readOnly ? true : false);
        }
    }, [initialValues]);
    React.useEffect(() => {
        if (removeSpecialCharacters((address === null || address === void 0 ? void 0 : address.cep) || "").length === 8 && !requesting) {
            getAddressByZipcode(address.cep || "");
        }
    }, [address.cep]);
    const { getFieldDecorator } = form;
    const decorator = formKey || "address";
    getFieldDecorator(decorator, {
        initialValue: initialValues,
    });
    const getInputId = (input) => `${decorator}.${input}`;
    const setFormState = () => {
        form.setFieldsValue({
            [decorator]: Object.assign(Object.assign({}, address), { number: `${address.number}` }),
        });
    };
    const getAddressByZipcode = (zipcode) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setAddress(Object.assign(Object.assign({}, addressInitialState), { cep: address.cep }));
            setError("");
            if (zipcode) {
                setLoading(true);
                setRequesting(true);
                const fetchedAddress = yield getAddressByCep(zipcode);
                setAddress(Object.assign(Object.assign({}, address), fetchedAddress));
                form.setFieldsValue({
                    [decorator]: Object.assign(Object.assign({}, address), fetchedAddress),
                });
            }
        }
        catch (e) {
            const getAddressByZipCodeError = e;
            catchErrors(getAddressByZipCodeError.type === "service_error"
                ? { message: t("cepNotFound") }
                : getAddressByZipCodeError, setError, t("addressCantLoad"));
        }
        finally {
            setDisabledFields(false);
            setLoading(false);
            setRequesting(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        title && React.createElement(FormTitle, { title: title }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 16 },
                React.createElement(Spin, { spinning: loading },
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 6 },
                            React.createElement(Form.Item, { className: s.inputCep, label: "CEP", validateStatus: error ? "error" : "" },
                                React.createElement(ReactInputMask, { mask: "99.999-999", disabled: readOnly ? readOnly : false, value: address.cep || "", defaultValue: address.cep || "", onChange: (e) => {
                                        handleChangeAddress("cep", e.target.value);
                                    }, onFocus: () => setSelectedItem(getInputId("cep")) }, () => (React.createElement(Input, { id: getInputId("cep"), allowClear: !readOnly, disabled: readOnly ? readOnly : false, placeholder: "00.000-000" }))))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { label: t("streetLabel") },
                                React.createElement(Input, { id: getInputId("street"), allowClear: !readOnly, placeholder: t("streetPlaceholderLabel"), disabled: readOnly, value: address.street ? address.street : "", onFocus: () => setSelectedItem(getInputId("street")), onChange: (e) => handleChangeAddress("street", e.target.value), onBlur: setFormState, maxLength: 500 }))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(Form.Item, { label: t("numberLabel") },
                                React.createElement(Input, { id: getInputId("number"), maxLength: 7, placeholder: "1029", disabled: readOnly, value: address.number ? address.number : "", onFocus: () => setSelectedItem(getInputId("number")), onChange: (e) => handleChangeAddress("number", e.target.value), onBlur: setFormState }))),
                        React.createElement(Col, { span: 12, className: s.errorContainer }, error && React.createElement("div", { className: s.error }, error))),
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 10 },
                            React.createElement(Form.Item, { label: t("neighborhoodLabel") },
                                React.createElement(Input, { id: getInputId("neighborhood"), allowClear: !readOnly, placeholder: t("neighborhoodPlaceholderLabel"), disabled: readOnly, onFocus: () => setSelectedItem(getInputId("neighborhood")), value: address.neighborhood ? address.neighborhood : "", onChange: (e) => handleChangeAddress("neighborhood", e.target.value), onBlur: setFormState, maxLength: 255 }))),
                        React.createElement(Col, { span: 11 },
                            React.createElement(Form.Item, { label: t("cityLabel") },
                                React.createElement(Input, { id: getInputId("city"), allowClear: !disabledFields, placeholder: "Salvador", disabled: disabledFields, onFocus: () => setSelectedItem(getInputId("city")), value: address.city ? address.city : "", onChange: (e) => handleChangeAddress("city", e.target.value), onBlur: setFormState, maxLength: 255 }))),
                        React.createElement(Col, { span: 3 },
                            React.createElement(Form.Item, { label: t("stateLabel") },
                                React.createElement(Input, { id: getInputId("state"), allowClear: !disabledFields, placeholder: "BA", disabled: disabledFields, onFocus: () => setSelectedItem(getInputId("state")), value: address.state ? address.state : "", onChange: (e) => handleChangeAddress("state", e.target.value), onBlur: setFormState, maxLength: 50 })))),
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 24 },
                            React.createElement(Form.Item, { label: t("complementLabel") },
                                React.createElement(Input, { id: getInputId("complement"), allowClear: !readOnly, placeholder: t("complementPlaceholderLabel"), disabled: readOnly, onFocus: () => setSelectedItem(getInputId("complement")), value: address.complement ? address.complement : "", onChange: (e) => handleChangeAddress("complement", e.target.value), onBlur: setFormState, maxLength: 500 })))))))));
});
export default AddressForm;
