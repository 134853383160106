exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.bodyWrapper___155Rr {\n  min-height: 60px; }\n  .bodyWrapper___155Rr div[class*=\"ant-form-item-label\"] {\n    font-weight: bold; }\n\n.select___1gngK {\n  width: 100%; }\n\n.firstRow___3M9BX {\n  display: grid;\n  grid-template-columns: 260px auto;\n  grid-column-gap: 40px;\n  max-width: 780px;\n  max-height: 100px; }\n\n.secondRow___3LWy5 {\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-column-gap: 20px;\n  max-width: 780px; }\n\n.buttonsWrapper___3AEqi {\n  display: flex;\n  align-items: center;\n  margin-top: 20px; }\n", ""]);

// Exports
exports.locals = {
	"bodyWrapper": "bodyWrapper___155Rr",
	"select": "select___1gngK",
	"firstRow": "firstRow___3M9BX",
	"secondRow": "secondRow___3LWy5",
	"buttonsWrapper": "buttonsWrapper___3AEqi"
};