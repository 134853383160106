import React from "react";
import { Form } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styles from "./style.scss";
import { goBackTwoDecimalPlaces } from "../../../../../src/shared/utils/numbers";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
const tooltipFormatter = (point) => {
    var _a;
    return point.series.name + ": <b>" + "R$ " + formatCurrency((_a = point.y) !== null && _a !== void 0 ? _a : 0) + "<br/>";
};
function getChartData(balance) {
    var _a, _b, _c;
    return [
        [`Saldo Total`, goBackTwoDecimalPlaces((_a = balance === null || balance === void 0 ? void 0 : balance.total) !== null && _a !== void 0 ? _a : 0)],
        ["Conta Pagamento", goBackTwoDecimalPlaces((_b = balance === null || balance === void 0 ? void 0 : balance.payPaymentSlip) !== null && _b !== void 0 ? _b : 0)],
        ["Conta Emissão", goBackTwoDecimalPlaces((_c = balance === null || balance === void 0 ? void 0 : balance.paymentSlipRecipient) !== null && _c !== void 0 ? _c : 0)],
    ];
}
const highchartsOptions = (balance) => {
    return {
        chart: {
            type: "column",
            events: {
                load() {
                    this.showLoading("Carregando...");
                    setTimeout(this.hideLoading.bind(this), 2000);
                },
            },
        },
        title: {
            text: "Saldo da conta",
            align: "left",
        },
        xAxis: {
            type: "category",
            labels: {
                autoRotation: [-45, -90],
                style: {
                    fontSize: "12px",
                    fontFamily: "Verdana, sans-serif",
                    fontWeight: "600",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "",
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            formatter: function () {
                return tooltipFormatter(this.point);
            },
        },
        series: [
            {
                minPointLength: 1,
                name: "Valor",
                type: "column",
                colors: ["#008B96", "#FF964A", "#2BC773"],
                colorByPoint: true,
                groupPadding: 0,
                data: getChartData(balance),
            },
        ],
        credits: {
            enabled: false,
        },
    };
};
export const AccountsBalanceChart = ({ balance }) => {
    return (React.createElement(Form, { className: styles.form },
        React.createElement(HighchartsReact, { highcharts: Highcharts, options: highchartsOptions(balance) })));
};
