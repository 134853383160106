import { __awaiter } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal, Input, Radio, Row, Col } from "antd";
import s from "./style.scss";
const CedeContractInnerForm = ({ form, onCancel, onOk, selectedContractsAmount, remainingInstallments, visible, okText, }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const handleChange = () => {
        form.resetFields(["interval"]);
    };
    const handleClose = () => {
        onCancel();
    };
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                onOk(values);
                form.resetFields();
            }
        });
    });
    const validateInterval = (_, value, callback) => {
        if (!form.getFieldValue("allInstallments")) {
            try {
                const selectedInterval = extractInstallmentsNumbers(value);
                selectedInterval.every((e) => remainingInstallments.includes(e))
                    ? callback()
                    : callback("Parcelas selecionadas não estão em aberto!");
            }
            catch (error) {
                callback("Número de parcelas inválido");
            }
        }
        callback();
    };
    const extractInstallmentsNumbers = (value) => {
        const givenInstallments = [];
        if (value) {
            const numbers = value.split(",");
            numbers.forEach((n) => {
                const isRange = n.includes("-");
                if (isRange) {
                    const range = n.split("-");
                    const start = Number(range[0]);
                    const end = Number(range[1]);
                    if (isNaN(start) || isNaN(end)) {
                        throw "Número de parcelas inválido";
                    }
                    if (end < start) {
                        throw "Número de parcelas inválido";
                    }
                    for (let i = start; i <= end; i++) {
                        if (!givenInstallments.includes(i)) {
                            givenInstallments.push(i);
                        }
                    }
                }
                else if (n) {
                    const installment = Number(n);
                    if (isNaN(installment)) {
                        throw "Número de parcelas inválido";
                    }
                    if (!givenInstallments.includes(installment)) {
                        givenInstallments.push(installment);
                    }
                }
            });
        }
        else {
            throw "Intervalo não pode ser vazio!";
        }
        return givenInstallments.sort((a, b) => a - b);
    };
    return (React.createElement(Modal, { className: s.modal, visible: visible, title: selectedContractsAmount > 1
            ? t("assignText") + selectedContractsAmount + " " + t("contracts.contractsLabel")
            : t("assignText") + t("contracts.contractLabel"), okText: okText, onCancel: handleClose, onOk: handleSubmit, destroyOnClose: true, maskClosable: true, width: "40%" },
        React.createElement(Form, { layout: "vertical", hideRequiredMark: true },
            React.createElement(Row, { gutter: 20 },
                React.createElement(Form.Item, { label: t("assigneeLabel") }, getFieldDecorator("assigneCompany", {
                    rules: [
                        {
                            required: true,
                            message: t("assingeeRequiredMessage"),
                        },
                    ],
                })(React.createElement(Input, { placeholder: t("companyLabel"), allowClear: true })))),
            React.createElement(Row, { gutter: 40 },
                React.createElement(Col, { span: 6 },
                    React.createElement(Form.Item, { label: t("installmentsAssignedLabel") }, getFieldDecorator("allInstallments", {
                        initialValue: true,
                    })(React.createElement(Radio.Group, { onChange: handleChange },
                        React.createElement(Radio, { value: true }, t("allLabel")),
                        React.createElement(Radio, { value: false, disabled: selectedContractsAmount > 1 }, t("customLabel")))))),
                React.createElement(Col, { span: 14 },
                    React.createElement(Form.Item, { label: t("intervalLabel") }, getFieldDecorator("interval", {
                        rules: [
                            {
                                validator: validateInterval,
                                message: "Número de parcelas inválido",
                            },
                        ],
                    })(React.createElement(Input, { placeholder: t("intervalPlaceholder"), disabled: selectedContractsAmount > 1 || form.getFieldValue("allInstallments") }))))))));
};
export const CedeContractForm = Form.create({ name: "cede_contract_form" })(CedeContractInnerForm);
