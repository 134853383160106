import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./styles.scss";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
const PdfViewer = ({ fileUrl, setLoading }) => {
    const [numPage, setNumPage] = useState(0);
    const onDocumentLoadSuccess = (numPages) => {
        setNumPage(numPages);
        setLoading(false);
    };
    return (React.createElement(Document, { onLoadError: console.error, file: fileUrl, onLoadSuccess: ({ numPages }) => onDocumentLoadSuccess(numPages) }, Array.from(new Array(numPage), (_, index) => (React.createElement(Page, { width: 900, scale: 1, key: `pagina_${index + 1}`, pageNumber: index + 1 })))));
};
export default PdfViewer;
