import React from "react";
import { Form, Spin, DatePicker, Button } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
import { getTodayDateLocalTimezoneFormat } from "../../../../shared/utils/date";
const InnerForm = ({ form, loading, onSubmit }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                onSubmit(values);
            }
        });
    };
    return (React.createElement(Form, { hideRequiredMark: true, className: styles.form },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", null,
                React.createElement(Form.Item, { label: t("cnabRequestDateLabel"), "data-cy": "cnab-input-date" }, getFieldDecorator("targetDate", {
                    rules: [
                        {
                            required: true,
                            message: t("cnabDateRequiredMessage"),
                        },
                    ],
                })(React.createElement(DatePicker, { placeholder: getTodayDateLocalTimezoneFormat(), format: "DD/MM/YYYY" }))))),
        React.createElement(Button, { type: "primary", onClick: handleSubmit }, t("confirmLabel"))));
};
export const CnabForm = Form.create({
    name: "cnab_form",
})(InnerForm);
