import { __awaiter } from "tslib";
import React, { useEffect, useState, useMemo } from "react";
import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { useStores } from "../../../utils/mobx";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { SimpleFieldSearch } from "../../../components/SimpleFieldSearch";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import LegalResponsibleTable from "../../../components/LegalResponsible/LegalResponsibleTable";
import { CompanyInfoModal } from "../../../components/LegalResponsible/CompanyInfoModal";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompanyLegalResponsible = observer(() => {
    const [legalResponsibles, setLegalResponsibles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterName, setFilterName] = useState("");
    const { t } = useTranslation();
    const { companyStore: { selectedCompany }, legalResponsibleStore: { getLegalResponsibles }, routerStore, generalStore: { catchErrors }, generalStore, } = useStores();
    const fetchLegalResponsibles = (companyId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const fetchedLegalResponsibles = yield getLegalResponsibles(companyId);
            setLegalResponsibles(fetchedLegalResponsibles);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        if (selectedCompany === null) {
            routerStore.push(AdminRoutes.Company.Report);
        }
        else {
            fetchLegalResponsibles(selectedCompany.id);
        }
    }, [selectedCompany, routerStore]);
    const handleFilterLegalResponsible = (name) => {
        setFilterName(name);
    };
    const handleRemoveFilter = () => {
        setFilterName("");
    };
    const legalResponsiblesWithFilter = useMemo(() => legalResponsibles.filter(({ name }) => !filterName || name.toLowerCase().includes(filterName.toLowerCase())), [legalResponsibles, filterName]);
    return (selectedCompany && (React.createElement(React.Fragment, null,
        React.createElement(CompanyInfoModal, null),
        React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: AdminRoutes.Company.Actions, title: t("legalResponsibles.title"), info: React.createElement(React.Fragment, null,
                React.createElement("b", null, selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.name),
                " ",
                React.createElement("span", null,
                    "CNPJ: ",
                    formatCnpj(selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.cnpj))), description: t("legalResponsibles.detailPageDescription") }),
        React.createElement(SimpleFieldSearch, { placeholder: t("legalResponsibles.searchPlaceholder"), handleSubmit: handleFilterLegalResponsible, onClear: handleRemoveFilter }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(Spin, { spinning: loading }, isEmpty(legalResponsibles) && !loading ? (React.createElement("h1", { className: styles.infoWrapper }, t("legalResponsibles.nolegalResponsiblesMessage", {
                companyName: selectedCompany.name,
            }))) : (React.createElement(React.Fragment, null,
                React.createElement(LegalResponsibleTable, { legalResponsibles: legalResponsiblesWithFilter }))))))));
});
export default CompanyLegalResponsible;
