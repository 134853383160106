import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { formatCurrencyNullIF, formatToPercentageNullIf, } from "../../../../../shared/utils/formatters";
import { getDateLocalTimezoneFormat } from "../../../../../shared/utils/date";
import { goBackTwoDecimalPlaces } from "../../../../../shared/utils/numbers";
import styles from "./style.scss";
const SimulationMoreInformationTable = ({ priceSimulationPjResult, }) => {
    const { t } = useTranslation();
    const getRowClassName = (_, index) => index % 2 === 0 ? `${styles.even}` : `${styles.odd}`;
    const columns = [
        {
            title: "Descrição",
            dataIndex: "otherInformationsTitle",
            align: "left",
            className: `${styles.otherInformationsTitle}`,
        },
        {
            title: "valor",
            dataIndex: "otherInformationsValue",
            align: "right",
            className: `${styles.otherInformationsValue}`,
        },
    ];
    const getSimulationData = () => {
        return [
            {
                otherInformationsTitle: `${t("simulator.lastInstallmentDueDate")}`,
                otherInformationsValue: getDateLocalTimezoneFormat(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.lastInstallmentDate),
            },
            {
                otherInformationsTitle: `${t("simulator.effectiveInterestRatePerDay")}`,
                otherInformationsValue: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.interestRatePerDay),
            },
            {
                otherInformationsTitle: `${t("simulator.effectiveInterestRatePerYear")}`,
                otherInformationsValue: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.interestRatePerYear),
            },
            {
                otherInformationsTitle: `${t("simulator.iofRatePerDay")}`,
                otherInformationsValue: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.dailyIOF),
            },
            {
                otherInformationsTitle: `${t("simulator.iofAfterYear")}`,
                otherInformationsValue: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.maxIOFAfterYear),
            },
            {
                otherInformationsTitle: `${t("simulator.iofRateFixed")}`,
                otherInformationsValue: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.fixedIOFRate),
            },
            {
                otherInformationsTitle: `${t("simulator.seguroPrestamistaRate")}`,
                otherInformationsValue: formatToPercentageNullIf((priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.additionalSeguroPrestamista)
                    ? priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.seguroPrestamistaRate
                    : null),
            },
            {
                otherInformationsTitle: `${t("simulator.financedIof", {
                    iofRatePerDay: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.dailyIOF),
                })}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.financedIOF)),
            },
            {
                otherInformationsTitle: `${t("simulator.iofFixed", {
                    iofRateFixed: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.fixedIOFRate),
                })}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.fixedIOF)),
            },
            {
                otherInformationsTitle: `${t("simulator.iofTotal")}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.totalIOF)),
            },
            {
                otherInformationsTitle: `${t("simulator.structuringFee")}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.structuringValue)),
            },
            {
                otherInformationsTitle: `${t("simulator.seguroValue")}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.additionalSeguroPrestamista)),
            },
            {
                otherInformationsTitle: `${t("simulator.ccbValue")}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.ccbValue)),
            },
            {
                otherInformationsTitle: `${t("simulator.pmtValue")}`,
                otherInformationsValue: formatCurrencyNullIF(goBackTwoDecimalPlaces(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.regularPmtValue)),
            },
            {
                otherInformationsTitle: `${t("simulator.cetDescription")}`,
                otherInformationsValue: formatToPercentageNullIf(priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.totalEffectiveCostPerMonth),
            },
        ];
    };
    const data = getSimulationData();
    return (React.createElement("div", { "data-cy": "more-information-table_data-cy", className: styles.tableWrapper },
        React.createElement(Table, { pagination: false, showHeader: false, columns: columns, dataSource: data, rowClassName: getRowClassName })));
};
export default SimulationMoreInformationTable;
