import React, { useEffect } from "react";
import i18n from "../../../../i18n";
import InputCurrency from "../../../../../shared/components/InputCurrency";
import styles from "./style.scss";
import TooltipDisplay from "../../../../../shared/components/TooltipDisplay";
const SingleApproval = ({ defaultTransferAmount, onChange }) => {
    useEffect(() => {
        onChange(defaultTransferAmount);
    }, [defaultTransferAmount]);
    return (React.createElement("div", null,
        React.createElement("div", { className: styles.description }, `${i18n.t("loanApplication.confirmationAcceptText")} ${i18n.t("loanApplication.singleRequestText")}?`),
        React.createElement("div", { className: styles.titleWrapper },
            React.createElement("div", null, i18n.t("loanApplication.transferValue")),
            React.createElement(TooltipDisplay, { title: i18n.t("loanApplication.valueToSendToCabine"), placement: "top" })),
        React.createElement(InputCurrency, { key: `input-value-${defaultTransferAmount}`, defaultValue: defaultTransferAmount, "data-cy": "transfer-amount-input", min: 0, onChange: onChange })));
};
export default SingleApproval;
