exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.headerWrapper___129gr {\n  display: flex;\n  width: 100%;\n  padding: 40px 0px 20px;\n  justify-content: space-between; }\n\n.headerFields___1eg13 {\n  width: 70%; }\n\n.valueContainerWrapper___M-xf3 {\n  width: 25%; }\n  .valueContainerWrapper___M-xf3 div[class*=\"valueContainer\"] {\n    margin-bottom: 0px;\n    padding: 8px;\n    width: 100%; }\n  .valueContainerWrapper___M-xf3 div[class*=\"valueTitleContainer\"] {\n    align-items: start;\n    margin-top: 24px; }\n    .valueContainerWrapper___M-xf3 div[class*=\"valueTitleContainer\"] span {\n      font-size: 16px;\n      margin-bottom: 8px; }\n  .valueContainerWrapper___M-xf3 div[class*=\"valueWrapper\"] span {\n    font-size: 32px; }\n\n.container___3Qx6m {\n  display: flex;\n  width: 100%;\n  column-gap: 30px; }\n\n.subtitle___1wBqc {\n  font-weight: 600;\n  font-size: 14px;\n  color: #4D4D50; }\n", ""]);

// Exports
exports.locals = {
	"headerWrapper": "headerWrapper___129gr",
	"headerFields": "headerFields___1eg13",
	"valueContainerWrapper": "valueContainerWrapper___M-xf3",
	"container": "container___3Qx6m",
	"subtitle": "subtitle___1wBqc"
};