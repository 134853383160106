import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdminContext } from "../../../contexts/AdminContext";
import { FilterForm } from "../../../../shared/components/FilterForm";
import s from "./style.scss";
const AdminFilterForm = ({ handleSubmit }) => {
    const { t } = useTranslation();
    const { profiles } = useContext(AdminContext);
    const items = useMemo(() => [
        {
            key: "nome",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "profile",
            name: "profile",
            type: "select",
            placeholder: "Selecionar Perfil",
            props: {
                filterOption: (input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            selectOptions: [
                { value: "", label: "Selecionar Perfil" },
                ...profiles.map(({ id, title }) => ({
                    value: id,
                    label: title,
                })),
            ],
        },
    ], [profiles]);
    return (React.createElement(FilterForm, { className: s.filterForm, items: items, handleSubmit: (values) => {
            if (handleSubmit) {
                handleSubmit(values);
            }
        } }));
};
export default AdminFilterForm;
