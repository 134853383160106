import { __rest } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Input, Col, Row } from "antd";
import { convertUndefinedPropertiesToNull } from "../../../shared/utils/formatters";
import s from "./style.scss";
const InnerFilterForm = (_a) => {
    var { form, placeholder, onClear } = _a, props = __rest(_a, ["form", "placeholder", "onClear"]);
    const { getFieldsValue, getFieldDecorator } = form;
    const handleSubmit = (e) => {
        e.preventDefault();
        const values = convertUndefinedPropertiesToNull(getFieldsValue());
        props.handleSubmit(values.query);
    };
    const { t } = useTranslation();
    return (React.createElement(Form, { className: s.form, onSubmit: handleSubmit },
        React.createElement(Row, { gutter: 0 },
            React.createElement(Col, { span: 22 },
                React.createElement(Form.Item, null, getFieldDecorator("query", {})(React.createElement(Input, { allowClear: true, autoFocus: true, placeholder: placeholder, "data-cy": "search-input-data-cy", onChange: (e) => {
                        if (!e.target.value) {
                            if (onClear) {
                                onClear();
                            }
                        }
                    } })))),
            React.createElement(Col, { span: 2 },
                React.createElement(Button, { className: s.blueButton, htmlType: "submit", style: { width: "100%" }, "data-cy": "search-button-data-cy" }, t("searchLabel"))))));
};
export const SimpleFieldSearch = Form.create({
    name: "simple_search_form",
})(InnerFilterForm);
