import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionLink } from "../../../components/SectionLink";
import { useStores } from "../../../utils/mobx";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import useReactRouter from "use-react-router";
import { Spin } from "antd";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompaniesGroupDetail = observer(() => {
    const { match: { params: { id }, }, } = useReactRouter();
    const { t } = useTranslation();
    const { routerStore } = useStores();
    const { selectedCompaniesGroup, companiesGroupId, setCompaniesGroupId, clearFilters, loading } = useContext(CompaniesGroupContext);
    useEffect(() => {
        clearFilters();
        setCompaniesGroupId(id);
    }, [id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { spinning: loading },
            React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: AdminRoutes.CompaniesGroups.Report, title: String((selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.name) || ""), description: selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.description }),
            React.createElement(SectionContainer, { className: styles.container },
                React.createElement(SectionLink, { title: t("companiesGroups.companies.title"), description: t("companiesGroups.companies.detail", {
                        companiesGroupName: (selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.name) || "",
                    }), onClick: () => routerStore.push(`${AdminRoutes.CompaniesGroups.Actions}/${companiesGroupId}/empresas`) }),
                React.createElement(SectionLink, { title: t("externalApi.title"), description: t("externalApi.detailPageDescription"), onClick: () => routerStore.push(`${AdminRoutes.CompaniesGroups.Actions}/${companiesGroupId}/api-externa`) }),
                React.createElement(SectionLink, { title: t("companyEmployee.title"), description: t("companiesGroups.employee.description"), onClick: () => routerStore.push(`${AdminRoutes.CompaniesGroups.Actions}/${companiesGroupId}/colaboradores`) })))));
});
export default CompaniesGroupDetail;
