import { __awaiter } from "tslib";
import React from "react";
import SubMenu from "antd/lib/menu/SubMenu";
import { NavLink, matchPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, Avatar } from "antd";
import { useStores } from "../../utils/mobx";
import { getUserInitials } from "../../../shared/utils";
import styles from "./style.scss";
const checkActive = (path) => {
    return !!matchPath(path, {
        path: window.location.pathname,
        exact: true,
    });
};
const activeItemKeysOfSubMenu = (menu) => {
    const isSubMenu = menu.items && menu.items.length > 0;
    if (isSubMenu) {
        return menu.items.filter((item) => checkActive(item.to)).map((item) => item.label);
    }
    else {
        return menu.to && checkActive(menu.to) ? [menu.label] : [];
    }
};
const getActiveItems = (items) => {
    var _a;
    if (!!((_a = Array.prototype) === null || _a === void 0 ? void 0 : _a.flatMap)) {
        return items.flatMap((nav) => activeItemKeysOfSubMenu(nav));
    }
    else {
        return items.reduce((acc, current) => acc.concat(activeItemKeysOfSubMenu(current)), []);
    }
};
export const Navbar = ({ items, user }) => {
    const { authStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const activeItems = getActiveItems(items);
    const isSubMenu = (item) => item.items && item.items.length;
    const { t } = useTranslation();
    const logout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield authStore.logout();
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("logoutError"));
        }
    });
    return (React.createElement(Menu, { className: styles.navbar, mode: "horizontal", theme: "dark", selectedKeys: activeItems },
        items
            .filter((item) => !item.isUserSubmenu)
            .map((item) => isSubMenu(item) ? (React.createElement(SubMenu, { key: item.label, title: item.label }, item.items &&
            item.items.map((subItem) => (React.createElement(Menu.Item, { key: subItem.label },
                React.createElement(NavLink, { to: subItem.to || "", exact: true }, subItem.label)))))) : (React.createElement(Menu.Item, { key: item.label },
            React.createElement(NavLink, { to: item.to || "", exact: true }, item.label)))),
        user && (React.createElement(SubMenu, { title: React.createElement(Avatar, { className: styles.avatar, size: "large" }, getUserInitials(user.name)) },
            React.createElement("div", { className: styles.userInfo },
                React.createElement("div", { className: styles.name }, user.name),
                React.createElement("div", null, user.email)),
            items
                .filter((item) => item.isUserSubmenu)
                .map((item) => (React.createElement(Menu.Item, { key: item.label },
                React.createElement(NavLink, { to: item.to, exact: true }, item.label)))),
            React.createElement(Menu.Item, { onClick: logout }, "Logout")))));
};
