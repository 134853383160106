export function setContextMenuDataHandler(contextMenuData, setContextMenuData, record) {
    return (e) => {
        e.preventDefault();
        const event = e;
        if (!contextMenuData.visible) {
            document.addEventListener(`click`, function onClickOutside() {
                setContextMenuData(Object.assign(Object.assign({}, contextMenuData), { visible: false }));
                document.removeEventListener(`click`, onClickOutside);
            });
        }
        setContextMenuData({
            currentContract: record,
            visible: true,
            x: event.pageX,
            y: event.pageY,
        });
    };
}
