import React, { useCallback, useEffect } from "react";
import { Col, Form, Input, Modal, Row, Spin, DatePicker, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { MaskedInput } from "antd-mask-input";
import { checkCNPJ, checkCPF, checkEmail, isOnlyNumbers, isValidPassword, } from "../../../../shared/utils/formValidator";
import InputPhone from "../../../../shared/components/InputPhone";
import { decoratorConfigDefaultOnBlur } from "../../../utils/decoratorsConfig";
import { parseStringToMoment } from "../../../../shared/utils/date";
const InnerForm = ({ form, visible, title, onCancel, onCreate, okText, initialValues, loading, className, error, onCloseAlert, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { validateFields, getFieldDecorator } = form;
    const { t } = useTranslation();
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                onCreate(values);
            }
        });
    };
    function isDisabled(hasInitialValues) {
        return hasInitialValues ? true : false;
    }
    const handleCancel = useCallback(() => {
        onCancel();
        form.resetFields();
    }, [onCancel, form]);
    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);
    const passwordIsValid = (_, value, callback) => {
        if (value && !isValidPassword(value)) {
            callback(t("escrow.passwordValidation"));
        }
        else {
            callback();
        }
    };
    const transactionalPasswordIsValid = (_, value, callback) => {
        if (value && !isOnlyNumbers(value)) {
            callback(t("escrow.transactionalPasswordValidation"));
        }
        else {
            callback();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: visible, title: title, onCancel: handleCancel, onOk: handleSubmit, okText: okText || t("registerLabel"), width: 800, className: className, okButtonProps: { disabled: isDisabled(initialValues) } },
            React.createElement(Form, { hideRequiredMark: true },
                React.createElement(Spin, { spinning: loading },
                    React.createElement("h3", null,
                        React.createElement("strong", null, "Dados da Empresa")),
                    React.createElement(Row, { gutter: 22 },
                        React.createElement(Col, { span: 11 },
                            React.createElement(Form.Item, { label: t("nameLabel"), "data-cy": "companyName" }, getFieldDecorator("companyName", {
                                initialValue: initialValues ? initialValues.socialReason : "",
                                rules: [
                                    {
                                        required: true,
                                        message: t("nameLabel") + t("isRequiredLabel"),
                                    },
                                ],
                            })(React.createElement(Input, { autoFocus: true, allowClear: true, disabled: isDisabled(initialValues) })))),
                        React.createElement(Col, { span: 10 },
                            React.createElement(Form.Item, { label: "CNPJ", "data-cy": "cnpj" }, getFieldDecorator("cnpj", {
                                initialValue: initialValues ? initialValues.cnpj : "",
                                rules: [{ validator: checkCNPJ }],
                            })(React.createElement(MaskedInput, { mask: "11.111.111/1111-11", allowClear: true, maxLength: 15, disabled: isDisabled(initialValues) }))))),
                    React.createElement("h3", null,
                        React.createElement("strong", null, "Dados do Representante")),
                    React.createElement(Row, { gutter: 22 },
                        React.createElement(Col, { span: 11 },
                            React.createElement(Form.Item, { label: t("escrow.fullName"), "data-cy": "fullName" }, getFieldDecorator("fullName", {
                                initialValue: initialValues ? (_a = initialValues.representativeData) === null || _a === void 0 ? void 0 : _a.fullName : "",
                                rules: [
                                    {
                                        required: true,
                                        message: t("escrow.fullName") + t("isRequiredLabel"),
                                    },
                                ],
                            })(React.createElement(Input, { autoFocus: true, allowClear: true, maxLength: 300, disabled: isDisabled(initialValues) })))),
                        React.createElement(Col, { span: 10 },
                            React.createElement(Form.Item, { label: "CPF", "data-cy": "cpf" }, getFieldDecorator("cpf", {
                                initialValue: initialValues ? (_b = initialValues.representativeData) === null || _b === void 0 ? void 0 : _b.cpf : "",
                                rules: [{ validator: checkCPF }],
                            })(React.createElement(MaskedInput, { mask: "111.111.111-11", allowClear: true, maxLength: 15, disabled: isDisabled(initialValues) }))))),
                    React.createElement(Row, { gutter: 22 },
                        React.createElement(Col, { span: 11 },
                            React.createElement(Form.Item, { label: t("loginEmailLabel"), "data-cy": "email" }, getFieldDecorator("email", {
                                initialValue: initialValues ? (_c = initialValues.representativeData) === null || _c === void 0 ? void 0 : _c.email : "",
                                rules: [
                                    {
                                        validator: checkEmail,
                                        message: t("authentication.invalidEmailText"),
                                    },
                                ],
                            })(React.createElement(Input, { allowClear: true, maxLength: 300, disabled: isDisabled(initialValues) })))),
                        React.createElement(Col, { span: 10 },
                            React.createElement(Form.Item, { label: t("escrow.birthDate"), "data-cy": "birthDate" }, getFieldDecorator(`birthDate`, {
                                initialValue: initialValues
                                    ? parseStringToMoment((_d = initialValues.representativeData) === null || _d === void 0 ? void 0 : _d.birthDate)
                                    : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: t("mandatoryField", { field: t("escrow.birthDate") }),
                                    },
                                ],
                            })(React.createElement(DatePicker, { showToday: true, format: "DD/MM/YYYY", allowClear: true, "data-cy": "birth-date-input", disabled: isDisabled(initialValues) }))))),
                    React.createElement(Row, { gutter: 22 },
                        React.createElement(Col, { span: 11 },
                            React.createElement(Form.Item, { label: t("escrow.socialName"), "data-cy": "socialName" }, getFieldDecorator("socialName", {
                                initialValue: initialValues ? (_e = initialValues.representativeData) === null || _e === void 0 ? void 0 : _e.socialName : "",
                                rules: [
                                    {
                                        required: false,
                                        message: t("mandatoryField", { field: t("escrow.socialName") }),
                                    },
                                ],
                            })(React.createElement(Input, { allowClear: true, maxLength: 300, disabled: isDisabled(initialValues) })))),
                        React.createElement(Col, { span: 10 },
                            React.createElement(Form.Item, { label: t("escrow.phoneNumber"), "data-cy": "phoneNumber" }, getFieldDecorator("phoneNumber", Object.assign({ initialValue: initialValues ? (_f = initialValues.representativeData) === null || _f === void 0 ? void 0 : _f.phone : "", rules: [
                                    {
                                        required: true,
                                        message: t("mandatoryField", { field: t("escrow.phoneNumber") }),
                                    },
                                ] }, decoratorConfigDefaultOnBlur))(React.createElement(InputPhone, { placeholder: "(XX) XXXXX-XXXX", allowClear: true, name: "phoneNumber", disabled: isDisabled(initialValues) }))))),
                    React.createElement(Row, { gutter: 22 },
                        React.createElement(Col, { span: 11 },
                            React.createElement(Form.Item, { label: t("escrow.accessPassword"), "data-cy": "accessPassword" }, getFieldDecorator("accessPassword", {
                                rules: [
                                    {
                                        required: true,
                                        message: t("mandatoryField", { field: t("escrow.accessPassword") }),
                                    },
                                    {
                                        min: 6,
                                        message: React.createElement(React.Fragment, null, t("escrow.passwordMinCharactersText")),
                                    },
                                    {
                                        max: 16,
                                        message: React.createElement(React.Fragment, null, t("escrow.passwordMaxCharactersText")),
                                    },
                                    {
                                        validator: passwordIsValid,
                                    },
                                ],
                            })(React.createElement(Input, { type: "password", allowClear: true, placeholder: t("authentication.insertPasswordText"), disabled: isDisabled(initialValues) })))),
                        React.createElement(Col, { span: 10 },
                            React.createElement(Form.Item, { label: t("escrow.transactionalPassword"), "data-cy": "transactionalPassword" }, getFieldDecorator("transactionalPassword", {
                                rules: [
                                    {
                                        required: true,
                                        message: t("mandatoryField", { field: t("escrow.transactionalPassword") }),
                                    },
                                    {
                                        min: 6,
                                        message: (React.createElement(React.Fragment, null, t("escrow.transactionalPasswordValidationMinCharactersText"))),
                                    },
                                    {
                                        max: 6,
                                        message: React.createElement(React.Fragment, null, t("escrow.transactionalPasswordMaxCharactersText")),
                                    },
                                    {
                                        validator: transactionalPasswordIsValid,
                                    },
                                ],
                            })(React.createElement(Input, { type: "password", allowClear: true, placeholder: t("authentication.insertPasswordText"), disabled: isDisabled(initialValues) }))))),
                    error && (React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 24 },
                            React.createElement(Alert, { message: error, type: "error", showIcon: true, closable: true, onClose: onCloseAlert })))))))));
};
export const ApproverForm = Form.create({
    name: "approver_form",
})(InnerForm);
