import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import SimulationValueContainer from "../SimulationResult/SimulationValueContainer";
import SimulationMoreInformationTable from "../SimulationResult/SimulationMoreInformationTable";
import { SimulationDownloadHeaderFields } from "./SimulationDownloadHeaderFields";
import { Typograph } from "../../../../shared/components/Typograph";
import { SimulationDownloadInstallmentsList } from "./SimulationDownloadInstallmentsList";
import styles from "./style.scss";
export const SimulationDownloadModal = ({ downloadSimulationData, priceSimulationPjResult, visible, onCancel, }) => {
    const { t } = useTranslation();
    return (React.createElement(Modal, { visible: visible, width: "1700px", okButtonProps: { style: { display: "none" } }, cancelButtonProps: { style: { display: "none" } }, bodyStyle: { overflowX: "scroll" }, onCancel: onCancel },
        React.createElement("div", { className: styles.headerWrapper },
            React.createElement("div", { className: styles.headerFields },
                React.createElement(SimulationDownloadHeaderFields, { downloadSimulationData: downloadSimulationData })),
            React.createElement("div", { className: styles.valueContainerWrapper },
                React.createElement(SimulationValueContainer, { priceSimulationPjResult: priceSimulationPjResult }))),
        React.createElement("div", { className: styles.container },
            React.createElement("div", { style: { width: "70%" } },
                React.createElement(Typograph, { className: styles.subtitle }, t("installmentsLowerLabel")),
                React.createElement(SimulationDownloadInstallmentsList, { installments: priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.installments })),
            React.createElement("div", { style: { width: "30%" } },
                React.createElement(Typograph, { className: styles.subtitle }, t("simulator.otherInformations")),
                React.createElement(SimulationMoreInformationTable, { priceSimulationPjResult: priceSimulationPjResult })))));
};
