import { __awaiter } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useStores } from "../../../utils/mobx";
import styles from "./style.scss";
export const UnblockUserModal = ({ onCancel, onSuccess, visible, userId, }) => {
    const { t } = useTranslation();
    const { userReportStores, generalStore: { setErrorMessage, catchErrors, setSuccessMessage }, } = useStores();
    const unblockUsers = () => __awaiter(void 0, void 0, void 0, function* () {
        if (userId) {
            try {
                yield userReportStores.unblockUser(userId);
                setSuccessMessage(t("users.unblockUserSuccessMessage"));
                onSuccess();
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("users.defaultUnblockUserErrorMessage"));
                onCancel();
            }
        }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        unblockUsers();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { className: styles.modal, visible: visible, title: t("unblockUserLabel"), onCancel: onCancel, onOk: handleSubmit, destroyOnClose: true, maskClosable: true, width: "40%", okText: t("confirmLabel") },
            React.createElement("div", null, t("users.unblockUserConfirmMessage")))));
};
