import { __awaiter } from "tslib";
import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../../shared/components/SectionFooter";
import { Pagination } from "../../../../shared/components/Pagination";
import AdminTable from "../../../components/Admin/AdminTable";
import AdminFilterForm from "../../../components/Admin/AdminFilterForm";
import { NewAdminForm } from "../../../components/Admin/Forms/NewUserForm";
import { EditAdminForm } from "../../../components/Admin/Forms/EditAdminForm";
import { AdminContext } from "../../../contexts/AdminContext";
import { useStores } from "../../../utils/mobx";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const AdminList = observer(() => {
    const { t } = useTranslation();
    const [newUserModalIsOpen, setNewUserModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalContributors, setTotalContributors] = useState(0);
    const [searchTerm, setSearchTerm] = React.useState(undefined);
    const [selectedProfileId, setSelectedProfileId] = React.useState(undefined);
    const { adminStore: { getAdminsTotal }, generalStore, generalStore: { catchErrors }, } = useStores();
    const { fetchProfiles, fetchAdmins, loading, editingAdmin } = useContext(AdminContext);
    const fetchUsersTotal = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const totalFetched = yield getAdminsTotal({
                name: searchTerm,
                profileId: selectedProfileId,
            });
            setTotalContributors(totalFetched);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    const handleOpenNewUserForm = () => {
        setNewUserModalIsOpen(true);
    };
    const handleCloseNewUserForm = () => {
        setNewUserModalIsOpen(false);
    };
    const handleUserFilterFormSubmit = (values) => {
        setSearchTerm(values.name);
        setSelectedProfileId(values.profile);
        setCurrentPage(1);
        setRowsPerPage(10);
    };
    useEffect(() => {
        fetchProfiles();
    }, []);
    useEffect(() => {
        if (!newUserModalIsOpen && !editingAdmin) {
            fetchAdmins({ name: searchTerm, profileId: selectedProfileId }, currentPage, rowsPerPage);
            fetchUsersTotal();
        }
    }, [
        fetchAdmins,
        editingAdmin,
        newUserModalIsOpen,
        currentPage,
        rowsPerPage,
        searchTerm,
        selectedProfileId,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(NewAdminForm, { onClose: handleCloseNewUserForm, open: newUserModalIsOpen }),
        React.createElement(EditAdminForm, { open: !!editingAdmin }),
        React.createElement(SectionHeader, { title: t("users.title"), hasBackButton: true, linkToBackButton: AdminRoutes.LoansRequests, description: ` ${t("users.description")}. `, actions: [
                {
                    label: "Novo Usuário",
                    handleAction: handleOpenNewUserForm,
                    dataCy: "new-admin-button-data-cy",
                },
            ] }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(AdminFilterForm, { handleSubmit: handleUserFilterFormSubmit }),
            React.createElement(Spin, { spinning: loading }, false ? (React.createElement("h1", null, t("companyEmployee.noEmployeeMessage"))) : (React.createElement(React.Fragment, null,
                React.createElement(AdminTable, null))))),
        React.createElement(SectionFooter, null,
            React.createElement(Pagination, { current: currentPage, total: totalContributors, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setRowsPerPage(size);
                } }))));
});
export default AdminList;
