exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.firstRow___17sil {\n  display: grid;\n  grid-template-columns: 60% auto;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.secondRow___2pPsk {\n  display: grid;\n  grid-template-columns: auto 60%;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.selectionGroupsWrapper___3evDw {\n  display: flex;\n  grid-column-gap: 20px; }\n  .selectionGroupsWrapper___3evDw div {\n    max-width: 210px; }\n\n.footerWrapper___2Y9ie {\n  display: flex; }\n\n.addButton___2lHvg {\n  display: flex;\n  align-items: center; }\n  .addButton___2lHvg svg {\n    margin-right: 5px; }\n  .addButton___2lHvg:hover {\n    color: #0bc2d2; }\n    .addButton___2lHvg:hover path {\n      fill: #0bc2d2; }\n", ""]);

// Exports
exports.locals = {
	"firstRow": "firstRow___17sil",
	"secondRow": "secondRow___2pPsk",
	"selectionGroupsWrapper": "selectionGroupsWrapper___3evDw",
	"footerWrapper": "footerWrapper___2Y9ie",
	"addButton": "addButton___2lHvg"
};