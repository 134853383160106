import { __awaiter } from "tslib";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import ActionTable from "../../../shared/components/ActionTable";
import { useStores } from "../../utils/mobx";
import UserReportFilterForm from "../../components/UserReport/UserReportFilterForm";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { columns } from "./column";
import { Pagination } from "../../../shared/components/Pagination";
import { Icon, Modal, Tabs } from "antd";
import { setContextMenuDataHandler } from "../../utils/table.utils";
import { defaultContextMenuData } from "../Contracts";
import { defaultConfirmProps } from "../../../shared/utils";
import ContractModalHeader from "../../components/Contracts/ContractModalHeader";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { formatBoolean, formatCpf, formatCurrencyNullIF, formatPhoneNumberWithDDI, } from "../../../shared/utils/formatters";
import { ContractDataTabCard } from "../../components/Contracts/ContractDataTabCard";
import FloatingList from "../../components/FloatingList";
import { FileText } from "../../../shared/components/Icons/FileText";
import styles from "./style.scss";
import { formatUserSituation, formatUserStatus } from "../../utils/userReport.utils";
import { nullIfEmpty } from "../../../shared/utils/filter";
import ActionSelect from "../../../shared/components/ActionSelect";
import { SuspendUserModal } from "../../components/UserReport/SuspendUserModal";
import UserSuspensionTable from "../../components/UserReport/UserSuspensionsTable";
import { ReactivateUserModal } from "../../components/UserReport/ReactivateUserModal";
import { UnblockUserModal } from "../../components/UserReport/UnblockUserModal";
import { EditUserModalForm } from "../../components/UserReport/EditUserModal";
import { EditIcon } from "../../../shared/components/Icons/Edit";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { goBackTwoDecimalPlaces } from "../../../shared/utils/numbers";
import { CloseDigitalAccountModal } from "../../components/UserReport/CloseDigitalAccountModal";
const confirm = Modal.confirm;
const { TabPane } = Tabs;
const defaultFilter = {
    name: null,
    cpf: null,
    companyName: null,
    hiringSituation: null,
    status: null,
};
const UserReport = () => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState(defaultFilter);
    const [contextMenuData, setContextMenuData] = useState(defaultContextMenuData);
    const [selectedUsersKeys, setSelectedUsersKeys] = useState([]);
    const [unblockUserModalIsOpen, setUnblockUserModalIsOpen] = useState(false);
    const [closeDigitalAccountModalIsOpen, setCloseDigitalAccountModalIsOpen] = useState(false);
    const [suspendUserModalIsOpen, setSuspendUserModalIsOpen] = useState(false);
    const [reactivateUserModalIsOpen, setReactivateUserModalIsOpen] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState();
    const { generalStore: { setErrorMessage, catchErrors, clearMessage }, userReportStores, } = useStores();
    const fetchUsers = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingUsers(true);
        try {
            const responseTotal = yield userReportStores.getUsersReportTotal(filter);
            setTotalUsers(responseTotal);
            const response = yield userReportStores.getUsersReport(filter, currentPage, rowsPerPage);
            setUsers(response.users);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("users.usersApiErrorMessage"));
        }
        finally {
            setLoadingUsers(false);
        }
    });
    const applyFilter = (values) => {
        setCurrentPage(1);
        setFilter(Object.assign(Object.assign({}, values), { hiringSituation: nullIfEmpty(values.hiringSituation) }));
    };
    useEffect(() => {
        fetchUsers();
    }, [filter, currentPage, rowsPerPage]);
    const hasUsers = users.length > 0;
    const handleSelectChange = (selectedRowKeys) => {
        setSelectedUsersKeys(selectedRowKeys);
    };
    const showUserDetails = (user) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userSuspensionHistoryData = yield userReportStores.getUserSuspensionHistory(user.id);
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement(ContractModalHeader, { identifier: formatUserStatus(user.status), title: user.userName })), content: (React.createElement("div", { className: styles.modal },
                    React.createElement("div", { className: styles.modalBodyDialog },
                        React.createElement(ListColumnInfo, { labels: [
                                t("cpfLabel"),
                                t("situationLabel"),
                                t("loanApplication.admissionDate"),
                                t("grossSalaryLabel"),
                                t("salaryLabel"),
                                t("monthlyPayrollDiscountWithOtherInstitutions"),
                                t("inssAwayLabel"),
                                t("balanceAvailableLabel"),
                            ], values: [
                                formatCpf(user.cpf),
                                formatUserSituation(user.hiringSituation),
                                getDateLocalTimezoneFormat(user.admissionDate),
                                formatCurrencyNullIF(goBackTwoDecimalPlaces(user.grossSalary)),
                                formatCurrencyNullIF(goBackTwoDecimalPlaces(user.salary)),
                                formatCurrencyNullIF(goBackTwoDecimalPlaces(user.monthlyPayrollDiscountWithOtherInstitutions)),
                                formatBoolean(user.inssAway),
                                formatCurrencyNullIF(goBackTwoDecimalPlaces(user.balance)),
                            ] }),
                        React.createElement(ContractDataTabCard, { generalData: user },
                            React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "user-suspensions-tab-button" }, t("suspensionsHistory")), key: "suspensionsHistory" },
                                React.createElement("div", { "data-cy": "user-suspensions-history-data" },
                                    React.createElement(UserSuspensionTable, { userSuspensionHistoryData: userSuspensionHistoryData }))))))), className: styles.containerDialog, width: "50%", maskClosable: true, onOk: () => handleSelectChange([...selectedUsersKeys, user.id]), okText: t("selectLabel"), okButtonProps: {
                    icon: "check",
                    type: "primary",
                    disabled: selectedUsersKeys.includes(user.id),
                }, cancelText: t("closeText") }));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contracts.contractsApiErrorMessage"));
        }
    });
    const actions = [
        {
            value: "suspend",
            label: t("suspendLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("suspend", selectedKeys);
            },
        },
        {
            value: "reactivate",
            label: t("reactivateLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("reactivate", selectedKeys);
            },
        },
        {
            value: "unblock",
            label: t("unblockLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("unblock", selectedKeys);
            },
        },
        {
            value: "closeDigitalAccount",
            label: t("closeDigitalAccountLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("closeDigitalAccount", selectedKeys);
            },
        },
    ];
    const handleOpenConfirm = (status, selectedUsers) => {
        clearMessage();
        const totalSelectedUsers = selectedUsers ? selectedUsers.length : 0;
        if (status === "suspend" && totalSelectedUsers > 1) {
            return setErrorMessage(t("users.suspendMultipleUsersMessage"));
        }
        else if (status === "reactivate" && totalSelectedUsers > 1) {
            return setErrorMessage(t("users.reactivateMultipleUsersMessage"));
        }
        else if (status === "unblock" && totalSelectedUsers > 1) {
            return setErrorMessage(t("users.unblockMultipleUsersMessage"));
        }
        if (selectedUsers === undefined || totalSelectedUsers === 0) {
            return setErrorMessage(t("users.emptySelectionMessage"));
        }
        if (status === "suspend") {
            setSuspendUserModalIsOpen(true);
        }
        else if (status === "reactivate") {
            setReactivateUserModalIsOpen(true);
        }
        else if (status === "unblock") {
            setUnblockUserModalIsOpen(true);
        }
        else if (status === "closeDigitalAccount") {
            setCloseDigitalAccountModalIsOpen(true);
        }
    };
    const closeModal = () => {
        setSuspendUserModalIsOpen(false);
        setReactivateUserModalIsOpen(false);
        setUnblockUserModalIsOpen(false);
        setCloseDigitalAccountModalIsOpen(false);
    };
    const filterSelectedUser = (key) => {
        const filteredUsers = users.filter((user) => {
            return user.id === key;
        });
        return filteredUsers ? filteredUsers[0] : null;
    };
    const selectedUser = filterSelectedUser(selectedUsersKeys[0]);
    const onSuccess = () => {
        setSuspendUserModalIsOpen(false);
        setReactivateUserModalIsOpen(false);
        setUnblockUserModalIsOpen(false);
        setCloseDigitalAccountModalIsOpen(false);
        fetchUsers();
        setSelectedUsersKeys([]);
    };
    const onSuccessEditUser = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowEditUserModal(false);
        fetchUsers();
    });
    function handleEditUser(user) {
        setUserToEdit(user);
        setShowEditUserModal(true);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("users.title") }),
        React.createElement(UserReportFilterForm, { applyFilter: applyFilter }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(SuspendUserModal, { onCancel: closeModal, visible: suspendUserModalIsOpen, userId: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id, onSuccess: onSuccess }),
            React.createElement(ReactivateUserModal, { onCancel: closeModal, visible: reactivateUserModalIsOpen, userId: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id, onSuccess: onSuccess }),
            React.createElement(UnblockUserModal, { onCancel: closeModal, visible: unblockUserModalIsOpen, userId: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id, onSuccess: onSuccess }),
            React.createElement(CloseDigitalAccountModal, { onCancel: closeModal, visible: closeDigitalAccountModalIsOpen, closeDigitalAccountParams: { cpf: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.cpf, userId: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id }, onSuccess: onSuccess }),
            React.createElement(EditUserModalForm, { user: userToEdit, visible: showEditUserModal, onCancel: () => setShowEditUserModal(false), onSuccess: onSuccessEditUser }),
            React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                React.createElement(ActionTable, { className: styles.table, rowClassName: (record) => record.status === "suspended" ? styles.rowRed : "", columns: columns, scroll: { y: 600 }, rowSelection: {
                        selectedRowKeys: selectedUsersKeys,
                        onChange: handleSelectChange,
                    }, onRow: (user) => ({
                        onContextMenu: setContextMenuDataHandler(contextMenuData, setContextMenuData, user),
                    }), loading: loadingUsers, dataSource: users.map((user) => {
                        var _a;
                        return (Object.assign(Object.assign({}, user), { key: user.id, mobilePhone: formatPhoneNumberWithDDI((_a = user.mobilePhone) !== null && _a !== void 0 ? _a : ""), actions: (React.createElement(Icon, { onClick: () => handleEditUser(user), component: () => React.createElement(EditIcon, null), "data-cy": "edit-user-icon-wrapper" })) }));
                    }) }))),
        React.createElement(ActionSelect, { actions: actions, currentPage: currentPage || 1, selectedKeys: selectedUsersKeys ? selectedUsersKeys : [] }),
        React.createElement(FloatingList, { contextMenuData: contextMenuData, items: [
                {
                    avatar: React.createElement(FileText, { fill: "#ff7600" }),
                    onClick: () => {
                        showUserDetails(contextMenuData.currentContract);
                    },
                    title: t("contracts.ccbDetailLabel"),
                },
            ] }),
        React.createElement(SectionFooter, null, hasUsers && (React.createElement(Pagination, { current: currentPage, total: totalUsers, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
};
export default UserReport;
