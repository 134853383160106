import { __awaiter } from "tslib";
import { api } from "../AppWrapper";
export class EscrowStore {
    constructor() {
        this.getApprovers = (companyQuery) => api.getApprovers({ companyQuery });
        this.getMovers = (companyQuery) => api.getMovers({ companyQuery });
        this.createEscrowApprover = (createApproverCompanyDto) => __awaiter(this, void 0, void 0, function* () {
            api.createEscrowApprover({ createApproverCompanyDto });
        });
        this.createEscrowMover = (createMoverCompanyDto) => api.createEscrowMover({ createMoverCompanyDto });
    }
}
