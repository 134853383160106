exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.container___q6Fk2, .horizontalContainer___rR-lo, .verticalContainer___4wfx9 {\n  display: grid;\n  flex-direction: row;\n  background: #FAFAFA;\n  width: 100%;\n  height: 100%;\n  padding: 20px 25px;\n  border-radius: 8px; }\n\n.horizontalContainer___rR-lo {\n  gap: 20px; }\n\n.verticalContainer___4wfx9 {\n  gap: 10px; }\n\n.horizontalOrientation___7TNMa {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between; }\n\n.verticalOrientation___1tb5C {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between; }\n\n.contentContainer___-ujDA {\n  background: #FAFAFA;\n  padding: 8px 20px;\n  border-radius: 8px;\n  width: 100%; }\n\n.subtitleGrid___2n7gJ {\n  font-weight: bold;\n  font-size: 16px;\n  color: #01929f; }\n\n.titleGrid___2vMwr {\n  font-weight: bold;\n  color: #4D4D50; }\n", ""]);

// Exports
exports.locals = {
	"container": "container___q6Fk2",
	"horizontalContainer": "horizontalContainer___rR-lo",
	"verticalContainer": "verticalContainer___4wfx9",
	"horizontalOrientation": "horizontalOrientation___7TNMa",
	"verticalOrientation": "verticalOrientation___1tb5C",
	"contentContainer": "contentContainer___-ujDA",
	"subtitleGrid": "subtitleGrid___2n7gJ",
	"titleGrid": "titleGrid___2vMwr"
};