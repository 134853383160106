import React from "react";
import { Input } from "antd";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormTitleH3 } from "../../../FormTitleH3";
import { getFormItemName } from "../../../../utils/form.utils";
import { DeletableCard } from "../../../DeletableCard";
import style from "./style.scss";
export const SupplierFields = ({ form, companyInfo, readOnly, suppliers, formKey = "suppliers", onDeleteClick, hasOnlyOneSupplier, }) => {
    const { getFieldDecorator } = form;
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitleH3, { title: t("portalPj.mainSuppliers") }), suppliers === null || suppliers === void 0 ? void 0 :
        suppliers.map((supplier, index) => {
            const fieldName = getFormItemName(formKey, index);
            return (React.createElement(DeletableCard, { key: fieldName, deleteButtonProps: {
                    onClick: () => {
                        if (onDeleteClick) {
                            onDeleteClick(index);
                        }
                    },
                    disabled: !!supplier.id || hasOnlyOneSupplier,
                }, "data-cy": "supplier-form-item", hideDeleteButton: readOnly },
                React.createElement(Form.Item, { label: "id", style: { display: "none" } },
                    getFieldDecorator(`${fieldName}.id`, {
                        initialValue: supplier.id,
                    }),
                    React.createElement(Input, { disabled: readOnly })),
                React.createElement(Form.Item, { label: "companyId", style: { display: "none" } },
                    getFieldDecorator(`${fieldName}.companyId`, {
                        initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.id,
                    }),
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: "deleted", style: { display: "none" } },
                    getFieldDecorator(`${fieldName}.deleted`, {
                        initialValue: false,
                    }),
                    React.createElement(Input, { disabled: readOnly })),
                React.createElement("div", { className: style.firstRow },
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: t("person.name"), "data-cy": "supplier-name-input-wrapper" }, getFieldDecorator(`${fieldName}.name`, {
                            initialValue: supplier.name,
                            rules: [
                                {
                                    message: t("mandatoryField", { field: t("person.name") }),
                                },
                            ],
                        })(React.createElement(Input, { disabled: readOnly })))),
                    React.createElement("div", null,
                        React.createElement(Form.Item, { label: t("companyForm.phone"), "data-cy": "supplier-phone-input-wrapper" }, getFieldDecorator(`${fieldName}.phone`, {
                            initialValue: supplier.phone,
                        })(React.createElement(Input, { disabled: readOnly })))))));
        })));
};
