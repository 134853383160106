import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { SectionLink } from "../../../components/SectionLink";
import { Spin } from "antd";
import s from "./style.scss";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { Modal } from "antd";
import { getDateLocalTimezoneFormat } from "../../../../shared/utils/date";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { AdminRoutes } from "../../../constants/routes.constants";
const confirm = Modal.confirm;
export const AgreementList = observer(() => {
    const { companyStore: { selectedCompany }, generalStore: { catchErrors }, generalStore, agreementStore, routerStore, } = useStores();
    const [loading, setLoading] = React.useState(false);
    const [agreements, setAgreements] = React.useState([]);
    const hasAgreements = loading === false && agreements.length > 0;
    const { t } = useTranslation();
    React.useEffect(() => {
        if (selectedCompany) {
            getAgreements(selectedCompany.id);
        }
    }, []);
    const getAgreements = (companyId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const agreements = yield agreementStore.getAllCompanyAgreements(companyId);
            setAgreements(agreements);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("agreement.agreementErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const confirmNewAgreement = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            if (selectedCompany) {
                yield agreementStore.canCreateAgreement(selectedCompany.id);
                confirm({
                    title: `${t("agreement.createNewAgreementText")} ${selectedCompany.name}`,
                    content: (React.createElement(React.Fragment, null,
                        React.createElement("p", null,
                            t("agreement.creatingAgreementText"),
                            " ",
                            selectedCompany.name,
                            " ",
                            t("agreement.creatingAgreemenRemainingText")),
                        React.createElement("p", null, t("agreement.updatedNewAgreementText")))),
                    okButtonProps: {
                        type: "primary",
                    },
                    okText: t("confirmLabel"),
                    cancelText: t("closeText"),
                    onOk: () => newAgreement(),
                    width: "40%",
                    maskClosable: true,
                    icon: null,
                });
            }
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("agreement.agreementErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const newAgreement = () => {
        routerStore.push(AdminRoutes.Company.AgreementRegister);
    };
    const handleAgreementClick = (agreement) => {
        routerStore.push(`${AdminRoutes.Company.Agreements}/${agreement.id}`);
    };
    const activeAgreement = agreements.find((agreement) => agreement.active);
    const others = agreements.filter((agreement) => !agreement.active);
    return (selectedCompany && (React.createElement("div", null,
        React.createElement(SectionHeader, { className: s.header, hasBackButton: true, title: selectedCompany.name, description: formatCnpj(selectedCompany.cnpj), actions: [
                {
                    label: t("agreement.newAgreementTitle"),
                    handleAction: confirmNewAgreement,
                },
            ] }),
        React.createElement(Spin, { spinning: loading }, hasAgreements ? (React.createElement(React.Fragment, null,
            activeAgreement && (React.createElement(SectionContainer, { className: s.smallContainer },
                React.createElement(SectionLink, { key: activeAgreement.id, title: `${t("agreement.currentAgreementLabel")} \u2022 ${getDateLocalTimezoneFormat(activeAgreement.dueDate)}`, onClick: () => handleAgreementClick(activeAgreement), description: t("agreement.agreementDescriptionLabel") }))),
            others.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("h4", { className: s.text }, t("agreement.oldAgreementLabel")),
                React.createElement(SectionContainer, { className: s.container }, others.map((agreement) => (React.createElement(SectionLink, { key: agreement.id, title: `${getDateLocalTimezoneFormat(agreement.dueDate)} -`, description: agreement.createdAt
                        ? `${t("agreement.createdAt")} ${getDateLocalTimezoneFormat(agreement.createdAt)}`
                        : "", onClick: () => handleAgreementClick(agreement) })))))))) : (React.createElement(SectionContainer, { className: s.container }, agreements.length === 0 && !loading && (React.createElement("h1", null,
            t("agreement.noAgreementText"),
            " ",
            selectedCompany.name,
            "."))))))));
});
