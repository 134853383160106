import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { itemsContainsValueById } from "../../../../shared/utils/colection";
import styles from "./style.scss";
const { Option } = Select;
export const ProfileSelect = ({ onProfileChange, profiles, initialValue, disabled = false, }) => {
    const { t } = useTranslation();
    const profilesHasInitialValue = itemsContainsValueById(profiles, initialValue);
    return (React.createElement(Select, { key: `select-${initialValue}-${profilesHasInitialValue}`, className: styles.select, showSearch: true, placeholder: `${t("addLabel")} perfil...`, optionFilterProp: "children", onChange: onProfileChange, defaultValue: profilesHasInitialValue ? initialValue : undefined, filterOption: (input, option) => {
            var _a, _b;
            const childrenProps = option.props.children.props;
            if (!childrenProps) {
                return false;
            }
            const profileTitle = (_b = (_a = childrenProps.children[0]) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.children;
            return profileTitle.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }, "data-cy": "profile-select-data-cy", disabled: disabled },
        React.createElement(Option, { value: "" }, `${t("addLabel")} perfil...`), profiles === null || profiles === void 0 ? void 0 :
        profiles.map((profile) => (React.createElement(Option, { key: profile.id, value: profile.id },
            React.createElement("div", null,
                React.createElement("div", { className: styles.title }, profile.title),
                React.createElement("div", { className: styles.description }, profile.description)))))));
};
