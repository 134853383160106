import { __awaiter, __decorate } from "tslib";
import { observable, action } from "mobx";
import { api } from "../AppWrapper";
// NewHREmployee, HREmployee
// TO DO: Remove all unused code and change from CompanyBasicInfo[] to string[] if needed
export class ContributorsStore {
    constructor() {
        this.selectedCompany = null;
        this.setSelectedCompany = (company) => {
            this.selectedCompany = company;
            localStorage.setItem("selectedCompany", JSON.stringify(company));
        };
        this.getAllCompanies = (filter, page, rowsPerPage) => api.getCompaniesBasicInfo({ nameToFilter: filter, page, rowsPerPage });
        this.getAllCompaniesGroups = (filter, page, rowsPerPage) => api.getCompaniesGroups({ filter, page, rowsPerPage });
        this.getContributorsTotal = (filter) => api.getHrEmployeesTotal({ filter });
        this.getCompanyById = (id) => api.getCompanyInfo({ id });
        this.saveCompany = (company) => __awaiter(this, void 0, void 0, function* () { return api.saveCompany({ companyInfo: company }); });
        // public getHREmployees = (id: string) => api.getHREmployees({ companyId: id });
        this.getAllEmployees = (filter, page, rowsPerPage) => api.getAllHREmployees({ filter, page, rowsPerPage });
        // TO DO: Change this and chage the method ContributorsList
        this.createContributor = (newEmployee) => {
            return api.createHREmployee({
                newEmployee,
            });
        };
        this.editContributor = (employee) => api.editHREmployee({ employee });
        // TO DO: Change this
        this.removeContributor = (id) => api.removeHREmployee({ employeeId: id });
        const companiesIds = localStorage.getItem("selectedCompanies")
            ? JSON.parse(localStorage.getItem("selectedCompanies"))
            : null;
        this.selectedCompany = companiesIds;
    }
}
__decorate([
    observable
], ContributorsStore.prototype, "selectedCompany", void 0);
__decorate([
    action
], ContributorsStore.prototype, "setSelectedCompany", void 0);
