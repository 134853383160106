import React from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react-lite";
import { Row, Col, Alert } from "antd";
import { useStores } from "../../utils/mobx";
import { Header } from "../../components/Header";
import s from "./style.scss";
export const MainComp = observer(({ children }) => {
    const { generalStore, authStore } = useStores();
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { user: authStore.user }),
        React.createElement("div", { className: s.mainWrapper },
            React.createElement("div", { className: s.message }, generalStore.message && (React.createElement(Alert, { className: s.mainAlert, message: generalStore.message, type: generalStore.messageType, showIcon: true, closable: true, onClose: () => generalStore.clearMessage() }))),
            React.createElement(Row, { type: "flex", justify: "center", className: s.contentContainer },
                React.createElement(Col, { span: 23 }, children)))));
});
export const Main = withRouter(React.memo(MainComp));
