import React from "react";
import { useTranslation } from "react-i18next";
import Form from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import StepContentCard from "../StepContentCard";
import { Typograph } from "../../../../shared/components/Typograph";
import styles from "./styles.scss";
export const OpinionForm = ({ form, opinion }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    return (React.createElement(StepContentCard, { title: React.createElement(Typograph, { variant: "modalTitle" }, t("simulatorContractStep.opinion")), subTitle: React.createElement(Typograph, { variant: "subtitle" }, t("opinionStep.subtitle")) },
        React.createElement("div", { className: styles.container },
            React.createElement(Form.Item, { className: styles.opinionWrapper }, getFieldDecorator("opinion", {
                initialValue: opinion,
            })(React.createElement(TextArea, { style: { textAlign: "justify" }, placeholder: t("opinionStep.opinionPlaceholder"), allowClear: true, "data-testid": "opinion-input" }))))));
};
