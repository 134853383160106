import i18n from "../../../i18n";
const signedAgreementContract = {
    label: i18n.t("agreement.signedContractAssignment"),
    id: "signedAgreementContract",
    subItems: [
        {
            label: i18n.t("agreement.signedAgreement"),
            id: "signedAgreementContract.signedContract",
        },
    ],
};
const creditPolicySubItems = [
    {
        label: i18n.t("agreement.minWorkTimeLabel"),
        id: "minWorkTime",
    },
    {
        label: i18n.t("agreement.maxLoanInSalariesLabel"),
        id: "maxLoanInSalaries",
    },
    {
        label: i18n.t("agreement.maxPercentAllowedLabel") + " (%)",
        id: "maxPercentAllowed",
    },
    {
        label: i18n.t("agreement.maxAllowedInstallmentsLabel"),
        id: "maximumInstallments",
    },
    {
        label: i18n.t("agreement.minLoanValueLabel") + " (R$)",
        id: "minimumLoanAmount",
    },
    {
        label: i18n.t("agreement.structuringRateLabel"),
        id: "structuringRate",
    },
    {
        label: i18n.t("agreement.dueDateLabel"),
        id: "dueDate",
    },
    {
        label: i18n.t("agreement.tedValueLabel") + " (R$)",
        id: "tedValue",
    },
    {
        label: i18n.t("agreement.custodyValueLabel") + " (R$)",
        id: "custodyValue",
    },
    {
        label: i18n.t("agreement.thirdPartyValueLabel") + " (R$)",
        id: "thirdPartyValue",
    },
    {
        label: i18n.t("agreement.denyParallelLoansLabel"),
        id: "denyParallelLoans",
    },
    {
        label: i18n.t("agreement.extendedMaximumInstallments"),
        id: "extendedMaximumInstallments",
    },
];
const creditPolicy = {
    label: i18n.t("agreement.creditPolicyLabel"),
    id: "creditPolicy",
    subItems: creditPolicySubItems,
};
export function getAgreementFields(shouldShowMinWorkTimeForTermExtension, shouldShowInterestRateRange) {
    const policySubItems = getCreditPolicySubItems(shouldShowMinWorkTimeForTermExtension, shouldShowInterestRateRange);
    return [signedAgreementContract, Object.assign(Object.assign({}, creditPolicy), { subItems: policySubItems })];
}
function getCreditPolicySubItems(shouldShowMinWorkTimeForTermExtension, shouldShowInterestRateRange) {
    let subItems = [...creditPolicySubItems];
    if (shouldShowMinWorkTimeForTermExtension) {
        subItems = [
            ...subItems,
            {
                label: i18n.t("agreement.minWorkTimeForTermExtension"),
                id: "minWorkTimeForTermExtension",
            },
        ];
    }
    if (shouldShowInterestRateRange) {
        subItems = [
            ...subItems,
            {
                label: i18n.t("agreement.minInterestRate"),
                id: "minInterestRate",
            },
            {
                label: i18n.t("agreement.maxInterestRate"),
                id: "maxInterestRate",
            },
        ];
    }
    else {
        subItems = [
            ...subItems,
            {
                label: i18n.t("agreement.interestRateLabel"),
                id: "maxInterestRate",
            },
        ];
    }
    return subItems;
}
