exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.bodyWrapper___3m8sw {\n  min-height: 60px; }\n\n.formText___2KSp9 {\n  margin-top: -10px;\n  margin-bottom: 10px; }\n\n.formItem___9vX78 {\n  padding: 10px;\n  margin-bottom: 0px; }\n\n.formItemLabel___3Ccy6 {\n  font-weight: bold; }\n\ndiv[class$=\"radio-group-reschedule\"] {\n  display: flex; }\n", ""]);

// Exports
exports.locals = {
	"bodyWrapper": "bodyWrapper___3m8sw",
	"formText": "formText___2KSp9",
	"formItem": "formItem___9vX78",
	"formItemLabel": "formItemLabel___3Ccy6"
};