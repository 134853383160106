import { __awaiter } from "tslib";
import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../../shared/components/SectionFooter";
import { Pagination } from "../../../../shared/components/Pagination";
import { SectionLink } from "../../../components/SectionLink";
import { Spin } from "antd";
import { useStores } from "../../../utils/mobx";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import { NewCompaniesGroupModal } from "../../../components/CompaniesGroup/Forms/NewCompaniesGroupModal";
import { FilterForm } from "../../../../shared/components/FilterForm";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompaniesGroupList = observer(() => {
    const { generalStore, generalStore: { catchErrors }, routerStore, } = useStores();
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [newGroupModalIsOpen, setNewGroupModalIsOpen] = useState(false);
    const { fetchCompaniesGroups, loading, fetchCompaniesGroupsTotal, companiesGroupTotal, companiesGroups, } = useContext(CompaniesGroupContext);
    const fetchCompaniesGroupsFromStore = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield fetchCompaniesGroups({ name, companyName }, currentPage, rowsPerPage);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    const fetchCompaniesGroupsTotalFromStore = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield fetchCompaniesGroupsTotal({ name, companyName });
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    useEffect(() => {
        fetchCompaniesGroupsFromStore();
        fetchCompaniesGroupsTotalFromStore();
    }, [name, companyName, currentPage, rowsPerPage]);
    const handleOpenNewAccessProfileModal = () => {
        setNewGroupModalIsOpen(true);
    };
    const handleCloseNewAccessProfileModal = (companiesGroupWasCreated) => {
        if (companiesGroupWasCreated) {
            fetchCompaniesGroupsFromStore();
        }
        setNewGroupModalIsOpen(false);
    };
    const resetPagination = () => {
        setCurrentPage(1);
        setRowsPerPage(10);
    };
    const handleSearchSubmit = (nameFilter, companyNameFilter) => {
        setName(nameFilter);
        setCompanyName(companyNameFilter);
        resetPagination();
    };
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "companyName",
            name: "companyName",
            type: "input",
            placeholder: t("companyLabel"),
        },
    ];
    const hasMoreGroupsThanRowsPerPage = companiesGroupTotal && companiesGroupTotal > rowsPerPage;
    return (React.createElement(React.Fragment, null,
        React.createElement(NewCompaniesGroupModal, { open: newGroupModalIsOpen, onClose: handleCloseNewAccessProfileModal }),
        React.createElement(SectionHeader, { title: t("companiesGroups.title"), description: t("companiesGroups.description"), actions: [
                {
                    label: t("companiesGroups.newGroup"),
                    handleAction: handleOpenNewAccessProfileModal,
                },
            ] }),
        React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: (values) => {
                handleSearchSubmit(values.name, values.companyName);
            } }),
        React.createElement(SectionContainer, { className: styles.container, "data-cy": "profiles-wrapper-data-cy" },
            React.createElement(Spin, { spinning: loading }, isEmpty(companiesGroups) ? (React.createElement("h1", null,
                `${t("companiesGroups.notFoundMessage")} `,
                ".")) : (companiesGroups === null || companiesGroups === void 0 ? void 0 : companiesGroups.map((companyGroup) => (React.createElement(SectionLink, { key: companyGroup.id, title: companyGroup.name, info: String(companyGroup.description), onClick: () => {
                    routerStore.push(`${AdminRoutes.CompaniesGroups.Actions}/${companyGroup.id}`);
                }, "data-cy": "companies-group-section-link" })))))),
        React.createElement(SectionFooter, null, hasMoreGroupsThanRowsPerPage && (React.createElement(Pagination, { current: currentPage, total: companiesGroupTotal, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
});
export default CompaniesGroupList;
