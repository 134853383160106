import { __awaiter, __rest } from "tslib";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, DatePicker, Input, Modal, Spin, Alert } from "antd";
const PortabilityLoanContractInnerForm = (_a) => {
    var { form, sourceCompany, destinyCompanies, onDateChange, visible, onSubmit, onCancel, children, errorMessage, loading } = _a, rest = __rest(_a, ["form", "sourceCompany", "destinyCompanies", "onDateChange", "visible", "onSubmit", "onCancel", "children", "errorMessage", "loading"]);
    const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } = form;
    useEffect(() => {
        if (visible) {
            setFieldsValue({ sourceCompany: sourceCompany === null || sourceCompany === void 0 ? void 0 : sourceCompany.name });
        }
    }, [sourceCompany, visible]);
    const { t } = useTranslation();
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                onSubmit(values);
            }
        }));
    });
    const shouldSubmit = !!getFieldValue("sourceCompany") &&
        !!getFieldValue("destinyCompanyId") &&
        !!getFieldValue("transferDate") &&
        !errorMessage;
    return (React.createElement(Modal, { title: t("portabilityTitle"), visible: visible, okText: t("confirmLabel"), onCancel: onCancel, onOk: handleSubmit, maskClosable: true, destroyOnClose: true, width: "30%", okButtonProps: { disabled: !shouldSubmit } },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Form, Object.assign({ layout: "vertical" }, rest),
                React.createElement("div", null,
                    React.createElement(Form.Item, { label: t("originLabel") }, getFieldDecorator("sourceCompany")(React.createElement(Input, { disabled: true })))),
                React.createElement("div", null,
                    React.createElement(Form.Item, { label: t("destinyLabel") }, getFieldDecorator("destinyCompanyId", {
                        rules: [
                            {
                                required: true,
                                message: t("destinyLabel") + t("isRequiredLabel"),
                            },
                        ],
                    })(React.createElement(Select, { "data-cy": "destiny-company-wrapper", placeholder: t("portability.assignmentDestinyCompanyPlaceholder") }, destinyCompanies.map((company) => (React.createElement(Select.Option, { key: company.id, value: company.id }, company.name))))))),
                React.createElement("div", null,
                    React.createElement(Form.Item, { "data-cy": "transfer-date-wrapper", label: t("transferDateLabel") }, getFieldDecorator("transferDate", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("transferDateLabel") }),
                            },
                        ],
                    })(React.createElement(DatePicker, { onChange: (date) => {
                            onDateChange(date);
                        }, placeholder: "dd/mm/aaaa", format: "DD/MM/YYYY" }))))),
            children,
            errorMessage && React.createElement(Alert, { type: "error", closable: true, showIcon: true, message: errorMessage }))));
};
export const PortabilityLoanContractForm = Form.create({
    name: "portability_contract_form",
})(PortabilityLoanContractInnerForm);
