import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, DatePicker, InputNumber, Radio } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import { toInt } from "../../../../../shared/utils/formatters";
import { useStores } from "../../../../utils/mobx";
import InputCurrency from "../../../../../shared/components/InputCurrency";
import { toMoment } from "../../../../../shared/utils/date";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const RequiredCategorizationForm = observer(({ form, initialValues }) => {
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { getFieldDecorator } = form;
    const formKey = "patrimonialInfo";
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.patrimonialInfoTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.constitutionDateLabel") }, getFieldDecorator(`${formKey}.constitutionDate`, {
                    initialValue: initialValues.constitutionDate
                        ? toMoment(initialValues.constitutionDate)
                        : null,
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("companyForm.constitutionDateLabel") }),
                        },
                    ],
                })(React.createElement(DatePicker, { placeholder: "10/10/2010", format: "DD/MM/YYYY" })))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: t("companyForm.lastStaturaryChangeDateLabel") }, getFieldDecorator(`${formKey}.lastStaturaryChangeDate`, {
                    initialValue: initialValues.lastStaturaryChangeDate
                        ? toMoment(initialValues.lastStaturaryChangeDate)
                        : null,
                })(React.createElement(DatePicker, { placeholder: "10/10/2010", format: "DD/MM/YYYY" }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: t("companyForm.changeAmountLabel") }, getFieldDecorator(`${formKey}.changeAmount`, Object.assign({ initialValue: initialValues.changeAmount, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(InputNumber, { max: 10000, onFocus: () => setSelectedItem(`${formKey}.changeAmount`), min: 0, placeholder: "0" })))),
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: t("companyForm.socialCapitalLabel") }, getFieldDecorator(`${formKey}.socialCapital`, Object.assign({ initialValue: initialValues.socialCapital, normalize: (value) => toInt(value), rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("companyForm.socialCapitalLabel") }),
                        },
                    ] }, decoratorConfigDefaultOnBlur))(React.createElement(InputCurrency, { min: 0, onFocus: () => setSelectedItem(`${formKey}.socialCapital`) })))),
            React.createElement(Col, { span: 7 },
                React.createElement(Form.Item, { label: t("companyForm.netWorthLabel") }, getFieldDecorator(`${formKey}.netWorth`, Object.assign({ initialValue: initialValues.netWorth, normalize: (value) => toInt(value) }, decoratorConfigDefaultOnBlur))(React.createElement(InputCurrency, { min: 0, onFocus: () => setSelectedItem(`${formKey}.netWorth`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 11 },
                React.createElement(Form.Item, { label: t("companyForm.netWorthReferenceDateLabel") }, getFieldDecorator(`${formKey}.netWorthReferenceDate`, {
                    initialValue: initialValues.netWorthReferenceDate
                        ? toMoment(initialValues.netWorthReferenceDate)
                        : null,
                })(React.createElement(DatePicker, { placeholder: "10/10/2010", format: "DD/MM/YYYY" }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: t("companyForm.economicSectorLabel") }, getFieldDecorator(`${formKey}.economicSector`, {
                    initialValue: initialValues.economicSector,
                })(React.createElement(Radio.Group, null,
                    React.createElement(Radio, { value: "private" }, t("companyForm.privateLabel")),
                    React.createElement(Radio, { value: "municipal" }, t("companyForm.municipalLabel")),
                    React.createElement(Radio, { value: "state" }, t("companyForm.statePublicLabel")),
                    React.createElement(Radio, { value: "federal" }, t("companyForm.federalLabel")))))),
            React.createElement(Col, { span: 5 },
                React.createElement(Form.Item, { label: t("companyForm.capitalOriginLabel") }, getFieldDecorator(`${formKey}.capitalOrigin`, {
                    initialValue: initialValues.capitalOrigin,
                })(React.createElement(Radio.Group, null,
                    React.createElement(Radio, { value: "national" }, t("companyForm.nationalLabel")),
                    React.createElement(Radio, { value: "foreign" }, t("companyForm.foreignLabel")),
                    React.createElement(Radio, { value: "mix" }, t("companyForm.mixedLabel")),
                    React.createElement(Radio, { value: "other" }, t("companyForm.otherLabel")))))))));
});
export default RequiredCategorizationForm;
