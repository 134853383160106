import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionLink } from "../../../components/SectionLink";
import { useStores } from "../../../utils/mobx";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const CompanyDetail = observer(() => {
    // TODO - Contratos        - Vai para o relatório de contratos passando o filtro da empresa.
    const { t } = useTranslation();
    const { companyStore: { selectedCompany }, routerStore, } = useStores();
    React.useEffect(() => {
        if (selectedCompany === null) {
            routerStore.push(AdminRoutes.Company.Report);
        }
    });
    return (selectedCompany && (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: AdminRoutes.Company.Report, title: selectedCompany.name, description: formatCnpj(selectedCompany.cnpj) }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(SectionLink, { title: t("company.companyInfoLabel"), description: t("company.infoDescriptionText"), onClick: () => routerStore.push(`${AdminRoutes.Company.Edit}/${selectedCompany.id}`) }),
            React.createElement(SectionLink, { title: t("agreement.agreementLabel"), description: t("company.agreementDescriptionText"), onClick: () => {
                    routerStore.push(AdminRoutes.Company.Agreements);
                } }),
            React.createElement(SectionLink, { title: t("companyEmployee.title"), description: t("company.employeeDescriptionText"), onClick: () => routerStore.push(AdminRoutes.Company.Employees) }),
            React.createElement(SectionLink, { title: t("legalResponsibles.title"), description: t("legalResponsibles.detailPageDescription"), onClick: () => routerStore.push(AdminRoutes.Company.LegalResponsibles) }),
            React.createElement(SectionLink, { title: t("company.DocumentLabel"), description: t("company.documentDescriptionText"), onClick: () => routerStore.push(AdminRoutes.Company.Documents) }),
            React.createElement(SectionLink, { title: t("contracts.contractsLabel"), description: t("company.contractsDescriptionText"), onClick: () => routerStore.push(AdminRoutes.Company.Contracts) })))));
});
export default CompanyDetail;
