import { __awaiter } from "tslib";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../utils/mobx";
import { AdminForm } from "../AdminForm";
import { AdminContext } from "../../../../contexts/AdminContext";
export const NewAdminForm = ({ open, onClose }) => {
    const { t } = useTranslation();
    const { profiles } = useContext(AdminContext);
    const [loading, setLoading] = useState(false);
    const { adminStore: { createAdmin }, generalStore, generalStore: { catchErrors }, } = useStores();
    const handleCreateUser = useCallback((user, setErrorMessage) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            setLoading(true);
            yield createAdmin(Object.assign(Object.assign({}, user), { profileId: ((_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.id) || null }));
            if (onClose) {
                onClose();
            }
            generalStore.setSuccessMessage(t("users.creationSuccessMessage"));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("users.registerErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    }), [createAdmin, generalStore, catchErrors, onClose]);
    return (React.createElement(AdminForm, { onCancel: onClose, onCreate: handleCreateUser, profiles: profiles, title: t("users.newUser"), visible: open, loading: loading, initialValues: { cpf: "", email: "", id: "", name: "", profile: null, profileId: null }, className: "new-admin-modal" }));
};
