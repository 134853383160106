import React from "react";
import styles from "./style.scss";
const ContractModalHeader = ({ identifier, subtitle, title, }) => {
    return (React.createElement("div", { className: styles.modalHeaderDialog },
        React.createElement("div", null,
            React.createElement("h2", { className: styles.modalTitleDialog }, title),
            React.createElement("div", { className: styles.companyDialog }, subtitle)),
        React.createElement("div", { "data-cy": "ccb-number-wrapper", className: styles.ccbNumber }, identifier)));
};
export default ContractModalHeader;
