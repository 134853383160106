import { __awaiter } from "tslib";
import React, { useState, useCallback, useEffect } from "react";
import { Col, Form, Input, Modal, Row, Spin, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { checkCPF, checkEmail } from "../../../../../shared/utils/formValidator";
import { MaskedInput } from "antd-mask-input";
import { ProfileSelect } from "../../../CustomSelects/ProfileSelect";
import styles from "./style.scss";
const InnerForm = ({ form, visible, title, onCancel, onCreate, okText, initialValues, profiles, loading, className, }) => {
    const { validateFields, getFieldDecorator } = form;
    const { t } = useTranslation();
    const [selectedProfile, setSelectedProfile] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (visible) {
            setErrorMessage("");
        }
    }, [visible]);
    const handleSubmit = useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        setErrorMessage("");
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                yield onCreate(Object.assign(Object.assign(Object.assign({}, initialValues), values), { profile: selectedProfile }), setErrorMessage);
            }
        }));
    }), [validateFields, onCreate, form, selectedProfile]);
    const handleCancel = useCallback(() => {
        onCancel();
        form.resetFields();
    }, [onCancel, form]);
    const handleProfileFilterChange = useCallback((profileId) => {
        const newSelectedProfile = profiles.find(({ id }) => id === profileId);
        setSelectedProfile(newSelectedProfile);
    }, [profiles]);
    useEffect(() => {
        if (initialValues) {
            setSelectedProfile(initialValues.profile);
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);
    return (React.createElement(Modal, { visible: visible, title: title, onCancel: handleCancel, onOk: handleSubmit, okText: okText || t("registerLabel"), width: 560, className: className },
        React.createElement(Form, { hideRequiredMark: true },
            React.createElement(Spin, { spinning: loading },
                React.createElement("div", null,
                    React.createElement(Form.Item, { className: styles.profileInput, label: "Perfil" },
                        React.createElement(ProfileSelect, { onProfileChange: handleProfileFilterChange, profiles: profiles, initialValue: selectedProfile === null || selectedProfile === void 0 ? void 0 : selectedProfile.id }))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 14 },
                        React.createElement(Form.Item, { label: t("nameLabel") }, getFieldDecorator("name", {
                            initialValue: initialValues ? initialValues.name : "",
                            rules: [
                                {
                                    required: true,
                                    message: t("nameLabel") + t("isRequiredLabel"),
                                },
                            ],
                        })(React.createElement(Input, { autoFocus: true, allowClear: true, maxLength: 300 })))),
                    React.createElement(Col, { span: 10 },
                        React.createElement(Form.Item, { label: "CPF" }, getFieldDecorator("cpf", {
                            initialValue: initialValues ? initialValues.cpf : "",
                            rules: [{ validator: checkCPF }],
                        })(React.createElement(MaskedInput, { mask: "111.111.111-11", allowClear: true, maxLength: 15 }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 14 },
                        React.createElement(Form.Item, { label: t("loginEmailLabel") }, getFieldDecorator("email", {
                            initialValue: initialValues ? initialValues.email : "",
                            rules: [
                                {
                                    validator: checkEmail,
                                    message: t("authentication.invalidEmailText"),
                                },
                            ],
                        })(React.createElement(Input, { allowClear: true, maxLength: 300 }))))),
                errorMessage && (React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 24 },
                        React.createElement(Alert, { message: errorMessage, type: "error", showIcon: true, closable: true, onClose: () => setErrorMessage("") }))))))));
};
export const AdminForm = Form.create({
    name: "admin_form",
})(InnerForm);
