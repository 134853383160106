import React from "react";
import { useTranslation } from "react-i18next";
import { EntitiesSelector } from "../../EntitiesSelector";
const CompaniesGroupSelector = ({ visible, initialValues, companiesGroups, onChange, }) => {
    const { t } = useTranslation();
    const handleChange = (entities) => {
        const companiesGroupsWithRelated = [];
        initialValues === null || initialValues === void 0 ? void 0 : initialValues.forEach((companiesGroup) => {
            const findCompaniesGroup = entities.find(({ id }) => id === companiesGroup.id);
            const hasRemoved = !findCompaniesGroup;
            if (hasRemoved) {
                companiesGroupsWithRelated.push({
                    companiesGroupId: companiesGroup.id,
                    related: false,
                });
            }
        });
        entities.forEach((companiesGroup) => {
            const hasAssociation = initialValues === null || initialValues === void 0 ? void 0 : initialValues.find(({ id }) => id === companiesGroup.id);
            if (!hasAssociation) {
                companiesGroupsWithRelated.push({
                    companiesGroupId: companiesGroup.id,
                    related: true,
                });
            }
        });
        return companiesGroupsWithRelated;
    };
    return (React.createElement(EntitiesSelector, { visible: visible, initialValues: initialValues, entities: companiesGroups, onChange: (entities) => {
            onChange(handleChange(entities));
        }, inputProps: {
            id: "companies_groups_input",
            placeholder: `${t("addLabel")} ${t("companiesGroups.title").toLowerCase()}...`,
            label: t("companiesGroups.title"),
            autoCompleteDataCy: "companies-groups-auto-complete-data-cy",
        } }));
};
export default CompaniesGroupSelector;
