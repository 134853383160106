import React from "react";
import { useTranslation } from "react-i18next";
import { EntitiesSelector } from "../../EntitiesSelector";
import { formatCnpj } from "../../../../shared/utils/formatters";
const CompanySelector = ({ visible, initialValues, companies, onChange, }) => {
    const { t } = useTranslation();
    return (React.createElement(EntitiesSelector, { visible: visible, initialValues: initialValues === null || initialValues === void 0 ? void 0 : initialValues.map((company) => (Object.assign(Object.assign({}, company), { description: company.cnpj }))), entities: companies.map((company) => (Object.assign(Object.assign({}, company), { description: company.cnpj }))), onChange: (entities) => {
            onChange(entities.map((entity) => (Object.assign(Object.assign({}, entity), { cnpj: entity.description }))));
        }, inputProps: {
            id: "companies_input",
            placeholder: `${t("addLabel")} ${t("companyLabel").toLowerCase()}...`,
            label: t("companiesLabel"),
            autoCompleteDataCy: "company-auto-complete-data-cy",
        }, formatters: { description: formatCnpj } }));
};
export default CompanySelector;
