import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../../shared/utils/formatters";
import RevertButton from "../RevertButton";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import styles from "./style.scss";
export const PaymentsTable = ({ handleRevertPayment, payments }) => {
    const { t } = useTranslation();
    const paymentsColumns = [
        {
            title: t("agreement.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date) => getDateLocalTimezoneFormat(date),
            width: 120,
        },
        {
            title: t("paymentDateLabel"),
            dataIndex: "paidDate",
            key: "paidDate",
            render: (date) => getDateLocalTimezoneFormat(date),
            width: 190,
        },
        {
            title: t("amortizationLabel"),
            dataIndex: "paidByAmortization",
            key: "paidByAmortization",
            render: (paidByAmortization) => (paidByAmortization ? "Sim" : "Não"),
        },
        {
            title: t("grossValueLabel"),
            dataIndex: "grossValue",
            key: "grossValue",
            render: (grossValue) => formatStringCurrency(grossValue),
        },
        {
            title: t("paidValueLabel"),
            dataIndex: "paidValue",
            key: "paidValue",
            render: (paidValue) => formatStringCurrency(paidValue),
        },
        {
            title: t("revertLabel"),
            dataIndex: "reversed",
            key: "reversed",
            width: 120,
            render: (deleted, payment) => (React.createElement("div", { className: styles.revertButtonWrapper, "data-cy": "revert-button-wrapper-data-cy" },
                React.createElement(RevertButton, { disabled: deleted, handleClick: () => {
                        handleRevertPayment(payment);
                    } }))),
        },
    ];
    return (React.createElement("div", { className: styles.paymentsTableWrapper, "data-cy": "payments-table-data-cy" },
        React.createElement(Table, { className: styles.paymentsTable, columns: paymentsColumns, pagination: false, dataSource: payments, scroll: { y: 180 }, style: { height: 200 }, rowClassName: (payment) => payment.reversed ? String(styles.deletedPayment) : "" })));
};
