import { Form, Input, Radio, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkCPF, checkRG } from "../../../../../shared/utils/formValidator";
import { civilStatusOptions, marriageRegimeOptions, } from "../../../../containers/SimulatorRequest/Utils/civilStatus";
import styles from "../style.scss";
const CivilStatusForm = ({ getFieldDecoratorName, form, guarantor, }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    function isMarried() {
        const civilStatus = form.getFieldValue(getFieldDecoratorName("civilStatus"));
        return civilStatus === "married";
    }
    function isSingleOrDivorcedOrWidower() {
        const civilStatus = form.getFieldValue(getFieldDecoratorName("civilStatus"));
        return (civilStatus === "single" ||
            civilStatus === "divorcee" ||
            civilStatus === "separate" ||
            civilStatus === "widow");
    }
    function isCommunityOfGoods() {
        const marriageRegime = form.getFieldValue(getFieldDecoratorName("marriageRegime"));
        return marriageRegime === "partialCommunion" || marriageRegime === "universalCommunion";
    }
    function isUnionStable() {
        const stableUnion = form.getFieldValue(getFieldDecoratorName("stableUnion"));
        return stableUnion === true || stableUnion === "true";
    }
    function renderSpouseFields() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Form.Item, { label: t("spouseNameLabel"), className: styles.largeFields, "data-testid": "spouse-name" }, getFieldDecorator(getFieldDecoratorName("spouseName"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("spouseNameLabel"),
                        }),
                    },
                ],
                initialValue: guarantor === null || guarantor === void 0 ? void 0 : guarantor.spouseName,
            })(React.createElement(Input, { placeholder: t("spouseNameLabel") }))),
            React.createElement(Form.Item, { label: t("spouseCpfLabel"), className: styles.shortFields, "data-testid": "spouse-cpf" }, getFieldDecorator(getFieldDecoratorName("spouseCpf"), {
                rules: [{ required: true, validator: checkCPF }],
                initialValue: guarantor === null || guarantor === void 0 ? void 0 : guarantor.spouseCpf,
            })(React.createElement(MaskedInput, { mask: "111.111.111-11", allowClear: true, maxLength: 15 }))),
            React.createElement(Form.Item, { label: t("spouseRgLabel"), className: styles.shortFields, "data-testid": "spouse-rg" }, getFieldDecorator(getFieldDecoratorName("spouseRg"), {
                rules: [{ required: true, validator: checkRG }],
                initialValue: guarantor === null || guarantor === void 0 ? void 0 : guarantor.spouseRg,
            })(React.createElement(Input, { placeholder: t("spouseRgLabel") })))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { label: t("civilStatusLabel"), className: styles.selectShortFields }, getFieldDecorator(getFieldDecoratorName("civilStatus"), {
            rules: [
                {
                    required: true,
                    message: t("mandatoryField", {
                        field: t("civilStatusLabel"),
                    }),
                },
            ],
            initialValue: guarantor === null || guarantor === void 0 ? void 0 : guarantor.civilStatus,
        })(React.createElement(Select, { className: styles.shortFields, placeholder: t("civilStatusLabel"), "data-testid": "civil-status-select" }, civilStatusOptions.map((option) => (React.createElement(Select.Option, { key: option.label, value: option.value }, option.label)))))),
        isSingleOrDivorcedOrWidower() && (React.createElement(Form.Item, { label: t("isStableUnionLabel"), "data-testid": "is-stable-union" }, getFieldDecorator(getFieldDecoratorName("stableUnion"), {
            rules: [
                {
                    required: true,
                    message: t("mandatoryField", {
                        field: t("isStableUnionLabel"),
                    }),
                },
            ],
            initialValue: guarantor === null || guarantor === void 0 ? void 0 : guarantor.stableUnion,
        })(React.createElement(Radio.Group, null,
            React.createElement(Radio.Button, { value: true, className: styles.radioOption }, t("yesLabel")),
            React.createElement(Radio.Button, { value: false, className: styles.radioOption }, t("noLabel")))))),
        isUnionStable() && renderSpouseFields(),
        isMarried() && (React.createElement(React.Fragment, null,
            React.createElement(Form.Item, { label: t("marriageRegimeLabel"), className: styles.selectLargeFields }, getFieldDecorator(getFieldDecoratorName("marriageRegime"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("marriageRegimeLabel"),
                        }),
                    },
                ],
                initialValue: guarantor === null || guarantor === void 0 ? void 0 : guarantor.marriageRegime,
            })(React.createElement(Select, { className: styles.largeFields, placeholder: t("marriageRegimeLabel"), "data-testid": "marriage-regime-select" }, marriageRegimeOptions.map((option) => (React.createElement(Select.Option, { key: option.label, value: option.value }, option.label)))))),
            isCommunityOfGoods() && renderSpouseFields()))));
};
export default CivilStatusForm;
