import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "antd/lib/form";
import { Col, Input, Row } from "antd";
import { MaskedInput } from "antd-mask-input";
import { checkCep } from "../../../../utils/formValidator";
const AddressForm = ({ form, parentName, initialValues, disabled, readOnly, }) => {
    const { getFieldDecorator, setFieldsValue } = form;
    const formKey = parentName ? `${parentName}.` : "";
    const { t } = useTranslation();
    const clearFormsInputs = () => {
        if (!!parentName) {
            setFieldsValue({
                [parentName]: {
                    address: {
                        cep: "",
                        city: "",
                        complement: "",
                        neighborhood: "",
                        number: "",
                        state: "",
                        street: "",
                    },
                },
            });
        }
        else {
            setFieldsValue({
                address: {
                    cep: "",
                    city: "",
                    complement: "",
                    neighborhood: "",
                    number: "",
                    state: "",
                    street: "",
                },
            });
        }
    };
    useEffect(() => {
        if (disabled) {
            clearFormsInputs();
        }
    }, [disabled]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { label: "CEP" }, getFieldDecorator(`${formKey}address.cep`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.cep,
                    rules: [
                        {
                            validator: checkCep,
                        },
                    ],
                })(React.createElement(MaskedInput, { autoFocus: true, mask: "11.111-111", disabled: disabled || readOnly })))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: t("streetLabel") }, getFieldDecorator(`${formKey}address.street`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.street,
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("streetLabel") }),
                        },
                    ],
                })(React.createElement(Input, { allowClear: true, placeholder: readOnly ? "" : t("streetPlaceholderLabel"), maxLength: 500, disabled: disabled || readOnly })))),
            React.createElement(Col, { span: 6 },
                React.createElement(Form.Item, { key: initialValues === null || initialValues === void 0 ? void 0 : initialValues.number, label: t("numberLabel") }, getFieldDecorator(`${formKey}address.number`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.number,
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("numberLabel") }),
                        },
                    ],
                })(React.createElement(Input, { maxLength: 7, placeholder: readOnly ? "" : "1029", disabled: disabled || readOnly }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 10 },
                React.createElement(Form.Item, { label: t("neighborhoodLabel") }, getFieldDecorator(`${formKey}address.neighborhood`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.neighborhood,
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("neighborhoodLabel") }),
                        },
                    ],
                })(React.createElement(Input, { allowClear: true, placeholder: readOnly ? "" : t("neighborhoodPlaceholderLabel"), disabled: disabled || readOnly })))),
            React.createElement(Col, { span: 11 },
                React.createElement(Form.Item, { label: t("cityLabel") }, getFieldDecorator(`${formKey}address.city`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.city,
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("cityLabel") }),
                        },
                    ],
                })(React.createElement(Input, { allowClear: true, placeholder: readOnly ? "" : t("cityPlaceHolder"), maxLength: 255, disabled: disabled || readOnly })))),
            React.createElement(Col, { span: 3 },
                React.createElement(Form.Item, { label: t("stateLabel") }, getFieldDecorator(`${formKey}address.state`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.state,
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", { field: t("stateLabel") }),
                        },
                    ],
                })(React.createElement(Input, { allowClear: true, placeholder: readOnly ? "" : t("statePlaceHolder"), maxLength: 50, disabled: disabled || readOnly }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 24 },
                React.createElement(Form.Item, { label: t("complementLabel") }, getFieldDecorator(`${formKey}address.complement`, {
                    initialValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues.complement,
                })(React.createElement(Input, { allowClear: true, placeholder: readOnly ? "" : t("complementPlaceholderLabel"), maxLength: 500, disabled: disabled || readOnly })))))));
};
export default AddressForm;
