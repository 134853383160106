import { __rest } from "tslib";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, DatePicker, Button } from "antd";
import s from "./style.scss";
import { formatCurrencyNoPrefix, toInt } from "../../../shared/utils/formatters";
import InputCurrency from "../../../shared/components/InputCurrency";
import { DateFormat, getDateLocalTimezoneFormat } from "../../../shared/utils/date";
const AmortizeLoanContractInnerForm = (_a) => {
    var { form, onSubmit, remainingInstallments, title, disableOkButton } = _a, rest = __rest(_a, ["form", "onSubmit", "remainingInstallments", "title", "disableOkButton"]);
    const [amortizeType, setAmortizeType] = React.useState("installments");
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const handleChange = (e) => {
        setAmortizeType(e.toString());
        handleEditing();
    };
    const handleEditing = () => {
        disableOkButton(true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                const options = Object.assign(Object.assign({}, values), { date: getDateLocalTimezoneFormat(values.date, DateFormat.ISO) });
                onSubmit(options);
            }
        });
    };
    const getRemainingInstallmentsOptions = (remaing) => {
        const options = [];
        for (let i = 1; i <= remaing; i++) {
            options.push(i);
        }
        return options;
    };
    return (React.createElement(Form, Object.assign({ className: s.form, layout: "vertical" }, rest),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 10 },
                React.createElement(Form.Item, { label: t("amortizeByLabel") }, getFieldDecorator("type", {
                    initialValue: "installments",
                    rules: [
                        {
                            required: true,
                            message: t("amortizeByLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(Select, { onChange: handleChange },
                    React.createElement(Select.Option, { value: "installments" }, t("installmentsLowerLabel")),
                    React.createElement(Select.Option, { value: "value" }, t("lowerCaseValueLabel")))))),
            React.createElement(Col, { span: 14 },
                React.createElement(Form.Item, { label: t("amortizingDateLabel") }, getFieldDecorator("date", {
                    rules: [
                        {
                            required: true,
                            message: t("amortizeDateRequiredMessage"),
                        },
                    ],
                })(React.createElement(DatePicker, { onChange: handleEditing, placeholder: "dd/mm/aaaa", format: "DD/MM/YYYY" }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 10 }, amortizeType === "installments" ? (React.createElement(Form.Item, { label: t("installmentsAmountLabel") }, getFieldDecorator("installments", {
                initialValue: 1,
                rules: [
                    {
                        required: true,
                        message: t("installmentsAmountRequiredMessage"),
                    },
                ],
            })(React.createElement(Select, { onChange: handleEditing }, getRemainingInstallmentsOptions(remainingInstallments).map((i) => (React.createElement(Select.Option, { key: i, value: i }, i))))))) : (React.createElement(Form.Item, { label: `${t("lowerCaseValueLabel")} (R$)` }, getFieldDecorator("value", {
                normalize: (value) => toInt(value),
                rules: [
                    {
                        required: true,
                        message: t("lowerCaseValueLabel") + t("isRequiredLabel"),
                    },
                ],
            })(React.createElement(InputCurrency, { onChange: handleEditing, placeholder: "1.000,00", min: 0, formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}` }))))),
            React.createElement(Col, { span: 14 },
                React.createElement(Form.Item, { label: t("amortizingOrderLabel") }, getFieldDecorator("order", {
                    rules: [
                        {
                            required: true,
                            message: t("amortizingOrderLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(Select, { onChange: handleEditing },
                    React.createElement(Select.Option, { value: "descending" }, t("oldestToNewestLabel")),
                    React.createElement(Select.Option, { value: "ascending" }, t("newestToOldestLabel"))))))),
        React.createElement(Button, { className: s.blueButton, onClick: handleSubmit, type: "default", htmlType: "submit" }, t("calculateLabel"))));
};
export const AmortizeLoanContractForm = Form.create({
    name: "amortize_contract_form",
})(AmortizeLoanContractInnerForm);
