import React from "react";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { Money } from "../../../../../shared/components/Icons/Money";
import { formatCurrencyNullIF } from "../../../../../shared/utils/formatters";
import { goBackTwoDecimalPlaces } from "../../../../../shared/utils/numbers";
import styles from "./style.scss";
const SimulationValueContainer = ({ priceSimulationPjResult, spinning, }) => {
    const { t } = useTranslation();
    const value = priceSimulationPjResult === null || priceSimulationPjResult === void 0 ? void 0 : priceSimulationPjResult.ccbValue;
    return (React.createElement("div", { className: styles.valueContainer },
        React.createElement("div", { className: styles.valueTitleContainer },
            React.createElement(Money, null),
            React.createElement("span", { "data-cy": "ccb-value-title-data-cy" }, t("simulator.ccbValueTitle"))),
        !spinning ? (React.createElement("div", { className: styles.valueWrapper },
            React.createElement("span", { "data-cy": "ccb-value-data-cy" }, formatCurrencyNullIF(goBackTwoDecimalPlaces(value))))) : (React.createElement(Skeleton, { className: styles.skeleton, active: true, paragraph: { rows: 1 }, title: false }))));
};
export default SimulationValueContainer;
