import { __awaiter } from "tslib";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
const SimulatorContext = createContext({});
const SimulatorProvider = observer(({ children }) => {
    const [contractDataValues, setContractDataValues] = useState(() => {
        const contractDataValue = localStorage.getItem("@ContractDataValues");
        return contractDataValue ? JSON.parse(contractDataValue) : {};
    });
    const [ccbPreviewUrl, setCcbPreviewUrl] = useState();
    const [guarantorsData, setGuarantors] = useState([]);
    const [guaranteesData, setGuarantees] = useState(null);
    const [conditions, setConditions] = useState({});
    const [opinion, setOpinion] = useState("");
    const [simulationToRemove, setSimulationToRemove] = useState(null);
    useEffect(() => {
        if (simulationToRemove) {
            deleteSimulationInLocalStorage(simulationToRemove);
        }
    }, [simulationToRemove]);
    useEffect(() => {
        const simulationId = contractDataValues.simulationId;
        const guarantorsItem = localStorage.getItem(`@Guarantors${simulationId}`);
        const guarantors = guarantorsItem ? JSON.parse(guarantorsItem) : [];
        setGuarantors(guarantors);
        const guaranteesItem = localStorage.getItem(`@Guarantees${simulationId}`);
        const guarantees = guaranteesItem ? Number(guaranteesItem) : null;
        setGuarantees(guarantees);
        const conditionsItem = localStorage.getItem(`@Conditions${simulationId}`);
        const conditionsparsed = conditionsItem ? JSON.parse(conditionsItem) : {};
        setConditions(conditionsparsed);
        const opinionItem = localStorage.getItem(`@Opinion${simulationId}`);
        const opinionparsed = opinionItem || "";
        setOpinion(opinionparsed);
    }, [contractDataValues]);
    const deleteSimulationInLocalStorage = useCallback((simulationIdToRemove) => {
        localStorage.removeItem(`@Guarantors${simulationIdToRemove}`);
        localStorage.removeItem(`@Guarantees${simulationIdToRemove}`);
        localStorage.removeItem(`@Conditions${simulationIdToRemove}`);
        localStorage.removeItem(`@Opinion${simulationIdToRemove}`);
        const simulationIdsItem = localStorage.getItem("@SimulationIds");
        const simulationIdsParsed = simulationIdsItem ? JSON.parse(simulationIdsItem) : [];
        const simulationIdsFiltered = simulationIdsParsed.filter((element) => element !== simulationIdToRemove);
        localStorage.setItem(`@SimulationIds`, JSON.stringify(simulationIdsFiltered));
        setSimulationToRemove(null);
    }, []);
    const setSimulatorRequestContractDataValues = useCallback((receivedValues) => __awaiter(void 0, void 0, void 0, function* () {
        setContractDataValues(receivedValues);
        localStorage.setItem("@ContractDataValues", JSON.stringify(receivedValues));
    }), []);
    const setCcbRequestPreviewUrl = useCallback((url) => __awaiter(void 0, void 0, void 0, function* () {
        setCcbPreviewUrl(url);
    }), []);
    const setGuarantorsData = useCallback((args) => {
        const { simulationId, guarantorsData } = args;
        if (guarantorsData) {
            setGuarantors(guarantorsData);
            const simulationIdsItem = localStorage.getItem(`@SimulationIds`);
            const simulationIdsParsed = simulationIdsItem
                ? JSON.parse(simulationIdsItem)
                : [];
            const alreadyHasSimulationId = simulationIdsParsed.includes(simulationId);
            const shouldRemoveSimulationId = !alreadyHasSimulationId && simulationIdsParsed.length === 5;
            if (shouldRemoveSimulationId) {
                setSimulationToRemove(simulationIdsParsed[0]);
            }
            localStorage.setItem(`@Guarantors${simulationId}`, JSON.stringify(guarantorsData));
            if (!alreadyHasSimulationId) {
                localStorage.setItem(`@SimulationIds`, JSON.stringify([...simulationIdsParsed, simulationId]));
            }
        }
    }, []);
    const setConditionsData = useCallback((args) => {
        const { conditionsData, simulationId } = args;
        if (conditionsData) {
            setConditions(conditionsData);
            localStorage.setItem(`@Conditions${simulationId}`, JSON.stringify(conditionsData));
        }
    }, []);
    const setOpinionData = useCallback((args) => {
        const { opinionData, simulationId } = args;
        if (opinionData) {
            setOpinion(opinionData);
            localStorage.setItem(`@Opinion${simulationId}`, opinionData);
        }
    }, []);
    const setGuaranteesData = useCallback((args) => {
        const { simulationId, guarantees } = args;
        if (guarantees) {
            setGuarantees(guarantees);
            localStorage.setItem(`@Guarantees${simulationId}`, guarantees.toString());
        }
    }, []);
    const contextValues = {
        contractDataValues,
        setSimulatorRequestContractDataValues,
        ccbPreviewUrl,
        setCcbRequestPreviewUrl,
        setGuarantorsData,
        guarantorsData,
        setGuaranteesData,
        guarantees: guaranteesData,
        setConditionsData,
        conditions,
        setOpinionData,
        opinion,
        deleteSimulationInLocalStorage,
    };
    return React.createElement(SimulatorContext.Provider, { value: contextValues }, children);
});
export { SimulatorContext, SimulatorProvider };
