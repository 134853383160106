import { __awaiter } from "tslib";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { FilterForm } from "../../../../shared/components/FilterForm";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../../shared/components/SectionFooter";
import { Pagination } from "../../../../shared/components/Pagination";
import { Button, Modal } from "antd";
import { Spin } from "antd";
import { useStores } from "../../../utils/mobx";
import ActionTable from "../../../../shared/components/ActionTable";
import { formatCpf } from "../../../../shared/utils/formatters";
import { defaultConfirmProps } from "../../../../shared/utils";
import { ContributorsForm } from "../ContributorsForm";
import { getCompaniesIds } from "../../../utils/company.utils";
import { AdminRoutes } from "../../../constants/routes.constants";
import styles from "./style.scss";
const confirm = Modal.confirm;
const emptyValues = {
    id: "",
    name: "",
    cpf: "",
    email: "",
    companies: [],
    companiesGroups: [],
};
const ContributorsList = observer(() => {
    const { t } = useTranslation();
    const { contributorsStore, companyStore, companiesGroupStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [contributors, setContributors] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companiesGroups, setCompaniesGroups] = useState([]);
    const [selectedContributor, setSelectedContributor] = useState(Object.assign({}, emptyValues));
    const [errorMessageForm, setErrorMessageForm] = useState("");
    const [showModalForm, setShowModalForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalContributors, setTotalContributors] = useState(0);
    const [searchTerm, setSearchTerm] = React.useState(null);
    const [companyName, setCompanyName] = useState(null);
    const columns = [
        {
            title: t("companyEmployee.name"),
            dataIndex: "name",
            key: "name",
            width: 300,
            fixed: "left",
        },
        {
            title: t("companiesLabel"),
            dataIndex: "associatedCompanies",
            key: "company",
            render: (companiesToRender) => {
                let companiesLength = null;
                if (companiesToRender) {
                    companiesLength = companiesToRender.length;
                }
                let contributorCompanies = "";
                if (companiesLength) {
                    contributorCompanies = companiesToRender[0].name;
                }
                return (React.createElement(React.Fragment, null,
                    contributorCompanies,
                    companiesLength > 1 && (React.createElement("span", { className: styles.companyBadge },
                        "+",
                        companiesLength - 1))));
            },
        },
        {
            title: t("companiesGroups.title"),
            dataIndex: "companiesGroups",
            key: "companiesGroup",
            render: (companiesGroupsToRender) => {
                let companiesGroupsLength = null;
                if (companiesGroupsToRender) {
                    companiesGroupsLength = companiesGroupsToRender.length;
                }
                let contributorCompaniesGroups = "";
                if (companiesGroupsLength) {
                    contributorCompaniesGroups = companiesGroupsToRender[0].name;
                }
                return (React.createElement(React.Fragment, null,
                    contributorCompaniesGroups,
                    companiesGroupsLength > 1 && (React.createElement("span", { className: styles.companyBadge },
                        "+",
                        companiesGroupsLength - 1))));
            },
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: t("companyEmployee.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: "",
            key: "actions",
            dataIndex: "actions",
        },
    ];
    {
        /* GET ALL COMPANIES BY API */
    }
    React.useEffect(() => {
        getAllContributors();
    }, [currentPage, rowsPerPage, searchTerm, companyName]);
    React.useEffect(() => {
        getAllCompanies();
        getAllCompaniesGroups();
    }, []);
    const handleCancelForm = () => {
        setShowModalForm(false);
    };
    const handleSubmitForm = (values, companiesAssociated, companiesGroupsWithRelated) => __awaiter(void 0, void 0, void 0, function* () {
        const companiesIds = getCompaniesIds(companiesAssociated);
        try {
            setLoadingForm(true);
            let savedContributor = null;
            if (selectedContributor.id) {
                savedContributor = yield contributorsStore.editContributor(Object.assign(Object.assign({ id: selectedContributor.id }, values), { companiesIds, companiesGroups: companiesGroupsWithRelated }));
                if (savedContributor) {
                    const newContributors = contributors.map((e) => savedContributor && e.id === savedContributor.id ? savedContributor : e);
                    setContributors(newContributors);
                    generalStore.setSuccessMessage(t("companyEmployee.successfulEditMessage"));
                }
            }
            else {
                savedContributor = yield contributorsStore.createContributor(Object.assign(Object.assign({}, values), { companiesIds, companiesGroups: companiesGroupsWithRelated }));
                if (savedContributor) {
                    setContributors([...contributors, savedContributor]);
                }
                generalStore.setSuccessMessage(t("companyEmployee.sucessfulRegisterMessage"));
            }
            setShowModalForm(false);
        }
        catch (error) {
            catchErrors(error, setErrorMessageForm, t("companyEmployee.registerErrorMessage"));
            return error;
        }
        finally {
            setLoadingForm(false);
        }
    });
    const getAllCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const companiesTotal = yield companyStore.getCompaniesTotal(null);
            const result = yield contributorsStore.getAllCompanies(searchTerm, 1, companiesTotal);
            setCompanies(result.companies);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const getAllCompaniesGroups = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const companiesGroupsTotal = yield companiesGroupStore.fetchCompaniesGroupsTotal({
                name: null,
                companyName: null,
            });
            const result = yield contributorsStore.getAllCompaniesGroups(null, 1, companiesGroupsTotal);
            setCompaniesGroups(result.companiesGroups);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("companiesGroups.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    {
        /* Get All contributors by API */
    }
    const getAllContributors = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const filters = {
                name: searchTerm,
                companyName,
            };
            setLoading(true);
            const total = yield contributorsStore.getContributorsTotal(filters);
            const contributorsList = yield contributorsStore.getAllEmployees(filters, currentPage, rowsPerPage);
            setContributors(contributorsList.hrEmployees);
            setTotalContributors(total);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("companyEmployee.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const hasContributors = loading === false && contributors.length > 0;
    {
        /* ITEMS TO FILTER FORM */
    }
    const items = [
        {
            key: "nome",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "companyName",
            name: "companyName",
            type: "input",
            placeholder: "Empresa/Grupo Empresarial",
        },
    ];
    {
        /* EDIT A CONTRIBUTOR */
    }
    const handleEdit = (contributor) => {
        setErrorMessageForm("");
        setSelectedContributor(contributor);
        setShowModalForm(true);
    };
    {
        /* DELETE A CONTRIBUTOR */
    }
    const handleDelete = (contributor) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: t("companyEmployee.deleteEmployeeLabel"), content: (React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    t("companyEmployee.removalConfirmationMessage"),
                    " ",
                    React.createElement("strong", null, contributor.name),
                    " ",
                    t("companyEmployee.nextRemovalContributorsMessage"),
                    "?"),
                React.createElement("p", null, t("companyEmployee.removalInfoMessage")))), width: 600, okText: t("removeLabel"), onOk: () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    setLoading(true);
                    yield contributorsStore.removeContributor(contributor.id);
                    const newContributors = contributors.filter((c) => c.id !== contributor.id);
                    setContributors(newContributors);
                    generalStore.setSuccessMessage(t("companyEmployee.sucessfulRemovalMessage"));
                }
                catch (error) {
                    catchErrors(error, generalStore.setErrorMessage, t("companyEmployee.removalApiErrorMessage"));
                    setShowModalForm(false);
                }
                finally {
                    setLoading(false);
                }
            }) }));
    };
    {
        /* FILTER FORM FUNCTION */
    }
    const applySearchTerm = (value) => {
        setCurrentPage(1);
        setRowsPerPage(10);
        setSearchTerm(value.name);
        setCompanyName(value.companyName);
    };
    return (
    // selectedCompany && (
    React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("companyEmployee.title"), hasBackButton: true, linkToBackButton: AdminRoutes.LoansRequests, description: ` ${t("contributors.description")}. `, actions: [
                {
                    label: t("companyEmployee.actionLabel"),
                    handleAction: () => {
                        setErrorMessageForm("");
                        setSelectedContributor(Object.assign({}, emptyValues));
                        setShowModalForm(true);
                    },
                },
            ] }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: applySearchTerm }),
            React.createElement(Spin, { spinning: loading }, contributors.length === 0 && !loading ? (React.createElement("h1", null,
                t("companyEmployee.noEmployeeMessage"),
                ".")) : (React.createElement(React.Fragment, null,
                React.createElement("div", { "data-cy": "colaborators-table-wrapper" },
                    React.createElement(ActionTable, { className: styles.table, columns: columns, loading: loading, scroll: { x: 1500 }, dataSource: contributors.map((contributor) => (Object.assign(Object.assign({}, contributor), { key: contributor.id, cpf: formatCpf(contributor.cpf), associatedCompanies: contributor.companies, actions: (React.createElement(React.Fragment, null,
                                React.createElement(Button, { type: "link", onClick: () => handleEdit(contributor), "data-cy": "edit-button-data-cy" }, t("editUpperLabel")),
                                " | ",
                                React.createElement(Button, { type: "link", onClick: () => handleDelete(contributor) }, t("removeUpperLabel")))) }))) })))))),
        React.createElement(SectionFooter, null, hasContributors && (React.createElement(Pagination, { current: currentPage, total: totalContributors, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } }))),
        React.createElement(ContributorsForm, { visible: showModalForm, title: `${selectedContributor.id ? t("editLabel") : t("newLabel")} ${t("companyEmployee.label")}`, onCancel: handleCancelForm, onCreate: handleSubmitForm, onCloseAlert: () => setErrorMessageForm(""), okText: selectedContributor.id ? t("saveLabel") : t("registerLabel"), initialValues: selectedContributor, companies: companies, companiesGroups: companiesGroups, loading: loadingForm, error: errorMessageForm })));
});
export default ContributorsList;
