import React from "react";
import { Row, Col } from "antd";
import { Navbar } from "../Navbar";
import { CredifitLogo } from "../../../shared/components/Icons/CredifitLogo";
import { getAdminNavbarRoutes } from "../../utils/routes.utils";
import styles from "./style.scss";
export const Header = ({ user }) => {
    return (React.createElement("header", { className: styles.header },
        React.createElement(Row, { type: "flex", justify: "center" },
            React.createElement(Col, { span: 23, className: styles.headerContainer },
                React.createElement(CredifitLogo, { width: 158, height: 42 }),
                React.createElement("div", null,
                    React.createElement(Navbar, { items: getAdminNavbarRoutes(user === null || user === void 0 ? void 0 : user.accessModules), user: user }))))));
};
