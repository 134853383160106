import React from "react";
import { useTranslation } from "react-i18next";
import { FilterForm } from "../../../../shared/components/FilterForm";
import styles from "./style.scss";
const BirthdayWithdrawalContractsFilterForm = ({ applyFilter }) => {
    const { t } = useTranslation();
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: t("cpfLabel"),
            inputMask: "111.111.111-11",
        },
        {
            key: "ccbNumber",
            name: "ccbNumber",
            type: "input",
            placeholder: t("ccbNumberLabel"),
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: "Status",
            mode: "multiple",
            selectOptions: [
                {
                    value: "active",
                    label: t("birthdayWithdrawalContracts.activeLabel"),
                },
                {
                    value: "deniedCaixa",
                    label: t("birthdayWithdrawalContracts.deniedCaixaLabel"),
                },
                {
                    value: "failedCaixa",
                    label: t("birthdayWithdrawalContracts.failedCaixaLabel"),
                },
                {
                    value: "processingCaixa",
                    label: t("birthdayWithdrawalContracts.processingCaixaLabel"),
                },
                {
                    value: "processingCredifit",
                    label: t("birthdayWithdrawalContracts.processingCredifitLabel"),
                },
                {
                    value: "canceledCredifit",
                    label: t("birthdayWithdrawalContracts.canceledCredifitLabel"),
                },
                {
                    value: "pendingIdentityValidations",
                    label: t("birthdayWithdrawalContracts.pendingIdentityValidationsLabel"),
                },
            ],
        },
        {
            key: "date",
            name: "data",
            type: "dateRange",
        },
    ];
    return React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: applyFilter });
};
export default BirthdayWithdrawalContractsFilterForm;
