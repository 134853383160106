import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input } from "antd";
import InputPhone from "../../../../../shared/components/InputPhone";
import DynamicForm from "../../../../components/DynamicForm";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const SupplierForm = observer(({ form, initialValues }) => {
    const { t } = useTranslation();
    const renderItem = (getFieldDecorator, itemName, initialValues) => (React.createElement(Row, { gutter: 20, key: itemName },
        React.createElement(Col, { span: 10 },
            React.createElement(Form.Item, { label: t("companyForm.providerLabel") }, getFieldDecorator(`${itemName}.name`, Object.assign({ initialValue: initialValues ? initialValues.name : null }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { allowClear: true, placeholder: t("companyForm.providerPlaceholder"), maxLength: 255 })))),
        React.createElement(Col, { span: 6 },
            React.createElement(Form.Item, { label: t("companyForm.telephoneLabel"), help: t("companyForm.telephoneHelp") }, getFieldDecorator(`${itemName}.phone`, Object.assign({ initialValue: initialValues ? initialValues.phone : "" }, decoratorConfigDefaultOnBlur))(React.createElement(InputPhone, null))))));
    return (React.createElement(DynamicForm, { title: t("companyForm.mainProvidersTitle"), addItemButtonText: t("companyForm.newProviderButtonLabel"), defaultEmptyValue: {
            id: null,
            name: null,
            phone: null,
        }, formKey: "suppliers", renderItem: renderItem, form: form, initialValues: initialValues }));
});
export default SupplierForm;
