exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.firstRow___3trQO {\n  display: grid;\n  grid-template-columns: 60% auto;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.secondRow___aWFhB {\n  display: grid;\n  grid-template-columns: auto 60%;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.selectionGroupsWrapper___2sy3Z {\n  display: flex;\n  grid-column-gap: 20px; }\n  .selectionGroupsWrapper___2sy3Z div {\n    max-width: 170px; }\n\n.footerWrapper___3Xj9a {\n  display: flex; }\n\n.addButton___2QIpl {\n  display: flex;\n  align-items: center; }\n  .addButton___2QIpl svg {\n    margin-right: 5px; }\n  .addButton___2QIpl:hover {\n    color: #0bc2d2; }\n    .addButton___2QIpl:hover path {\n      fill: #0bc2d2; }\n\n.cardWrapper___1SDf5 {\n  max-width: 870px;\n  margin-bottom: 20px; }\n  .cardWrapper___1SDf5 div[class$=\"card-base\"] {\n    box-shadow: none; }\n\n.buttonsWrapper___iEwUB {\n  display: flex;\n  justify-content: space-between; }\n", ""]);

// Exports
exports.locals = {
	"firstRow": "firstRow___3trQO",
	"secondRow": "secondRow___aWFhB",
	"selectionGroupsWrapper": "selectionGroupsWrapper___2sy3Z",
	"footerWrapper": "footerWrapper___3Xj9a",
	"addButton": "addButton___2QIpl",
	"cardWrapper": "cardWrapper___1SDf5",
	"buttonsWrapper": "buttonsWrapper___iEwUB"
};