export const companyGeneralSteps = [
    {
        title: "Dados da Empresa",
        description: "CNPJ, Razão Social, Endere...",
        dataCy: "step-company-data",
    },
    {
        title: "Atividade e Dados Financeiros",
        description: "Description",
        dataCy: "step-financial-data",
    },
    {
        title: "Sócio/Representante Legal",
        description: "Description",
        dataCy: "step-legal-responsible",
    },
    {
        title: "Instituições Financeiras onde mantém ou manteve operações de crédito",
        description: "Description",
        dataCy: "step-financial-institution",
    },
    {
        title: "Principais Fornecedores",
        description: "Description",
        dataCy: "step-supplier",
    },
    {
        title: "Informações Adicionais",
        description: "Description",
        dataCy: "step-additional-info",
    },
];
export const companySignatureSteps = [
    ...companyGeneralSteps,
    {
        title: "Assinatura eletrônica",
        description: "Área para assinatura eletrônica das informações visualizadas",
        dataCy: "eletronic-signature",
    },
];
var CompanyStepsIndexes;
(function (CompanyStepsIndexes) {
    CompanyStepsIndexes[CompanyStepsIndexes["COMPANY_DATA_STEP_INDEX"] = 0] = "COMPANY_DATA_STEP_INDEX";
    CompanyStepsIndexes[CompanyStepsIndexes["FINANCIAL_DATA_STEP_INDEX"] = 1] = "FINANCIAL_DATA_STEP_INDEX";
    CompanyStepsIndexes[CompanyStepsIndexes["LEGAL_RESPONSIBLE_STEP_INDEX"] = 2] = "LEGAL_RESPONSIBLE_STEP_INDEX";
    CompanyStepsIndexes[CompanyStepsIndexes["FINANCIAL_INSTITUITIONS_STEP_INDEX"] = 3] = "FINANCIAL_INSTITUITIONS_STEP_INDEX";
    CompanyStepsIndexes[CompanyStepsIndexes["SUPPLIERS_STEP_INDEX"] = 4] = "SUPPLIERS_STEP_INDEX";
    CompanyStepsIndexes[CompanyStepsIndexes["ADITIONAL_INFORMATIONS_STEP_INDEX"] = 5] = "ADITIONAL_INFORMATIONS_STEP_INDEX";
})(CompanyStepsIndexes || (CompanyStepsIndexes = {}));
var SignatureStepsIndexes;
(function (SignatureStepsIndexes) {
    SignatureStepsIndexes[SignatureStepsIndexes["ELETRONIC_SIGNATURE_STEP_INDEX"] = 6] = "ELETRONIC_SIGNATURE_STEP_INDEX";
})(SignatureStepsIndexes || (SignatureStepsIndexes = {}));
export const { ADITIONAL_INFORMATIONS_STEP_INDEX, COMPANY_DATA_STEP_INDEX, ELETRONIC_SIGNATURE_STEP_INDEX, FINANCIAL_DATA_STEP_INDEX, FINANCIAL_INSTITUITIONS_STEP_INDEX, LEGAL_RESPONSIBLE_STEP_INDEX, SUPPLIERS_STEP_INDEX, } = Object.assign(Object.assign({}, CompanyStepsIndexes), SignatureStepsIndexes);
