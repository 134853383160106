import i18n from "../i18n";
export const formatUserStatus = (status) => {
    switch (status) {
        case "registered":
        case "pendingEmail":
        case "pendingPassword":
        case "pendingTermsOfUse":
        case "blocked":
        case "unhired":
        case "suspended":
        case "pendingIdentityValidations":
            return i18n.t(`users.status.${status}Label`);
        default:
            return i18n.t("defaultNullFieldPlaceholder");
    }
};
export const formatUserSituation = (status) => {
    switch (status) {
        case "hired":
        case "unhired":
            return i18n.t(`users.status.${status}Label`);
        default:
            return i18n.t("defaultNullFieldPlaceholder");
    }
};
