import { __awaiter } from "tslib";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import styles from "./style.scss";
import { Form, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
export const CloseDigitalAccountInnerModal = ({ onCancel, onSuccess, visible, closeDigitalAccountParams, form, }) => {
    const { t } = useTranslation();
    const { getFieldDecorator, validateFields } = form;
    const { userReportStores, generalStore: { setErrorMessage, catchErrors, setSuccessMessage }, } = useStores();
    const closeDigitalAccount = (reason) => __awaiter(void 0, void 0, void 0, function* () {
        const { userId, cpf } = closeDigitalAccountParams;
        if (cpf && userId) {
            try {
                yield userReportStores.closeDigitalAccount({
                    cpf,
                    requestId: userId,
                    closeAccountReason: reason,
                });
                setSuccessMessage(t("users.closeDigitalAccountuccessMessage"));
                onSuccess();
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("users.defaultCloseDigitalAccountErrorMessage"));
                onCancel();
            }
        }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                closeDigitalAccount(values.reason);
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { className: styles.modal, visible: visible, title: t("closeDigitalAccountLabel"), onCancel: onCancel, onOk: handleSubmit, destroyOnClose: true, maskClosable: true, width: "40%", okText: t("confirmLabel") },
            React.createElement(Form, { layout: "vertical", hideRequiredMark: true },
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Form.Item, { "data-cy": "reason-wrapper", label: t("reasonLabel") }, getFieldDecorator("reason", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("reasonLabel") }),
                            },
                        ],
                    })(React.createElement(TextArea, { style: { height: 120 }, placeholder: t("reasonPlaceholder"), allowClear: true }))))))));
};
export const CloseDigitalAccountModal = Form.create({
    name: "close_digital_account_modal",
})(CloseDigitalAccountInnerModal);
