import React from "react";
import { useTranslation } from "react-i18next";
import { formatUserStatus } from "../../../utils/userReport.utils";
import { FilterForm } from "../../../../shared/components/FilterForm";
import styles from "./style.scss";
const UserReportFilterForm = ({ applyFilter }) => {
    const { t } = useTranslation();
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: t("cpfLabel"),
            inputMask: "111.111.111-11",
        },
        {
            key: "companyName",
            name: "companyName",
            type: "input",
            placeholder: t("companyLabel"),
        },
        {
            key: "hiringSituation",
            name: "hiringSituation",
            type: "select",
            selectDefaultValue: "",
            selectOptions: [
                {
                    value: "",
                    label: t("situationLabel"),
                },
                {
                    value: "hired",
                    label: t("users.status.hiredLabel"),
                },
                {
                    value: "unhired",
                    label: t("users.status.unhiredLabel"),
                },
            ],
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: t("statusLabel"),
            mode: "multiple",
            selectOptions: [
                {
                    value: "registered",
                    label: formatUserStatus("registered"),
                },
                {
                    value: "pendingEmail",
                    label: formatUserStatus("pendingEmail"),
                },
                {
                    value: "pendingPassword",
                    label: formatUserStatus("pendingPassword"),
                },
                {
                    value: "blocked",
                    label: formatUserStatus("blocked"),
                },
                {
                    value: "pendingTermsOfUse",
                    label: formatUserStatus("pendingTermsOfUse"),
                },
                {
                    value: "suspended",
                    label: formatUserStatus("suspended"),
                },
                {
                    value: "pendingIdentityValidations",
                    label: formatUserStatus("pendingIdentityValidations"),
                },
                {
                    value: "unhired",
                    label: formatUserStatus("unhired"),
                },
            ],
        },
    ];
    return React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: applyFilter });
};
export default UserReportFilterForm;
