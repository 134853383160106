import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import StepContent from "../../../../../components/Simulator/StepContent";
import { CcbPjStep } from "../../Steps/steps";
import { hasErrors } from "../../../../../../shared/utils/form.utils";
import RequestBottomActions from "../../../../../components/Simulator/RequestBottomActions";
import { Typograph } from "../../../../../../shared/components/Typograph";
import StepContentCard from "../../../../../components/Simulator/StepContentCard";
import { SemanticButton } from "../../../../../../shared/components/SemanticButton";
import { Minus } from "../../../../../../shared/components/Icons/Minus";
import { Plus } from "../../../../../../shared/components/Icons/Plus";
import GuarantorForm from "../../../../../components/Simulator/GuarantorForm";
import { getReversedOrder } from "../../../../../../shared/utils/colection";
import styles from "./styles.scss";
import { SimulatorContext } from "../../../../../../admin/contexts/SimulatorContext";
export const Guarantors = observer(({ currentStep, navigateToNextStep, form, onBackButtonClick }) => {
    const { contractDataValues, setGuarantorsData, guarantorsData } = useContext(SimulatorContext);
    const { t } = useTranslation();
    const { validateFieldsAndScroll, getFieldsValue } = form;
    const [fieldsWithError, setFieldsWithError] = useState([]);
    const [guarantors, setGuarantors] = useState([1]);
    const [displayedGuarantor, setDisplayedGuarantor] = useState(1);
    useEffect(() => {
        if (guarantorsData && guarantorsData.length > 0) {
            const guarantorsQte = guarantorsData.map((_, index) => index + 1);
            setGuarantors(guarantorsQte);
        }
    }, [guarantorsData]);
    const handleNextStep = () => {
        validateFieldsAndScroll(["guarantors"], (errors) => {
            if (hasErrors(errors)) {
                const guarantorsWithErrors = errors.guarantors;
                const guarantorFieldsKeys = guarantorsWithErrors.map((_, index) => index + 1);
                const guarantorsFieldsKeysHided = guarantorFieldsKeys.filter((fieldKey) => !isHide(fieldKey));
                setFieldsWithError(guarantorsFieldsKeysHided);
                return;
            }
            const guarantorsValue = getFieldsValue(["guarantors"]).guarantors;
            setGuarantorsData({
                guarantorsData: guarantorsValue,
                simulationId: contractDataValues.simulationId,
            });
            navigateToNextStep();
        });
    };
    const removePendencyIconFromCard = (currentCardKey) => {
        setFieldsWithError((oldValue) => oldValue.filter((field) => field !== currentCardKey));
    };
    const isHide = (currentGuaranteeKey) => {
        return displayedGuarantor === currentGuaranteeKey;
    };
    const handleAddGuarantor = () => {
        setGuarantors((oldValue) => {
            const newGuarantor = oldValue.length + 1;
            setDisplayedGuarantor(newGuarantor);
            return [...oldValue, newGuarantor];
        });
    };
    const handleRemoveLastGuarantor = () => {
        setGuarantors((oldValue) => {
            const lastGuarantorIndex = oldValue.length - 1;
            if (lastGuarantorIndex < 0) {
                return oldValue;
            }
            setDisplayedGuarantor(lastGuarantorIndex);
            return oldValue.filter((_, index) => index !== lastGuarantorIndex);
        });
    };
    const hasError = (currentGuaranteeKey) => {
        return !!fieldsWithError.find((fieldWithErrorKey) => fieldWithErrorKey === currentGuaranteeKey);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContent, { hided: currentStep === CcbPjStep.GUARANTORS, dataCy: "guarantors-data-cy" },
            React.createElement(StepContentCard, { title: React.createElement(Typograph, { variant: "modalTitle" }, t("guarantorsStep.title")), titleActions: React.createElement(React.Fragment, null,
                    React.createElement(SemanticButton, { className: styles.removeButtonWithIcon, onClick: handleRemoveLastGuarantor, disabled: guarantors.length === 0 },
                        React.createElement(Typograph, { variant: "modalTitle" }, t("guarantorsStep.removeLastGuarantor")),
                        React.createElement("span", { className: styles.iconWrapper },
                            React.createElement(Minus, null))),
                    React.createElement(SemanticButton, { className: styles.buttonWithIcon, onClick: handleAddGuarantor },
                        React.createElement(Typograph, { variant: "modalTitle" }, t("guarantorsStep.addGuarantor")),
                        React.createElement("span", { className: styles.iconWrapper },
                            React.createElement(Plus, null)))) },
                React.createElement("div", { className: styles.cardsWrapper }, getReversedOrder(guarantors).map((currentGuarantorKey, index) => (React.createElement("div", { key: currentGuarantorKey, "data-testid": `guarantor-${currentGuarantorKey}` },
                    React.createElement(GuarantorForm, { title: t("guarantorsStep.guarantor", { number: currentGuarantorKey }), hide: isHide(currentGuarantorKey), guarantorValue: guarantorsData && guarantorsData[index], onToogleValue: () => {
                            setDisplayedGuarantor(currentGuarantorKey);
                            removePendencyIconFromCard(currentGuarantorKey);
                        }, form: form, index: currentGuarantorKey, hasPendencies: hasError(currentGuarantorKey) })))))),
            React.createElement(RequestBottomActions, { onBackClick: onBackButtonClick, onNextClick: handleNextStep }))));
});
