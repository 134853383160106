import i18n from "../../i18n";
export const repurchaseTableColumns = [
    {
        title: i18n.t("installmentLabel"),
        dataIndex: "number",
        key: "number",
        width: 100,
    },
    {
        title: i18n.t("charge.dueDateLabel"),
        dataIndex: "dueDate",
        key: "dueDate",
    },
    {
        title: i18n.t("chargeReport.installmentValue"),
        dataIndex: "value",
        key: "value",
    },
    {
        title: i18n.t("openValueLabel"),
        dataIndex: "remainingValue",
        key: "remainingValue",
    },
];
