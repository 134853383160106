import React from "react";
import { useTranslation } from "react-i18next";
import { DateFormat } from "../../../../../shared/utils/date";
import { formatCurrency, formatBoolean, formatCnpj, formatCurrencyNoPrefix, formatCurrencyNoPrefixFiveDecimals, } from "../../../../../shared/utils/formatters";
import { goBackFiveDecimalPlaces, goBackTwoDecimalPlaces } from "../../../../../shared/utils/numbers";
import styles from "./style.scss";
export const SimulationDownloadHeaderFields = ({ downloadSimulationData, }) => {
    var _a;
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.header },
            React.createElement("div", { className: styles.titleGrid },
                React.createElement("div", null, t("companyLabel")),
                React.createElement("div", null, t("cnpjLabel")),
                React.createElement("div", null, t("requestValueLabel")),
                React.createElement("div", null, t("installmentsQuantityLabel")),
                React.createElement("div", null, t("loanApplication.valueCreditDateLabel"))),
            React.createElement("div", { className: styles.subtitleGrid },
                React.createElement("div", null, downloadSimulationData.companyName),
                React.createElement("div", null, formatCnpj(downloadSimulationData.cnpj)),
                React.createElement("div", null, formatCurrency(goBackTwoDecimalPlaces(downloadSimulationData.requestedAmount))),
                React.createElement("div", null, downloadSimulationData.installmentsQuantity),
                React.createElement("div", null, (_a = downloadSimulationData.valueCreditDate) === null || _a === void 0 ? void 0 : _a.format(DateFormat.BR)))),
        React.createElement("div", { className: styles.header },
            React.createElement("div", { className: styles.titleGrid },
                React.createElement("div", null, t("loanApplication.interestRatePerMonthLabel")),
                React.createElement("div", null, t("agreement.structuringRateLabel")),
                React.createElement("div", null, t("simulator.seguroPrestamista")),
                React.createElement("div", null, t("simulator.tedValue")),
                React.createElement("div", null, t("simulator.otherTaxes"))),
            React.createElement("div", { className: styles.subtitleGrid },
                React.createElement("div", null, formatCurrencyNoPrefix(goBackTwoDecimalPlaces(downloadSimulationData.interestRatePerMonth))),
                React.createElement("div", null, formatCurrencyNoPrefixFiveDecimals(goBackFiveDecimalPlaces(downloadSimulationData.structuringRate))),
                React.createElement("div", null, formatBoolean(!!downloadSimulationData.joinSeguroPrestamista)),
                React.createElement("div", null, formatCurrency(goBackTwoDecimalPlaces(downloadSimulationData.tedValue))),
                React.createElement("div", null, formatCurrency(goBackTwoDecimalPlaces(downloadSimulationData.thirdPartyValue)))))));
};
