import React, { useContext } from "react";
import { CcbPjStep } from "../../Steps/steps";
import StepContent from "../../../../../components/Simulator/StepContent";
import RequestBottomActions from "../../../../../components/Simulator/RequestBottomActions";
import { hasErrors } from "../../../../../../shared/utils/form.utils";
import { GuaranteesForm } from "../../../../../../admin/components/Simulator/GuaranteesForm";
import { observer } from "mobx-react-lite";
import { SimulatorContext } from "../../../../../../admin/contexts/SimulatorContext";
export const Guarantees = observer(({ currentStep, navigateToNextStep, navigateToPreviousStep, form }) => {
    const { validateFieldsAndScroll, getFieldsValue } = form;
    const { setGuaranteesData, guarantees, contractDataValues } = useContext(SimulatorContext);
    const handleNextStep = () => {
        validateFieldsAndScroll(["qteGuarantees"], (errors) => {
            if (hasErrors(errors)) {
                return;
            }
            const guaranteesData = getFieldsValue(["qteGuarantees"]).qteGuarantees;
            setGuaranteesData({
                guarantees: guaranteesData,
                simulationId: contractDataValues.simulationId,
            });
            navigateToNextStep();
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContent, { hided: currentStep === CcbPjStep.GUARANTEES, dataCy: "guarantees-data-cy" },
            React.createElement(GuaranteesForm, { form: form, guarantees: guarantees }),
            React.createElement(RequestBottomActions, { onBackClick: navigateToPreviousStep, onNextClick: handleNextStep }))));
});
