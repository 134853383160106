import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ButtonWithPreventDefault } from "../../../../shared/components/ButtonWithPreventDefault";
import styles from "./style.scss";
const OpinionStepBottomActions = ({ onBackClick, onApprovalClick, onReprovalClick, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.bottomNavigatorWrapper },
        React.createElement(ButtonWithPreventDefault, { type: "default", handleClick: onBackClick, "data-testid": "back-button" }, t("backLabel")),
        React.createElement("div", { className: styles.rightBottomNavigatorWrapper },
            React.createElement(ButtonWithPreventDefault, { type: "primary", className: classNames(styles.redButton, styles.button), handleClick: onReprovalClick, "data-testid": "reproval-button" }, t("opinionStep.reprovalButtonLabel")),
            React.createElement(ButtonWithPreventDefault, { type: "primary", className: classNames(styles.blueButton, styles.button), handleClick: onApprovalClick, "data-testid": "approval-button" }, t("opinionStep.approvalButtonLabel")))));
};
export default OpinionStepBottomActions;
