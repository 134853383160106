import { __awaiter } from "tslib";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { CompaniesGroupContext } from "../../../contexts/CompaniesGroupContext";
import { useStores } from "../../../utils/mobx";
import HrEmployeesShortTable from "../../../components/HrEmployee/HrEmployeeShortTable";
import { getCompaniesIds } from "../../../utils/company.utils";
import { AdminRoutes } from "../../../constants/routes.constants";
const CompaniesGroupEmployees = () => {
    const { match: { params: { id }, }, } = useReactRouter();
    const { t } = useTranslation();
    const { generalStore: { catchErrors }, companyStore, generalStore, companiesGroupStore, } = useStores();
    const { selectedCompaniesGroup, setCompaniesGroupId, companiesGroupId } = useContext(CompaniesGroupContext);
    const [hrEmployees, setHrEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchHrEmployees = (companiesGroupIdToFetch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const fetchedHrEmployees = yield companiesGroupStore.fetchHrEmployesByCompaniesGroupId(companiesGroupIdToFetch);
            setHrEmployees(fetchedHrEmployees);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("companyEmployee.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        setCompaniesGroupId(id);
    }, [id]);
    useEffect(() => {
        if (selectedCompaniesGroup) {
            fetchHrEmployees(selectedCompaniesGroup.id);
        }
    }, [selectedCompaniesGroup]);
    const handleRemove = (employee) => __awaiter(void 0, void 0, void 0, function* () {
        const companiesIds = getCompaniesIds(employee === null || employee === void 0 ? void 0 : employee.companies);
        if (selectedCompaniesGroup) {
            yield companyStore.editHREmployee(Object.assign(Object.assign({}, employee), { companiesIds, companiesGroups: [
                    {
                        companiesGroupId: selectedCompaniesGroup.id,
                        related: false,
                    },
                ] }));
        }
    });
    return (selectedCompaniesGroup && (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: `${AdminRoutes.CompaniesGroups.Actions}/${companiesGroupId}`, title: t("companyEmployee.title"), description: `${t("companiesGroups.employee.description")} ${selectedCompaniesGroup === null || selectedCompaniesGroup === void 0 ? void 0 : selectedCompaniesGroup.name}` }),
        React.createElement(HrEmployeesShortTable, { initialEmployees: hrEmployees, initialLoading: loading, onRemove: handleRemove, removeModalProps: {
                removeConfirmationMessage: (React.createElement(React.Fragment, null,
                    t("companiesGroups.employee.nextRemovalConfirmationMessage"),
                    " ",
                    React.createElement("strong", null, selectedCompaniesGroup ? selectedCompaniesGroup.name : ""))),
            }, sectionProps: {
                noEmployeeMessage: (React.createElement(React.Fragment, null,
                    t("companyEmployee.noEmployeeMessage"),
                    " ",
                    selectedCompaniesGroup.name,
                    ".")),
            } }))));
};
export default CompaniesGroupEmployees;
