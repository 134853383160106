import { __awaiter } from "tslib";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, DatePicker, Form, Input, Row, Spin } from "antd";
import InputCurrency from "../../../../../shared/components/InputCurrency";
import { getToday } from "../../../../../shared/utils/date";
import { useStores } from "../../../../utils/mobx";
import styles from "./style.scss";
export const CessionForm = ({ cession, form, inputName, onDateChange, }) => {
    const [loading, setLoading] = useState(false);
    const { cessionReportStore: { calculateCessionValueByDate }, } = useStores();
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    const calculateCessionValueByDateFromApi = (date, loanId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const cessionBalanceByDate = yield calculateCessionValueByDate(date, loanId);
        onDateChange(cessionBalanceByDate);
        setLoading(false);
    });
    const handleDateChange = (date, loanId) => __awaiter(void 0, void 0, void 0, function* () {
        if (date) {
            calculateCessionValueByDateFromApi(date, loanId);
        }
        else {
            onDateChange(0);
        }
    });
    return (React.createElement(Spin, { spinning: loading },
        React.createElement("div", { className: styles.cessionInputItem, key: cession.id },
            React.createElement(Row, { gutter: 20 },
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { label: "Valor da Cess\u00E3o (R$):" }, getFieldDecorator(`${inputName}.cessionValue`, {
                        rules: [
                            {
                                required: true,
                                message: "Para confirmar a cessão dos contrato é necessário informar o valor da cessão.",
                            },
                        ],
                    })(React.createElement(InputCurrency, { "data-cy": "cession-value-input", style: { marginBottom: -13 }, min: 0 })))),
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { "data-cy": "cession-date-wrapper", label: t("assignmentDate") }, getFieldDecorator(`${inputName}.cessionDate`, {
                        initialValue: getToday(),
                        rules: [
                            {
                                required: true,
                                message: t("cession.assingmentDateRequiredMessage"),
                            },
                        ],
                    })(React.createElement(DatePicker, { className: styles.date, showToday: true, format: "DD/MM/YYYY", allowClear: true, placeholder: t("assignmentDatePlaceholder"), "data-cy": "cession-date-input", onChange: (date) => {
                            handleDateChange(date, cession.loanId);
                        } })))),
                React.createElement(Col, null,
                    React.createElement(Form.Item, { style: { display: "none" } }, getFieldDecorator(`${inputName}.cessionId`, {
                        initialValue: cession.id,
                    })(React.createElement(Input, { type: "hidden" }))))))));
};
