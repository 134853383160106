import React from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { MaskedInput } from "antd-mask-input";
import { Typograph } from "../../../../shared/components/Typograph";
import { HideableContainer } from "../../../../shared/components/HideableContainer";
import { getFormItemName } from "../../../../shared/utils/form.utils";
import { checkCep, checkCPF } from "../../../../shared/utils/formValidator";
import CivilStatusForm from "./CivilStatusForm";
import styles from "./style.scss";
import { getTodayDateLocalTimezoneFormat, toMoment } from "../../../../shared/utils/date";
const documentTypeOptions = [
    "RG",
    "CNH",
    "CRC",
    "OAB",
    "CRA",
    "CRM",
    "CRO",
];
const GuarantorForm = ({ index, title, onToogleValue, hide, guarantorValue, form, hasPendencies, }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    const fieldName = getFormItemName("guarantors", index - 1);
    const getFieldDecoratorName = (property) => `${fieldName}.${property}`;
    return (React.createElement(HideableContainer, { title: React.createElement("div", { className: styles.titleWrapper },
            React.createElement(Typograph, { variant: "modalTitle", className: styles.title }, title),
            hasPendencies && (React.createElement("div", { className: styles.pendenciesAlert },
                React.createElement("div", { className: styles.circle }),
                " ",
                t("pendenciesLabel")))), buttonProps: { arrowClassName: styles.hideButton }, className: styles.mainContainer, onTooglehide: onToogleValue, hide: hide },
        React.createElement("div", { className: styles.formFields },
            React.createElement(Form.Item, { label: t("nameLabel"), className: styles.largeFields }, getFieldDecorator(getFieldDecoratorName("name"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("nameLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.name,
            })(React.createElement(Input, { placeholder: t("nameLabel"), "data-testid": "name" }))),
            React.createElement(Form.Item, { label: "CPF", className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("cpf"), {
                rules: [
                    {
                        required: true,
                        message: " ",
                    },
                    { validator: checkCPF },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.cpf,
            })(React.createElement(MaskedInput, { mask: "111.111.111-11", allowClear: true, maxLength: 15, "data-testid": "cpf" }))),
            React.createElement(Form.Item, { label: t("nationalityLabel"), className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("nationality"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("nationalityLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.nationality,
            })(React.createElement(Input, { placeholder: t("nationalityLabel"), "data-testid": "nationality" }))),
            React.createElement(Form.Item, { label: t("placeOfBirthLabel"), className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("placeOfBirth"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("placeOfBirthLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.placeOfBirth,
            })(React.createElement(Input, { placeholder: t("placeOfBirthLabel"), "data-testid": "placeOfBirth" }))),
            React.createElement(Form.Item, { label: t("documentLabel"), className: styles.selectShortFields }, getFieldDecorator(getFieldDecoratorName("documentType"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("documentLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.documentType,
            })(React.createElement(Select, { className: styles.shortFields, "data-testid": "documentType", placeholder: t("documentLabel") }, documentTypeOptions.map((option) => (React.createElement(Select.Option, { key: option, value: option }, option)))))),
            React.createElement(Form.Item, { label: t("documentNumberLabel"), className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("documentNumber"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("documentNumberLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.documentNumber,
            })(React.createElement(Input, { placeholder: t("documentNumberLabel"), "data-testid": "documentNumber" }))),
            React.createElement(Form.Item, { label: t("dispatcherLabel"), className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("dispatcher"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("dispatcherLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.dispatcher,
            })(React.createElement(Input, { placeholder: t("dispatcherLabel"), "data-testid": "dispatcher" }))),
            React.createElement(Form.Item, { label: t("ufLabel"), className: styles.shortenFields }, getFieldDecorator(getFieldDecoratorName("documentState"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("ufLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.documentState,
            })(React.createElement(Input, { maxLength: 2, placeholder: t("ufLabel"), "data-testid": "documentState" }))),
            React.createElement(Form.Item, { label: t("emissionLabel"), className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("documentEmissionDate"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("emissionLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue && toMoment(guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.documentEmissionDate),
            })(React.createElement(DatePicker, { placeholder: getTodayDateLocalTimezoneFormat(), format: "DD/MM/YYYY", "data-cy": "emission-data-cy", "data-testid": "emission", allowClear: true }))),
            React.createElement(Form.Item, { label: t("addressLabel"), className: styles.largeFields }, getFieldDecorator(getFieldDecoratorName("address"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("addressLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.address,
            })(React.createElement(Input, { placeholder: t("addressLabel"), "data-testid": "address" }))),
            React.createElement(Form.Item, { label: "CEP", className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("cep"), {
                rules: [
                    {
                        required: true,
                        message: " ",
                    },
                    {
                        validator: checkCep,
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.cep,
            })(React.createElement(MaskedInput, { autoFocus: true, mask: "11.111-111", "data-testid": "cep" }))),
            React.createElement(Form.Item, { label: t("cityLabel"), className: styles.shortFields }, getFieldDecorator(getFieldDecoratorName("addressCity"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("cityLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.addressCity,
            })(React.createElement(Input, { placeholder: t("cityLabel"), "data-testid": "city" }))),
            React.createElement(Form.Item, { label: t("ufLabel"), className: styles.shortenFields }, getFieldDecorator(getFieldDecoratorName("addressState"), {
                rules: [
                    {
                        required: true,
                        message: t("mandatoryField", {
                            field: t("ufLabel"),
                        }),
                    },
                ],
                initialValue: guarantorValue === null || guarantorValue === void 0 ? void 0 : guarantorValue.addressState,
            })(React.createElement(Input, { maxLength: 2, placeholder: t("ufLabel"), "data-testid": "uf" }))),
            React.createElement(CivilStatusForm, { guarantor: guarantorValue, getFieldDecoratorName: getFieldDecoratorName, form: form }))));
};
export default GuarantorForm;
