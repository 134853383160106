import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import { formatCnpj, formatCurrency } from "../../../../shared/utils/formatters";
import { Typograph } from "../../../../shared/components/Typograph";
import { FormFieldWrapper } from "../../../../shared/components/FormFieldWrapper";
import { getTodayDateLocalTimezoneFormat } from "../../../../shared/utils/date";
import { formatNumberOfInstallmentsInput } from "../../../utils/simulator.utils";
import { SimulatorContext } from "../../../contexts/SimulatorContext";
import styles from "./style.scss";
import StepContent from "../../../../admin/components/Simulator/StepContent";
import { CcbPjStep } from "../ContractStepsForm/Steps/steps";
export const ContractDataForm = ({ form, currentStep }) => {
    const { contractDataValues } = useContext(SimulatorContext);
    const { getFieldDecorator } = form;
    const { t } = useTranslation();
    return (React.createElement(StepContent, { hided: currentStep !== CcbPjStep.CCB_PREVIEW, dataCy: "contractData-data-cy" },
        React.createElement("div", { className: styles.sectionCard, "data-cy": "request-fields-section-data-cy" },
            React.createElement(Typograph, { className: styles.title, variant: "modalTitle" }, t("simulator.contractData")),
            React.createElement(Typograph, { variant: "modalTitle" }, t("companyNameLabel")),
            React.createElement(Typograph, { className: styles.regularText, variant: "subtitle" }, contractDataValues.companyName),
            React.createElement(Typograph, { variant: "modalTitle" }, t("cnpjLabel")),
            React.createElement(Typograph, { className: styles.regularText, variant: "subtitle" }, formatCnpj(contractDataValues.cnpj)),
            React.createElement(Typograph, { variant: "modalTitle" }, t("simulator.ccbValueTitle")),
            React.createElement(Typograph, { className: styles.boldText, variant: "subtitle" }, formatCurrency(contractDataValues.ccbValue || 0)),
            React.createElement(Typograph, { variant: "modalTitle" }, t("installmentsQuantityLabel")),
            React.createElement(Typograph, { className: styles.boldText, variant: "subtitle" }, formatNumberOfInstallmentsInput(contractDataValues.installmentsQuantity)),
            React.createElement(FormFieldWrapper, null,
                React.createElement(Typograph, { variant: "modalTitle" }, t("simulator.ccbEmitDateTitle")),
                getFieldDecorator("ccbEmitDate", {
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", {
                                field: t("simulator.ccbEmitDateTitle"),
                            }),
                        },
                    ],
                })(React.createElement(DatePicker, { placeholder: getTodayDateLocalTimezoneFormat(), format: "DD/MM/YYYY", "data-cy": "ccb-emit-date-input" }))))));
};
