import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import InputCurrencyPrefix from "../../../../shared/components/InputCurrencyPrefix";
import { ReceivementIcon } from "../../../../shared/components/Icons/ReceivementIcon";
import { Typograph } from "../../../../shared/components/Typograph";
import { getDateIsoFormat, parseStringToMoment } from "../../../../shared/utils/date";
import styles from "./style.scss";
import { debounce } from "lodash";
const InstallmentsForm = ({ installments = [], handleInstallmentDate, handleInstallmentValue, }) => {
    const isLastInstallment = (index) => {
        return index === installments.length - 1;
    };
    const [installmentsState, setInstallmentsState] = useState(installments);
    useEffect(() => {
        setInstallmentsState(installments);
    }, [installments]);
    const updateInstallmentValue = (index, newValue) => {
        const updatedInstallments = installmentsState.map((installment, idx) => idx === index ? Object.assign(Object.assign({}, installment), newValue) : installment);
        setInstallmentsState(updatedInstallments);
        handleInstallmentValue(updatedInstallments[index]);
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.columnTitles },
            React.createElement("div", { className: styles.columnLeftTitle },
                React.createElement(Typograph, { variant: "modalTitle" }, "Parcelas")),
            React.createElement("div", { className: styles.columnRightTitles },
                React.createElement(Typograph, { variant: "modalTitle", className: styles.columnTitle }, "Juros"),
                React.createElement(Typograph, { variant: "modalTitle", className: styles.columnTitle }, "Amortiza\u00E7\u00E3o"),
                React.createElement(Typograph, { variant: "modalTitle", className: styles.columnTitle }, "PMT"))),
        installmentsState.map((installment, index) => (React.createElement("div", { key: `${installment.number}-${installment.value}-${installment.date}`, className: styles.installmentContainer },
            React.createElement("div", { className: styles.columnLeft },
                React.createElement("div", { className: styles.installmentDescription },
                    React.createElement("div", { className: styles.iconWrapper },
                        React.createElement(ReceivementIcon, null)),
                    React.createElement("div", null,
                        React.createElement(Typograph, { variant: "subtitle", className: styles.subtitle },
                            "Parcela ",
                            installment.number),
                        React.createElement("div", { className: styles.datepickerWrapper },
                            React.createElement(DatePicker, { defaultValue: parseStringToMoment(installment.date), placeholder: "10/10/2010", format: "DD/MM/YYYY", onChange: (date) => {
                                    if (date) {
                                        handleInstallmentDate(Object.assign(Object.assign({}, installment), { date: getDateIsoFormat(date) }));
                                    }
                                }, "data-cy": "installment-date-input-data-cy" }))))),
            React.createElement("div", { className: styles.columnRight },
                React.createElement("div", { className: styles.rightColumnDiv },
                    React.createElement("div", { className: styles.valueWrapper },
                        React.createElement(InputCurrencyPrefix, { value: installment.interestValue, "data-cy": "interest-value-input-data-cy", "data-testid": "interest-value-input", className: styles.valueInput, disabled: true }))),
                React.createElement("div", { className: styles.rightColumnDiv },
                    React.createElement("div", { className: styles.valueWrapper },
                        React.createElement(InputCurrencyPrefix, { value: installment.amortizationValue, "data-cy": "amortization-value-input-data-cy", "data-testid": "amortization-value-input", className: styles.valueInput, disabled: true }))),
                React.createElement("div", { className: styles.rightColumnDiv },
                    React.createElement("div", { className: styles.valueWrapper },
                        React.createElement(InputCurrencyPrefix, { defaultValue: installment.value, "data-cy": "installment-value-input-data-cy", "data-testid": "installment-value-input", className: styles.valueInput, disabled: isLastInstallment(index), onChange: debounce((value) => {
                                updateInstallmentValue(index, { value });
                            }, 1000) })))))))));
};
export default InstallmentsForm;
