import { __awaiter } from "tslib";
import { api } from "../AppWrapper";
export class ChargeReportStore {
    constructor() {
        this.getCharges = (filter, page, rowsPerPage, month) => __awaiter(this, void 0, void 0, function* () {
            return yield api.getChargeReport({
                month: month.month,
                year: month.year,
                page,
                rowsPerPage,
                chargeFilter: filter,
            });
        });
        this.getOldestMonthWithActiveInstallment = () => __awaiter(this, void 0, void 0, function* () { return api.getOldestMonthWithActiveInstallment(); });
        this.getChargeSummary = (month, filter) => __awaiter(this, void 0, void 0, function* () { return api.getChargeSummary({ month: month.month, year: month.year, chargeFilter: filter }); });
        this.getChargeNote = (noteId) => __awaiter(this, void 0, void 0, function* () { return api.getChargeNote({ noteId }); });
        this.saveChargeNote = (newNote) => __awaiter(this, void 0, void 0, function* () { return api.saveChargeNote({ chargeNote: newNote }); });
        this.setChargeAsPaid = (month, charge, paidDate) => __awaiter(this, void 0, void 0, function* () { return api.setChargeAsPaid({ month: month.month, year: month.year, chargeInfo: charge, paidDate }); });
        this.exportChargeReport = (monthAndYear, filter) => __awaiter(this, void 0, void 0, function* () {
            return api.exportchargeReport({
                month: monthAndYear.month,
                year: monthAndYear.year,
                chargeFilter: filter,
            });
        });
    }
}
