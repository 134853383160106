import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Spin, Modal } from "antd";
import { SectionHeader } from "../../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SubSectionHeader } from "../../../../shared/components/SubSectionHeader";
import { TableOfContents } from "../../../components/TableOfContents";
import { getAgreementFields } from "../AgreementForm/types";
import { useStores } from "../../../utils/mobx";
import { AgreementForm } from "../AgreementForm";
import useReactRouter from "use-react-router";
import s from "./style.scss";
import { downloadFileFromBuffer } from "../../../../shared/utils/file";
import { defaultConfirmProps } from "../../../../shared/utils";
const confirm = Modal.confirm;
const AgreementRegister = observer(() => {
    const { generalStore: { catchErrors, setErrorMessage, clearMessage }, companyStore: { selectedCompany }, generalStore, agreementStore, } = useStores();
    const [loading, setLoading] = React.useState(false);
    const [agreement, setAgreement] = React.useState(null);
    const [disableForm, setDisableForm] = React.useState(false);
    const [shouldShowMinWorkTimeForTermExtension, setShouldShowMinWorkTimeForTermExtension] = React.useState(false);
    const [shouldShowInterestRateRange, setShouldShowInterestRateRange] = React.useState(false);
    const { match } = useReactRouter();
    const { t } = useTranslation();
    React.useEffect(() => {
        const id = match.params.id;
        if (id) {
            getAgreementById(id);
        }
    }, []);
    const getAgreementById = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            clearMessage();
            const agreementById = yield agreementStore.getAgreementById(id);
            setAgreement(agreementById);
            setDisableForm(true);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("agreement.couldntSaveAgreementErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const setFocus = (id) => {
        const element = document.getElementById(`agreement_form_${id}`) ||
            document.querySelector(`input[id~="${id}"]`);
        if (element) {
            if (element.classList.contains("ant-calendar-picker")) {
                const input = element.querySelector("input");
                input.focus();
                input.click();
            }
            else {
                element.focus();
            }
        }
    };
    const handleSubmit = (values, file) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            setLoading(true);
            clearMessage();
            values.id = agreement ? agreement.id : null;
            values.active = agreement ? agreement.active : true;
            values.agreementNumber = agreement ? agreement.agreementNumber : null;
            values.creditCalculationAlgorithm = (_a = values.creditCalculationAlgorithm) !== null && _a !== void 0 ? _a : "default";
            values.minInterestRate = agreement
                ? agreement.minInterestRate
                : (_b = values.minInterestRate) !== null && _b !== void 0 ? _b : values.maxInterestRate;
            if (selectedCompany) {
                const savedAgreement = yield agreementStore.createAgreement(selectedCompany.id, values, file);
                setAgreement(savedAgreement);
                setDisableForm(true);
                generalStore.setSuccessMessage(`${t("agreement.successfullyCreatedAgreementMessage")}`);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("agreement.couldntSaveAgreementErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const downloadAgreementFile = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            clearMessage();
            if (agreement) {
                const file = yield agreementStore.getAgreementFile(agreement.id);
                if (file) {
                    downloadFileFromBuffer(file, agreement.filename);
                }
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("agreement.getAgreementFileFail"));
        }
        finally {
            setLoading(false);
        }
    });
    const cancelAgreement = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            clearMessage();
            if (agreement) {
                yield agreementStore.cancelAgreement(agreement.id);
                setAgreement(Object.assign(Object.assign({}, agreement), { active: false }));
                generalStore.setSuccessMessage(`${t("agreement.successfullyCancelledAgreementMessage")}`);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("agreement.cancelledAgreementMessageFailed"));
        }
        finally {
            setLoading(false);
        }
    });
    const openConfirmCancel = () => {
        confirm(Object.assign(Object.assign({ title: t("agreement.cancelAgreementTitle"), content: (React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    t("agreement.confirmationCancellingAgreement"),
                    " ",
                    selectedCompany ? selectedCompany.name : "",
                    "?"),
                React.createElement("p", null, t("agreement.confirmationCancellingAgreementExtraText")))) }, defaultConfirmProps), { onOk: cancelAgreement, width: "40%", okText: t("agreement.cancelAgreementLabel") }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: selectedCompany ? selectedCompany.name : "", description: agreement ? "" : t("agreement.createCompanyNewAgreementText"), hasBackButton: true }),
        React.createElement(SectionContainer, { className: s.container },
            React.createElement(Spin, { spinning: loading },
                React.createElement(SubSectionHeader, { title: t("agreement.agreementFormTitle") }),
                React.createElement("div", { className: s.rowContainer },
                    React.createElement("div", { className: s.side },
                        React.createElement(TableOfContents, { title: t("agreement.agreementInfoTitle"), items: getAgreementFields(shouldShowMinWorkTimeForTermExtension, shouldShowInterestRateRange), handleSelectItem: setFocus })),
                    React.createElement(AgreementForm, { handleSubmit: handleSubmit, initialValues: agreement, companyId: selectedCompany ? selectedCompany.id : null, disableFields: disableForm, downloadAgreementFile: downloadAgreementFile, openConfirmCancel: openConfirmCancel, setShouldShowMinWorkTimeForTermExtension: setShouldShowMinWorkTimeForTermExtension, setShouldShowInterestRateRange: setShouldShowInterestRateRange }))))));
});
export default AgreementRegister;
