import { __awaiter } from "tslib";
import React, { useState } from "react";
import { useStores } from "../../utils/mobx";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { CnabForm } from "./Form";
import { getDateIsoFormat } from "../../../shared/utils/date";
export const Cnab = () => {
    const { generalStore: { setErrorMessage, setSuccessMessage, clearMessage, catchErrors }, cnabStore, } = useStores();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const confirmSubmission = (options) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            clearMessage();
            setLoading(true);
            yield cnabStore.requestCnab(getDateIsoFormat(options.targetDate));
            setSuccessMessage(t("successRequestCnabMessage"));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("cnabTitle") }),
        React.createElement(CnabForm, { onSubmit: confirmSubmission, loading: loading })));
};
