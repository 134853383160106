exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.sectionLink___32Uc- {\n  cursor: pointer;\n  width: 100%;\n  height: 83px;\n  padding: 32px 58px 32px 32px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n  .sectionLink___32Uc- .title___3eiFF {\n    font-size: 16px;\n    color: #4D4D50;\n    font-weight: 700;\n    margin-bottom: 0;\n    display: inline-block;\n    margin-right: 16px; }\n  .sectionLink___32Uc- > div {\n    display: flex;\n    align-items: center; }\n  .sectionLink___32Uc- .description___2QuEP {\n    color: #4D4D50; }\n  .sectionLink___32Uc- .containerText___3xFYU {\n    display: flex;\n    align-items: center; }\n    .sectionLink___32Uc- .containerText___3xFYU p {\n      margin-bottom: 0px !important;\n      color: #aaa; }\n\n.sectionLink___32Uc-:hover, .sectionLink___32Uc-:focus, .sectionLink___32Uc-:active {\n  outline: none;\n  background-color: rgba(255, 118, 0, 0.15); }\n", ""]);

// Exports
exports.locals = {
	"sectionLink": "sectionLink___32Uc-",
	"title": "title___3eiFF",
	"description": "description___2QuEP",
	"containerText": "containerText___3xFYU"
};