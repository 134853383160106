import { __awaiter, __rest } from "tslib";
import moment from "moment";
import React, { useState } from "react";
import { Alert, DatePicker, Form, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { formatCurrencyNoPrefix, formatStringCurrency, toInt, } from "../../../shared/utils/formatters";
import { TableGray } from "../../../shared/components/TableGray";
import { DateFormat, getDateLocalTimezoneFormat, getToday } from "../../../shared/utils/date";
import { repurchaseTableColumns } from "./column";
import { useStores } from "../../utils/mobx";
import InputCurrency from "../../../shared/components/InputCurrency";
import { sumValues } from "../../../shared/utils/numbers";
import { ConfirmRepurchaseCessionModal } from "../ConfirmeRepurchaseCessionModal";
import styles from "./style.scss";
const RepurchaseCessionModal = (_a) => {
    var { cession, visible, form, onCancel, onSuccess } = _a, rest = __rest(_a, ["cession", "visible", "form", "onCancel", "onSuccess"]);
    const { getFieldDecorator, validateFields, getFieldsValue } = form;
    const { t } = useTranslation();
    const { generalStore: { catchErrors, setSuccessMessage }, cessionReportStore: { getOpenInstallmentsGivenByCession, repurchaseGivenInstallments }, } = useStores();
    const [openInstallmentsGiven, setOpenInstallmentsGiven] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedRepurchaseKeys, setSelectedRepurchaseKeys] = useState([]);
    const [showConfirmRepurchaseModal, setShowConfirmRepurchaseModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    React.useEffect(() => {
        const repurchaseCessionModalHasBeenOpened = cession && visible;
        if (repurchaseCessionModalHasBeenOpened) {
            setSelectedRepurchaseKeys([]);
            setErrorMessage("");
            getOpenInstallmentsGiven(cession.id);
        }
    }, [cession, visible]);
    const getOpenInstallmentsGiven = (cessionId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const installments = yield getOpenInstallmentsGivenByCession(cessionId);
            setOpenInstallmentsGiven(installments);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("cession.repurchaseErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors) => {
            if (!errors) {
                setShowConfirmRepurchaseModal(true);
            }
        });
    });
    const handleSelectedChange = (selected, _) => {
        setSelectedRepurchaseKeys(selected);
    };
    const getCessionInstallmentsForTable = () => {
        return openInstallmentsGiven.map((installment) => (Object.assign(Object.assign({}, installment), { key: installment.dueDate, value: formatStringCurrency(installment.value), remainingValue: formatStringCurrency(installment.remainingValue), dueDate: getDateLocalTimezoneFormat(installment.dueDate) })));
    };
    const cessionInstallmentsDataSource = getCessionInstallmentsForTable();
    const getselectedInstallments = () => {
        return selectedRepurchaseKeys.map((selectedRepurchase) => {
            const installment = openInstallmentsGiven.filter((installments) => {
                return installments.dueDate === selectedRepurchase;
            });
            return installment[0];
        });
    };
    const confirmRepurchase = () => __awaiter(void 0, void 0, void 0, function* () {
        setConfirmLoading(true);
        if (cession) {
            try {
                const values = getFieldsValue();
                yield repurchaseGivenInstallments(cession.id, values.repurchaseValue, getDateLocalTimezoneFormat(values.repurchaseDate, DateFormat.ISO), selectedRepurchaseKeys);
                setSuccessMessage(t("cession.repurchaseSuccessMessage"));
                onSuccess();
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("cession.defaultRepurchaseErrorMessage"));
            }
            finally {
                setConfirmLoading(false);
                setShowConfirmRepurchaseModal(false);
            }
        }
    });
    const selectedInstallements = getselectedInstallments();
    const installmentsValue = selectedInstallements.map((installment) => installment.value);
    const installmentsToPurchaseValue = sumValues(installmentsValue);
    return (React.createElement(Modal, { visible: visible, title: React.createElement("span", { className: styles.title }, t("cession.repurchaseCessionLabel")), onCancel: onCancel, onOk: handleSubmit, okButtonProps: { disabled: !selectedRepurchaseKeys.length }, destroyOnClose: true, maskClosable: true, okText: t("cession.confirmRepurchase"), width: "966px" },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: styles.formWrapper },
                React.createElement("div", { className: styles.header },
                    React.createElement("div", { className: styles.titleGrid },
                        React.createElement("div", null, t("ccbNumberLabel")),
                        React.createElement("div", null, t("nameLabel")),
                        React.createElement("div", null, t("cession.dateLabel")),
                        React.createElement("div", null, t("cession.assigneeCompanyLabel"))),
                    React.createElement("div", { className: styles.subtitleGrid },
                        React.createElement("div", null, cession === null || cession === void 0 ? void 0 : cession.ccbNumber),
                        React.createElement("div", null, cession === null || cession === void 0 ? void 0 : cession.userName),
                        React.createElement("div", null, getDateLocalTimezoneFormat(cession === null || cession === void 0 ? void 0 : cession.cessionDate)),
                        React.createElement("div", null, cession === null || cession === void 0 ? void 0 : cession.assigneCompany))),
                React.createElement(Form, Object.assign({ className: styles.form, hideRequiredMark: true }, rest),
                    React.createElement("div", { className: styles.tableWrapper, "data-cy": "cession-installments-table-data-cy" },
                        React.createElement(TableGray, { className: styles.installmentsTable, columns: repurchaseTableColumns, rowSelection: {
                                selectedRowKeys: selectedRepurchaseKeys,
                                onChange: handleSelectedChange,
                                indeterminate: false,
                            }, dataSource: cessionInstallmentsDataSource, scroll: { y: 250 }, pagination: false })),
                    React.createElement("div", { className: styles.footer },
                        React.createElement("div", { className: styles.footerTitleGrid },
                            React.createElement("div", null, "Selecionada(s)"),
                            React.createElement("div", null, "Valor total das parcelas"),
                            React.createElement("div", null, "Valor de recompra"),
                            React.createElement("div", null, "Data da recompra")),
                        React.createElement("div", { className: styles.subtitlefooterGrid },
                            React.createElement("div", { "data-cy": "selectedRepurchase-data-cy" }, selectedRepurchaseKeys.length),
                            React.createElement("div", { "data-cy": "installmentsToPurchaseValue-data-cy" }, formatStringCurrency(installmentsToPurchaseValue)),
                            React.createElement("div", null,
                                React.createElement(Form.Item, null, getFieldDecorator("repurchaseValue", {
                                    normalize: (value) => toInt(value),
                                    rules: [
                                        {
                                            required: true,
                                            message: t("cession.repurchaseRequiredValue"),
                                        },
                                    ],
                                })(React.createElement(InputCurrency, { "data-cy": "repurchase-value-input", style: { marginBottom: -13 }, min: 0, formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}` })))),
                            React.createElement("div", null,
                                React.createElement(Form.Item, { "data-cy": "repurchase-date-wrapper" }, getFieldDecorator("repurchaseDate", {
                                    initialValue: getToday(),
                                    rules: [
                                        {
                                            required: true,
                                            message: t("cession.repurchaseRequiredDate"),
                                        },
                                    ],
                                })(React.createElement(DatePicker, { className: styles.date, showToday: true, format: "DD/MM/YYYY", allowClear: true, placeholder: t("cession.selectRepurchaseDate"), "data-cy": "repurchase-date-input", disabledDate: (date) => (date ? date > moment().endOf("day") : false) })))))),
                    React.createElement("div", null, errorMessage && React.createElement(Alert, { type: "error", closable: true, showIcon: true, message: errorMessage }))))),
        React.createElement(ConfirmRepurchaseCessionModal, { visible: showConfirmRepurchaseModal, onCancel: () => setShowConfirmRepurchaseModal(false), onOk: confirmRepurchase, loading: confirmLoading })));
};
export const ConfirmationRepurchaseModalForm = Form.create({
    name: "repurchase_ccb",
})(RepurchaseCessionModal);
