import { __awaiter } from "tslib";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "antd";
import Table from "antd/lib/table";
import Text from "antd/lib/typography/Text";
import ActionTable from "../../../shared/components/ActionTable";
import { FilterForm } from "../../../shared/components/FilterForm";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import { MonthSelector } from "../../../shared/components/MonthSelector";
import { Pagination } from "../../../shared/components/Pagination";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SummaryItem } from "../../../shared/components/SummaryItem";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
import { getDateLocalTimezoneFormat, getFirstDayInNextMonth, getMonths, } from "../../../shared/utils/date";
import { downloadFileFromBuffer } from "../../../shared/utils/file";
import { nullIfEmpty } from "../../../shared/utils/filter";
import { cut, formatCnpj, formatCpf, formatStringCurrency, formatStringCurrencyNoPrefix, } from "../../../shared/utils/formatters";
import { getRoundedNumber, sumValues } from "../../../shared/utils/numbers";
import { ChargePaymentForm } from "../../components/ChargeReport/ChargePaymentForm";
import { useStores } from "../../utils/mobx";
import { getChargeReportColumns, getChargeReportColumnsCcbs } from "./constants";
import s from "./style.scss";
const ChargeReport = observer(() => {
    const { t } = useTranslation();
    const columns = getChargeReportColumns(t);
    const columnsCcbs = getChargeReportColumnsCcbs(t);
    const { generalStore: { setErrorMessage, clearMessage, catchErrors, setSuccessMessage }, chargeReportStore, installmentsStore, } = useStores();
    const [months, setMonths] = useState([]);
    const [chargeSummary, setChargeSummary] = useState({
        totalItems: 0,
        totalAmount: 0,
    });
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [charges, setCharges] = useState([]);
    const [loadingCharges, setLoadingCharges] = useState(false);
    const [selectedCharge, setSelectedCharge] = useState();
    const [selectedCcbs, setSelectedCcbs] = useState([]);
    const checkDocumentType = (charge) => {
        let document;
        charge.cpf !== null ? (document = formatCpf(charge.cpf)) : (document = formatCnpj(charge.cnpj));
        return document;
    };
    const setRowKeys = (charge) => {
        return charge.entityId + charge.assigneCompanyFromCession + charge.dueDate + charge.paid;
    };
    const loanLabels = {
        turning: t("charge.turningLabel"),
        payday: t("charge.payrollLabel"),
        fgts: t("charge.fgtsLabel"),
    };
    const checkLoanType = (charge) => {
        return loanLabels[charge.type];
    };
    const [currentFilter, setCurrentFilter] = useState({
        person: null,
        name: null,
        type: null,
        cpfCnpj: null,
        draweeSector: null,
        assigneCompanyFromCession: null,
        paid: null,
    });
    React.useEffect(() => {
        getOldestMonthWithActiveInstallment();
    }, []);
    React.useEffect(() => {
        if (selectedMonth) {
            getCharges(currentFilter);
        }
    }, [rowsPerPage, currentPage, selectedMonth]);
    const getOldestMonthWithActiveInstallment = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const firstMonth = yield chargeReportStore.getOldestMonthWithActiveInstallment();
            if (firstMonth) {
                const monthsUntilToday = getAllMonthsUntilToday(firstMonth);
                const actualMonth = monthsUntilToday[0] || firstMonth;
                setSelectedMonth(actualMonth);
                setMonths(monthsUntilToday);
            }
            else {
                setLoadingCharges(false);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("charge.errorMessage"));
            setLoadingCharges(false);
        }
    });
    function getPaidCcbs(chargeInfos) {
        return chargeInfos
            .map((charge) => {
            if (!charge.paid) {
                return charge.ccbs.map((ccb) => ccb.number);
            }
            return [];
        })
            .flat();
    }
    const selectAllCcbs = (chargeInfos) => {
        const ccbsNumbers = getPaidCcbs(chargeInfos);
        handleSelectedCcbs(ccbsNumbers);
    };
    const getAllMonthsUntilToday = (firstMonth) => {
        const referenceDate = getFirstDayInNextMonth();
        const startDate = new Date(firstMonth.year, Number(firstMonth.month), 1);
        return getMonths(startDate, referenceDate).reverse();
    };
    const getCharges = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        clearMessage();
        setLoadingCharges(true);
        setCharges([]);
        if (selectedMonth !== null) {
            try {
                const summary = yield chargeReportStore.getChargeSummary(selectedMonth, currentFilter);
                setChargeSummary(summary);
                const chargeReport = yield chargeReportStore.getCharges(filter, currentPage, rowsPerPage, selectedMonth);
                setCharges(chargeReport.chargeInfos);
                setCurrentPage(chargeReport.currentPage);
                setRowsPerPage(chargeReport.rowsPerPage);
                selectAllCcbs(chargeReport.chargeInfos);
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("getChargesFail"));
            }
            finally {
                setLoadingCharges(false);
            }
        }
    });
    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
        setCurrentPage(1);
    };
    const items = [
        {
            key: "pessoa",
            name: "pessoa",
            type: "select",
            placeholder: t("charge.personLabel"),
            selectDefaultValue: "",
            selectOptions: [
                { value: "cpf", label: t("charge.cpfPersonLabel") },
                {
                    value: "cnpj",
                    label: t("charge.cnpjPersonLabel"),
                },
                {
                    value: "",
                    label: t("charge.allLabel"),
                },
            ],
        },
        {
            key: "cpfCnpj",
            name: "cpfCnpj",
            type: "input",
            placeholder: t("charge.personCpfCnpjLabel"),
        },
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("charge.personCompanyNameLabel"),
        },
        {
            key: "draweeSector",
            name: "draweeSector",
            type: "input",
            placeholder: t("charge.sector"),
        },
        {
            key: "paid",
            name: "paid",
            type: "select",
            selectDefaultValue: null,
            selectOptions: [
                {
                    value: null,
                    label: t("charge.status"),
                },
                {
                    value: false,
                    label: t("charge.opened"),
                },
                { value: true, label: t("charge.paid") },
            ],
        },
        {
            key: "type",
            name: "type",
            type: "select",
            placeholder: t("charge.typeLabel"),
            selectDefaultValue: "payday",
            selectOptions: [
                {
                    value: "turning",
                    label: t("charge.turningLabel"),
                },
                {
                    value: "payday",
                    label: t("charge.payrollLabel"),
                },
                {
                    value: "fgts",
                    label: t("charge.fgtsLabel"),
                },
                {
                    value: "",
                    label: t("charge.allLabel"),
                },
            ],
        },
        {
            key: "assigneCompanyFromCession",
            name: "assigneCompanyFromCession",
            type: "input",
            placeholder: t("charge.assigneCompanyFromCession"),
        },
    ];
    const clearSelectedCharge = () => setSelectedCharge(null);
    const buildChargeInfoToConfirm = (charge) => {
        const ccbsToPaid = getCcbsToPay(charge);
        const chargeValue = getChargeValue(charge);
        return Object.assign(Object.assign({}, charge), { ccbs: ccbsToPaid, value: chargeValue });
    };
    const confirmPaymentOk = (charge, paidDate) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedMonth !== null) {
            setLoadingCharges(true);
            try {
                const chargeInfoToConfirm = buildChargeInfoToConfirm(charge);
                yield chargeReportStore.setChargeAsPaid(selectedMonth, chargeInfoToConfirm, paidDate);
                yield getCharges(currentFilter);
                setSuccessMessage(t("charge.confirmPaymentSuccessMessage"));
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("charge.confirmPaymentErrorMessage"));
            }
            finally {
                clearSelectedCharge();
                setLoadingCharges(false);
            }
        }
    });
    const confirmExtension = (charge, reason) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedMonth !== null) {
            setLoadingCharges(true);
            try {
                const chargeInfoToConfirm = buildChargeInfoToConfirm(charge);
                yield installmentsStore.rescheduleInstallment(chargeInfoToConfirm, reason);
                yield getCharges(currentFilter);
                setSuccessMessage(t("charge.extendPaymentSuccessMessage"));
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("charge.extendPaymentErrorMessage"));
            }
            finally {
                clearSelectedCharge();
                setLoadingCharges(false);
            }
        }
    });
    const applyFilter = (values) => {
        const filter = {
            person: nullIfEmpty(values.pessoa),
            name: nullIfEmpty(values.name),
            type: nullIfEmpty(values.type),
            cpfCnpj: nullIfEmpty(values.cpfCnpj),
            draweeSector: nullIfEmpty(values.draweeSector),
            assigneCompanyFromCession: nullIfEmpty(values.assigneCompanyFromCession),
            paid: nullIfEmpty(values.paid),
        };
        setCurrentPage(1);
        setRowsPerPage(10);
        setCurrentFilter(filter);
    };
    React.useEffect(() => {
        getCharges(currentFilter);
    }, [currentFilter]);
    const { totalItems, totalAmount } = chargeSummary;
    const hasCharges = !loadingCharges && charges.length > 0;
    const exportChargeReport = (monthAndYear, filter) => __awaiter(void 0, void 0, void 0, function* () {
        if (monthAndYear !== null) {
            try {
                const chargeReport = yield chargeReportStore.exportChargeReport(monthAndYear, filter);
                downloadFileFromBuffer(chargeReport, t("charge.reportTitle") + monthAndYear.month + "/" + monthAndYear.year + ".xlsx");
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("exportApiErrorMessage"));
            }
        }
    });
    const handleSelectedCcbs = (selected) => {
        setSelectedCcbs(selected);
    };
    function getCcbsToPay(charge) {
        return charge.ccbs.filter((ccb) => selectedCcbs.includes(ccb.number));
    }
    function calculateChargeValue(charge) {
        const ccbsToPaid = getCcbsToPay(charge);
        const ccbsInstallmentValues = ccbsToPaid.map((ccb) => ccb.chargeValueForInstallment);
        const chargeValue = sumValues(ccbsInstallmentValues);
        return getRoundedNumber(chargeValue);
    }
    function getChargeValue(charge) {
        if (charge.paid) {
            return charge.value;
        }
        return calculateChargeValue(charge);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ChargePaymentForm, { charge: selectedCharge, confirmPaymentOk: confirmPaymentOk, confirmExtension: confirmExtension, onClose: clearSelectedCharge, loading: loadingCharges }),
        React.createElement(SectionHeader, { title: t("charge.reportTitle"), description: t("charge.reportSubTitle"), actions: [
                {
                    label: t("exportReportLabel"),
                    handleAction: () => exportChargeReport(selectedMonth, currentFilter),
                    icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                    disabled: charges.length === 0,
                },
            ] }),
        React.createElement(FilterForm, { className: s.chargeForm, items: items, handleSubmit: applyFilter }),
        React.createElement(SectionContainer, { className: s.container },
            months.length > 0 && React.createElement(MonthSelector, { months: months, handleSelect: handleMonthSelect }),
            React.createElement(ActionTable, { className: s.table, columns: columns, loading: loadingCharges, scroll: { y: 500 }, expandedRowRender: (charge) => (React.createElement(Table, { className: s.tableCcbs, columns: columnsCcbs, pagination: false, rowSelection: {
                        selectedRowKeys: selectedCcbs,
                        onChange: handleSelectedCcbs,
                        indeterminate: false,
                        getCheckboxProps: () => ({ disabled: charge.paid }),
                    }, dataSource: charge.ccbs.map((ccb) => (Object.assign(Object.assign({}, ccb), { key: ccb.number, chargeValueForInstallment: formatStringCurrency(ccb.chargeValueForInstallment), numberOfInstallments: (React.createElement(React.Fragment, null,
                            ccb.numberOfInstallments,
                            ccb.extendedReason && (React.createElement(TooltipDisplay, { classname: s.tooltipContainer, title: React.createElement("div", { className: s.tooltipContainer },
                                    React.createElement("p", null,
                                        "Reprograma\u00E7\u00E3o de parcelas referente a: ",
                                        ccb.extendedReason)) })))) }))) })), dataSource: charges.map((charge) => (Object.assign(Object.assign({}, charge), { key: setRowKeys(charge), name: cut(charge.name), cpfCnpj: checkDocumentType(charge), type: checkLoanType(charge), assigneCompanyFromCession: charge.assigneCompanyFromCession, value: formatStringCurrencyNoPrefix(getChargeValue(charge)), dueDate: getDateLocalTimezoneFormat(charge.dueDate), status: (React.createElement(React.Fragment, null, !charge.paid ? (React.createElement(Button, { onClick: () => setSelectedCharge(Object.assign(Object.assign({}, charge), { value: getChargeValue(charge) })), className: s.button, type: "link", "data-cy": "confirm-payment-button-data-cy" }, t("charge.confirmPaymentUpperText"))) : (React.createElement(Text, { className: s.text }, t("charge.paidUpperLabel"))))) }))) })),
        React.createElement("div", { className: s.footer }, hasCharges && React.createElement(SummaryItem, { title: "Total", value: formatStringCurrency(totalAmount) })),
        React.createElement(SectionFooter, null,
            React.createElement(Pagination, { current: currentPage, total: totalItems, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setRowsPerPage(size);
                } }))));
});
export default ChargeReport;
