import { __rest } from "tslib";
import { Radio } from "antd";
import React, { useState } from "react";
import styles from "./styles.scss";
export const CustomRadio = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const [radioValue, setRadioValue] = useState(1);
    const handleRadioButton = (event) => {
        setRadioValue(event.target.value);
    };
    return (React.createElement(Radio.Group, Object.assign({ onChange: handleRadioButton, className: styles.radioButonContainer, value: radioValue }, props), children));
};
