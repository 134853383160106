import React from "react";
import styles from "./style.scss";
const StepContentCard = ({ children, title, subTitle, titleActions, }) => {
    return (React.createElement("div", { className: styles.mainContainer },
        title && (React.createElement("div", { className: styles.titleSection },
            React.createElement("div", { className: styles.titlesContainer },
                React.createElement("div", { className: styles.title }, title),
                subTitle && React.createElement("div", { className: styles.subTitle }, subTitle)),
            React.createElement("div", { className: styles.actionButtonsWrapper }, titleActions))),
        children));
};
export default StepContentCard;
