import { __awaiter } from "tslib";
import React, { useContext, useState } from "react";
import useReactRouter from "use-react-router";
import StepContent from "../../../../../components/Simulator/StepContent";
import { OpinionForm } from "../../../../../components/Simulator/OpinionForm";
import { CcbPjStep } from "../../Steps/steps";
import OpinionStepBottomActions from "../../../../../../admin/components/Simulator/OpinionStepBottomActions";
import { useStores } from "../../../../../../admin/utils/mobx";
import { SimulatorContext } from "../../../../../../admin/contexts/SimulatorContext";
import { Spin } from "antd";
import { hasErrors } from "../../../../../../shared/utils/form.utils";
import { mapGenerateCcbPjPreviewParams } from "../../../Utils/generateCcb";
import { bufferToPDFFileUrl } from "../../../../../../shared/utils/file";
import { useTranslation } from "react-i18next";
import { AdminRoutes } from "../../../../../constants/routes.constants";
export const Opinion = ({ currentStep, navigateToNextStep, navigateToPreviousStep, form, }) => {
    const { match: { params: { simulationId }, }, } = useReactRouter();
    const { history, generalStore: { catchErrors, setErrorMessage }, } = useStores();
    const { ccbPjStore } = useStores();
    const { t } = useTranslation();
    const { setCcbRequestPreviewUrl, setOpinionData, opinion, contractDataValues } = useContext(SimulatorContext);
    const [loading, setLoading] = useState(false);
    const { validateFieldsAndScroll, getFieldsValue } = form;
    const generateCcbPreview = () => __awaiter(void 0, void 0, void 0, function* () {
        const fields = form.getFieldsValue();
        try {
            setLoading(true);
            const generateCcbPjPreviewParams = mapGenerateCcbPjPreviewParams(fields, simulationId);
            const ccb = yield ccbPjStore.generateCcbPjPreview(generateCcbPjPreviewParams);
            const ccbPreviewUrl = bufferToPDFFileUrl(ccb);
            yield setCcbRequestPreviewUrl(ccbPreviewUrl);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const handleApprovalClick = () => __awaiter(void 0, void 0, void 0, function* () {
        validateFieldsAndScroll(["ccbEmitDate"], (errors) => __awaiter(void 0, void 0, void 0, function* () {
            if (hasErrors(errors)) {
                catchErrors(errors, setErrorMessage, errors.ccbEmitDate.errors[0].message);
                return;
            }
            const opinionData = getFieldsValue(["opinion"]).opinion;
            setOpinionData({ opinionData, simulationId: contractDataValues.simulationId });
            yield generateCcbPreview();
            navigateToNextStep();
        }));
    });
    const handleReprovalClick = () => {
        history.push(AdminRoutes.Simulator.Report);
    };
    return (React.createElement(StepContent, { hided: currentStep === CcbPjStep.OPINION, dataCy: "opinion-data-cy" },
        React.createElement(Spin, { spinning: loading },
            React.createElement(OpinionForm, { opinion: opinion, form: form }),
            React.createElement(OpinionStepBottomActions, { onBackClick: navigateToPreviousStep, onApprovalClick: handleApprovalClick, onReprovalClick: handleReprovalClick }))));
};
