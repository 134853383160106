import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
import { Modal, Icon, Alert, Spin } from "antd";
import { ConfirmModalContent } from "../../../shared/components/ConfirmModalContent";
const AmortizeConfirmationModal = (props) => {
    const { t } = useTranslation();
    return (React.createElement(Modal, { className: s.modal, visible: props.visible, okText: t("confirmLabel"), onCancel: props.onCancel, onOk: props.onOk, maskClosable: true, destroyOnClose: true, width: "45%", okButtonProps: { disabled: props.loading } },
        React.createElement(Spin, { spinning: props.loading },
            React.createElement("div", { className: classNames("ant-modal-title", s.modalHeader) },
                React.createElement("div", { className: s.confirmHeader },
                    t("contracts.confirmAmortizingFromLabel"),
                    " ",
                    props.selectedContract ? props.selectedContract.userName : "",
                    React.createElement(Icon, { onClick: props.onClickDownload, className: s.icon, type: "download" })),
                React.createElement(ConfirmModalContent, { labels: props.labels, values: props.values }),
                props.errorMessage && (React.createElement(Alert, { type: "error", closable: true, showIcon: true, message: props.errorMessage, onClose: props.onCloseAlert }))))));
};
export default AmortizeConfirmationModal;
