import React from "react";
import { Icon, Tabs, Tooltip } from "antd";
import { ListColumnInfo } from "../../../../shared/components/ListColumnInfo";
import { formatAddress, formatBankAccountType } from "../../../../shared/utils/formatters";
import { getDateLocalTimezoneFormat } from "../../../../shared/utils/date";
import { loanLabels } from "../../../containers/LoanApplications";
import styles from "./style.scss";
const { TabPane } = Tabs;
export const ContractDataTabCard = ({ generalData, children, bankDataToolTipDescription, }) => {
    var _a, _b, _c, _d, _e;
    function paymentChoiceIsBankAccount() {
        return generalData.financialData.userPaymentChoise === "byBankAccount";
    }
    return (React.createElement(Tabs, { type: "card" },
        React.createElement(TabPane, { tab: React.createElement("div", null,
                React.createElement("span", { "data-cy": "bank-data-tab-button" }, paymentChoiceIsBankAccount() ? "Dados Bancários" : "Dados do PIX"),
                " ",
                React.createElement(Tooltip, { title: bankDataToolTipDescription, placement: "bottom" },
                    React.createElement(Icon, { className: styles.icon, type: "exclamation-circle" }))), key: "bankData" },
            React.createElement("div", { className: styles.tableWrapper, "data-cy": "bank-data" }, paymentChoiceIsBankAccount() ? (React.createElement(React.Fragment, null,
                React.createElement(ListColumnInfo, { labels: [
                        loanLabels.bankName,
                        loanLabels.bankNumber,
                        loanLabels.bankBranch,
                        loanLabels.bankAccount,
                        loanLabels.accountType,
                    ], values: [
                        (_a = generalData.financialData.bankAccount) === null || _a === void 0 ? void 0 : _a.bankName,
                        (_b = generalData.financialData.bankAccount) === null || _b === void 0 ? void 0 : _b.bankNumber,
                        (_c = generalData.financialData.bankAccount) === null || _c === void 0 ? void 0 : _c.branchNumber,
                        (_d = generalData.financialData.bankAccount) === null || _d === void 0 ? void 0 : _d.accountNumber,
                        formatBankAccountType((_e = generalData.financialData.bankAccount) === null || _e === void 0 ? void 0 : _e.accountType),
                    ] }))) : (React.createElement(React.Fragment, null,
                React.createElement(ListColumnInfo, { labels: [loanLabels.pixKey], values: [`CPF: ${generalData.financialData.pixKey}`] }))))),
        React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "contact-tab-button" }, "Contato"), key: "contact" },
            React.createElement("div", { className: styles.tableWrapper, "data-cy": "contact-data" },
                React.createElement(ListColumnInfo, { labels: [
                        loanLabels.mainPhone,
                        loanLabels.email,
                        loanLabels.dateOfBirth,
                        loanLabels.residentialPhone,
                        loanLabels.secondaryEmail,
                        loanLabels.address,
                    ], values: [
                        generalData.mobilePhone,
                        generalData.email,
                        getDateLocalTimezoneFormat(generalData.dateOfBirth),
                        generalData.residentialPhone,
                        generalData.secondaryEmail,
                        generalData.address && formatAddress(generalData.address),
                    ] }))),
        children));
};
