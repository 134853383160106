import React from "react";
import { observer } from "mobx-react-lite";
import ActionTable from "../ActionTable";
import { FilterForm } from "../FilterForm";
import { ConcealableTableHeaderRow } from "../ConcealableTableHeaderRow";
import styles from "./style.scss";
export const TableWithFilter = observer(({ columns, dataSource, table, selectedRowKeys, onFilterChange, filterProps }) => {
    const defaultFiltersItems = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: "Nome",
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterForm, { className: styles.filterForm, items: (filterProps === null || filterProps === void 0 ? void 0 : filterProps.items) || defaultFiltersItems, handleSubmit: (values) => {
                onFilterChange(values);
            }, handleChange: (values) => {
                if (!(filterProps === null || filterProps === void 0 ? void 0 : filterProps.items) && !values.name) {
                    onFilterChange(values);
                }
            } }),
        React.createElement("div", { className: styles.tableWrapper },
            React.createElement(ActionTable, { columns: columns, dataSource: dataSource, components: {
                    header: {
                        row: (props) => (React.createElement(ConcealableTableHeaderRow, { columns: columns, selectedItems: table.headerProps.selectedItems, totalOfItems: table.headerProps.totalOfItems, onToogleClick: table.headerProps.onToogleClick, show: table.headerProps.show, action: table.headerProps.action }, props.children)),
                    },
                    body: {
                        row: (props) => {
                            return React.createElement("tr", { className: "table-base-row" }, props.children);
                        },
                    },
                }, rowSelection: {
                    type: "checkbox",
                    selectedRowKeys,
                    onSelect: (record, selected) => {
                        if (table.rowSelection.onSelect) {
                            table.rowSelection.onSelect(record, selected);
                        }
                    },
                } }))));
});
