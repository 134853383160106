import { __awaiter } from "tslib";
import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Col, DatePicker, Input, InputNumber, Row, Spin, Form } from "antd";
import SelectSearchField from "../../../shared/components/SelectSearchField";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { useStores } from "../../utils/mobx";
import { formatCnpj, formatCurrency, formatCurrencyNoPrefix, formatCurrencyNoPrefixFiveDecimals, } from "../../../shared/utils/formatters";
import { Typograph } from "../../../shared/components/Typograph";
import { mapCompaniesToSelectOptions } from "../../../shared/utils/mappers";
import { FormFieldWrapper } from "../../../shared/components/FormFieldWrapper";
import { HideableContainer } from "../../../shared/components/HideableContainer";
import InstallmentsForm from "../../components/Simulator/InstallmentsForm";
import InputCurrencyPrefix from "../../../shared/components/InputCurrencyPrefix";
import InputCurrency from "../../../shared/components/InputCurrency";
import { CustomSlider } from "../../../shared/components/Slider";
import { getToday, getTodayDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { debounce, isEmpty, isEqual } from "lodash";
import SimulationResult from "../../../admin/components/Simulator/SimulationResult";
import useReactRouter from "use-react-router";
import { removeItemFromCollectionById } from "../../../shared/utils/colection";
import { formatNumberOfInstallmentsInput } from "../../utils/simulator.utils";
import { goBackTwoDecimalPlaces } from "../../../shared/utils/numbers";
import { SimulatorContext } from "../../contexts/SimulatorContext";
import { AdminError } from "../../../admin/errors/adminError";
import { SimulationDownloadModal } from "../../components/Simulator/SimulationDownload";
import { AdminRoutes } from "../../constants/routes.constants";
import styles from "./style.scss";
const requestedAmountKey = "requestedAmount";
const numberOfInstallmentsKey = "numberOfInstallments";
const BaseSimulator = ({ form }) => {
    const { match: { params: { simulationId }, }, } = useReactRouter();
    const { getFieldDecorator, getFieldValue, setFieldsValue, getFieldsValue, validateFields } = form;
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState();
    const [priceSimulationPj, setPriceSimulationPj] = useState();
    const [ccbPjSimulationParams, setCcbPjSimulationParams] = useState();
    const [initialSimulationValues, setInitialSimulationValues] = useState();
    const [initialInstallments, setInitialInstallments] = useState([]);
    const [installmentsDates, setInstallmentsDates] = useState([]);
    const [installmentsValues, setInstallmentsValues] = useState([]);
    const [showDownloadSimulationModal, setShowDownloadSimulationModal] = useState(false);
    const ccbPjSimulationDtoRef = useRef();
    const installmentsDatesRef = useRef([]);
    const installmentsValuesRef = useRef([]);
    const selectedCompanyRef = useRef();
    selectedCompanyRef.current = selectedCompany;
    ccbPjSimulationDtoRef.current = ccbPjSimulationParams;
    installmentsDatesRef.current = installmentsDates;
    installmentsValuesRef.current = installmentsValues;
    const today = useMemo(() => getToday(), []);
    const { contributorsStore, companyStore, generalStore, generalStore: { catchErrors }, ccbPjStore, routerStore, } = useStores();
    const { setSimulatorRequestContractDataValues } = useContext(SimulatorContext);
    useEffect(() => {
        getAllCompanies();
        fetchInitialSimulationValues();
    }, []);
    useEffect(() => {
        if (!isEmpty(companies) && (initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.companyId)) {
            handleCompaniesSelectChange(initialSimulationValues.companyId);
        }
    }, [companies, initialSimulationValues]);
    const getAllCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const companiesTotal = yield companyStore.getCompaniesTotal(null);
            const result = yield contributorsStore.getAllCompanies("", 1, companiesTotal);
            setCompanies(result.companies);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
    });
    const fetchInitialSimulationValues = () => __awaiter(void 0, void 0, void 0, function* () {
        if (simulationId) {
            try {
                const simulation = yield ccbPjStore.getSimulationPj(simulationId);
                setInitialInstallments(simulation.installments);
                setInitialSimulationValues(simulation);
            }
            catch (error) {
                catchErrors(error, generalStore.setErrorMessage, t("simulator.defaultFetchErrorMessage"));
            }
        }
        else {
            setDefaultInitialSimulationValues();
        }
    });
    function setDefaultInitialSimulationValues() {
        setInitialSimulationValues({
            requestedAmount: 12000000,
            numberOfInstallments: 12,
            valueCreditDate: today,
            installmentReferenceDate: today,
            interestRatePerMonth: 175,
            structuringRate: 100000,
            joinSeguroPrestamista: false,
            thirdPartyValue: 2250,
            tedValue: 0,
        });
    }
    useEffect(() => {
        if (ccbPjSimulationParams) {
            setInstallmentsDates(initialInstallments);
            setInstallmentsValues(initialInstallments);
            setInitialInstallments([]);
        }
    }, [ccbPjSimulationParams]);
    const simulateCcb = (args) => __awaiter(void 0, void 0, void 0, function* () {
        if (!args.ccbPjSimulationParams) {
            return;
        }
        try {
            generalStore.setErrorMessage("");
            setLoading(true);
            const simulatedCcbPj = yield ccbPjStore.simulateCcbPj(args);
            setPriceSimulationPj(simulatedCcbPj);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("simulator.failedToGenerateSimulation"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        handleFormChange();
    }, [getFieldsValue()]);
    const handleFormChange = () => {
        const value = getFieldsValue();
        if (!isEqual(ccbPjSimulationDtoRef.current, value)) {
            setCcbPjSimulationParams(value);
        }
    };
    const handleCompaniesSelectChange = (selectedId) => setSelectedCompany(companies.find(({ id }) => id === selectedId));
    const onChangeRequestValue = (newValue) => {
        setFieldsValue({ [requestedAmountKey]: newValue });
    };
    const onChangeNumberOfInstallments = (newValue) => {
        setFieldsValue({ [numberOfInstallmentsKey]: newValue });
    };
    function buildContractDataValues(savedSimulationId) {
        var _a, _b;
        return {
            companyName: (_a = selectedCompanyRef.current) === null || _a === void 0 ? void 0 : _a.name,
            cnpj: (_b = selectedCompanyRef.current) === null || _b === void 0 ? void 0 : _b.cnpj,
            ccbValue: goBackTwoDecimalPlaces(priceSimulationPj === null || priceSimulationPj === void 0 ? void 0 : priceSimulationPj.ccbValue),
            installmentsQuantity: priceSimulationPj === null || priceSimulationPj === void 0 ? void 0 : priceSimulationPj.installments.length,
            simulationId: savedSimulationId,
        };
    }
    function saveCcbSimulationPj(values) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!selectedCompanyRef.current) {
                throw new AdminError(`${t("requiredCompanyNameText")}`, "API666SCRC", "SimulationError");
            }
            if (!priceSimulationPj) {
                throw new AdminError(`${t("requiredPriceSimulationText")}`, "API667PSPJ", "SimulationError");
            }
            const savedSimulationId = yield ccbPjStore.saveCcbSimulationPj({
                companyId: selectedCompanyRef.current.id,
                priceSimulationPj,
                simulationFormArgs: values,
                simulationId,
            });
            setSimulatorRequestContractDataValues(buildContractDataValues(savedSimulationId));
            return savedSimulationId;
        });
    }
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (errors) {
                return;
            }
            try {
                setLoading(true);
                const savedSimulationId = yield saveCcbSimulationPj(values);
                routerStore.push(`${AdminRoutes.Simulator.Simulation}/${savedSimulationId}`);
                generalStore.setSuccessMessage(`${t("simulationSavedSuccessfullyText")}`);
            }
            catch (error) {
                catchErrors(error, generalStore.setErrorMessage, t("saveCcbSimulatorFail"));
            }
            finally {
                setLoading(false);
            }
        }));
    });
    const handleStartRequest = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (errors) {
                return;
            }
            try {
                setLoading(true);
                const savedSimulationId = yield saveCcbSimulationPj(values);
                routerStore.push(`${AdminRoutes.Simulator.Request}/${savedSimulationId}`);
            }
            catch (error) {
                catchErrors(error, generalStore.setErrorMessage, t("saveCcbSimulationPjFail"));
            }
            finally {
                setLoading(false);
            }
        }));
    });
    const handleInstallmentDate = (installment) => {
        setInstallmentsDates((installmentsOldValue) => {
            if (installment.number === 1) {
                return [installment];
            }
            const installmentsNewValue = installmentsOldValue.filter((i) => i.number < installment.number && i.number !== 1);
            return [priceSimulationPj.installments[0], ...installmentsNewValue, installment];
        });
    };
    useEffect(() => {
        debouncedSimulateCcb();
    }, [installmentsDates, installmentsValues]);
    const handleInstallmentValue = (installment) => {
        setInstallmentsValues((installmentsOldValue) => {
            const installmentWithId = installmentsOldValue.map((i) => (Object.assign(Object.assign({}, i), { id: i.number })));
            const installmentsNewValue = removeItemFromCollectionById(installmentWithId, installment.number);
            return [...installmentsNewValue, installment];
        });
    };
    const debouncedSimulateCcb = useRef(debounce(() => {
        simulateCcb({
            ccbPjSimulationParams: ccbPjSimulationDtoRef.current,
            installmentsDates: installmentsDatesRef.current,
            installmentsValues: installmentsValuesRef.current,
        });
    }, 1000)).current;
    const handleDownloadSimulation = (e) => __awaiter(void 0, void 0, void 0, function* () {
        generalStore.clearMessage();
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (errors) {
                return;
            }
            try {
                setLoading(true);
                yield saveCcbSimulationPj(values);
                setShowDownloadSimulationModal(true);
            }
            catch (error) {
                catchErrors(error, generalStore.setErrorMessage, t("saveCcbSimulationPjFail"));
            }
            finally {
                setLoading(false);
            }
        }));
    });
    function buildDownloadSimulationData() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return {
            companyName: (_a = selectedCompanyRef.current) === null || _a === void 0 ? void 0 : _a.name,
            cnpj: (_b = selectedCompanyRef.current) === null || _b === void 0 ? void 0 : _b.cnpj,
            requestedAmount: (_c = ccbPjSimulationDtoRef.current) === null || _c === void 0 ? void 0 : _c.requestedAmount,
            installmentsQuantity: priceSimulationPj === null || priceSimulationPj === void 0 ? void 0 : priceSimulationPj.installments.length,
            valueCreditDate: (_d = ccbPjSimulationDtoRef.current) === null || _d === void 0 ? void 0 : _d.valueCreditDate,
            installmentReferenceDate: (_e = ccbPjSimulationDtoRef.current) === null || _e === void 0 ? void 0 : _e.installmentReferenceDate,
            interestRatePerMonth: (_f = ccbPjSimulationDtoRef.current) === null || _f === void 0 ? void 0 : _f.interestRatePerMonth,
            structuringRate: (_g = ccbPjSimulationDtoRef.current) === null || _g === void 0 ? void 0 : _g.structuringRate,
            joinSeguroPrestamista: (_h = ccbPjSimulationDtoRef.current) === null || _h === void 0 ? void 0 : _h.joinSeguroPrestamista,
            tedValue: (_j = ccbPjSimulationDtoRef.current) === null || _j === void 0 ? void 0 : _j.tedValue,
            thirdPartyValue: (_k = ccbPjSimulationDtoRef.current) === null || _k === void 0 ? void 0 : _k.thirdPartyValue,
        };
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { hasBackButton: true, linkToBackButton: AdminRoutes.Simulator.Report, title: t("simulationLabel") }),
        React.createElement(SimulationDownloadModal, { downloadSimulationData: buildDownloadSimulationData(), priceSimulationPjResult: priceSimulationPj, visible: showDownloadSimulationModal, onCancel: () => setShowDownloadSimulationModal(false) }),
        React.createElement(Form, { layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit },
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.sectionCard, "data-cy": "request-fields-section-data-cy" },
                    React.createElement(FormFieldWrapper, null,
                        React.createElement(Typograph, { variant: "modalTitle" }, t("companyNameLabel")),
                        React.createElement(SelectSearchField, { mode: "default", placeholder: t("companyLabel"), selectOptions: mapCompaniesToSelectOptions(companies), className: styles.field, onChange: handleCompaniesSelectChange, "data-cy": "companies-select-data-cy", defaultValue: selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id })),
                    React.createElement(FormFieldWrapper, null,
                        React.createElement(Typograph, { variant: "modalTitle" }, t("cnpjLabel")),
                        React.createElement(Input, { allowClear: true, value: formatCnpj(selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.cnpj), disabled: true, "data-cy": "cnpj-input-data-cy" })),
                    React.createElement(FormFieldWrapper, null,
                        React.createElement(Typograph, { variant: "modalTitle" }, t("requestValueLabel")),
                        getFieldDecorator(requestedAmountKey, {
                            initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.requestedAmount,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", { field: t("requestValueLabel") }),
                                },
                            ],
                        })(React.createElement(InputCurrency, { className: styles.sliderInput, min: 10000, step: 500000, max: 2100000000, formatter: (value) => formatCurrency(`${value}`), onChange: onChangeRequestValue, "data-cy": "request-value-input" })),
                        React.createElement(CustomSlider, { min: 500000, max: 500000000, step: 500000, value: getFieldValue(requestedAmountKey), onChange: onChangeRequestValue })),
                    React.createElement(FormFieldWrapper, null,
                        React.createElement(Typograph, { variant: "modalTitle" }, t("installmentsQuantityLabel")),
                        getFieldDecorator(numberOfInstallmentsKey, {
                            initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.numberOfInstallments,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", { field: t("installmentsQuantityLabel") }),
                                },
                            ],
                        })(React.createElement(InputCurrency, { className: styles.sliderInput, min: 1, max: 360, formatter: (value) => formatNumberOfInstallmentsInput(value), onChange: onChangeNumberOfInstallments, "data-cy": "number-of-installments-input" })),
                        React.createElement(CustomSlider, { min: 1, max: 60, step: 1, value: getFieldValue(numberOfInstallmentsKey), onChange: onChangeNumberOfInstallments })),
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 13 },
                            React.createElement(FormFieldWrapper, null,
                                React.createElement(Typograph, { variant: "modalTitle" }, t("loanApplication.valueCreditDateLabel")),
                                getFieldDecorator("valueCreditDate", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.valueCreditDate,
                                    rules: [
                                        {
                                            required: true,
                                            message: t("mandatoryField", {
                                                field: t("loanApplication.valueCreditDateLabel"),
                                            }),
                                        },
                                    ],
                                })(React.createElement(DatePicker, { placeholder: getTodayDateLocalTimezoneFormat(), format: "DD/MM/YYYY", "data-cy": "value-credit-date-input" })))),
                        React.createElement(Col, { span: 11 },
                            React.createElement(FormFieldWrapper, null,
                                React.createElement(Typograph, { variant: "modalTitle" }, t("loanApplication.interestRatePerMonthLabel")),
                                getFieldDecorator("interestRatePerMonth", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.interestRatePerMonth,
                                    rules: [
                                        {
                                            required: true,
                                            message: t("mandatoryField", {
                                                field: t("loanApplication.interestRatePerMonthLabel"),
                                            }),
                                        },
                                    ],
                                })(React.createElement(InputNumber, { min: 0, placeholder: "0,00", formatter: (value) => formatCurrencyNoPrefix(`${value}`), "data-cy": "interest-rate-per-month-input" }))))),
                    React.createElement(Row, { gutter: 20 },
                        React.createElement(Col, { span: 12 },
                            React.createElement(FormFieldWrapper, null,
                                React.createElement(Typograph, { variant: "modalTitle" }, t("loanApplication.installmentReferenceDateLabel")),
                                getFieldDecorator("installmentReferenceDate", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.installmentReferenceDate,
                                    rules: [
                                        {
                                            required: true,
                                            message: t("mandatoryField", {
                                                field: t("loanApplication.installmentReferenceDateLabel"),
                                            }),
                                        },
                                    ],
                                })(React.createElement(DatePicker, { placeholder: getTodayDateLocalTimezoneFormat(), format: "DD/MM/YYYY", "data-cy": "value-first-installment-date-input" })))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(FormFieldWrapper, null,
                                React.createElement(Typograph, { variant: "modalTitle" }, t("agreement.structuringRateLabel")),
                                getFieldDecorator("structuringRate", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.structuringRate,
                                    rules: [
                                        {
                                            required: true,
                                            message: t("mandatoryField", {
                                                field: t("agreement.structuringRateLabel"),
                                            }),
                                        },
                                    ],
                                })(React.createElement(InputNumber, { min: 0, placeholder: "0,00", formatter: (value) => formatCurrencyNoPrefixFiveDecimals(`${value}`), "data-cy": "structuring-rate-input" }))))),
                    React.createElement(HideableContainer, { className: styles.hideableContainer, title: React.createElement(Typograph, { className: styles.hideableContainerTitle, variant: "modalTitle" }, t("simulator.otherData")) },
                        React.createElement("div", { className: styles.otherDataContainer },
                            React.createElement(FormFieldWrapper, { className: styles.seguroPrestamistaCheck },
                                React.createElement(Typograph, { variant: "modalTitle" }, t("simulator.seguroPrestamista")),
                                getFieldDecorator("joinSeguroPrestamista", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.joinSeguroPrestamista,
                                    valuePropName: "checked",
                                })(React.createElement(Checkbox, { "data-cy": "seguro-prestamista-check-data-cy" }))),
                            React.createElement(FormFieldWrapper, null,
                                React.createElement(Typograph, { variant: "modalTitle" }, t("simulator.tedValue")),
                                getFieldDecorator("tedValue", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.tedValue,
                                })(React.createElement(InputCurrencyPrefix, { min: 0, "data-cy": "ted-value-input-data-cy" }))),
                            React.createElement(FormFieldWrapper, null,
                                React.createElement(Typograph, { variant: "modalTitle" }, t("simulator.otherTaxes")),
                                getFieldDecorator("thirdPartyValue", {
                                    initialValue: initialSimulationValues === null || initialSimulationValues === void 0 ? void 0 : initialSimulationValues.thirdPartyValue,
                                })(React.createElement(InputCurrencyPrefix, { min: 0, "data-cy": "other-tax-input-data-cy" })))))),
                React.createElement("div", { style: { width: "100%" } },
                    React.createElement(Spin, { spinning: loading },
                        React.createElement("div", { className: styles.simulationResult },
                            React.createElement(InstallmentsForm, { installments: priceSimulationPj === null || priceSimulationPj === void 0 ? void 0 : priceSimulationPj.installments, handleInstallmentDate: handleInstallmentDate, handleInstallmentValue: handleInstallmentValue }),
                            React.createElement(SimulationResult, { spinning: loading, priceSimulationPjResult: priceSimulationPj, onClickSubmit: handleSubmit, onStartRequest: handleStartRequest, onDownloadSimulation: handleDownloadSimulation }))))))));
};
export const Simulator = Form.create({
    name: "simulator_form",
})(BaseSimulator);
