import { __awaiter } from "tslib";
import React, { useState, useEffect, createRef } from "react";
import { Modal, Spin, Steps } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../utils/mobx";
import { companyGeneralSteps } from "../../../../shared/components/Company/Forms/Steps";
import { cut } from "../../../../shared/utils/formatters";
import { ReadOnlyForm } from "../../../../shared/components/Company/Forms/ReadOnlyForm";
import s from "./style.scss";
const { Step } = Steps;
export const CompanyInfoModal = observer(() => {
    const [companyInfo, setCompanyInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const companyInfoWrapperRef = createRef();
    const { legalResponsibleStore: { signatureId, getCompanyInfo, setSignatureId }, generalStore, generalStore: { catchErrors }, } = useStores();
    const { t } = useTranslation();
    const fetchCompanyInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const fetchedCompanyInfo = yield getCompanyInfo();
            setCompanyInfo(fetchedCompanyInfo);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        if (!!signatureId) {
            setCurrentStep(0);
            fetchCompanyInfo();
        }
    }, [signatureId]);
    useEffect(() => {
        if (companyInfoWrapperRef && companyInfoWrapperRef.current) {
            companyInfoWrapperRef.current.scrollTo({ top: 0, behavior: "auto" });
        }
    }, [companyInfoWrapperRef]);
    const handleCloseModal = () => {
        setSignatureId("");
    };
    const handleStepChange = (newIndex) => {
        setCurrentStep(newIndex);
    };
    const getStepDescription = (index) => {
        if (index === currentStep) {
            return t("portalPj.steps.viewing");
        }
        else if (index < currentStep) {
            return t("portalPj.steps.visualized");
        }
        return t("portalPj.steps.pending");
    };
    return (React.createElement(Modal, { title: "", okText: "OK", onOk: handleCloseModal, onCancel: handleCloseModal, cancelButtonProps: { style: { display: "none" } }, visible: !!signatureId, width: 1200 },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: s.contentWrapper },
                React.createElement(Steps, { size: "small", direction: "vertical", current: currentStep, onChange: handleStepChange, className: s.stepsWrapper }, companyGeneralSteps.map((step, index) => (React.createElement(Step, { key: step.title, title: cut(step.title, 25), description: getStepDescription(index), "data-cy": step.dataCy })))),
                React.createElement("div", { style: { display: loading || !companyInfo ? "none" : "block" }, className: s.formWrapper, ref: companyInfoWrapperRef },
                    React.createElement(ReadOnlyForm, { companyInfo: companyInfo, currentStep: currentStep }))))));
});
