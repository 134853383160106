import React from "react";
import { getDateLocalTimezoneFormat } from "../../../../shared/utils/date";
import { formatStringCurrencyNoPrefix } from "../../../../shared/utils/formatters";
import Table from "../../../../shared/components/Table";
import i18n from "../../../../admin/i18n";
const WithdrawalsAntecipatedTable = ({ withdrawalsAntecipated, }) => {
    const withdrawalsAntecipatedColumns = [
        {
            title: i18n.t("dateLabel"),
            dataIndex: "date",
            key: "date",
            render: (date) => getDateLocalTimezoneFormat(date),
        },
        {
            title: i18n.t("lowerCaseValueLabel"),
            dataIndex: "value",
            key: "value",
            render: (value) => formatStringCurrencyNoPrefix(value),
        },
    ];
    return React.createElement(Table, { columns: withdrawalsAntecipatedColumns, data: withdrawalsAntecipated });
};
export default WithdrawalsAntecipatedTable;
