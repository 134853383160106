import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, Modal, Spin, Alert } from "antd";
import { checkCPF } from "../../../../shared/utils/formValidator";
import isEmail from "validator/lib/isEmail";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
const InnerForm = observer(({ form, visible, title, onCancel, onCreate, onCloseAlert, okText, initialValues, loading, error, }) => {
    const { validateFields, getFieldDecorator } = form;
    const { t } = useTranslation();
    const checkEmail = (_, value, callback) => {
        if (!value || value === "") {
            callback(t("companyEmployee.insertEmailText"));
            return;
        }
        if (isEmail(value)) {
            callback();
            return;
        }
        callback(t("invalidEmailText"));
    };
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                let result;
                result = yield onCreate(values);
                if (!result) {
                    form.resetFields();
                }
            }
        }));
    });
    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };
    return (React.createElement(Modal, { visible: visible, title: title, onCancel: handleCancel, onOk: handleSubmit, okText: okText || t("registerLabel") },
        React.createElement(Form, { hideRequiredMark: true },
            React.createElement(Spin, { spinning: loading },
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 14 },
                        React.createElement(Form.Item, { label: t("nameLabel") }, getFieldDecorator("name", {
                            initialValue: initialValues ? initialValues.name : "",
                            rules: [
                                {
                                    required: true,
                                    message: t("nameLabel") + t("isRequiredLabel"),
                                },
                            ],
                        })(React.createElement(Input, { autoFocus: true, allowClear: true, maxLength: 300 })))),
                    React.createElement(Col, { span: 10 },
                        React.createElement(Form.Item, { label: "CPF" }, getFieldDecorator("cpf", {
                            initialValue: initialValues ? initialValues.cpf : "",
                            rules: [{ validator: checkCPF }],
                        })(React.createElement(MaskedInput, { mask: "111.111.111-11", allowClear: true, maxLength: 15 }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 14 },
                        React.createElement(Form.Item, { label: t("loginEmailLabel") }, getFieldDecorator("email", {
                            initialValue: initialValues ? initialValues.email : "",
                            rules: [{ validator: checkEmail }],
                        })(React.createElement(Input, { allowClear: true, maxLength: 300 }))))),
                error && (React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 24 },
                        React.createElement(Alert, { message: error, type: "error", showIcon: true, closable: true, onClose: onCloseAlert }))))))));
});
export const HrEmployeeForm = Form.create({
    name: "hr_employee_form",
})(InnerForm);
