import i18n from "../../../../../admin/i18n";
export var CcbPjStep;
(function (CcbPjStep) {
    CcbPjStep[CcbPjStep["GUARANTORS"] = 0] = "GUARANTORS";
    CcbPjStep[CcbPjStep["GUARANTEES"] = 1] = "GUARANTEES";
    CcbPjStep[CcbPjStep["CONDITIONS"] = 2] = "CONDITIONS";
    CcbPjStep[CcbPjStep["OPINION"] = 3] = "OPINION";
    CcbPjStep[CcbPjStep["CCB_PREVIEW"] = 4] = "CCB_PREVIEW";
})(CcbPjStep || (CcbPjStep = {}));
export const contractCcbPjSteps = [
    {
        title: i18n.t("simulatorContractStep.guarantors"),
        description: "Description",
        dataCy: "step-Guarantors",
    },
    {
        title: i18n.t("simulatorContractStep.guarantees"),
        description: "Description",
        dataCy: "step-guarantees",
    },
    {
        title: i18n.t("simulatorContractStep.conditions"),
        description: "Description",
        dataCy: "step-conditions",
    },
    {
        title: i18n.t("simulatorContractStep.opinion"),
        description: "Description",
        dataCy: "step-opinion",
    },
    {
        title: i18n.t("simulatorContractStep.ccbPreview"),
        description: "Description",
        dataCy: "step-ccb-preview",
    },
];
