import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Upload } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import s from "../style.scss";
import { IconButton } from "../../../../../shared/components/IconButton";
import { DownloadIcon } from "../../../../../shared/components/Icons/Download";
const SignedAgreementForm = observer(({ disabled, form, filename, downloadAgreementFile }) => {
    const { getFieldDecorator } = form;
    const [fileList, setFileList] = React.useState([]);
    const { t } = useTranslation();
    const validateFile = (_, value, callback) => {
        if (value === undefined) {
            callback(t("agreement.uploadRequiredText"));
            return;
        }
        if (!value.file.type.includes("pdf")) {
            callback(t("agreement.pdfFileText"));
            return;
        }
        callback();
    };
    const onRemove = () => {
        setFileList([]);
    };
    const beforeUpload = (file) => {
        if (file.type.includes("pdf")) {
            setFileList([file]);
        }
        return false;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("agreement.signedContractAssignment") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: t(""), extra: filename ? "" : t("agreement.selectPdfLabel") }, getFieldDecorator("file", {
                    rules: [
                        {
                            validator: validateFile,
                        },
                    ],
                })(filename ? (React.createElement(React.Fragment, null,
                    React.createElement(IconButton, { buttonIcon: () => React.createElement(DownloadIcon, { fill: "#8d8d8d" }), onClick: downloadAgreementFile }, t("agreement.downloadAgreementLabel")),
                    React.createElement("p", null, filename))) : (React.createElement(Upload, { disabled: disabled, accept: ".pdf", multiple: false, beforeUpload: beforeUpload, onRemove: onRemove, fileList: fileList, className: s.uploadButton },
                    React.createElement(Button, { type: "ghost", icon: "upload", disabled: disabled }, t("agreement.uploadLabel"))))))))));
});
export default SignedAgreementForm;
