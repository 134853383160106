import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { PortabilityLoanContractForm } from "../PortabilityLoanContractForm";
import { useStores } from "../../../../utils/mobx";
import PortabilityInstallmentsTable from "../PortabilityInstallments";
import { useTranslation } from "react-i18next";
import { DateFormat, getDateLocalTimezoneFormat } from "../../../../../shared/utils/date";
const PortabilityModal = ({ visible, onCancel, loanId, onSuccess, }) => {
    const { t } = useTranslation();
    const { contractStore: { simulatePortability, makeLoanPortability, getLatestSourceCompany, getUserActiveCompaniesByLoanId, }, generalStore: { catchErrors, setSuccessMessage }, } = useStores();
    const [remainingInstallments, setRemainingInstallments] = useState();
    const [sourceCompany, setSourceCompany] = useState();
    const [destinyCompanies, setDestinyCompanies] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    function cleanPortabilityInstallments() {
        setErrorMessage(undefined);
        setRemainingInstallments(undefined);
    }
    const handleDateChange = (date) => __awaiter(void 0, void 0, void 0, function* () {
        const shouldSimulate = loanId && date;
        cleanPortabilityInstallments();
        if (shouldSimulate) {
            try {
                setLoading(true);
                const installments = yield simulatePortability(getDateLocalTimezoneFormat(date, DateFormat.ISO), loanId);
                setRemainingInstallments(installments);
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("portability.defaultErrorMessage"));
            }
            finally {
                setLoading(false);
            }
        }
    });
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const shouldSubmit = (sourceCompany === null || sourceCompany === void 0 ? void 0 : sourceCompany.id) && loanId;
        if (shouldSubmit) {
            try {
                setLoading(true);
                yield makeLoanPortability(sourceCompany.id, values.destinyCompanyId, loanId, getDateLocalTimezoneFormat(values.transferDate, DateFormat.ISO));
                setSuccessMessage(t("portability.portabilitySuccessMessage"));
                onSuccess();
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("portability.defaultErrorMessage"));
            }
            finally {
                setLoading(false);
            }
        }
    });
    function cleanPortabilityOptions() {
        setSourceCompany(undefined);
        setDestinyCompanies([]);
        setRemainingInstallments(undefined);
    }
    const fetchPortabilityOptions = (loanIdToFetchPortabilityOptions) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            cleanPortabilityOptions();
            setLoading(true);
            const latestSourceCompany = yield getLatestSourceCompany(loanIdToFetchPortabilityOptions);
            const userActiveCompaniesByLoanId = yield getUserActiveCompaniesByLoanId(loanIdToFetchPortabilityOptions);
            setSourceCompany(latestSourceCompany);
            setDestinyCompanies(userActiveCompaniesByLoanId);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("portability.defaultErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        const portabilityModalHasBeenOpened = loanId && visible;
        if (portabilityModalHasBeenOpened) {
            setErrorMessage(undefined);
            fetchPortabilityOptions(loanId);
        }
    }, [loanId, visible]);
    return (React.createElement(PortabilityLoanContractForm, { sourceCompany: sourceCompany, destinyCompanies: destinyCompanies, onDateChange: handleDateChange, visible: visible, onCancel: onCancel, onSubmit: handleSubmit, errorMessage: errorMessage, loading: loading }, remainingInstallments && (React.createElement(PortabilityInstallmentsTable, { installments: remainingInstallments }))));
};
export default PortabilityModal;
