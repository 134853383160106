import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { TableWithFilter } from "../../../../shared/components/TableWithFilter";
import { filterActivatedItemsByName, filterItemsByName } from "../../../../shared/utils/colection";
const mapUsersToTableDataSource = (users) => users === null || users === void 0 ? void 0 : users.map((user) => (Object.assign(Object.assign({}, user), { key: user.id })));
const UsersTable = observer(({}) => {
    const { t } = useTranslation();
    const [showAllUsers, setShowAllUsers] = useState(false);
    const { accessProfileStore, accessProfileStore: { addUser, allUsers, isFilteringUsers, userNameFilter, removeUser }, } = useStores();
    const columns = [
        { title: t("nameLabel"), dataIndex: "name", key: "name", width: "30%" },
        { title: t("emailLabel"), dataIndex: "email", key: "email" },
    ];
    const selectedRowKeys = useMemo(() => allUsers === null || allUsers === void 0 ? void 0 : allUsers.filter(({ activated }) => activated).map(({ id }) => id), [allUsers]);
    const usersTableSource = useMemo(() => {
        let usersFiltered;
        if (showAllUsers) {
            usersFiltered = filterItemsByName(allUsers, userNameFilter);
        }
        else {
            usersFiltered = filterActivatedItemsByName(allUsers, userNameFilter);
        }
        const usersFilteredMapedToDataSource = mapUsersToTableDataSource(usersFiltered);
        return usersFilteredMapedToDataSource;
    }, [showAllUsers, allUsers, selectedRowKeys, userNameFilter]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableWithFilter, { columns: columns, dataSource: usersTableSource, selectedRowKeys: selectedRowKeys, onFilterChange: (values) => {
                accessProfileStore.setUserNameFilter(values.name);
            }, table: {
                headerProps: {
                    action: "Associar",
                    hideToogleButton: isFilteringUsers,
                    selectedItems: Number(selectedRowKeys === null || selectedRowKeys === void 0 ? void 0 : selectedRowKeys.length),
                    totalOfItems: Number(allUsers === null || allUsers === void 0 ? void 0 : allUsers.length),
                    show: showAllUsers,
                    onToogleClick: () => setShowAllUsers((oldValue) => !oldValue),
                },
                rowSelection: {
                    onSelect: (user, selected) => (selected ? addUser(user) : removeUser(user)),
                },
            } })));
});
export default UsersTable;
