import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, InputNumber as AntdInputNumber, DatePicker, Radio } from "antd";
import { useStores } from "../../../../utils/mobx";
import { formatCurrencyNoPrefix, toInt } from "../../../../../shared/utils/formatters";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import InputNumber from "../../../../../shared/components/InputNumber";
import InputCurrency from "../../../../../shared/components/InputCurrency";
import moment from "moment";
import s from "./style.scss";
import { toMoment } from "../../../../../shared/utils/date";
import { isInterestRateRange } from "../../../../../shared/utils/agreements";
const MAX_PERCENT_ALLOWED = 3500;
const CreditPolicyForm = observer(({ form, disabled, initialValues, setShouldShowMinWorkTimeForTermExtension, setShouldShowInterestRateRange, }) => {
    var _a, _b, _c, _d, _e;
    const { getFieldDecorator } = form;
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { t } = useTranslation();
    function getHasExtendedMaximumInstallments() {
        const hasExtendedMaximumInstallments = !!form.getFieldValue("extendedMaximumInstallments");
        setShouldShowMinWorkTimeForTermExtension(hasExtendedMaximumInstallments);
        return hasExtendedMaximumInstallments;
    }
    function getIsInterestRateRange(agreementValues) {
        const isInterestRateRangeValue = agreementValues
            ? isInterestRateRange(agreementValues)
            : !!form.getFieldValue("isInterestRateRange");
        setShouldShowInterestRateRange(isInterestRateRangeValue);
        return isInterestRateRangeValue;
    }
    const checkMaxPercentAllowed = (_, value, callback) => {
        if (!value) {
            callback(t("agreement.maxPercentAllowedLabel") + t("isRequiredLabel"));
            return;
        }
        if (value > MAX_PERCENT_ALLOWED) {
            callback(t("agreement.percentLimit"));
            return;
        }
        callback();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("agreement.creditPolicyLabel") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.minWorkTimeLabel"), extra: t("agreement.inMonthLabel") }, getFieldDecorator("minWorkTime", {
                    initialValue: initialValues ? initialValues.minWorkTime : "",
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.minWorkTimeLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(InputNumber, { "data-testid": "minWorkTime", disabled: disabled, onFocus: () => setSelectedItem("minWorkTime") })))),
            React.createElement(Col, { sm: 17, lg: 10, xxl: 7 },
                React.createElement(Form.Item, { label: t("agreement.maxLoanInSalariesLabel") }, getFieldDecorator("maxLoanInSalaries", {
                    initialValue: initialValues !== null ? initialValues.maxLoanInSalaries : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.maxLoanInSalariesLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(AntdInputNumber, { disabled: disabled, "data-testid": "maxLoanInSalaries", placeholder: "0,00", formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, onFocus: () => setSelectedItem(`maxLoanInSalaries`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.maxPercentAllowedLabel") + " (%)", extra: t("agreement.percentLimit") }, getFieldDecorator("maxPercentAllowed", {
                    initialValue: initialValues !== null ? initialValues.maxPercentAllowed : null,
                    normalize: (value) => toInt(value),
                    rules: [{ validator: checkMaxPercentAllowed }],
                })(React.createElement(AntdInputNumber, { disabled: disabled, placeholder: "0,00", "data-testid": "maxPercentAllowed", parser: (value) => toInt(value), formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, max: MAX_PERCENT_ALLOWED, onFocus: () => setSelectedItem(`maxPercentAllowed`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.isInterestRateRange"), "data-cy": "is-interest-rate-range-form-item", "data-testid": "isInterestRateRange" }, getFieldDecorator("isInterestRateRange", {
                    initialValue: initialValues ? isInterestRateRange(initialValues) : false,
                })(React.createElement(Radio.Group, { disabled: disabled },
                    React.createElement(Radio, { "data-testid": "yesIsInterestRateRange", value: true }, t("yesLabel")),
                    React.createElement(Radio, { "data-testid": "noIsInterestRateRange", value: false }, t("noLabel"))))))),
        React.createElement(Row, { gutter: 20 },
            getIsInterestRateRange(initialValues) && (React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.minInterestRate") + " %" }, getFieldDecorator("minInterestRate", {
                    initialValue: initialValues !== null ? initialValues.minInterestRate : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.minInterestRate") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(AntdInputNumber, { disabled: disabled, placeholder: "0,00", "data-testid": "minInterestRate", formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, onFocus: () => setSelectedItem(`minInterestRate`) }))))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t(getIsInterestRateRange(initialValues)
                        ? "agreement.maxInterestRate"
                        : "agreement.interestRateLabel") + " %" }, getFieldDecorator("maxInterestRate", {
                    initialValue: initialValues !== null ? initialValues.maxInterestRate : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: (getIsInterestRateRange(initialValues)
                                ? t("agreement.maxInterestRate")
                                : t("agreement.interestRateLabel")) + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(AntdInputNumber, { disabled: disabled, placeholder: "0,00", "data-testid": "maxInterestRate", formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, onFocus: () => setSelectedItem(`maxInterestRate`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.maxAllowedInstallmentsLabel") }, getFieldDecorator("maximumInstallments", {
                    initialValue: initialValues !== null ? initialValues.maximumInstallments : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.maxAllowedInstallmentsLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(InputNumber, { "data-testid": "maximumInstallments", disabled: disabled, onFocus: () => setSelectedItem(`maximumInstallments`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { className: s.inputNumber, label: t("agreement.minLoanValueLabel") + " (R$)" }, getFieldDecorator("minimumLoanAmount", {
                    initialValue: initialValues !== null ? initialValues.minimumLoanAmount : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.minLoanValueLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(InputCurrency, { disabled: disabled, "data-testid": "minimumLoanAmount", placeholder: "0,00", min: 0, formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, onFocus: () => setSelectedItem(`minimumLoanAmount`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.structuringRateLabel") }, getFieldDecorator("structuringRate", {
                    initialValue: initialValues !== null ? initialValues.structuringRate : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.structuringRateLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(AntdInputNumber, { disabled: disabled, "data-testid": "structuringRate", placeholder: "0,00", formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, min: 0, onFocus: () => setSelectedItem(`structuringRate`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.dueDateLabel") }, getFieldDecorator("dueDate", {
                    initialValue: initialValues !== null ? toMoment(initialValues.dueDate) : null,
                    rules: [
                        {
                            required: true,
                            message: t("agreement.dueDateLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(DatePicker, { "data-testid": "dueDate", "data-cy": "agreementDueDate", placeholder: "22/12/2021", format: "DD/MM/YYYY", disabled: disabled, disabledDate: (date) => (date ? date <= moment().endOf("day") : false) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { className: s.inputNumber, label: t("agreement.tedValueLabel") + " (R$)" }, getFieldDecorator("tedValue", {
                    initialValue: (_a = initialValues === null || initialValues === void 0 ? void 0 : initialValues.tedValue) !== null && _a !== void 0 ? _a : 0,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.tedValueLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(InputCurrency, { "data-testid": "tedValue", disabled: disabled, placeholder: "0,00", min: 0, formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, onFocus: () => setSelectedItem(`tedValue`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { className: s.inputNumber, label: t("agreement.custodyValueLabel") + " (R$)" }, getFieldDecorator("custodyValue", {
                    initialValue: (_b = initialValues === null || initialValues === void 0 ? void 0 : initialValues.custodyValue) !== null && _b !== void 0 ? _b : 0,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.custodyValueLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(InputCurrency, { disabled: disabled, "data-testid": "custodyValue", placeholder: "0,00", min: 0, formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, onFocus: () => setSelectedItem(`custodyValue`) }))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { className: s.inputNumber, label: t("agreement.thirdPartyValueLabel") + " (R$)" }, getFieldDecorator("thirdPartyValue", {
                    initialValue: (_c = initialValues === null || initialValues === void 0 ? void 0 : initialValues.thirdPartyValue) !== null && _c !== void 0 ? _c : 0,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("agreement.thirdPartyValueLabel") + t("isRequiredLabel"),
                        },
                    ],
                })(React.createElement(InputCurrency, { disabled: disabled, "data-testid": "thirdPartyValue", placeholder: "0,00", min: 0, formatter: (value) => `${formatCurrencyNoPrefix(`${value}`)}`, onFocus: () => setSelectedItem(`thirdPartyValue`) })))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.denyParallelLoansLabel"), "data-cy": "deny-parallel-loans-form-item", "data-testid": "denyParallelLoans" }, getFieldDecorator("denyParallelLoans", {
                    initialValue: (_d = initialValues === null || initialValues === void 0 ? void 0 : initialValues.denyParallelLoans) !== null && _d !== void 0 ? _d : false,
                })(React.createElement(Radio.Group, { disabled: disabled },
                    React.createElement(Radio, { value: true }, t("yesLabel")),
                    React.createElement(Radio, { value: false }, t("noLabel"))))))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.extendedMaximumInstallments") }, getFieldDecorator("extendedMaximumInstallments", {
                    initialValue: initialValues !== null ? initialValues.extendedMaximumInstallments : null,
                    normalize: (value) => toInt(value),
                })(React.createElement(InputNumber, { "data-testid": "extendedMaximumInstallments", disabled: disabled, min: 0, onFocus: () => setSelectedItem(`extendedMaximumInstallments`) })))),
            getHasExtendedMaximumInstallments() && (React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.minWorkTimeForTermExtension"), extra: t("agreement.inMonthLabel") }, getFieldDecorator("minWorkTimeForTermExtension", {
                    initialValue: initialValues !== null ? initialValues.minWorkTimeForTermExtension : null,
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: true,
                            message: t("mandatoryField", {
                                field: t("agreement.minWorkTimeForTermExtension"),
                            }),
                        },
                    ],
                })(React.createElement(InputNumber, { "data-testid": "minWorkTimeForTermExtension", disabled: disabled, min: 1, onFocus: () => setSelectedItem(`minWorkTimeForTermExtension`) }))))),
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { label: t("agreement.creditCalculationAlgorithmLabel"), "data-cy": "credit-calculation-algorithm-form-item", "data-testid": "creditCalculationAlgorithm" }, getFieldDecorator("creditCalculationAlgorithm", {
                    initialValue: (_e = initialValues === null || initialValues === void 0 ? void 0 : initialValues.creditCalculationAlgorithm) !== null && _e !== void 0 ? _e : "default",
                })(React.createElement(Radio.Group, { disabled: disabled },
                    React.createElement(Radio, { value: "default" }, t("agreement.algorithm.defaultLabel")),
                    React.createElement(Radio, { value: "machine_learning" }, t("agreement.algorithm.machineLearningLabel")))))))));
});
export default CreditPolicyForm;
