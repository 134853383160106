export function sumValues(values) {
    return values.reduce((total, value) => total + value, 0);
}
export function shiftTwoDecimalPlacesToRight(value) {
    return value * 100;
}
export function goBackTwoDecimalPlaces(value) {
    return value && value / 100;
}
export function goBackFiveDecimalPlaces(value) {
    return value && value / 100000;
}
export function getRoundedNumber(value) {
    return Number(value.toFixed());
}
