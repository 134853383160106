import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Typograph } from "../../../../shared/components/Typograph";
import { HideableContainer } from "../../../../shared/components/HideableContainer";
import { DownloadIcon } from "../../../../shared/components/Icons/Download";
import classNames from "classnames";
import SimulationValueContainer from "./SimulationValueContainer";
import styles from "./style.scss";
import SimulationMoreInformationTable from "./SimulationMoreInformationTable";
const SimulationResult = ({ priceSimulationPjResult, spinning, onClickSubmit, onStartRequest, onDownloadSimulation, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.container },
        React.createElement(SimulationValueContainer, { spinning: spinning, priceSimulationPjResult: priceSimulationPjResult }),
        React.createElement(HideableContainer, { className: styles.hideableContainer, title: React.createElement(Typograph, { className: styles.hideableContainerTitle, variant: "modalTitle" }, t("simulator.otherInformations")) },
            React.createElement(SimulationMoreInformationTable, { priceSimulationPjResult: priceSimulationPjResult })),
        React.createElement("div", { className: styles.buttonsWrapper, "data-testid": "buttons-wrapper" },
            React.createElement("div", { className: styles.buttonsWrapper },
                React.createElement("div", null,
                    React.createElement(Button, { className: styles.button, type: "primary", htmlType: "submit", disabled: spinning, "data-cy": "save-button-data-cy", onClick: onClickSubmit }, t("saveSimulationLabel"))),
                React.createElement("div", null,
                    React.createElement(Button, { className: styles.button, type: "primary", "data-cy": "download-request-button-data-cy", onClick: onDownloadSimulation },
                        t("downloadLabel"),
                        " ",
                        React.createElement(DownloadIcon, null)))),
            React.createElement("div", null,
                React.createElement(Button, { className: classNames(styles.blueButton, styles.button), type: "primary", "data-testid": "start-request-button", "data-cy": "start-request-button-data-cy", onClick: onStartRequest }, t("startRequestLabel"))))));
};
export default SimulationResult;
