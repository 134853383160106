import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { useStores } from "../../../utils/mobx";
import { PasswordConfirmForm } from "../../../../shared/components/PasswordConfirmForm";
export const RedefinePassword = observer(() => {
    const { authStore, generalStore: { catchErrors }, } = useStores();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const { match } = useReactRouter();
    const { t } = useTranslation();
    const register = (password, passwordConfirm) => __awaiter(void 0, void 0, void 0, function* () {
        const id = match.params.id;
        setErrorMessage("");
        setLoading(true);
        try {
            yield authStore.registerNewPassword(id, password, passwordConfirm);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("registerNewPasswordFail"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Cadastre uma nova senha para sua conta"),
        React.createElement(PasswordConfirmForm, { loading: loading, handleSubmit: register, error: errorMessage })));
});
