import { createContext } from "react";
export const companyInfoInitialState = {
    cnpj: "",
    fantasyName: "",
    socialName: "",
    stateSubscription: "",
    inscricaoMunicipal: "",
};
export const addressInitialState = {
    cep: "",
    city: "",
    complement: "",
    neighborhood: "",
    number: "",
    state: "",
    street: "",
};
export const CompanyFormContext = createContext({
    companyInfo: companyInfoInitialState,
    address: addressInitialState,
    handleChangeAddress: () => { },
    handleChangeCompanyInfo: () => { },
    setAddress: () => { },
    setCompanyInfo: () => { },
});
