exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.paymentsTableWrapper___37Urj {\n  min-height: 200px; }\n  .paymentsTableWrapper___37Urj th {\n    background-color: #e6f4f5 !important;\n    text-transform: none !important; }\n\n.paymentsTable___3YzlW {\n  overflow: hidden;\n  min-height: 220px !important; }\n  .paymentsTable___3YzlW div[class*=\"table-body\"] {\n    min-height: 150px !important; }\n\n.revertButtonWrapper___uM-MA {\n  display: flex;\n  align-items: center;\n  height: 20px; }\n  .revertButtonWrapper___uM-MA svg {\n    transform: scale(0.8); }\n\n.deletedPayment___2rYv1 td {\n  text-decoration: line-through;\n  color: #eb5b5b !important; }\n", ""]);

// Exports
exports.locals = {
	"paymentsTableWrapper": "paymentsTableWrapper___37Urj",
	"paymentsTable": "paymentsTable___3YzlW",
	"revertButtonWrapper": "revertButtonWrapper___uM-MA",
	"deletedPayment": "deletedPayment___2rYv1"
};