import { __awaiter } from "tslib";
import { api } from "../AppWrapper";
export class BirthdayWithdrawalContractStore {
    constructor() {
        this.getContracts = (filter, page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () { return api.getBirthdayWithdrawalContracts({ filter, page, rowsPerPage }); });
        this.getContractsTotal = (filter) => __awaiter(this, void 0, void 0, function* () { return api.getBirthdayWithdrawalContractTotal({ filter }); });
        this.exportContracts = (filter) => __awaiter(this, void 0, void 0, function* () { return api.exportBirthdayWithdrawalContracts({ filter }); });
        this.cancelFgtsBirthdayWithdrawals = (fgtsWithdrawalsIds) => api.cancelFgtsBirthdayWithdrawals({ fgtsWithdrawalsIds });
    }
}
