import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input } from "antd";
import { FormTitle } from "../../../../../shared/components/FormTitle";
import { useStores } from "../../../../utils/mobx";
import { decoratorConfigDefaultOnBlur } from "../../../../../admin/utils/decoratorsConfig";
const AdditionalInfoForm = observer(({ form, initialValue }) => {
    const { getFieldDecorator } = form;
    const { tableOfContentStore: { setSelectedItem }, } = useStores();
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: t("companyForm.additionalInfoTitle") }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 16 },
                React.createElement(Form.Item, { label: t("companyForm.additionalInfoLabel") }, getFieldDecorator("additionalInfo", Object.assign({ initialValue }, decoratorConfigDefaultOnBlur))(React.createElement(Input, { allowClear: true, maxLength: 300, onFocus: () => setSelectedItem(`additionalInfo`) })))))));
});
export default AdditionalInfoForm;
