import i18n from "../../../../admin/i18n";
export const marriageRegimeOptions = [
    {
        value: "completeSeparation",
        label: i18n.t("guarantorsStep.completeSeparation"),
    },
    {
        value: "partialCommunion",
        label: i18n.t("guarantorsStep.partialCommunion"),
    },
    {
        value: "universalCommunion",
        label: i18n.t("guarantorsStep.universalCommunion"),
    },
];
export const civilStatusOptions = [
    {
        value: "divorcee",
        label: i18n.t("guarantorsStep.divorcee"),
    },
    {
        value: "separate",
        label: i18n.t("guarantorsStep.separate"),
    },
    {
        value: "married",
        label: i18n.t("guarantorsStep.married"),
    },
    {
        value: "single",
        label: i18n.t("guarantorsStep.single"),
    },
    {
        value: "widow",
        label: i18n.t("guarantorsStep.widow"),
    },
];
