import { __awaiter } from "tslib";
import React, { useState } from "react";
import html2canvas from "html2canvas";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../utils/mobx";
import onlyNumbers from "@brazilian-utils/helper-only-numbers";
import JoyRide from "react-joyride";
import { Icon, Modal, Tabs } from "antd";
import { FilterForm } from "../../../shared/components/FilterForm";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import ActionTable from "../../../shared/components/ActionTable";
import { formatCpf, formatStringCurrency, formatStringCurrencyNoPrefix, } from "../../../shared/utils/formatters";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { Pagination } from "../../../shared/components/Pagination";
import { defaultColumns } from "./column";
import { CedeContractForm } from "../../components/CedeContractForm";
import AmortizeModal from "../../components/AmortizeModal";
import PortabilityModal from "../../components/Contracts/Portability/PortabilityModal";
import { InstallmentsModal } from "../../components/InstallmentsModal";
import ActionSelect from "../../../shared/components/ActionSelect";
import CancelationModal from "../../components/Contracts/Cancelation/CancelationModal";
import { File } from "../../../shared/components/Icons/File";
import { FileText } from "../../../shared/components/Icons/FileText";
import Table from "../../../shared/components/Table";
import useReactRouter from "use-react-router";
import { download, downloadFileFromBuffer } from "../../../shared/utils/file";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import AmortizeConfirmationModal from "../../components/AmortizeConfirmationModal";
import { DateFormat, getDateLocalTimezoneFormat, toMoment } from "../../../shared/utils/date";
import { defaultConfirmProps } from "../../../shared/utils";
import { ContractDataTabCard } from "../../components/Contracts/ContractDataTabCard";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { loanLabels } from "../LoanApplications";
import i18n from "../../i18n";
import PortabilityTable from "../../components/Contracts/Portability/PortabilityTable";
import { formatTedStatus } from "../../utils/contracts.utils";
import DownloadTooltip from "../../components/DownloadTooltip";
import { getAdminContractsStatusLabel, getStatusAdminLabels, } from "../../../shared/constants/contracts.constants";
import { setContextMenuDataHandler } from "../../utils/table.utils";
import FloatingList from "../../components/FloatingList";
import ContractModalHeader from "../../components/Contracts/ContractModalHeader";
import { nullIfEmpty } from "../../../shared/utils/filter";
import { List } from "../../../shared/components/Icons/List";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
import { AdminRoutes } from "../../constants/routes.constants";
import classNames from "classnames";
import styles from "./style.scss";
const TOUR_STEPS = [
    {
        target: ".ant-table-content",
        content: i18n.t("contracts.tourContent"),
        disableScrollParentFix: true,
        disableOverlay: true,
        styles: {
            buttonNext: {
                display: "none",
            },
        },
    },
];
const confirm = Modal.confirm;
const { TabPane } = Tabs;
export const formatDateFilter = (values) => ({
    from: values.dataInitial ? values.dataInitial.toDate() : null,
    to: values.dataFinal ? values.dataFinal.toDate() : null,
});
export const defaultContextMenuData = {
    visible: false,
    x: 0,
    y: 0,
    currentContract: null,
};
export const ContractsCredifit = observer((props) => {
    const { t } = useTranslation();
    const { generalStore: { setErrorMessage, setSuccessMessage, clearMessage, catchErrors }, contractStore, companyStore, cessionReportStore, } = useStores();
    const { match } = useReactRouter();
    const fromCompany = () => {
        let initialFilter;
        if (companyStore.selectedCompany !== null && match.path === AdminRoutes.Company.Contracts) {
            initialFilter = {
                name: null,
                cpf: null,
                companyName: companyStore.selectedCompany.name,
                isOverdue: null,
                status: null,
                date: {
                    from: null,
                    to: null,
                },
                person: null,
                tedStatus: null,
                ccbNumber: null,
                companyGroupName: null,
            };
            return initialFilter;
        }
        initialFilter = {
            name: null,
            cpf: null,
            companyName: null,
            isOverdue: null,
            status: null,
            date: {
                from: null,
                to: null,
            },
            person: null,
            tedStatus: null,
            ccbNumber: null,
            companyGroupName: null,
        };
        return initialFilter;
    };
    const [cedeModal, setCedeModal] = useState(false);
    const [amortizeModal, setAmortizeModal] = useState(false);
    const [portabilityModalIsOpen, setPortabilityModalIsOpen] = useState(false);
    const [cancelationModalIsOpen, setCancelationModalIsOpen] = useState(false);
    const [installmentsModalContract, setInstallmentsModalContract] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const confirmationLabels = [
        t("contracts.amortizingTypeLabel"),
        t("amortizingDateLabel"),
        t("amortizingOrderLabel"),
        t("balanceLabel"),
        t("remainingInstallmentsLabel"),
        t("nextDueLabel"),
        t("lastDueLabel"),
    ];
    const [modalAmortization, setModalAmortization] = useState(null);
    const [contextMenuData, setContextMenuData] = useState(defaultContextMenuData);
    const [confirmationValues, setConfirmationValues] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [contracts, setContracts] = useState([]);
    const [contractsOthers, setContractOthers] = useState([]);
    const [totalContracts, setTotalContracts] = useState(0);
    const [calcValues, setCalcValues] = useState([]);
    const [calcResults, setCalcResults] = useState(null);
    const [loadingContracts, setLoadingContracts] = useState(false);
    const [selectedContractsKeys, setSelectedContractsKeys] = useState([]);
    const [currentFilter, setCurrentFilter] = useState(fromCompany());
    const [loadingCalc, setLoadingCalc] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState("");
    const [disableModalButton, setDisableModalButton] = React.useState(true);
    const [amortizingError, setAmortizingError] = useState();
    React.useEffect(() => {
        if (!props.isPayroll) {
            setContractOthers([]);
        }
    }, []);
    React.useEffect(() => {
        getContracts(currentFilter);
    }, [currentFilter, rowsPerPage, currentPage]);
    const actions = [
        {
            value: "ceded",
            label: t("contracts.giveLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("ceded", selectedKeys);
            },
        },
        {
            value: "settled",
            label: t("contracts.amortizeLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("settled", selectedKeys);
            },
        },
        {
            value: "portabilited",
            label: t("contracts.portabilityLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("portabilited", selectedKeys);
            },
        },
        {
            value: "canceled",
            label: t("contracts.cancelLabel"),
            onClick: (selectedKeys) => {
                handleOpenConfirm("canceled", selectedKeys);
            },
        },
    ];
    const getContracts = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingContracts(true);
        try {
            const [responseTotal, response] = yield Promise.all([
                contractStore.getContractsTotal(filter),
                contractStore.getContracts(filter, currentPage, rowsPerPage),
            ]);
            setTotalContracts(responseTotal);
            setContracts(response.contracts);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contracts.contractsApiErrorMessage"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    const isActiveStatus = (status) => {
        return (status === "active" ||
            status === "given" ||
            status === "partialGiven" ||
            status === "pendingCession");
    };
    const isContractApproved = (contractStatus) => {
        return isActiveStatus(contractStatus) || contractStatus === "closed";
    };
    const checkStatus = (status) => {
        return !isActiveStatus(status);
    };
    const calcResultsLabels = {
        installments: t("installmentsLowerLabel"),
        value: t("lowerCaseValueLabel"),
        ascending: t("newestToOldestLabel"),
        descending: t("oldestToNewestLabel"),
    };
    const checkColumn = (isPayroll) => {
        let columns;
        if (isPayroll) {
            columns = [
                {
                    title: t("nameLabel"),
                    dataIndex: "userName",
                    key: "userName",
                    fixed: true,
                },
                {
                    title: t("ccbNumberLabel"),
                    dataIndex: "ccbNumber",
                    key: "ccbNumber",
                },
                {
                    title: t("companyLabel"),
                    dataIndex: "company",
                    key: "company",
                },
                {
                    title: "CPF",
                    dataIndex: "cpf",
                    key: "cpf",
                },
                ...defaultColumns,
            ];
        }
        columns = [
            {
                title: t("ccbNumberLabel"),
                dataIndex: "ccbNumber",
                key: "ccbNumber",
                fixed: true,
                width: 150,
            },
            {
                title: t("nameLabel"),
                dataIndex: "userName",
                key: "userName",
                fixed: true,
                width: 230,
            },
            {
                title: t("cpfCnpjLabel"),
                dataIndex: "cpf",
                key: "cpf",
            },
            {
                title: t("personOrCompanyLabel"),
                dataIndex: "company",
                key: "company",
            },
            ...defaultColumns,
        ];
        return columns;
    };
    const extraFilter = (isPayroll) => {
        const filters = [
            {
                key: "cpf",
                name: "cpf",
                type: "input",
                placeholder: "CPF",
                inputMask: "111.111.111-11",
            },
        ];
        if (isPayroll) {
            filters.push({
                key: "companyName",
                name: "companyName",
                type: "input",
                placeholder: t("companyLabel"),
                inputDefaultValue: companyStore.selectedCompany && match.path === AdminRoutes.Company.Contracts
                    ? companyStore.selectedCompany.name
                    : "",
            });
        }
        return filters;
    };
    const discountReportColumns = [
        {
            title: t("contracts.discountReport.dctoId"),
            dataIndex: "dctoId",
            key: "dctoId",
        },
        {
            title: t("contracts.discountReport.dueDate"),
            dataIndex: "dueDate",
            key: "dueDate",
        },
        {
            title: t("contracts.discountReport.faceValue"),
            dataIndex: "faceValue",
            key: "faceValue",
        },
        {
            title: t("contracts.discountReport.discount"),
            dataIndex: "discount",
            key: "discount",
        },
        {
            title: t("contracts.discountReport.daysDue"),
            dataIndex: "daysDue",
            key: "daysDue",
            align: "center",
        },
        {
            title: t("contracts.discountReport.installmentPresentValue"),
            dataIndex: "installmentPresentValue",
            key: "installmentPresentValue",
            align: "left",
        },
    ];
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: "Nome",
        },
        {
            key: "person",
            name: "person",
            type: "select",
            placeholder: t("charge.personLabel"),
            selectDefaultValue: "",
            selectOptions: [
                {
                    value: "",
                    label: t("charge.allLabel"),
                },
                { value: "cpf", label: t("charge.cpfPersonLabel") },
                {
                    value: "cnpj",
                    label: t("charge.cnpjPersonLabel"),
                },
            ],
        },
        ...extraFilter(props.isPayroll),
        {
            key: "isOverdue",
            name: "isOverdue",
            type: "select",
            selectDefaultValue: "",
            placeholder: t("contracts.overdueLabel"),
            selectOptions: [
                {
                    value: "false",
                    label: t("contracts.isntOverdueLabel"),
                },
                {
                    value: "true",
                    label: t("contracts.isOverdueLabel"),
                },
                {
                    value: "",
                    label: t("contracts.overdueLabel"),
                },
            ],
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: "Status",
            mode: "multiple",
            selectOptions: getAdminContractsStatusLabel(t),
        },
        {
            key: "tedStatus",
            name: "tedStatus",
            type: "select",
            selectDefaultValue: "",
            selectOptions: [
                {
                    value: "",
                    label: t("contracts.tedStatus.title"),
                },
                {
                    value: "processing",
                    label: formatTedStatus("processing"),
                },
                {
                    value: "registered",
                    label: formatTedStatus("registered"),
                },
                {
                    value: "registerFailed",
                    label: formatTedStatus("registerFailed"),
                },
            ],
        },
        {
            key: "data",
            name: "data",
            type: "dateRange",
        },
        {
            key: "ccbNumber",
            name: "ccbNumber",
            type: "input",
            placeholder: t("ccbNumberLabel"),
        },
        {
            key: "companyGroupName",
            name: "companyGroupName",
            type: "input",
            placeholder: t("companyGroupLabel"),
        }
    ];
    const applyFilter = (values) => {
        setCurrentPage(1);
        let overdue;
        values.isOverdue === ""
            ? (overdue = null)
            : values.isOverdue === "true"
                ? (overdue = true)
                : (overdue = false);
        const filter = {
            name: values.name,
            companyName: values.companyName,
            isOverdue: overdue,
            cpf: onlyNumbers(values.cpf),
            status: values.status,
            date: formatDateFilter(values),
            person: nullIfEmpty(values.person),
            tedStatus: nullIfEmpty(values.tedStatus),
            ccbNumber: nullIfEmpty(values.ccbNumber),
            companyGroupName: nullIfEmpty(values.companyGroupName),
        };
        setCurrentFilter(filter);
    };
    const checkAdimplencia = (contract) => contract.isOverdue
        ? t("contracts.isOverdueLabel")
        : contract.isOverdue === null
            ? "--"
            : t("contracts.isntOverdueLabel");
    const closeCedeModal = () => {
        setCedeModal(false);
        setAmortizeModal(false);
        setPortabilityModalIsOpen(false);
        setCalcResults(null);
        setCancelationModalIsOpen(false);
    };
    const handlePortabilityModalClose = () => {
        setPortabilityModalIsOpen(false);
        getContracts(currentFilter);
        setSelectedContractsKeys([]);
    };
    const handleOpenConfirm = (status, selectedContracts) => {
        clearMessage();
        setModalErrorMessage("");
        const totalSelectedContracts = selectedContracts ? selectedContracts.length : 0;
        const isSelectedMutiplesContracts = totalSelectedContracts > 1;
        if (status === "settled" && isSelectedMutiplesContracts) {
            return setErrorMessage(t("contracts.amortizeMultipleContractsMessage"));
        }
        else if (status === "portabilited" && isSelectedMutiplesContracts) {
            return setErrorMessage(t("contracts.portabilityMultipleContractsMessage"));
        }
        else if (status === "canceled" && isSelectedMutiplesContracts) {
            return setErrorMessage(t("contracts.cancelationMultipleContractsMessage"));
        }
        if (selectedContracts === undefined || totalSelectedContracts === 0) {
            setErrorMessage(t("contracts.emptySelectionMessage"));
        }
        else {
            if (status === "ceded") {
                setCedeModal(true);
            }
            else if (status === "portabilited") {
                setPortabilityModalIsOpen(true);
            }
            else if (status === "canceled") {
                setCancelationModalIsOpen(true);
            }
            else {
                setAmortizeModal(true);
            }
        }
    };
    const calculateAmortizing = (options) => __awaiter(void 0, void 0, void 0, function* () {
        setModalErrorMessage("");
        let simulation;
        setLoadingCalc(true);
        try {
            simulation = yield contractStore.simulateAmortizing(options, selectedContract.id);
            setDisableModalButton(false);
        }
        catch (error) {
            catchErrors(error, setModalErrorMessage, t("simulateAmortizingFail"));
            setDisableModalButton(true);
        }
        finally {
            setCalcValues(options);
            simulation ? setCalcResults(simulation) : setCalcResults(null);
            setLoadingCalc(false);
        }
    });
    const handleSelectedChange = (selected, _) => {
        setSelectedContractsKeys(selected);
    };
    const onClickDownload = (name) => {
        const modal = document.querySelector(name);
        const ccbId = selectedContract.ccbId.slice(-7);
        const userName = selectedContract.userName.toUpperCase().split(" ").join("_");
        if (modal !== null) {
            html2canvas(modal, {
                width: modal.offsetWidth,
                height: modal.offsetHeight,
                scale: window.devicePixelRatio,
                scrollY: 0,
                ignoreElements: (element) => element.tagName === "svg",
            }).then((canvas) => {
                const imgData = canvas.toDataURL();
                download(imgData, "AMORTIZACAO_" +
                    getDateLocalTimezoneFormat(toMoment(calcValues.date), DateFormat.FILE) +
                    "_" +
                    userName +
                    "_" +
                    ccbId);
            });
        }
    };
    const filterSelectedContract = (key) => {
        const filteredContracts = contracts.filter((contract) => {
            return contract.id === key;
        });
        return filteredContracts ? filteredContracts[0] : null;
    };
    const installmentsAmount = (installments) => installments === null
        ? "--"
        : installments === 0
            ? t("contracts.noneLabel")
            : installments > 1
                ? installments + t("contracts.monthsLabel")
                : installments + t("contracts.monthLabel");
    const confirmAmortizing = () => {
        setConfirmationModal(true);
        setConfirmationValues([
            calcResultsLabels[calcValues.type],
            getDateLocalTimezoneFormat(toMoment(calcValues.date), DateFormat.BR),
            calcResultsLabels[calcValues.order],
            formatStringCurrency(calcResults.balance),
            String(calcResults.remainingInstallments),
            calcResults.nextExpirationDate !== null
                ? calcResults.nextExpirationDate.toLocaleDateString()
                : "--",
            calcResults.lastExpirationDate !== null
                ? calcResults.lastExpirationDate.toLocaleDateString()
                : "--",
        ]);
        setModalAmortization({
            type: calcValues.type,
            date: calcValues.date,
            installments: calcValues.installments,
            value: calcValues.value,
            order: calcValues.order,
        });
    };
    const applyAmortizing = (amortization, loanId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingContracts(true);
        try {
            yield contractStore.applyAmortizing(amortization, loanId);
            setSuccessMessage(t("contracts.amortizingSuccessMessage"));
            setLoadingContracts(false);
            setCedeModal(false);
            setAmortizeModal(false);
            setPortabilityModalIsOpen(false);
            setCalcResults(null);
            setConfirmationModal(false);
            getContracts(currentFilter);
            setSelectedContractsKeys([]);
        }
        catch (error) {
            catchErrors(error, setAmortizingError, t("applyAmortizationFail"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    const cleanAmortizingError = () => {
        setAmortizingError("");
    };
    const handleCreateCessionsSuccess = (loansIds) => {
        const message = loansIds.length > 1
            ? t("contracts.multipleContractsSuccessMessage")
            : t("contracts.singleContractSuccessMessage");
        setSuccessMessage(message);
        setSelectedContractsKeys([]);
    };
    const createCessions = (loansIds, assigneCompany, allInstallments, interval) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingContracts(true);
        setCedeModal(false);
        try {
            const installments = allInstallments ? null : interval;
            yield cessionReportStore.createCessions(loansIds, assigneCompany, installments);
            handleCreateCessionsSuccess(loansIds);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contractsApiErrorMessage"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    const checkResponsible = (contract) => contract.responsible === null
        ? "--"
        : contract.responsible === "company"
            ? t("contracts.cnpjPersonLabel")
            : t("contracts.cpfPersonLabel");
    const hasContracts = !loadingContracts && contracts.length > 0;
    const selectedContract = filterSelectedContract(selectedContractsKeys[0]);
    const exportContractsReport = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const buffer = yield contractStore.exportContracts(filter);
            downloadFileFromBuffer(buffer, t("contracts.fileName"));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("exportApiErrorMessage"));
        }
    });
    const exportDetailedCcbReport = (loanId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const buffer = yield contractStore.exportDetailedCcbReport(loanId);
            downloadFileFromBuffer(buffer, "Relatorio de desagio.xlsx");
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("exportApiErrorMessage"));
        }
    });
    const mapDateArray = (contract, dateArray) => {
        return (React.createElement(React.Fragment, null, dateArray.map((installment, index) => (React.createElement("div", { className: styles.installmentsTooltip, key: contract.id + installment },
            React.createElement("span", null,
                getDateLocalTimezoneFormat(installment, DateFormat.ABR),
                index + 1 === dateArray.length ? "" : ", "),
            index % 4 === 3 ? React.createElement("br", null) : "")))));
    };
    const showDsicountReport = (contract) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingContracts(true);
        try {
            const { installmentsDetails } = yield contractStore.getDetailedCcbReport(contract.id);
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement("div", { className: styles.modalHeaderDialog },
                    React.createElement("h2", { className: styles.modalTitleDialog }, t("contracts.discountReport.title")),
                    React.createElement("div", { className: styles.companyDialog },
                        contract.userName,
                        " - ",
                        contract.companyName))), content: (React.createElement("div", { className: styles.modal },
                    React.createElement("div", { className: styles.modalBodyDialog, style: { padding: 0 } },
                        React.createElement(Table, { columns: discountReportColumns, data: installmentsDetails.map((installment) => (Object.assign(Object.assign({}, installment), { value: formatStringCurrency(installment.value), faceValue: formatStringCurrency(installment.faceValue), installmentPresentValue: formatStringCurrency(installment.installmentPresentValue), discount: formatStringCurrency(installment.discount) }))) }),
                        React.createElement("hr", null)))), className: styles.containerDialog, width: "95%", maskClosable: true, onOk: () => __awaiter(void 0, void 0, void 0, function* () {
                    exportDetailedCcbReport(contract.id);
                }), okText: t("contracts.discountReport.exportAsExcelLabel"), style: {
                    top: 20,
                    marginBottom: 100,
                }, okButtonProps: {
                    icon: "download",
                    type: "primary",
                }, cancelText: "Fechar" }));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contracts.contractsApiErrorMessage"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    const showDynamicInfos = (contract) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingContracts(true);
            const { dynamicData: { creditInfo }, } = yield contractStore.getCcbDataByLoanId(contract.id);
            const portabilityHistoryData = yield contractStore.portabilityHistory(contract.id);
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement(ContractModalHeader, { identifier: contract.ccbNumber, subtitle: contract.companyName, title: contract.userName })), content: (React.createElement("div", { className: styles.modal },
                    React.createElement("div", { className: styles.modalBodyDialog },
                        React.createElement(ListColumnInfo, { labels: [
                                loanLabels.cpf,
                                loanLabels.salary,
                                loanLabels.totalAmount,
                                loanLabels.percentage,
                                loanLabels.numberOfInstallments,
                            ], values: [
                                contract.cpf,
                                formatStringCurrencyNoPrefix(contract.salary),
                                formatStringCurrencyNoPrefix(creditInfo.totalValue),
                                contract.percentage,
                                creditInfo.numberOfInstallments,
                            ] }),
                        React.createElement("div", { className: styles.separation }),
                        React.createElement(ListColumnInfo, { labels: [
                                loanLabels.emittedDate,
                                loanLabels.ccbTotalValue,
                                loanLabels.netValueCreditedToIssuer,
                                loanLabels.installmentsValue,
                                loanLabels.firstInstallmentDue,
                                loanLabels.lastInstallmentDue,
                                loanLabels.monthlyInterestRate,
                                loanLabels.monthlyEffectiveCost,
                            ], values: [
                                creditInfo.emittedDate,
                                formatStringCurrencyNoPrefix(creditInfo.loanTotalValue),
                                formatStringCurrencyNoPrefix(creditInfo.netValueCreditedToIssuer),
                                formatStringCurrencyNoPrefix(creditInfo.installmentsValue),
                                creditInfo.firstInstallmentDue,
                                creditInfo.lastInstallmentDue,
                                creditInfo.monthlyInterestRate,
                                creditInfo.monthlyEffectiveCost,
                            ] }),
                        React.createElement(ContractDataTabCard, { generalData: contract, bankDataToolTipDescription: t("bankDataToolTipDescription") },
                            React.createElement(TabPane, { tab: React.createElement("span", { "data-cy": "portability-tab-button" }, t("portabilityHistory")), key: "portabilityHistory" },
                                React.createElement("div", { "data-cy": "portability-history-data" },
                                    React.createElement(PortabilityTable, { portabilityHistoryData: portabilityHistoryData }))))))), className: styles.containerDialog, width: "50%", maskClosable: true, onOk: () => handleSelectChange([...selectedContractsKeys, contract.id], null), okText: "Selecionar", okButtonProps: {
                    icon: "check",
                    type: "primary",
                    disabled: selectedContractsKeys.includes(contract.id),
                }, cancelText: "Fechar" }));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contracts.contractsApiErrorMessage"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    const statusLabels = getStatusAdminLabels(t);
    const handleSelectChange = (selectedRowKeys, _) => {
        setSelectedContractsKeys(selectedRowKeys);
    };
    const handleCloseInstallmentsModal = () => {
        setInstallmentsModalContract(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "container", className: styles.containerContracts },
            React.createElement(SectionHeader, { title: props.isPayroll
                    ? t("contracts.payrollContractTitle")
                    : t("contracts.otherContractTitle"), actions: [
                    {
                        label: t("exportSheetLabel"),
                        handleAction: () => exportContractsReport(currentFilter),
                        icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                        disabled: !props.isPayroll ? contractsOthers.length === 0 : contracts.length === 0,
                    },
                ] }),
            React.createElement(FilterForm, { className: styles.filterForm, items: items, handleSubmit: applyFilter }),
            React.createElement(SectionContainer, { className: styles.container },
                React.createElement(CedeContractForm, { onCancel: closeCedeModal, visible: cedeModal, remainingInstallments: selectedContract ? selectedContract.remainingInstallmentsNumber : null, okText: t("contracts.giveLabel"), selectedContractsAmount: selectedContractsKeys.length, onOk: (values) => createCessions(selectedContractsKeys, values.assigneCompany, values.allInstallments, values.interval) }),
                React.createElement(AmortizeModal, { title: selectedContract ? selectedContract.userName : "", subtitle: "CCB " +
                        (selectedContract && selectedContract.ccbNumber ? selectedContract.ccbNumber : ""), remainingInstallments: selectedContract ? selectedContract.remainingInstallments : 0, onCancel: closeCedeModal, onClickDownload: () => onClickDownload(".ant-modal-body"), visible: amortizeModal, okText: t("contracts.confirmAmortizingLabel"), onSubmit: calculateAmortizing, calcResult: calcResults, divId: "modal", onOk: () => confirmAmortizing(), loadingCalc: loadingCalc, errorMessage: modalErrorMessage, onCloseAlert: () => setModalErrorMessage(""), disableOkButton: disableModalButton }),
                React.createElement(AmortizeConfirmationModal, { onClickDownload: () => onClickDownload(".ant-modal-body"), onCancel: () => {
                        setConfirmationModal(false);
                        cleanAmortizingError();
                    }, visible: confirmationModal, labels: confirmationLabels, values: confirmationValues, selectedContract: selectedContract, onOk: () => applyAmortizing(modalAmortization, selectedContract.id), errorMessage: amortizingError, onCloseAlert: () => cleanAmortizingError(), loading: loadingContracts }),
                React.createElement(PortabilityModal, { onCancel: closeCedeModal, visible: portabilityModalIsOpen, loanId: selectedContract === null || selectedContract === void 0 ? void 0 : selectedContract.id, onSuccess: handlePortabilityModalClose }),
                React.createElement(CancelationModal, { onCancel: closeCedeModal, visible: cancelationModalIsOpen, contract: selectedContract, onSuccess: handlePortabilityModalClose }),
                React.createElement(InstallmentsModal, { contract: installmentsModalContract, onClose: handleCloseInstallmentsModal }),
                React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                    React.createElement(ActionTable, { className: classNames(styles.table, styles.tableCharges), rowClassName: (record) => {
                            if (record.responsible === "user" && record.isOverdue === true) {
                                return props.isPayroll ? styles.rowOverdueRed : styles.rowOverdueRedOthers;
                            }
                            if (record.responsible === "user") {
                                return props.isPayroll ? styles.row : styles.rowOthers;
                            }
                            if (record.isOverdue === true) {
                                return props.isPayroll ? styles.rowRed : styles.rowRedOthers;
                            }
                            return "";
                        }, scroll: { x: 3200 }, rowSelection: {
                            selectedRowKeys: selectedContractsKeys,
                            onChange: handleSelectedChange,
                            getCheckboxProps: (record) => {
                                return {
                                    disabled: record.remainingInstallments === null ||
                                        record.remainingInstallments === 0 ||
                                        checkStatus(record.status),
                                };
                            },
                        }, columns: checkColumn(props.isPayroll), onRow: (record) => ({
                            onContextMenu: setContextMenuDataHandler(contextMenuData, setContextMenuData, record),
                        }), loading: loadingContracts, dataSource: !props.isPayroll
                            ? contractsOthers
                            : contracts.map((contract) => (Object.assign(Object.assign({}, contract), { key: contract.id, statusDisplay: (React.createElement(React.Fragment, null,
                                    React.createElement("span", { "data-cy": "statusLabels-wrapper-data-cy" }, statusLabels[contract.status]),
                                    (contract.status === "given" || contract.status === "partialGiven") &&
                                        contract.cessionInfo !== null ? (React.createElement(TooltipDisplay, { classname: styles.tooltipContainer, title: React.createElement("div", { className: styles.tooltipContainer },
                                            React.createElement("p", null, "Cessionária: " + contract.cessionInfo.assigneCompany),
                                            React.createElement("div", null,
                                                "Parcelas:",
                                                mapDateArray(contract, contract.cessionInfo.installments))) })) : (""))), cpf: React.createElement("span", { "data-cy": "cpfCnpj-data-cy" }, formatCpf(contract.cpf)), ccbNumber: React.createElement("span", { "data-cy": "ccbNumber-data-cy" }, contract.ccbNumber), userName: contract.userName, company: (React.createElement("div", null,
                                    contract.companyName,
                                    contract.responsible === "user" && (React.createElement(TooltipDisplay, { classname: styles.tooltipContainer, title: React.createElement(React.Fragment, null,
                                            React.createElement("p", null, contract.userDeleted
                                                ? " Desligado(a)"
                                                : contract.inssAway
                                                    ? " Afastado(a) por INSS"
                                                    : "")) })))), ccbLink: contract.ccbLink, documentType: checkResponsible(contract), totalValue: formatStringCurrency(contract.totalValue), installments: installmentsAmount(contract.totalInstallments), remainingInstallmentsDisplay: installmentsAmount(contract.remainingInstallments), remainingValue: (React.createElement("span", { "data-cy": "remainingValue-data-cy" }, isContractApproved(contract.status)
                                    ? formatStringCurrency(contract.balance)
                                    : "--")), paidValue: (React.createElement("span", { "data-cy": "paidValue-data-cy" }, isContractApproved(contract.status)
                                    ? formatStringCurrency(contract.paidValue)
                                    : "--")), adimplencia: (React.createElement("div", { className: contract.isOverdue ? styles.overdue : "" },
                                    checkAdimplencia(contract),
                                    contract.isOverdue === true && contract.overdueInstallments !== null ? (React.createElement(TooltipDisplay, { classname: styles.tooltipContainer, title: React.createElement("div", null,
                                            React.createElement("p", null, "Referente aos meses: "),
                                            mapDateArray(contract, contract.overdueInstallments)) })) : (""))), contractSignDate: getDateLocalTimezoneFormat(contract.signDate), requestDate: getDateLocalTimezoneFormat(contract.requestDate), ccb: (React.createElement(DownloadTooltip, { link: contract.ccbLink, tooltipLabel: i18n.t("ccbTooltipLabel") })), policy: (React.createElement("div", { "data-cy": "policy-tooltip-wrapper-data-cy" },
                                    React.createElement(DownloadTooltip, { link: contract.seguroPrestamistaPolicyLink, tooltipLabel: i18n.t("policyTooltipLabel") }))) }))) }))),
            React.createElement(ActionSelect, { actions: actions, currentPage: currentPage || 1, selectedKeys: selectedContractsKeys ? selectedContractsKeys : [] }),
            React.createElement(SectionFooter, null, hasContracts && (React.createElement(Pagination, { current: currentPage, total: totalContracts, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setRowsPerPage(size);
                } })))),
        React.createElement(FloatingList, { contextMenuData: contextMenuData, items: [
                {
                    avatar: React.createElement(FileText, { fill: "#ff7600" }),
                    onClick: () => showDynamicInfos(contextMenuData.currentContract),
                    title: t("contracts.ccbDetailLabel"),
                },
                {
                    avatar: React.createElement(List, null),
                    onClick: () => setInstallmentsModalContract(contextMenuData.currentContract),
                    title: t("payments.installmentsList"),
                },
                {
                    avatar: React.createElement(File, { fill: "#ff7600" }),
                    onClick: () => showDsicountReport(contextMenuData.currentContract),
                    title: t("contracts.discountReport.title"),
                },
            ] }),
        !contractStore.tourDone && (React.createElement(JoyRide, { steps: TOUR_STEPS, styles: {
                beaconInner: {
                    background: "#ff7600",
                },
                beaconOuter: {
                    backgroundColor: "rgba(255, 118, 0, 0.5)",
                    borderColor: "#ff7600",
                },
            }, callback: (data) => {
                if (data.status === "finished") {
                    contractStore.setTourDone();
                }
            }, continuous: true, showSkipButton: true }))));
});
