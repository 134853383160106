import { __awaiter, __decorate } from "tslib";
import { observable, action, computed } from "mobx";
import { api } from "../AppWrapper";
export class AccessProfileStore {
    constructor() {
        this.selectedProfileValue = undefined;
        this.allProfilesRolesValue = [];
        this.allProfilesRolesValueInitial = [];
        this.allUsersValue = [];
        this.allUsersValueInitial = [];
        this.permissionNameFilterValue = "";
        this.userNameFilterValue = "";
        this.allCompaniesGroupsValue = [];
        this.allCompaniesGroupsInitial = [];
        this.companyNameFilterValue = "";
        this.getAllProfiles = (filter, page, rowsPerPage) => api.getProfiles({ filter, page, rowsPerPage });
        this.getProfilesTotal = (filter) => api.getProfilesTotal({ filter });
        this.saveProfile = (profileParams) => __awaiter(this, void 0, void 0, function* () {
            yield api.createProfile({ profileParams });
        });
        this.findProfileAndSetRole = (role, activated) => {
            if (this.allProfilesRolesValue) {
                this.allProfilesRolesValue = this.allProfilesRolesValue.map((permissionRole) => {
                    if (permissionRole.id === role.id) {
                        return Object.assign(Object.assign({}, role), { activated });
                    }
                    return permissionRole;
                });
            }
        };
        this.addProfileRole = (role) => {
            this.findProfileAndSetRole(role, true);
        };
        this.removeProfileRole = (role) => {
            this.findProfileAndSetRole(role, false);
        };
        this.findUserAndSetActivated = (user, activated) => {
            if (this.allUsersValue) {
                this.allUsersValue = this.allUsersValue.map((adminUser) => {
                    if (adminUser.id === user.id) {
                        return Object.assign(Object.assign({}, user), { activated });
                    }
                    return adminUser;
                });
            }
        };
        this.findCompaniesGroupAndSetActivated = (companiesGroup, activated) => {
            if (this.allCompaniesGroupsValue) {
                this.allCompaniesGroupsValue = this.allCompaniesGroupsValue.map((group) => {
                    if (group.id === companiesGroup.id) {
                        return Object.assign(Object.assign({}, companiesGroup), { activated });
                    }
                    return group;
                });
            }
        };
        this.addUser = (user) => {
            this.findUserAndSetActivated(user, true);
        };
        this.removeUser = (user) => {
            this.findUserAndSetActivated(user, false);
        };
        this.addCompaniesGroup = (company) => {
            this.findCompaniesGroupAndSetActivated(company, true);
        };
        this.removeCompaniesGroup = (company) => {
            this.findCompaniesGroupAndSetActivated(company, false);
        };
        this.getAccessProfileById = (profileId) => __awaiter(this, void 0, void 0, function* () {
            this.selectedProfileValue = yield api.getProfileById({ profileId });
            return this.selectedProfileValue;
        });
        this.fecthAllPermissions = (profileId) => __awaiter(this, void 0, void 0, function* () {
            this.allProfilesRolesValue = yield api.getRolesByProfileId({ profileId });
            this.allProfilesRolesValueInitial = [...this.allProfilesRolesValue];
        });
        this.fecthAllUsers = (profileId) => __awaiter(this, void 0, void 0, function* () {
            this.allUsersValue = yield api.getAdminsByProfileId({ profileId });
            this.allUsersValueInitial = [...this.allUsersValue];
        });
        this.fecthAllCompaniesGroups = (profileId) => __awaiter(this, void 0, void 0, function* () {
            this.allCompaniesGroupsValue =
                yield api.getCompaniesGroupsWithSecretAndAssocietedProfileRelationship({
                    profileId,
                });
            this.allCompaniesGroupsInitial = [...this.allCompaniesGroupsValue];
        });
        this.updateAdminsByProfileId = (profileId) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const changedUsers = (_a = this.allUsersValue) === null || _a === void 0 ? void 0 : _a.filter(({ activated }, index) => this.allUsersValueInitial[index].activated !== activated);
            this.allUsersValueInitial = [...this.allUsersValue];
            return api.associateAdminsToProfile({
                associateAdminsProfileParams: {
                    profileId,
                    admins: changedUsers.map(({ id, activated }) => ({ id, activated })),
                },
            });
        });
        this.updateRolesByProfileId = (profileId) => __awaiter(this, void 0, void 0, function* () {
            var _b;
            const changedRoles = (_b = this.allProfilesRoles) === null || _b === void 0 ? void 0 : _b.filter(({ activated }, index) => this.allProfilesRolesValueInitial[index].activated !== activated);
            this.allProfilesRolesValueInitial = [...this.allProfilesRoles];
            return api.associateRolesToProfile({
                associateRolesProfileParams: {
                    profileId,
                    roles: changedRoles.map(({ id, activated }) => ({ id, activated })),
                },
            });
        });
        this.updateCompaniesGroupsByProfileId = (profileId) => __awaiter(this, void 0, void 0, function* () {
            var _c;
            const changeCompanies = (_c = this.allCompaniesGroups) === null || _c === void 0 ? void 0 : _c.filter(({ activated }, index) => this.allCompaniesGroupsInitial[index].activated !== activated);
            this.allCompaniesGroupsInitial = [...this.allCompaniesGroups];
            return api.associateCompaniesGroupToExternalApiProfile({
                associateCompaniesGroupsProfileParams: {
                    profileId,
                    companiesGroupProfileAssociable: changeCompanies.map(({ id, activated, apiSecretId }) => ({
                        id,
                        activated,
                        apiSecretId,
                    })),
                },
            });
        });
        this.deleteProfile = (profileId) => __awaiter(this, void 0, void 0, function* () { return api.deleteProfile({ profileId }); });
    }
    get allProfilesRoles() {
        return this.allProfilesRolesValue;
    }
    get allUsers() {
        return this.allUsersValue;
    }
    get allCompaniesGroups() {
        return this.allCompaniesGroupsValue;
    }
    get permissionNameFilter() {
        return this.permissionNameFilterValue;
    }
    get userNameFilter() {
        return this.userNameFilterValue;
    }
    get companyNameFilter() {
        return this.companyNameFilterValue;
    }
    get isFilteringCompany() {
        return !!this.companyNameFilterValue;
    }
    get isFilteringUsers() {
        return !!this.userNameFilterValue;
    }
    get isFilteringPermission() {
        return !!this.permissionNameFilterValue;
    }
    get selectedProfile() {
        return this.selectedProfileValue;
    }
    clearFilters() {
        this.userNameFilterValue = "";
        this.permissionNameFilterValue = "";
        this.companyNameFilterValue = "";
    }
    setPermissionNameFilter(nameFilter) {
        this.permissionNameFilterValue = nameFilter;
    }
    setUserNameFilter(nameFilter) {
        this.userNameFilterValue = nameFilter;
    }
    setCompanyNameFilter(nameFilter) {
        this.companyNameFilterValue = nameFilter;
    }
}
__decorate([
    observable
], AccessProfileStore.prototype, "selectedProfileValue", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "allProfilesRolesValue", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "allProfilesRolesValueInitial", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "allUsersValue", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "allUsersValueInitial", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "permissionNameFilterValue", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "userNameFilterValue", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "allCompaniesGroupsValue", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "allCompaniesGroupsInitial", void 0);
__decorate([
    observable
], AccessProfileStore.prototype, "companyNameFilterValue", void 0);
__decorate([
    computed
], AccessProfileStore.prototype, "allProfilesRoles", null);
__decorate([
    computed
], AccessProfileStore.prototype, "allUsers", null);
__decorate([
    computed
], AccessProfileStore.prototype, "allCompaniesGroups", null);
__decorate([
    computed
], AccessProfileStore.prototype, "permissionNameFilter", null);
__decorate([
    computed
], AccessProfileStore.prototype, "userNameFilter", null);
__decorate([
    computed
], AccessProfileStore.prototype, "companyNameFilter", null);
__decorate([
    computed
], AccessProfileStore.prototype, "isFilteringCompany", null);
__decorate([
    computed
], AccessProfileStore.prototype, "isFilteringUsers", null);
__decorate([
    computed
], AccessProfileStore.prototype, "isFilteringPermission", null);
__decorate([
    computed
], AccessProfileStore.prototype, "selectedProfile", null);
__decorate([
    action
], AccessProfileStore.prototype, "clearFilters", null);
__decorate([
    action
], AccessProfileStore.prototype, "getProfilesTotal", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "saveProfile", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "addProfileRole", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "removeProfileRole", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "addUser", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "removeUser", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "addCompaniesGroup", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "removeCompaniesGroup", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "setPermissionNameFilter", null);
__decorate([
    action
], AccessProfileStore.prototype, "setUserNameFilter", null);
__decorate([
    action
], AccessProfileStore.prototype, "setCompanyNameFilter", null);
__decorate([
    action
], AccessProfileStore.prototype, "getAccessProfileById", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "fecthAllPermissions", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "fecthAllUsers", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "fecthAllCompaniesGroups", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "updateAdminsByProfileId", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "updateRolesByProfileId", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "updateCompaniesGroupsByProfileId", void 0);
__decorate([
    action
], AccessProfileStore.prototype, "deleteProfile", void 0);
