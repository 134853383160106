import React from "react";
import useReactRouter from "use-react-router";
import { Opinion } from "./Opinion";
import { CcbPreview } from "./CcbPreview";
import { Conditions } from "./Conditions";
import { Guarantees } from "./Guarantees";
import { Guarantors } from "./Guarantors";
import { useStores } from "../../../../utils/mobx";
import PdfViewer from "../../../../../shared/components/PdfViewer";
import { AdminRoutes } from "../../../../constants/routes.constants";
import styles from "./styles.scss";
export const StepsPages = ({ currentStep, navigateToNextStep, navigateToPreviousStep, form, }) => {
    const { match: { params: { simulationId }, }, } = useReactRouter();
    const { history } = useStores();
    const handleBackClick = () => {
        history.push(`${AdminRoutes.Simulator.Simulation}/${simulationId}`);
    };
    return (React.createElement("div", { className: styles.stepPageWrapper },
        React.createElement(Guarantors, { currentStep: currentStep, navigateToNextStep: navigateToNextStep, navigateToPreviousStep: navigateToPreviousStep, form: form, onBackButtonClick: handleBackClick }),
        React.createElement(Guarantees, { currentStep: currentStep, navigateToNextStep: navigateToNextStep, navigateToPreviousStep: navigateToPreviousStep, form: form }),
        React.createElement(Conditions, { form: form, currentStep: currentStep, navigateToNextStep: navigateToNextStep, navigateToPreviousStep: navigateToPreviousStep }),
        React.createElement(Opinion, { currentStep: currentStep, navigateToNextStep: navigateToNextStep, navigateToPreviousStep: navigateToPreviousStep, form: form }),
        React.createElement(CcbPreview, { PdfViewer: PdfViewer, form: form, currentStep: currentStep, navigateToPreviousStep: navigateToPreviousStep, onEditDataClick: handleBackClick })));
};
