import { useMemo } from "react";
export function itemsContainsValueById(items, valueId) {
    return useMemo(() => !!items.find(({ id }) => id === valueId), [items, valueId]);
}
function nameIncludesFilterName(name, nameFilter) {
    const nameFilterLowerCase = nameFilter === null || nameFilter === void 0 ? void 0 : nameFilter.toLowerCase();
    return name.toLowerCase().includes(nameFilterLowerCase || "");
}
export function filterItemsByName(items, nameFilter) {
    return items === null || items === void 0 ? void 0 : items.filter(({ name }) => nameIncludesFilterName(name, nameFilter));
}
export function filterActivatedItemsByName(items, nameFilter) {
    return items === null || items === void 0 ? void 0 : items.filter(({ name, activated }) => activated && nameIncludesFilterName(name, nameFilter));
}
export function filterItemsByTitle(items, titleFilter) {
    return items === null || items === void 0 ? void 0 : items.filter(({ title }) => nameIncludesFilterName(title, titleFilter));
}
export function filterActivatedItemsByTitle(items, titleFilter) {
    return items === null || items === void 0 ? void 0 : items.filter(({ title, activated }) => activated && nameIncludesFilterName(title, titleFilter));
}
export function mergeArrayOfObjects(original, newdata, selector) {
    const responseArray = [...original];
    newdata.forEach((dat) => {
        const foundIndex = responseArray.findIndex((ori) => ori[selector] === dat[selector]);
        if (foundIndex >= 0)
            responseArray.splice(foundIndex, 1, dat);
        else
            responseArray.push(dat);
    });
    return responseArray;
}
export function sortItemsByName(items) {
    return items.sort((firstItem, secondItem) => firstItem.name.localeCompare(secondItem.name));
}
export function getItemFromCollectionById(collection, id) {
    return collection.find((collectionItem) => collectionItem.id === id);
}
export function removeItemFromCollectionById(collection, id) {
    return collection.filter((item) => item.id !== id);
}
export function compareStrings(a, b) {
    if (!b) {
        return -1;
    }
    if (!a) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}
export function getReversedOrder(guarantors) {
    return guarantors.slice(0).reverse();
}
export function findAndRemoveArrayElementById(arrayElement, id) {
    const index = arrayElement.findIndex((item) => item.id === id);
    if (index !== -1) {
        arrayElement.splice(index, 1);
    }
    return arrayElement;
}
