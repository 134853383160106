import i18n from "../../../../../admin/i18n";
export const installmentPaymentWays = [
    {
        value: "bankSlip",
        label: i18n.t("conditionsStep.bankSlip"),
        dataCy: "bankSlip-data-cy",
        dataTestId: "bankSlip",
    },
    {
        value: "electronicAvailableTransfer",
        label: i18n.t("conditionsStep.ted"),
        dataCy: "ted-data-cy",
        dataTestId: "ted",
    },
    {
        value: "directDebit",
        label: i18n.t("conditionsStep.directDebit"),
        dataCy: "directDebit-data-cy",
        dataTestId: "directDebit",
    },
    {
        value: "accountDebit",
        label: i18n.t("conditionsStep.automaticDebit"),
        dataCy: "automaticDebit-data-cy",
        dataTestId: "automaticDebit",
    },
];
export const chargesCalculations = [
    {
        value: "debtBalanceIncident",
        label: i18n.t("conditionsStep.incidentOnDebitBalance"),
        dataCy: "incidentOnDebitBalance-data-cy",
        dataTestId: "incidentOnDebitBalance",
    },
    {
        value: "installmentAmortizationIncident",
        label: i18n.t("conditionsStep.incidentOnAmortizationInstallments"),
        dataCy: "incidentOnAmortizationInstallments-data-cy",
        dataTestId: "incidentOnAmortizationInstallments",
    },
];
