import { __awaiter } from "tslib";
import React from "react";
import { Modal, Form, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
const { TextArea } = Input;
const BaseCompaniesGroup = ({ open, title, onClose, onSubmit, form, loading, }) => {
    const { getFieldDecorator, validateFields, setFieldsValue } = form;
    const inputName = "companiesGroup";
    const { t } = useTranslation();
    const clearFields = () => {
        setFieldsValue({
            [inputName]: {
                name: undefined,
                description: undefined,
            },
        });
    };
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        clearFields();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                if (onSubmit) {
                    yield onSubmit(values.companiesGroup);
                }
                clearFields();
            }
        }));
    };
    return (React.createElement("div", { className: styles.modalWrapper },
        React.createElement(Modal, { title: React.createElement("div", null, title || t("companiesGroups.newGroup")), visible: open, onOk: handleSubmit, onCancel: handleClose, width: 700, okText: React.createElement("span", { "data-cy": "companies-group-submit-button" }, t("confirmLabel")) },
            React.createElement("div", { className: styles.bodyWrapper },
                React.createElement(Spin, { spinning: loading },
                    React.createElement(Form.Item, { label: t("nameLabel"), "data-cy": "name-input-wrapper" }, getFieldDecorator(`${inputName}.name`, {
                        rules: [
                            {
                                required: true,
                                message: t("companiesGroups.nameErrorMessage"),
                            },
                        ],
                    })(React.createElement(Input, { "data-cy": "name-input-data-cy", placeholder: t("companiesGroups.namePlaceholder") }))),
                    React.createElement(Form.Item, { label: t("accessProfile.descriptionField"), "data-cy": "description-input-wrapper" }, getFieldDecorator(`${inputName}.description`, {
                        rules: [
                            {
                                required: true,
                                message: t("companiesGroups.descriptionErrorMessage"),
                            },
                        ],
                    })(React.createElement(TextArea, { "data-cy": "description-input-data-cy", rows: 3, placeholder: t("companiesGroups.descriptionPlaceholder") }))))))));
};
export const BaseCompaniesGroupForm = Form.create({
    name: "companies_group_form",
})(BaseCompaniesGroup);
