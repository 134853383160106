import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ActionTable from "../../../../shared/components/ActionTable";
import { formatCpf, formatCurrencyNoPrefix } from "../../../../shared/utils/formatters";
import { SignaturesTable } from "../SignaturesTable";
import s from "./style.scss";
const LegalResponsibleTable = ({ legalResponsibles }) => {
    const { t } = useTranslation();
    const tableColumns = useMemo(() => [
        {
            title: t("companyEmployee.name"),
            dataIndex: "name",
            key: "name",
            width: 300,
        },
        {
            title: t("companyEmployee.cpf"),
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: t("companyEmployee.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("companyEmployee.societyPercent"),
            dataIndex: "societyPercent",
            key: "societyPercent",
        },
    ], []);
    const tableDataSource = useMemo(() => legalResponsibles.map((legalResponsible) => (Object.assign(Object.assign({}, legalResponsible), { key: legalResponsible.cpf, cpf: formatCpf(legalResponsible.cpf), societyPercent: legalResponsible.societyPercent
            ? `${formatCurrencyNoPrefix(String(legalResponsible.societyPercent))}%`
            : "--" }))), [legalResponsibles]);
    return (React.createElement(ActionTable, { className: s.table, columns: tableColumns, scroll: { x: 1000 }, dataSource: tableDataSource, expandedRowRender: ({ signatures }) => (React.createElement(SignaturesTable, { signatures: signatures })) }));
};
export default LegalResponsibleTable;
