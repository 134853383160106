import { __rest } from "tslib";
import React from "react";
import InputCurrency from "../InputCurrency";
import { formatCurrency } from "../../utils/formatters";
import styles from "./style.scss";
import classNames from "classnames";
const InputCurrencyPrefix = (_a) => {
    var props = __rest(_a, []);
    return (React.createElement(InputCurrency, Object.assign({ className: classNames([styles.input, props.className]), formatter: (value) => (value && value < 0 ? "- " : "") + formatCurrency(`${value}`) }, props)));
};
export default InputCurrencyPrefix;
