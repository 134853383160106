import React from "react";
import i18n from "../../i18n";
import { formatCpf, formatStringCurrency } from "../../../shared/utils/formatters";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { formatPrecatoryAnticipationStatus } from "../../utils/contracts.utils";
import DownloadTooltip from "../../components/DownloadTooltip";
export const columns = [
    {
        title: i18n.t("nameLabel"),
        dataIndex: "userName",
        key: "userName",
        fixed: true,
        width: 230,
    },
    {
        title: i18n.t("cpf"),
        dataIndex: "cpf",
        key: "cpf",
        render: (cpf) => formatCpf(cpf),
    },
    {
        title: i18n.t("contractNumber"),
        dataIndex: "ccbNumber",
        key: "ccbNumber",
    },
    {
        title: i18n.t("statusLabel"),
        dataIndex: "status",
        key: "status",
        render: (status) => formatPrecatoryAnticipationStatus(status),
    },
    {
        title: i18n.t("contractSignDateLabel"),
        dataIndex: "emittedDate",
        key: "emittedDate",
        render: (emittedDate) => getDateLocalTimezoneFormat(emittedDate),
    },
    {
        title: i18n.t("contracts.totalValueLabel"),
        dataIndex: "ccbValue",
        key: "ccbValue",
        render: (ccbValue) => formatStringCurrency(ccbValue),
    },
    {
        title: i18n.t("contractTitle"),
        dataIndex: "ccbLink",
        key: "ccbLink",
        align: "center",
        width: 125,
        render: (ccbLink) => (React.createElement(DownloadTooltip, { link: ccbLink, tooltipLabel: i18n.t("ccbTooltipLabel") })),
    },
];
