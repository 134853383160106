import React from "react";
import i18n from "../../i18n";
import { formatCpf } from "../../../shared/utils/formatters";
import { formatUserSituation, formatUserStatus } from "../../utils/userReport.utils";
import { IdentityValidationReportInfo } from "../LoanApplications/identityValidation";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
export const columns = [
    {
        title: i18n.t("nameLabel"),
        dataIndex: "userName",
        key: "userName",
        fixed: true,
        width: 300,
    },
    {
        title: i18n.t("cpfLabel"),
        dataIndex: "cpf",
        key: "cpf",
        render: (cpf) => formatCpf(cpf),
    },
    {
        title: i18n.t("companyLabel"),
        dataIndex: "companyName",
        key: "companyName",
    },
    {
        title: i18n.t("statusLabel"),
        dataIndex: "status",
        key: "status",
        render: (status, userForReport) => hasIdentityValidation(userForReport) ? (React.createElement(IdentityValidationReportInfo, { id: userForReport.id, reportLink: userForReport.identityValidationReportInfo.reportLink, reportNumber: userForReport.identityValidationReportInfo.reportNumber, textContent: formatUserStatus(status) })) : (React.createElement(React.Fragment, null,
            formatUserStatus(status),
            " ",
            status === "suspended" && (React.createElement(TooltipDisplay, { title: userForReport.suspensionReason, "data-cy": "tooltip-suspension-reason-data-cy" })))),
    },
    {
        title: i18n.t("phone"),
        dataIndex: "mobilePhone",
        key: "mobilePhone",
    },
    {
        title: i18n.t("emailLabel"),
        dataIndex: "email",
        key: "email",
    },
    {
        title: i18n.t("situationLabel"),
        dataIndex: "hiringSituation",
        key: "hiringSituation",
        render: (hiringSituation) => formatUserSituation(hiringSituation),
        width: 170,
    },
    {
        title: "",
        key: "actions",
        dataIndex: "actions",
        width: 80,
    },
];
const hasIdentityValidation = (userForReport) => {
    return !!(userForReport.identityValidationReportInfo &&
        userForReport.identityValidationReportInfo.reportNumber &&
        userForReport.identityValidationReportInfo.reportLink);
};
