import { __rest } from "tslib";
import React, { forwardRef, useState, useEffect } from "react";
import Input from "antd/lib/input";
const InputNumber = forwardRef((_a, ref) => {
    var { defaultValue } = _a, props = __rest(_a, ["defaultValue"]);
    const [value, setValue] = useState("");
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue.toString());
        }
    }, []);
    return (React.createElement(Input, Object.assign({ ref: ref, value: value, onChange: (e) => {
            const value = e.target.value.replace(/\D/g, "");
            setValue(value);
        } }, props)));
});
export default InputNumber;
