import { __awaiter } from "tslib";
import React, { createContext, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../utils/mobx";
import { getItemFromCollectionById, mergeArrayOfObjects, removeItemFromCollectionById, } from "../../../shared/utils/colection";
const emptyFilters = {
    cnpj: "",
    name: "",
    related: true,
};
export const CompaniesGroupContext = createContext({});
export const CompaniesGroupProvider = observer(({ children }) => {
    const { t } = useTranslation();
    const [companiesGroups, setCompaniesGroups] = useState([]);
    const [selectedCompaniesGroup, setSelectedCompaniesGroup] = useState(null);
    const [companiesGroupTotal, setCompaniesGroupTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [companiesWithChanges, setCompaniesWithChange] = useState([]);
    const [filters, setFilters] = useState(emptyFilters);
    const [companiesGroupId, setCompaniesGroupId] = useState();
    const { companiesGroupStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const fetchCompaniesGroup = () => __awaiter(void 0, void 0, void 0, function* () {
        if (companiesGroupId) {
            yield fetchCompaniesGroupById(companiesGroupId);
        }
    });
    useEffect(() => {
        fetchCompaniesGroup();
    }, [companiesGroupId]);
    const companiesToAdd = useMemo(() => companiesWithChanges.filter(({ related }) => related), [companiesWithChanges]);
    const companiesToRemove = useMemo(() => companiesWithChanges.filter(({ related }) => !related), [companiesWithChanges]);
    const companiesIdToAdd = useMemo(() => companiesToAdd.map(({ id }) => id), [companiesToAdd]);
    const companiesIdToRemove = useMemo(() => companiesToRemove.map(({ id }) => id), [companiesToRemove]);
    const companiesToAddMaped = useMemo(() => companiesToAdd.map(({ id }) => ({
        companyId: id,
        related: true,
    })), [companiesToAdd]);
    const companiesToRemoveMaped = useMemo(() => companiesToRemove.map(({ id }) => ({
        companyId: id,
        related: false,
    })), [companiesToRemove]);
    const fetchFilterdCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            if (companiesGroupId) {
                const fetchedCompanies = yield companiesGroupStore.fetchFilterdCompanies({
                    companiesGroupId,
                    cnpj: filters.cnpj,
                    fantasyName: filters.name,
                    name: filters.name,
                    related: filters.related,
                });
                setCompanies(fetchedCompanies);
            }
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const fetchCompaniesGroupById = (companiesGroupIdToFetch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            setSelectedCompaniesGroup(null);
            const fetchedCompaniesGroup = yield companiesGroupStore.fetchCompaniesGroupById(companiesGroupIdToFetch);
            setSelectedCompaniesGroup(fetchedCompaniesGroup);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const fetchCompaniesGroupsTotal = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const total = yield companiesGroupStore.fetchCompaniesGroupsTotal(filter);
            setCompaniesGroupTotal(total);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const fetchCompaniesGroups = (filter, currentPage, rowsPerPage) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const { companiesGroups: fetchedCompaniesGroups } = yield companiesGroupStore.fetchCompaniesGroups(filter, currentPage, rowsPerPage);
            setCompaniesGroups(fetchedCompaniesGroups);
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const updateCompaniesAfterPersist = () => {
        setCompanies((companiesValueOld) => companiesValueOld.map((company) => (Object.assign(Object.assign({}, company), { related: (company.related && !companiesIdToRemove.includes(company.id)) ||
                companiesIdToAdd.includes(company.id) }))));
    };
    const associateCompaniesGroupsCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield companiesGroupStore.associateCompaniesGroupsCompanies(companiesGroupId, [
                ...companiesToAddMaped,
                ...companiesToRemoveMaped,
            ]);
            updateCompaniesAfterPersist();
            generalStore.setSuccessMessage(t("companiesGroups.updateSuccessMessage"));
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("company.apiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const setFilter = (nameFilter, cnpjFilter) => {
        setFilters((oldValue) => (Object.assign(Object.assign({}, oldValue), { name: nameFilter, cnpj: cnpjFilter })));
    };
    const toogleRelatedFilter = () => {
        setFilters((oldValue) => (Object.assign(Object.assign({}, oldValue), { related: !oldValue.related })));
    };
    const addCompany = (company) => {
        setCompaniesWithChange((companiesWithChangeOldValue) => {
            const companyOldValue = getItemFromCollectionById(companiesWithChangeOldValue, company.id);
            const shouldRemoveCompanie = companyOldValue && !companyOldValue.related;
            if (shouldRemoveCompanie) {
                return removeItemFromCollectionById(companiesWithChangeOldValue, company.id);
            }
            return [...companiesWithChangeOldValue, Object.assign(Object.assign({}, company), { related: true })];
        });
    };
    const removeCompany = (company) => {
        setCompaniesWithChange((companiesWithChangeOldValue) => {
            const companyOldValue = getItemFromCollectionById(companiesWithChangeOldValue, company.id);
            const shouldRemoveCompanie = companyOldValue;
            if (shouldRemoveCompanie) {
                return removeItemFromCollectionById(companiesWithChangeOldValue, company.id);
            }
            return [...companiesWithChangeOldValue, Object.assign(Object.assign({}, company), { related: false })];
        });
    };
    const selectedCompaniesIds = useMemo(() => {
        const isFilteringByRelated = filters.related;
        let companiesToMap = companies;
        if (isFilteringByRelated) {
            companiesToMap = mergeArrayOfObjects(companies, companiesToAdd, "id");
        }
        const selectedCompanies = companiesToMap.filter(({ related, id }) => (related && !companiesIdToRemove.includes(id)) || companiesIdToAdd.includes(id));
        return selectedCompanies.map(({ id }) => id);
    }, [companies, companiesIdToAdd, companiesIdToRemove, filters]);
    const clearFilters = () => {
        setCompaniesWithChange([]);
        setFilters(emptyFilters);
    };
    const contextValues = {
        loading,
        companies,
        companiesGroups,
        companiesGroupTotal,
        filters,
        associateCompaniesGroupsCompanies,
        fetchFilterdCompanies,
        fetchCompaniesGroups,
        fetchCompaniesGroupsTotal,
        fetchCompaniesGroupById,
        selectedCompaniesGroup,
        setFilter,
        toogleRelatedFilter,
        addCompany,
        removeCompany,
        companiesGroupId,
        selectedCompaniesIds,
        setCompaniesGroupId,
        clearFilters,
        companiesToAdd,
    };
    return (React.createElement(CompaniesGroupContext.Provider, { value: contextValues }, children));
});
