import React, { useMemo } from "react";
import { Col, DatePicker, Input, InputNumber, Radio, Row } from "antd";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormTitleH3 } from "../../../FormTitleH3";
import { FormTitle as FormSectionTitle } from "../../../FormTitle";
import { Card } from "../../../CustomCard";
import { checkCNPJ, checkEmail } from "../../../../utils/formValidator";
import { MaskedInput } from "antd-mask-input";
import AddressForm from "../AddressForm";
import { toMoment } from "../../../../utils/date";
import { parseBigIntToString, toInt } from "../../../../utils/formatters";
import InputCurrency from "../../../InputCurrency";
export const CompanyDataFields = ({ form, companyInfo, readOnly, onHasExemptionRadioChange }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;
    const addressInitialValues = useMemo(() => {
        if (companyInfo) {
            return {
                cep: companyInfo.address.cep,
                city: companyInfo.address.city,
                complement: companyInfo.address.complement,
                neighborhood: companyInfo.address.neighborhood,
                number: companyInfo.address.number,
                state: companyInfo.address.state,
                street: companyInfo.address.street,
            };
        }
        return undefined;
    }, [companyInfo]);
    const chargeAddressInitialValues = useMemo(() => {
        if (companyInfo && companyInfo.chargeAddress && companyInfo.chargeAddress.address) {
            return {
                cep: companyInfo.chargeAddress.address.cep,
                city: companyInfo.chargeAddress.address.city,
                complement: companyInfo.chargeAddress.address.complement,
                neighborhood: companyInfo.chargeAddress.address.neighborhood,
                number: companyInfo.chargeAddress.address.number,
                state: companyInfo.chargeAddress.address.state,
                street: companyInfo.chargeAddress.address.street,
            };
        }
        return undefined;
    }, [companyInfo]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitleH3, { title: "Dados da Empresa" }),
        React.createElement("div", null,
            React.createElement(FormSectionTitle, { title: "Dados Gerais" }),
            React.createElement(Card, null,
                !readOnly && (React.createElement(Form.Item, { label: "id", style: { display: "none" } },
                    getFieldDecorator(`id`, { initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.id }),
                    React.createElement(Input, null))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: "CNPJ" }, getFieldDecorator(`general.cnpj`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.general.cnpj,
                            rules: [
                                {
                                    validator: checkCNPJ,
                                },
                            ],
                        })(React.createElement(MaskedInput, { disabled: readOnly, autoFocus: true, mask: "11.111.111/1111-11" }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 2 },
                        React.createElement(Form.Item, { label: t("companyForm.hasExemptionLabel") }, getFieldDecorator(`general.hasExemption`, {
                            initialValue: !(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.general.stateSubscription),
                        })(React.createElement(Radio.Group, { onChange: onHasExemptionRadioChange, disabled: readOnly },
                            React.createElement(Radio, { value: true }, t("yesLabel")),
                            React.createElement(Radio, { value: false }, t("noLabel")))))),
                    React.createElement(Col, { span: 6 },
                        React.createElement(Form.Item, { key: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.general.stateSubscription, label: t("companyForm.stateSubscriptionLabel") }, getFieldDecorator(`general.stateSubscription`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.general.stateSubscription,
                            rules: [
                                {
                                    required: !form.getFieldValue(`general.hasExemption`),
                                    message: () => (React.createElement("span", { "data-cy": "stateSubscription-error-message" }, t("companyForm.stateSubscriptionMessage"))),
                                },
                            ],
                            normalize: (value) => (value ? String(toInt(value)) : ""),
                        })(React.createElement(InputNumber, { "data-cy": "state-subscription-input", disabled: form.getFieldValue(`general.hasExemption`) || readOnly, maxLength: 40 }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: t("companyForm.socialNameLabel") }, getFieldDecorator(`general.socialName`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.general.socialName,
                            rules: [
                                {
                                    required: true,
                                    message: t("companyForm.socialNameMessage"),
                                },
                            ],
                        })(React.createElement(Input, { placeholder: readOnly ? "" : t("companyForm.socialNamePlaceholder"), maxLength: 500, disabled: readOnly })))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: t("companyForm.fantasyNameLabel") }, getFieldDecorator(`general.fantasyName`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.general.fantasyName,
                        })(React.createElement(Input, { maxLength: 500, placeholder: readOnly ? "" : t("companyForm.fantasyNamePlaceholder"), allowClear: true, disabled: readOnly }))))))),
        React.createElement("div", null,
            React.createElement(FormSectionTitle, { title: "Endere\u00E7o" }),
            React.createElement(Card, null,
                React.createElement(AddressForm, { form: form, initialValues: addressInitialValues, readOnly: readOnly }))),
        React.createElement("div", null,
            React.createElement(FormSectionTitle, { title: "Categoriza\u00E7\u00E3o Necess\u00E1ria" }),
            React.createElement(Card, null,
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 6 },
                        React.createElement(Form.Item, { label: t("companyForm.constitutionDateLabel") }, getFieldDecorator(`patrimonialInfo.constitutionDate`, {
                            initialValue: (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.constitutionDate)
                                ? toMoment(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.constitutionDate)
                                : null,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("companyForm.constitutionDateLabel"),
                                    }),
                                },
                            ],
                        })(React.createElement(DatePicker, { placeholder: readOnly ? "" : "10/10/2010", format: "DD/MM/YYYY", disabled: readOnly })))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: t("companyForm.lastStaturaryChangeDateLabel") }, getFieldDecorator(`patrimonialInfo.lastStaturaryChangeDate`, {
                            initialValue: (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.lastStaturaryChangeDate)
                                ? toMoment(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.lastStaturaryChangeDate)
                                : null,
                        })(React.createElement(DatePicker, { placeholder: readOnly ? "" : "10/10/2010", format: "DD/MM/YYYY", disabled: readOnly }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 6 },
                        React.createElement(Form.Item, { key: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.changeAmount, label: t("companyForm.changeAmountLabel") }, getFieldDecorator(`patrimonialInfo.changeAmount`, {
                            initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.changeAmount),
                            normalize: (value) => toInt(value),
                        })(React.createElement(InputNumber, { max: 10000, min: 0, placeholder: readOnly ? "" : "0", disabled: readOnly })))),
                    React.createElement(Col, { span: 5 },
                        React.createElement(Form.Item, { label: t("companyForm.socialCapitalLabel") }, getFieldDecorator(`patrimonialInfo.socialCapital`, {
                            initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.socialCapital),
                            normalize: (value) => toInt(value),
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("companyForm.socialCapitalLabel"),
                                    }),
                                },
                            ],
                        })(React.createElement(InputCurrency, { min: 0, disabled: readOnly })))),
                    React.createElement(Col, { span: 7 },
                        React.createElement(Form.Item, { key: `${companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.netWorth}`, label: t("companyForm.netWorthLabel") }, getFieldDecorator(`patrimonialInfo.netWorth`, {
                            initialValue: parseBigIntToString(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.netWorth),
                            normalize: (value) => toInt(value),
                        })(React.createElement(InputCurrency, { min: 0, disabled: readOnly }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 11 },
                        React.createElement(Form.Item, { label: t("companyForm.netWorthReferenceDateLabel") }, getFieldDecorator(`patrimonialInfo.netWorthReferenceDate`, {
                            initialValue: (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.netWorthReferenceDate)
                                ? toMoment(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.netWorthReferenceDate)
                                : null,
                        })(React.createElement(DatePicker, { placeholder: readOnly ? "" : "10/10/2010", format: "DD/MM/YYYY", disabled: readOnly }))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 5, "data-cy": "economic-sector-radio-group" },
                        React.createElement(Form.Item, { label: t("companyForm.economicSectorLabel") }, getFieldDecorator(`patrimonialInfo.economicSector`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.economicSector,
                        })(React.createElement(Radio.Group, { disabled: readOnly },
                            React.createElement(Radio, { value: "private" }, t("companyForm.privateLabel")),
                            React.createElement(Radio, { value: "municipal" }, t("companyForm.municipalLabel")),
                            React.createElement(Radio, { value: "state" }, t("companyForm.statePublicLabel")),
                            React.createElement(Radio, { value: "federal" }, t("companyForm.federalLabel")))))),
                    React.createElement(Col, { span: 5 },
                        React.createElement(Form.Item, { label: t("companyForm.capitalOriginLabel"), "data-cy": "capital-origin-radio-group" }, getFieldDecorator(`patrimonialInfo.capitalOrigin`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.patrimonialInfo.capitalOrigin,
                        })(React.createElement(Radio.Group, { disabled: readOnly },
                            React.createElement(Radio, { value: "national" }, t("companyForm.nationalLabel")),
                            React.createElement(Radio, { value: "foreign" }, t("companyForm.foreignLabel")),
                            React.createElement(Radio, { value: "mix" }, t("companyForm.mixedLabel")),
                            React.createElement(Radio, { value: "other" }, t("companyForm.otherLabel"))))))))),
        React.createElement("div", null,
            React.createElement(FormSectionTitle, { title: "Contato" }),
            React.createElement(Card, null,
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: t("companyForm.email") }, getFieldDecorator(`contact.email`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.contact.email,
                            rules: [
                                {
                                    required: true,
                                    validator: checkEmail,
                                },
                            ],
                        })(React.createElement(Input, { disabled: readOnly })))),
                    React.createElement(Col, { span: 6 },
                        React.createElement(Form.Item, { label: t("companyForm.telephoneLabel") }, getFieldDecorator(`contact.phone`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.contact.phone,
                            rules: [
                                {
                                    required: true,
                                    message: t("mandatoryField", {
                                        field: t("companyForm.telephoneLabel"),
                                    }),
                                },
                            ],
                        })(React.createElement(Input, { disabled: readOnly })))),
                    React.createElement(Col, { span: 7 },
                        React.createElement(Form.Item, { label: "Outros contatos" }, getFieldDecorator(`contact.otherContacts`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.contact.otherContacts,
                        })(React.createElement(Input, { disabled: readOnly }))))))),
        React.createElement("div", null,
            React.createElement(FormSectionTitle, { title: "Endere\u00E7o de cobran\u00E7a" }),
            React.createElement(Card, null,
                React.createElement(Form.Item, { label: "Mesmo endere\u00E7o" }, getFieldDecorator(`chargeAddress.sameAddress`, {
                    initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.chargeAddress.sameAddress,
                })(React.createElement(Radio.Group, { disabled: readOnly },
                    React.createElement(Radio, { value: true }, t("yesLabel")),
                    React.createElement(Radio, { value: false }, t("noLabel"))))),
                React.createElement(AddressForm, { form: form, parentName: "chargeAddress", initialValues: chargeAddressInitialValues, disabled: form.getFieldValue(`chargeAddress.sameAddress`), readOnly: readOnly }),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 10 },
                        React.createElement(Form.Item, { label: "Outro contato" }, getFieldDecorator(`chargeAddress.otherContact`, {
                            initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.chargeAddress.otherContact,
                        })(React.createElement(Input, { disabled: readOnly })))))))));
};
