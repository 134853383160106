exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.firstRow___acaWu {\n  display: grid;\n  grid-template-columns: 60% auto;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.secondRow___1n2y1 {\n  display: grid;\n  grid-template-columns: auto 60%;\n  width: 100%;\n  grid-column-gap: 10px; }\n\n.selectionGroupsWrapper___s3OCK {\n  display: flex;\n  grid-column-gap: 20px; }\n  .selectionGroupsWrapper___s3OCK div {\n    max-width: 170px; }\n\n.footerWrapper___2p-QA {\n  display: flex; }\n\n.addButton___1l_6T {\n  display: flex;\n  align-items: center; }\n  .addButton___1l_6T svg {\n    margin-right: 5px; }\n  .addButton___1l_6T:hover {\n    color: #0bc2d2; }\n    .addButton___1l_6T:hover path {\n      fill: #0bc2d2; }\n", ""]);

// Exports
exports.locals = {
	"firstRow": "firstRow___acaWu",
	"secondRow": "secondRow___1n2y1",
	"selectionGroupsWrapper": "selectionGroupsWrapper___s3OCK",
	"footerWrapper": "footerWrapper___2p-QA",
	"addButton": "addButton___1l_6T"
};