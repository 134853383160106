import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Modal, Spin, Alert } from "antd";
import { checkCPF } from "../../../../shared/utils/formValidator";
import isEmail from "validator/lib/isEmail";
const InnerForm = observer(({ form, visible, title, onCancel, onCloseAlert, okText, initialValues, loading, error, }) => {
    const { getFieldDecorator } = form;
    const { t } = useTranslation();
    const checkEmail = (_, value, callback) => {
        if (!value || value === "") {
            callback(t("companyEmployee.insertEmailText"));
            return;
        }
        if (isEmail(value)) {
            callback();
            return;
        }
        callback(t("invalidEmailText"));
    };
    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };
    return (React.createElement(Modal, { visible: visible, title: title, onCancel: handleCancel, okText: okText || t("registerLabel") },
        React.createElement(Form, { hideRequiredMark: true },
            React.createElement(Spin, { spinning: loading },
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 14 },
                        React.createElement(Form.Item, { label: t("nameLabel") }, getFieldDecorator("name", {
                            initialValue: initialValues ? initialValues.name : "",
                            rules: [
                                {
                                    required: true,
                                    message: t("nameLabel") + t("isRequiredLabel"),
                                },
                            ],
                        })(React.createElement("span", null)))),
                    React.createElement(Col, { span: 10 },
                        React.createElement(Form.Item, { label: "CPF" }, getFieldDecorator("cpf", {
                            initialValue: initialValues ? initialValues.cpf : "",
                            rules: [{ validator: checkCPF }],
                        })(React.createElement("span", null))))),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 14 },
                        React.createElement(Form.Item, { label: t("loginEmailLabel") }, getFieldDecorator("email", {
                            initialValue: initialValues ? initialValues.email : "",
                            rules: [{ validator: checkEmail }],
                        })(React.createElement("span", null))))),
                error && (React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 24 },
                        React.createElement(Alert, { message: error, type: "error", showIcon: true, closable: true, onClose: onCloseAlert }))))))));
});
export const LoanApplicationDetailsForm = Form.create({
    name: "loan_application_details_form",
})(InnerForm);
