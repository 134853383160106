import React, { useMemo } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { DateFormat, getDateLocalTimezoneFormat, getDurationFromNowAsHours, } from "../../../../shared/utils/date";
import { DocumentIcon } from "../../../../shared/components/Icons/Document";
import { useStores } from "../../../utils/mobx";
import styles from "./style.scss";
import TooltipDisplay from "../../../../shared/components/TooltipDisplay";
export const SignaturesTable = ({ signatures }) => {
    const { t } = useTranslation();
    const activeSignature = useMemo(() => signatures[0], []);
    const { legalResponsibleStore: { setSignatureId }, } = useStores();
    const willExpireOn = useMemo(() => {
        try {
            return Number(getDurationFromNowAsHours(activeSignature.expirationDate));
        }
        catch (error) {
            return null;
        }
    }, [activeSignature]);
    const columnsSignature = useMemo(() => [
        {
            title: t("signatures.status.title"),
            dataIndex: "status",
            key: "status",
            render: (status) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: styles.status },
                        " ",
                        t(`signatures.status.${status}`)),
                    status === "pending" && (React.createElement(TooltipDisplay, { title: t(`signatures.status.linkExpiresIn`, {
                            timeRemaining: activeSignature.expirationDate ? `${willExpireOn}h` : "--",
                        }), classname: styles.tooltipContainer }))));
            },
        },
        {
            title: t("signatures.date"),
            dataIndex: "acceptedTheTermsAt",
            key: "acceptedTheTermsAt",
            render: (acceptedTheTermsAt) => getDateLocalTimezoneFormat(acceptedTheTermsAt, DateFormat.BR_WITH_TIME),
        },
        {
            title: t("signatures.pep"),
            dataIndex: "pep",
            key: "pep",
            render: (pep) => (pep ? t("yesLabel") : pep === null ? "--" : t("noLabel")).toUpperCase(),
        },
        {
            title: t("signatures.data"),
            dataIndex: "id",
            key: "id",
            render: (id) => (React.createElement("a", { onClick: () => setSignatureId(id), "data-cy": "signature-company-data" },
                React.createElement(DocumentIcon, null))),
        },
    ], []);
    const signaturesTableDataSource = useMemo(() => signatures.map((signature) => (Object.assign(Object.assign({}, signature), { key: signature.id }))), [signatures]);
    return (React.createElement(Table, { className: styles.signaturesTable, columns: columnsSignature, pagination: false, dataSource: signaturesTableDataSource }));
};
