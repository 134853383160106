import { __awaiter } from "tslib";
import React, { useState } from "react";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import ActionTable from "../../../shared/components/ActionTable";
import styles from "./style.scss";
import { Button, Tabs } from "antd";
import { useStores } from "../../utils/mobx";
import { FilterForm } from "../../../shared/components/FilterForm";
import { useTranslation } from "react-i18next";
import { ApproverForm } from "./ApproverForm";
import { MoverForm } from "./MoverForm";
import { parseMomentToString } from "../../../shared/utils/date";
import { clearSymbols } from "../../../shared/utils/formatters";
import { formatCNPJ } from "@brazilian-utils/brazilian-utils";
const { TabPane } = Tabs;
var EscrowAreaIndexes;
(function (EscrowAreaIndexes) {
    EscrowAreaIndexes["APPROVER"] = "APPROVER";
    EscrowAreaIndexes["MOVER"] = "MOVER";
})(EscrowAreaIndexes || (EscrowAreaIndexes = {}));
export const Escrow = () => {
    const { generalStore: { setErrorMessage, catchErrors, setSuccessMessage }, escrowStore, } = useStores();
    const { t } = useTranslation();
    const getAprovers = (approversFilter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const approversResult = yield escrowStore.getApprovers(approversFilter);
            setApprovers(approversResult);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, "Erro ao obter aprovadores");
        }
        finally {
            setLoading(false);
        }
    });
    const getMovers = (moversFilter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const moversResult = yield escrowStore.getMovers(moversFilter);
            setMovers(moversResult);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, "Erro ao obter movimentadores");
        }
        finally {
            setLoading(false);
        }
    });
    const [currentApproversFilter, setCurrentApproversFilter] = useState({
        cnpj: null,
        companyName: null,
    });
    const [currentMoversFilter, setCurrentMoversFilter] = useState({
        cnpj: null,
        companyName: null,
    });
    const handleApproverSubmitForm = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield escrowStore.createEscrowApprover(Object.assign(Object.assign({}, values), { cnpj: clearSymbols(values.cnpj), accessPassword: values.accessPassword, representative: Object.assign(Object.assign({}, values), { cpf: clearSymbols(values.cpf), phoneNumber: `55${clearSymbols(values.phoneNumber)}`, birthDate: String(parseMomentToString(values.birthDate)), transactionalPassword: values.transactionalPassword }) }));
            setShowApproverModalForm(false);
            setSuccessMessage("Aprovador cadastrado com sucesso!");
        }
        catch (error) {
            catchErrors(error, setErrorMessageForm, "Erro ao realizar o cadastro do usuário aprovador");
        }
        finally {
            setLoading(false);
        }
    });
    const handleMoverSubmitForm = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            setLoading(true);
            yield escrowStore.createEscrowMover(Object.assign(Object.assign({}, values), { cnpj: clearSymbols(values.cnpj), accessPassword: values.accessPassword, approverCompanies: (_a = values.approverCompanies) === null || _a === void 0 ? void 0 : _a.map(({ id }) => ({ id: id })), representative: Object.assign(Object.assign({}, values), { cpf: clearSymbols(values.cpf), phoneNumber: `55${clearSymbols(values.phoneNumber)}`, birthDate: String(parseMomentToString(values.birthDate)), transactionalPassword: values.transactionalPassword }) }));
            setShowMoverModalForm(false);
            setSuccessMessage("Movimentador cadastrado com sucesso!");
        }
        catch (error) {
            catchErrors(error, setErrorMessageForm, "");
            return error;
        }
        finally {
            setLoading(false);
        }
    });
    const [escrowArea, setEscrowArea] = useState(EscrowAreaIndexes.APPROVER);
    const [loading, setLoading] = useState(false);
    const [selectedMoverRequestsKeys, setSelectedMoverRequestsKeys] = useState([]);
    const [selectedApproverRequestsKeys, setSelectedApproverRequestsKeys] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [selectedApprover, setSelectedApprover] = useState();
    const [movers, setMovers] = useState([]);
    const [selectedMover, setSelectedMover] = useState();
    const [errorMessageForm, setErrorMessageForm] = useState("");
    const [showApproverModalForm, setShowApproverModalForm] = useState(false);
    const [showMoverModalForm, setShowMoverModalForm] = useState(false);
    const handleApproveCancelForm = () => {
        setShowApproverModalForm(false);
        setSelectedApprover(undefined);
    };
    const handleMoverCancelForm = () => {
        setShowMoverModalForm(false);
        setSelectedMover(undefined);
    };
    const handleApproverSelectChange = (selectedRowKeys, _) => {
        setSelectedApproverRequestsKeys(selectedRowKeys);
    };
    const handleMoverSelectChange = (selectedRowKeys, _) => {
        setSelectedMoverRequestsKeys(selectedRowKeys);
    };
    const showApproverDynamicInfos = (approver) => {
        setShowApproverModalForm(true);
        setSelectedApprover(approver);
    };
    const showMoverDynamicInfos = (mover) => {
        setShowMoverModalForm(true);
        setSelectedMover(mover);
    };
    React.useEffect(() => {
        getAprovers(currentApproversFilter);
    }, [currentApproversFilter]);
    React.useEffect(() => {
        getMovers(currentMoversFilter);
    }, [currentMoversFilter]);
    const approversColumns = [
        {
            title: "Nome",
            dataIndex: "socialReason",
            key: "socialReason",
        },
        {
            title: "CNPJ",
            dataIndex: "cnpj",
            key: "cnpj",
        },
        {
            title: "Representante",
            dataIndex: "representativeData.fullName",
            key: "fullName",
        },
        {
            title: "Email",
            dataIndex: "representativeData.email",
            key: "email",
        },
        {
            title: "Telefone",
            dataIndex: "representativeData.phone",
            key: "phone",
        },
    ];
    const moversColumns = [
        {
            title: "Nome",
            dataIndex: "socialReason",
            key: "socialReason",
        },
        {
            title: "CNPJ",
            dataIndex: "cnpj",
            key: "cnpj",
        },
        {
            title: "Representante",
            dataIndex: "representativeData.fullName",
            key: "fullName",
        },
        {
            title: "Email",
            dataIndex: "representativeData.email",
            key: "email",
        },
        {
            title: "Telefone",
            dataIndex: "representativeData.phone",
            key: "phone",
        },
        {
            title: "Aprovadores",
            dataIndex: "approversSocialReasons",
            key: "approversSocialReasons",
        },
    ];
    const formatApprovers = (approversToFormat) => {
        return approversToFormat.map((inst, index) => (React.createElement("span", { key: `${inst.id}-${inst.name}` },
            inst.name,
            index <= approversToFormat.length - 2 ? ", " : "  ",
            index % 12 === 11 ? React.createElement("br", null) : "",
            "  ")));
    };
    const items = [
        {
            key: "name",
            name: "name",
            type: "input",
            placeholder: t("nameLabel"),
        },
        {
            key: "cnpj",
            name: "cnpj",
            type: "input",
            placeholder: t("cnpjLabel"),
        },
    ];
    const handleSubmitButton = () => {
        if (escrowArea === EscrowAreaIndexes.APPROVER) {
            setShowApproverModalForm(true);
        }
        else {
            setShowMoverModalForm(true);
        }
    };
    const applyApproversFilter = (values) => {
        const filter = {
            cnpj: clearSymbols(values.cnpj) || null,
            companyName: values.name || null,
        };
        setCurrentApproversFilter(filter);
    };
    const applyMoversFilter = (values) => {
        const filter = {
            cnpj: clearSymbols(values.cnpj) || null,
            companyName: values.name || null,
        };
        setCurrentMoversFilter(filter);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: "Escrow" }),
        React.createElement("div", { className: styles.headTable },
            React.createElement("h2", null,
                React.createElement("strong", null, "Visualizar")),
            React.createElement(Button, { type: "primary", size: "large", onClick: handleSubmitButton }, "Cadastrar Novo")),
        React.createElement(Tabs, { type: "card", onChange: (activeKey) => setEscrowArea(activeKey) },
            React.createElement(TabPane, { tab: React.createElement("span", null, "Aprovadores"), key: EscrowAreaIndexes.APPROVER },
                React.createElement("div", { "data-cy": "permission-table-wrapper" },
                    React.createElement(SectionContainer, { className: styles.container },
                        React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                            React.createElement(FilterForm, { items: items, handleSubmit: applyApproversFilter }),
                            React.createElement(ActionTable, { locale: { emptyText: "Não há Aprovadores" }, loading: loading, className: styles.table, dataSource: approvers.map((approver) => (Object.assign(Object.assign({}, approver), { cnpj: formatCNPJ(approver.cnpj) }))), rowClassName: () => {
                                    if (styles.rowClick) {
                                        return styles.rowClick;
                                    }
                                    return "";
                                }, onRow: (approver) => {
                                    return {
                                        onClick: () => showApproverDynamicInfos(approver),
                                    };
                                }, rowSelection: {
                                    selectedRowKeys: selectedApproverRequestsKeys,
                                    onChange: handleApproverSelectChange,
                                }, columns: approversColumns, scroll: { y: 460, x: 1800 } }))))),
            React.createElement(TabPane, { tab: React.createElement("span", null, "Movimentadores"), key: EscrowAreaIndexes.MOVER },
                React.createElement("div", { "data-cy": "permission-table-wrapper" },
                    React.createElement(SectionContainer, { className: styles.container },
                        React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                            React.createElement(FilterForm, { items: items, handleSubmit: applyMoversFilter }),
                            React.createElement(ActionTable, { locale: { emptyText: "Não há movimentadores" }, loading: loading, className: styles.table, dataSource: movers.map((mover) => {
                                    return Object.assign(Object.assign({}, mover), { cnpj: formatCNPJ(mover.cnpj), approversSocialReasons: mover.approversSocialReasons != null
                                            ? formatApprovers(mover.approversSocialReasons)
                                            : "--" });
                                }), rowClassName: () => {
                                    if (styles.rowClick) {
                                        return styles.rowClick;
                                    }
                                    return "";
                                }, onRow: (mover) => {
                                    return {
                                        onClick: () => showMoverDynamicInfos(Object.assign(Object.assign({}, mover), { approversSocialReasons: getApproversSocialReasons() })),
                                    };
                                    function getApproversSocialReasons() {
                                        return movers.filter(({ id }) => id === mover.id)[0].approversSocialReasons;
                                    }
                                }, rowSelection: {
                                    selectedRowKeys: selectedMoverRequestsKeys,
                                    onChange: handleMoverSelectChange,
                                }, columns: moversColumns, scroll: { y: 460, x: 1800 } })))))),
        React.createElement(ApproverForm, { visible: showApproverModalForm, onCancel: handleApproveCancelForm, onCreate: (value) => __awaiter(void 0, void 0, void 0, function* () {
                yield handleApproverSubmitForm(value);
            }), title: "Cadrastrar Novo Aprovador", loading: loading, onCloseAlert: () => setErrorMessageForm(""), error: errorMessageForm, initialValues: selectedApprover }),
        React.createElement(MoverForm, { visible: showMoverModalForm, onCancel: handleMoverCancelForm, onCreate: (value) => __awaiter(void 0, void 0, void 0, function* () {
                yield handleMoverSubmitForm(value);
            }), approveCompanies: approvers.map(({ id, socialReason }) => ({ id, name: socialReason })), title: "Cadrastrar Novo Movimentador", loading: loading, initialValues: selectedMover, error: errorMessageForm, onCloseAlert: () => setErrorMessageForm("") })));
};
