import React from "react";
import Form from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { FormTitleH3 } from "../../../FormTitleH3";
import { Card } from "../../../CustomCard";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
export const AditionalInformationsFields = ({ form, companyInfo, readOnly }) => {
    const { getFieldDecorator } = form;
    const formKey = "additionalInfo";
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitleH3, { title: t("portalPj.aditionalInfo") }),
        React.createElement(Card, null,
            React.createElement("div", { className: s.informationsLabel }, t("portalPj.aditionalInfoDescription")),
            React.createElement("div", { className: s.secondRow },
                React.createElement("div", null,
                    React.createElement(Form.Item, null, getFieldDecorator(`${formKey}`, { initialValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.additionalInfo })(React.createElement(TextArea, { disabled: readOnly, rows: 4 }))))))));
};
