exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.contentWrapper___2vGlU {\n  width: 100%;\n  padding: 16px; }\n\n.title___2HrrU {\n  font-size: 18px;\n  font-weight: bold;\n  color: #4D4D50;\n  margin-bottom: 15px; }\n\n.errorAlert___JPnuE {\n  margin-top: 20px; }\n\n.modal___2PMh6 div[class$=\"ant-modal-header\"] {\n  padding: 0; }\n", ""]);

// Exports
exports.locals = {
	"contentWrapper": "contentWrapper___2vGlU",
	"title": "title___2HrrU",
	"errorAlert": "errorAlert___JPnuE",
	"modal": "modal___2PMh6"
};