import { __awaiter } from "tslib";
import React, { useState } from "react";
import { Modal, Form, DatePicker, Spin, Radio, Input } from "antd";
import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../../../shared/utils/formatters";
import styles from "./style.scss";
const ChargePayment = ({ charge, onClose, confirmPaymentOk, confirmExtension, form, loading, }) => {
    const { getFieldDecorator, validateFields, setFieldsValue } = form;
    const { t } = useTranslation();
    const [extendPayment, setExtendPayment] = useState("no");
    const clearFields = () => {
        setFieldsValue({
            paidDate: null,
            extendPayment: "no",
            reason: null,
        });
        setExtendPayment("no");
    };
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        clearFields();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                if (charge) {
                    if (extendPayment === "yes" && confirmExtension) {
                        yield confirmExtension(charge, values.reason);
                    }
                    else if (confirmPaymentOk && values.paidDate) {
                        yield confirmPaymentOk(charge, values.paidDate.toDate());
                    }
                }
                clearFields();
            }
        }));
    };
    return (React.createElement("div", { className: styles.modalWrapper },
        React.createElement(Modal, { title: extendPayment === "yes"
                ? `${t("charge.extendPaymentTextLabel")} ${charge === null || charge === void 0 ? void 0 : charge.name}`
                : `${t("charge.confirmPaymentTextTitle")} ${charge === null || charge === void 0 ? void 0 : charge.name}`, visible: Boolean(charge), onOk: handleSubmit, onCancel: handleClose, width: 700, cancelText: t("cancelLabel"), maskClosable: true, okText: React.createElement("span", { "data-cy": "charge-payment-submit-button" }, t("confirmLabel")) },
            React.createElement(Spin, { spinning: loading },
                React.createElement("div", { className: styles.bodyWrapper },
                    React.createElement("div", { className: styles.formText }, extendPayment === "yes"
                        ? `${t("charge.extendPaymentTextContent")} ${charge === null || charge === void 0 ? void 0 : charge.name}?`
                        : `${t("charge.confirmPaymentTextContent")} ${charge === null || charge === void 0 ? void 0 : charge.name} ${t("charge.inValueText")} ${formatStringCurrency(charge === null || charge === void 0 ? void 0 : charge.value)}?`),
                    React.createElement(Form.Item, { label: React.createElement("span", { className: styles.formItemLabel }, t("extendPaymentLabel")), className: styles.formItem }, getFieldDecorator("extendPayment", {
                        initialValue: "no",
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("extendPaymentLabel") }),
                            },
                        ],
                    })(React.createElement(Radio.Group, { "data-cy": "extend-payment-radio-group", className: "radio-group-reschedule", onChange: (e) => setExtendPayment(e.target.value) },
                        React.createElement(Radio, { "data-cy": "extend-yes-radio", value: "yes" }, t("yesLabel")),
                        React.createElement(Radio, { "data-cy": "extend-no-radio", value: "no" }, t("noLabel"))))),
                    extendPayment === "no" ? (React.createElement(Form.Item, { label: React.createElement("span", { className: styles.formItemLabel }, t("paymentDateLabel")), className: styles.formItem }, getFieldDecorator("paidDate", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("paymentDateLabel") }),
                            },
                        ],
                    })(React.createElement(DatePicker, { placeholder: "dd/mm/aaaa", format: "DD/MM/YYYY", "data-cy": "payment-date-input-wrapper" })))) : (React.createElement(Form.Item, { label: React.createElement("span", { className: styles.formItemLabel }, t("extendedReason")), className: styles.formItem }, getFieldDecorator("reason", {
                        rules: [
                            {
                                required: true,
                                message: t("mandatoryField", { field: t("extendedReason") }),
                            },
                        ],
                    })(React.createElement(Input, { "data-cy": "reason-input" })))))))));
};
export const ChargePaymentForm = Form.create({
    name: "charge_payment_form",
})(ChargePayment);
