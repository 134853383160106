import React from "react";
import s from "./style.scss";
import { ListColumnInfo } from "../ListColumnInfo";
import { ColumnTextArea } from "../ColumnTextArea";
const emptyHandleChange = () => { };
export const ConfirmModalContent = (props) => {
    return (React.createElement("div", { className: s.modal },
        React.createElement("div", { className: s.modalBody },
            React.createElement(ListColumnInfo, { labels: props.labels, values: props.values })),
        props.hasTextArea && props.textAreaProps && (React.createElement(ColumnTextArea, { onChange: props.onChange ? props.onChange : emptyHandleChange, title: props.textAreaProps.title, value: props.textAreaProps.value }))));
};
