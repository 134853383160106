exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.date___16_eC {\n  width: 100%; }\n\n.form___1OC4I div[class$=\"ant-form-explain\"] {\n  font-size: 12px; }\n\n.form___1OC4I div[class$=\"ant-modal-body\"] {\n  padding: 10px 24px 0px 24px; }\n\n.form___1OC4I div[class$=\"ant-form\"] label {\n  font-size: 14px; }\n\ndiv[class*=\"ant-modal-body\"] {\n  padding: 10px 25px 0px 25px; }\n\n.title___3vk5L {\n  color: #4D4D50; }\n\n.formWrapper___Fix9y {\n  min-height: 500px;\n  background-color: #F4F4F4;\n  border-radius: 8px;\n  padding: 50px; }\n  .formWrapper___Fix9y .titleGrid___1wFH5,\n  .formWrapper___Fix9y .subtitleGrid___a913r,\n  .formWrapper___Fix9y .footerTitleGrid___139bZ,\n  .formWrapper___Fix9y .subtitlefooterGrid___3LQhQ {\n    display: grid;\n    grid-template-columns: 15% 35% 25% 25%;\n    column-gap: 10px;\n    font-weight: bold;\n    font-size: 19px;\n    color: #01929f; }\n  .formWrapper___Fix9y .titleGrid___1wFH5 {\n    font-size: 16px;\n    color: #4D4D50; }\n  .formWrapper___Fix9y .footerTitleGrid___139bZ {\n    font-size: 16px;\n    color: #4D4D50; }\n  .formWrapper___Fix9y .subtitlefooterGrid___3LQhQ {\n    font: normal normal 300 16px/22px Montserrat;\n    color: #4e4e4e; }\n\n.tableWrapper___1c66B {\n  min-height: 200px; }\n\n.installmentsTable___2U7nF {\n  overflow: hidden;\n  min-height: 320px !important; }\n  .installmentsTable___2U7nF div[class$=\"ant-table-selection\"] {\n    visibility: visible; }\n  .installmentsTable___2U7nF div[class*=\"table-body\"] {\n    min-height: 260px !important; }\n\n.header___EX2aw {\n  margin-bottom: 20px; }\n\n.footer___2wSl9 {\n  margin-bottom: 20px; }\n", ""]);

// Exports
exports.locals = {
	"date": "date___16_eC",
	"form": "form___1OC4I",
	"title": "title___3vk5L",
	"formWrapper": "formWrapper___Fix9y",
	"titleGrid": "titleGrid___1wFH5",
	"subtitleGrid": "subtitleGrid___a913r",
	"footerTitleGrid": "footerTitleGrid___139bZ",
	"subtitlefooterGrid": "subtitlefooterGrid___3LQhQ",
	"tableWrapper": "tableWrapper___1c66B",
	"installmentsTable": "installmentsTable___2U7nF",
	"header": "header___EX2aw",
	"footer": "footer___2wSl9"
};