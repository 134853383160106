import React from "react";
import { Row, Col, Icon } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnInfo } from "../../../shared/components/ColumnInfo";
import s from "./style.scss";
import { formatStringCurrency } from "../../../shared/utils/formatters";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
const AmortizeCalcResult = (props) => {
    const { t } = useTranslation();
    return (React.createElement("section", { className: s.container },
        React.createElement(Row, { gutter: 8 },
            React.createElement(Col, { span: 12 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("balanceInLabel") + " " + getDateLocalTimezoneFormat(props.balanceDate), value: formatStringCurrency(props.balance) })),
            React.createElement(Col, { span: 8 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("amortizedValueLabel"), value: formatStringCurrency(props.payValue) }))),
        React.createElement(Icon, { onClick: props.onClickDownload, className: s.icon, type: "download", "data-html2canvas-ignore": true }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("remainingInstallmentsLabel"), value: props.remainingInstallments })),
            React.createElement(Col, { span: 8 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("remainingValueLabel") + " " + getDateLocalTimezoneFormat(props.balanceDate), value: formatStringCurrency(props.remainingValue) }))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("nextDueLabel"), value: getDateLocalTimezoneFormat(props.nextExpirationDate) })),
            React.createElement(Col, { span: 8 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("nextDueLabel"), value: formatStringCurrency(props.nextInstallmentValue) }))),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("lastDueLabel"), value: getDateLocalTimezoneFormat(props.lastExpirationDate) })),
            React.createElement(Col, { span: 8 },
                React.createElement(ColumnInfo, { className: s.modalInfo, title: t("lastDueLabel"), value: formatStringCurrency(props.lastInstallmentValue) })))));
};
export default AmortizeCalcResult;
