import React from "react";
import classNames from "classnames";
import Modal from "antd/lib/modal";
import { AmortizeLoanContractForm } from "../AmortizeLoanContractForm";
import AmortizeCalcResult from "../AmortizeCalcResult";
import s from "./style.scss";
import { Spin, Alert } from "antd";
const AmortizeModal = (props) => {
    const handleSubmit = (values) => {
        setDisableButton(false);
        setBalanceDate(values.date);
        props.onSubmit(values);
    };
    const [disableButton, setDisableButton] = React.useState(true);
    const [balanceDate, setBalanceDate] = React.useState(null);
    return (React.createElement(Modal, { className: s.modal, visible: props.visible, okText: props.okText, onCancel: props.onCancel, okButtonProps: {
            disabled: props.disableOkButton || disableButton,
        }, onOk: props.onOk, maskClosable: true, destroyOnClose: true, width: "40%" },
        React.createElement(Spin, { spinning: props.loadingCalc },
            React.createElement("div", { id: props.divId },
                React.createElement("div", { className: classNames("ant-modal-title", s.modalHeader) },
                    React.createElement("p", null, props.title),
                    React.createElement("p", null,
                        " ",
                        props.subtitle)),
                React.createElement(AmortizeLoanContractForm, { remainingInstallments: props.remainingInstallments, onSubmit: handleSubmit, title: props.title, disableOkButton: setDisableButton }),
                props.errorMessage && (React.createElement(Alert, { type: "error", closable: true, showIcon: true, message: props.errorMessage, onClose: props.onCloseAlert })),
                props.calcResult && (React.createElement(AmortizeCalcResult, { onClickDownload: props.onClickDownload, balance: props.calcResult.balance, remainingInstallments: props.calcResult.remainingInstallments, nextExpirationDate: props.calcResult.nextExpirationDate, lastExpirationDate: props.calcResult.lastExpirationDate, nextInstallmentValue: props.calcResult.nextInstallmentValue, lastInstallmentValue: props.calcResult.lastInstallmentValue, payValue: props.calcResult.payValue, balanceDate: balanceDate, remainingValue: props.calcResult.remainingValue }))))));
};
export default AmortizeModal;
