import React from "react";
import { useTranslation } from "react-i18next";
import { FormTitle } from "../../../shared/components/FormTitle";
import { Row, Col } from "antd";
import s from "./style.scss";
const DynamicForm = ({ title, form, formKey, addItemButtonText, renderItem, defaultEmptyValue, initialValues, }) => {
    const { getFieldDecorator } = form;
    const [items, setItems] = React.useState([
        Object.assign({}, defaultEmptyValue),
    ]);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (initialValues && initialValues.length) {
            setItems(initialValues);
        }
    }, []);
    const add = () => {
        setItems([
            ...items,
            Object.assign({}, defaultEmptyValue),
        ]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { title: title }),
        items.map((_, index) => {
            const itemName = `${formKey}[${index}]`;
            return (React.createElement("div", { key: itemName }, renderItem(getFieldDecorator, itemName, initialValues ? initialValues[index] : null)));
        }),
        React.createElement(Row, { gutter: 20 },
            React.createElement(Col, { span: 12 },
                React.createElement("button", { className: s.addItemButton, onClick: add, type: "button" }, addItemButtonText || t("addLabel"))))));
};
export default DynamicForm;
