import { __awaiter } from "tslib";
import React, { useRef, useState } from "react";
import onlyNumbers from "@brazilian-utils/helper-only-numbers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useStores } from "../../utils/mobx";
import { formatCpf, formatStringCurrencyNoPrefix, zeroFill, } from "../../../shared/utils/formatters";
import ActionTable from "../../../shared/components/ActionTable";
import { SectionHeader } from "../../../shared/components/SectionHeader";
import { HeaderColumn } from "../../../shared/components/HeaderColumn";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { Pagination } from "../../../shared/components/Pagination";
import { defaultConfirmProps } from "../../../shared/utils";
import { FilterForm } from "../../../shared/components/FilterForm";
import { LoanApplicationDetailsForm } from "./details";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import MutipleApproval from "../../components/LoanApplications/ModalContent/MutipleApproval";
import SingleApproval from "../../components/LoanApplications/ModalContent/SingleApproval";
import { ContractDataTabCard } from "../../components/Contracts/ContractDataTabCard";
import i18n from "../../i18n";
import styles from "./style.scss";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { BlockUserForm } from "../../../shared/components/BlockUserForm";
import { IdentityValidationReportInfo } from "./identityValidation";
const confirm = Modal.confirm;
export const loanLabels = {
    cpf: "CPF",
    salary: `${i18n.t("loanApplication.salary")}(R$)`,
    totalAmount: `${i18n.t("loanApplication.totalAmount")}(R$)`,
    percentage: `${i18n.t("loanApplication.percentual")}(%)`,
    numberOfInstallments: i18n.t("loanApplication.installmentsLabel"),
    emittedDate: i18n.t("loanApplication.emittedDate"),
    ccbTotalValue: `${i18n.t("loanApplication.ccbTotalValue")}(R$)`,
    netValueCreditedToIssuer: `${i18n.t("loanApplication.netValueCreditedToIssuer")}(R$)`,
    installmentsValue: `${i18n.t("loanApplication.installmentsValue")}(R$)`,
    firstInstallmentDue: i18n.t("loanApplication.firstInstallmentDue"),
    lastInstallmentDue: i18n.t("loanApplication.lastInstallmentDue"),
    monthlyInterestRate: `${i18n.t("loanApplication.monthlyInterestRate")}(%)`,
    monthlyEffectiveCost: i18n.t("loanApplication.monthlyEffectiveCost"),
    bankName: i18n.t("loanApplication.bankName"),
    bankNumber: i18n.t("loanApplication.bankNumber"),
    bankBranch: i18n.t("loanApplication.bankBranch"),
    bankAccount: i18n.t("loanApplication.bankAccount"),
    mainPhone: i18n.t("loanApplication.mainPhone"),
    email: i18n.t("loanApplication.email"),
    dateOfBirth: i18n.t("loanApplication.dateOfBirth"),
    residentialPhone: i18n.t("loanApplication.residentialPhone"),
    secondaryEmail: i18n.t("loanApplication.secondaryEmail"),
    address: i18n.t("loanApplication.address"),
    accountType: i18n.t("loanApplication.accountType"),
    admissionDate: i18n.t("loanApplication.admissionDate"),
    predictedLoanValue: i18n.t("loanApplication.predictedLoanValue"),
    predictedInstallments: i18n.t("loanApplication.predictedInstallments"),
    maxUserBalance: i18n.t("loanApplication.maxUserBalance"),
};
const LoanApplication = observer(() => {
    const { t } = useTranslation();
    const { generalStore: { setErrorMessage, setSuccessMessage, clearMessage, catchErrors }, loanApplicationStore, } = useStores();
    const [loading, setLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loanRequests, setLoanRequests] = useState([]);
    const [totalRequests, setTotalRequests] = useState(0);
    const [selectedRequestsKeys, setSelectedRequestsKeys] = useState([]);
    const [transferAmount, setTransferAmount] = useState();
    const transferAmountRef = useRef();
    const [blockTemporaryUser, setBlockTemporaryUser] = useState(null);
    const blockTemporaryUserRef = useRef(null);
    blockTemporaryUserRef.current = blockTemporaryUser;
    transferAmountRef.current = transferAmount;
    const [currentFilter, setCurrentFilter] = useState({
        cpf: null,
        companyName: null,
    });
    const columns = [
        {
            title: t("companyLabel"),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: t("nameLabel"),
            dataIndex: "userName",
            key: "userName",
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: (React.createElement(HeaderColumn, { title: React.createElement("div", { className: styles.right },
                    React.createElement("div", { "data-cy": "identity-validation-data-cy" }, t("identityValidationLabel"))) })),
            dataIndex: "identityValidationReportInfo",
            key: "identityValidationReportInfo",
            align: "left",
        },
        {
            title: (React.createElement(HeaderColumn, { title: React.createElement("div", { className: styles.left },
                    React.createElement("div", null, t("loanApplication.remunerationLabel")),
                    " ",
                    t("loanApplication.availableLabel"),
                    " (R$)"), description: t("loanApplication.availableRemunerationDescriptionText") })),
            dataIndex: "salary",
            key: "salary",
            align: "right",
        },
        {
            title: (React.createElement(HeaderColumn, { title: React.createElement("div", { className: styles.left },
                    React.createElement("div", null, t("loanApplication.valueLabel")),
                    " ",
                    t("loanApplication.fundedLabel"),
                    "(R$)"), description: t("loanApplication.requestedValueDescriptionText") })),
            dataIndex: "totalAmount",
            key: "totalAmount",
            align: "right",
        },
        {
            title: React.createElement(HeaderColumn, { title: "%", description: t("loanApplication.percentualInfoText") }),
            dataIndex: "percentage",
            key: "percentage",
            align: "right",
        },
        {
            title: t("installmentsLabel"),
            dataIndex: "numberOfInstallments",
            key: "numberOfInstallments",
            align: "right",
        },
    ];
    const modalDeniedConfirmText = (total) => {
        return `${t("loanApplication.confirmationDenyText")} ${total > 1 ? t("loanApplication.everyRequestText") : t("loanApplication.singleRequestText")}?`;
    };
    const [showModalForm, setShowModalForm] = useState(false);
    const handleCancelForm = () => {
        setShowModalForm(false);
    };
    const [loadingForm] = useState(false);
    const [errorMessageForm, setErrorMessageForm] = useState("");
    const getLoanRequest = (id) => loanRequests === null || loanRequests === void 0 ? void 0 : loanRequests.find((loanRequest) => loanRequest.id === id);
    const statusMessages = {
        approved: {
            confirmTile: (total) => `${t("loanApplication.acceptLabel")} ${zeroFill(2, total)} ${total > 1 ? t("loanApplication.requestPluralLabel") : t("loanApplication.requestLabel")} `,
            confirmContent: (total, selectedLoans) => {
                var _a;
                return total > 1 ? (React.createElement(MutipleApproval, null)) : (React.createElement(SingleApproval, { defaultTransferAmount: selectedLoans ? (_a = getLoanRequest(selectedLoans[0])) === null || _a === void 0 ? void 0 : _a.creditInfo.totalAmount : undefined, onChange: setTransferAmount }));
            },
            successMessage: (total) => `${zeroFill(2, total)} ${total > 1
                ? t("loanApplication.acceptedRequestsConfirmationMessage")
                : t("loanApplication.acceptedSingleRequestConfirmationMessage")} ${t("loanApplication.confirmationSuccessfullyText")}`,
        },
        denied: {
            confirmTile: (total) => `${t("loanApplication.denyLabel")} ${zeroFill(2, total)} ${total > 1 ? t("loanApplication.requestPluralLabel") : t("loanApplication.requestLabel")} `,
            confirmContent: (total) => (React.createElement("div", null,
                React.createElement("div", null, modalDeniedConfirmText(total)),
                React.createElement("div", null,
                    React.createElement(BlockUserForm, { onChange: onChangeUserBlockFormOption })))),
            successMessage: (total) => `${zeroFill(2, total)} ${total > 1
                ? t("loanApplication.deniedRequestsConfirmationMessage")
                : t("loanApplication.deniedSingleRequestConfirmationMessage")} ${t("loanApplication.confirmationSuccessfullyText")}`,
        },
    };
    const filterItems = [
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: "CPF",
            inputMask: "111.111.111-11",
        },
        {
            key: "companyName",
            name: "companyName",
            type: "input",
            placeholder: t("companyLabel"),
        },
    ];
    const getLoanRequests = (currentFilterRequest, currentPageRequest, rowsPerPageRequest) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const response = yield loanApplicationStore.getLoanRequests(currentFilterRequest, currentPageRequest, rowsPerPageRequest);
            setLoanRequests(response.requests);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
            const total = yield loanApplicationStore.getLoanRequestTotal(currentFilterRequest);
            setTotalRequests(total);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("loanApplication.loanApplicationsApiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    React.useEffect(() => {
        getLoanRequests(currentFilter, currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage, currentFilter]);
    const showDynimicInfos = (loan) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement("div", { className: styles.modalHeaderDialog },
                React.createElement("h2", { className: styles.modalTitleDialog }, loan.userName),
                React.createElement("div", { className: styles.companyDialog }, loan.companyName))), content: (React.createElement("div", { className: styles.modal },
                React.createElement("div", { className: styles.modalBodyDialog },
                    React.createElement(ListColumnInfo, { labels: [
                            loanLabels.cpf,
                            loanLabels.salary,
                            loanLabels.totalAmount,
                            loanLabels.percentage,
                            loanLabels.numberOfInstallments,
                            loanLabels.admissionDate,
                            loanLabels.predictedLoanValue,
                            loanLabels.predictedInstallments,
                            loanLabels.maxUserBalance,
                        ], values: [
                            loan.cpf,
                            formatStringCurrencyNoPrefix(loan.salary),
                            formatStringCurrencyNoPrefix(loan.creditInfo.totalAmount),
                            loan.percentage,
                            loan.creditInfo.numberOfInstallments,
                            getDateLocalTimezoneFormat(loan.admissionDate),
                            formatStringCurrencyNoPrefix(loan.creditInfo.predictedValue),
                            loan.creditInfo.predictedInstallments,
                            formatStringCurrencyNoPrefix(loan.creditInfo.balance),
                        ] }),
                    React.createElement("div", { className: styles.separation }),
                    React.createElement(ListColumnInfo, { labels: [
                            loanLabels.emittedDate,
                            loanLabels.ccbTotalValue,
                            loanLabels.netValueCreditedToIssuer,
                            loanLabels.installmentsValue,
                            loanLabels.firstInstallmentDue,
                            loanLabels.lastInstallmentDue,
                            loanLabels.monthlyInterestRate,
                            loanLabels.monthlyEffectiveCost,
                        ], values: [
                            loan.creditInfo.emittedDate,
                            formatStringCurrencyNoPrefix(loan.creditInfo.ccbTotalValue),
                            formatStringCurrencyNoPrefix(loan.creditInfo.netValueCreditedToIssuer),
                            formatStringCurrencyNoPrefix(loan.creditInfo.installmentsValue),
                            loan.creditInfo.firstInstallmentDue,
                            loan.creditInfo.lastInstallmentDue,
                            loan.creditInfo.monthlyInterestRate,
                            loan.creditInfo.monthlyEffectiveCost,
                        ] }),
                    React.createElement(ContractDataTabCard, { generalData: loan })))), className: styles.containerDialog, width: "50%", maskClosable: true, onOk: () => handleSelectChange([...selectedRequestsKeys, loan.id], null), okText: "Selecionar", okButtonProps: {
                icon: "check",
                type: "primary",
                disabled: selectedRequestsKeys.includes(loan.id),
            }, cancelText: "Fechar" }));
    };
    const handleSelectChange = (selectedRowKeys, _) => {
        setSelectedRequestsKeys(selectedRowKeys);
    };
    const onChangeUserBlockFormOption = (shouldBlock) => {
        setBlockTemporaryUser(shouldBlock);
    };
    const handleOpenConfirm = (status, onOk, selectedKeys) => {
        clearMessage();
        const totalSelectedApplications = selectedKeys ? selectedKeys.length : 0;
        if (selectedKeys === undefined || totalSelectedApplications === 0) {
            setErrorMessage(t("loanApplication.emptySelectionText"));
        }
        else {
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: statusMessages[status].confirmTile(selectedKeys.length), content: statusMessages[status].confirmContent(selectedKeys.length, selectedKeys), onOk }));
        }
    };
    const changeStatusFromLoanRequest = (args) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        if (args.selectedRequestsKeysRequest) {
            try {
                const isSingleApproval = args.selectedRequestsKeysRequest.length === 1;
                yield loanApplicationStore.changeStatusFromLoanRequest(args.status, args.selectedRequestsKeysRequest, isSingleApproval ? args.transferAmountRequest : null, args.numberOfDaysToBlockUser);
                setSuccessMessage(statusMessages[args.status].successMessage(args.selectedRequestsKeysRequest.length));
            }
            catch (error) {
                catchErrors(error, setErrorMessage, "Oops, ocorreu um erro inesperado.");
            }
            finally {
                yield getLoanRequests(args.currentFilterRequest, args.currentPageRequest, args.rowsPerPageRequest);
                setSelectedRequestsKeys([]);
                setLoading(false);
            }
        }
    });
    const hasLoanRequests = !loading && loanRequests.length > 0;
    const applyFilter = (values) => {
        const filter = {
            cpf: values.cpf ? onlyNumbers(values.cpf) : null,
            companyName: values.companyName,
        };
        setCurrentPage(1);
        setRowsPerPage(10);
        setCurrentFilter(filter);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("loanApplication.loanApplicationTitle") }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(FilterForm, { items: filterItems, handleSubmit: applyFilter }),
            React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                React.createElement(ActionTable, { locale: { emptyText: t("loanApplication.emptyLoanApplicationText") }, className: styles.table, loading: loading, rowClassName: () => {
                        if (styles.rowClick) {
                            return styles.rowClick;
                        }
                        return "";
                    }, onRow: (loan) => {
                        return {
                            onClick: () => showDynimicInfos(loan),
                        };
                    }, rowSelection: {
                        selectedRowKeys: selectedRequestsKeys,
                        onChange: handleSelectChange,
                    }, dataSource: loanRequests.map((loan) => (Object.assign(Object.assign({}, loan), { companyName: loan.companyName, userName: loan.userName, cpf: formatCpf(loan.cpf), salary: formatStringCurrencyNoPrefix(loan.salary), totalAmount: formatStringCurrencyNoPrefix(loan.creditInfo.totalAmount), percentage: loan.percentage, numberOfInstallments: loan.creditInfo.numberOfInstallments, creditInfo: loan.creditInfo, key: loan.id, id: loan.id, financialData: loan.financialData, identityValidationReportInfo: hasIdentityValidation(loan) ? (React.createElement(IdentityValidationReportInfo, { id: loan.id, reportLink: loan.identityValidationReportInfo.reportLink, reportNumber: loan.identityValidationReportInfo.reportNumber, textContent: loan.identityValidationReportInfo.reportMatriz })) : (React.createElement("div", { "data-cy": `reporLink-data-cy-${loan.id}` }, t("defaultNullFieldPlaceholder"))) }))), columns: columns, actionsOutsideFooter: true, actions: [
                        {
                            value: "approved",
                            label: t("loanApplication.acceptRequestLabel"),
                            onClick: (selectedKeys, currentPageRequest) => {
                                handleOpenConfirm("approved", () => {
                                    changeStatusFromLoanRequest({
                                        status: "approved",
                                        currentFilterRequest: currentFilter,
                                        currentPageRequest,
                                        rowsPerPageRequest: rowsPerPage,
                                        selectedRequestsKeysRequest: selectedKeys,
                                        transferAmountRequest: transferAmountRef.current,
                                    });
                                }, selectedKeys);
                            },
                        },
                        {
                            value: "denied",
                            label: t("loanApplication.denyRequestLabel"),
                            onClick: (selectedKeys, currentPageRequest) => {
                                handleOpenConfirm("denied", () => {
                                    changeStatusFromLoanRequest({
                                        status: "denied",
                                        currentFilterRequest: currentFilter,
                                        currentPageRequest,
                                        rowsPerPageRequest: rowsPerPage,
                                        selectedRequestsKeysRequest: selectedKeys,
                                        numberOfDaysToBlockUser: blockTemporaryUserRef.current ? blockTemporaryUserRef.current : undefined,
                                    });
                                }, selectedKeys);
                            },
                        },
                    ], scroll: { y: 460, x: 1800 } }))),
        React.createElement(LoanApplicationDetailsForm, { visible: showModalForm, title: t("loanDetailsLowerLabel"), okText: t("saveLabel"), initialValues: null, loading: loadingForm, onCancel: handleCancelForm, onCloseAlert: () => setErrorMessageForm(""), error: errorMessageForm }),
        React.createElement(SectionFooter, null, hasLoanRequests && (React.createElement(Pagination, { current: currentPage, total: totalRequests, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
});
export default LoanApplication;
const hasIdentityValidation = (loan) => {
    return !!(loan.identityValidationReportInfo &&
        loan.identityValidationReportInfo.reportNumber &&
        loan.identityValidationReportInfo.reportLink &&
        loan.identityValidationReportInfo.reportMatriz);
};
