import { __rest } from "tslib";
import React from "react";
import { Button } from "antd";
export const ButtonWithPreventDefault = (_a) => {
    var { children, handleClick } = _a, rest = __rest(_a, ["children", "handleClick"]);
    return (React.createElement(Button, Object.assign({}, rest, { onClick: (e) => {
            e.preventDefault();
            handleClick();
        } }), children));
};
