import { __awaiter } from "tslib";
import React, { useState, useMemo } from "react";
import { Button, Form, Input, Modal, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { getDurationFromNowAsHours } from "../../../../../shared/utils/date";
import { useStores } from "../../../../utils/mobx";
import styles from "./style.scss";
export const LinkForm = ({ initialValues, companyId, form }) => {
    var _a, _b;
    const { validateFieldsAndScroll } = form;
    const { t } = useTranslation();
    const { companyStore, generalStore, legalResponsibleStore: { sendEmailToLegalResponsiblesWithSignatureLink }, } = useStores();
    const { catchErrors, setErrorMessage, clearMessage, setSuccessMessage } = generalStore;
    const [generatedLink, setGeneratedLink] = React.useState(((_a = initialValues.subscriptionLink) === null || _a === void 0 ? void 0 : _a.link) || "");
    const [dueDate, setDueDate] = useState((_b = initialValues.subscriptionLink) === null || _b === void 0 ? void 0 : _b.dueDate);
    const [loadingRegistrationToSignature, setLoadingRegistrationToSignature] = useState(false);
    const willExpireOn = useMemo(() => {
        try {
            return Number(getDurationFromNowAsHours(dueDate));
        }
        catch (error) {
            return null;
        }
    }, [dueDate]);
    function generateLink() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                clearMessage();
                const { link, dueDate: linkDueDate } = yield companyStore.createPJSubscriptionLinkByCompanyId(companyId || "");
                setDueDate(linkDueDate);
                setGeneratedLink(link);
                setSuccessMessage(t("linkgeratedsuccessfully"));
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("errorGeneratingLink"));
            }
        });
    }
    const okText = React.createElement("span", { "data-cy": "regenerate-link-modal-button-data-cy" }, t("yesLabel"));
    const onSubmit = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (willExpireOn && willExpireOn > 0) {
            Modal.confirm({
                title: "Atenção!",
                content: (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "O link atual ainda \u00E9 v\u00E1lido. Faltam ",
                        willExpireOn,
                        "h para ele expirar. Tem certeza que deseja gerar um novo?"))),
                okText,
                cancelText: t("noLabel"),
                onOk: () => generateLink(),
                width: "40%",
                maskClosable: true,
                icon: null,
            });
        }
        else {
            yield generateLink();
        }
    }), [dueDate, companyStore.selectedCompany]);
    const sendRegistrationToSignature = () => __awaiter(void 0, void 0, void 0, function* () {
        if (companyId) {
            try {
                setLoadingRegistrationToSignature(true);
                yield sendEmailToLegalResponsiblesWithSignatureLink(companyId);
                setSuccessMessage(t("sendRegistrationToSignatureSuccessfully"));
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("sendRegistrationToSignatureError"));
            }
            finally {
                setLoadingRegistrationToSignature(false);
            }
        }
    });
    const copyLink = React.useCallback(() => {
        navigator.clipboard.writeText(generatedLink);
        generalStore.setSuccessMessage("Link copiado!");
    }, [generatedLink]);
    const handleSendRegistrationToSignature = () => {
        validateFieldsAndScroll({
            scroll: {
                alignWithTop: true,
                offsetTop: 200,
            },
        }, (errors) => {
            if (!errors) {
                sendRegistrationToSignature();
            }
        });
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("h3", null, "Gerar link para envio ao respons\u00E1vel pelo preenchimento do cadastro da empresa"),
        React.createElement("div", { className: styles.inputContainer },
            React.createElement(Form.Item, { label: "Link gerado", help: "Este link ir\u00E1 expirar em 120 horas a partir da sua gera\u00E7\u00E3o" },
                React.createElement(Input, { value: generatedLink, placeholder: "https://pj.credifit.com.br/subscription?token={TOKEN}", onFocus: (inputElement) => inputElement.target.select() }),
                generatedLink && (React.createElement(Button, { onClick: copyLink, type: "ghost" }, "Copiar link")),
                React.createElement(Button, { onClick: onSubmit, type: "primary", style: { marginLeft: 8 }, "data-cy": "link-generation-button-data-cy" },
                    generatedLink ? "Renovar" : "Gerar",
                    " link"),
                React.createElement(Button, { onClick: handleSendRegistrationToSignature, type: "primary", style: { marginLeft: 8 }, "data-cy": "send-registration-to-signature-button-data-cy", disabled: loadingRegistrationToSignature }, t("sendRegistrationToSignatureLabel")))),
        willExpireOn !== null && (React.createElement("div", { className: styles.time, "data-cy": "link-time-remaining-data-cy" }, willExpireOn > 0 ? (React.createElement(Tag, { color: "green" },
            "Tempo restante: ",
            willExpireOn,
            "h")) : (React.createElement(Tag, { color: "red" }, "Link expirado :("))))));
};
