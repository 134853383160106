import i18n from "../i18n";
export const AdminRoutes = {
    LoansRequests: "/",
    Company: {
        Report: "/empresas",
        Register: "/empresas/cadastro",
        Edit: "/empresas/editar",
        Actions: "/empresas/acoes",
        LegalResponsibles: "/empresas/responsaveis",
        Documents: "/empresas/documentos",
        Employees: "/empresas/colaboradores",
        Agreements: "/empresas/convenios",
        AgreementRegister: "/empresas/convenios/cadastro",
        Contracts: "/empresas/contratos-consignado",
    },
    CompaniesGroups: {
        Report: "/grupos-empresariais",
        Actions: "/grupos-empresariais/acoes",
    },
    Contributors: "/colaboradores",
    FgtsParameters: "/parametros-fgts",
    ChangePassword: "/alterar-senha",
    ChargeReport: "/cobrancas",
    Contracts: "/contratos-consignado",
    OtherContracts: "/contratos-outros",
    FgtsContracts: "/contratos-saque-aniversario",
    PrecatoryAnticipationContracts: "/contratos-antecipacao-precatorio",
    UserReport: "/usuarios",
    CessionReport: "/cessoes",
    Admin: {
        Profiles: "/perfis",
        ProfileEdit: "/perfis/editar",
        Users: "/users",
    },
    Simulator: {
        Report: "/simulacoes",
        Simulation: "/simulador",
        Request: "/contratacao",
    },
    Cnab: "/cnab",
    paymentSlipReports: "/payment-slip/reports",
    Escrow: "/escrow",
};
export const routes = [
    {
        label: i18n.t("simulatorLabel"),
        to: AdminRoutes.Simulator.Report,
        module: "simulator",
    },
    {
        label: i18n.t("requestsLabel"),
        to: AdminRoutes.LoansRequests,
        module: "requests",
    },
    {
        label: i18n.t("cadastreLabel"),
        to: "",
        items: [
            {
                label: "Empresas",
                to: AdminRoutes.Company.Report,
                module: "companies",
            },
            {
                label: "Grupos Empresariais",
                to: AdminRoutes.CompaniesGroups.Report,
                module: "companiesGroups",
            },
            {
                label: "Colaboradores",
                to: AdminRoutes.Contributors,
                module: "companyEmployees",
            },
            {
                label: i18n.t("fgtsParameters.title"),
                to: AdminRoutes.FgtsParameters,
                module: "fgtsParameters",
            },
        ],
    },
    {
        label: i18n.t("reportsLabel"),
        to: "",
        items: [
            {
                label: i18n.t("payrollContractsLabel"),
                to: AdminRoutes.Contracts,
                module: "contracts",
            },
            {
                label: i18n.t("otherContractsLabel"),
                to: AdminRoutes.OtherContracts,
                module: "otherContracts",
            },
            {
                label: i18n.t("birthdayWithdrawalContractsLabel"),
                to: AdminRoutes.FgtsContracts,
                module: "birthdayWithdrawalContracts",
            },
            {
                label: i18n.t("precatoryAnticipationContractsLabel"),
                to: AdminRoutes.PrecatoryAnticipationContracts,
                module: "precatoryAnticipationContracts",
            },
            {
                label: i18n.t("chargesLabel"),
                to: AdminRoutes.ChargeReport,
                module: "charges",
            },
            {
                label: i18n.t("assignmentsLabel"),
                to: AdminRoutes.CessionReport,
                module: "cessions",
            },
            {
                label: i18n.t("users.title"),
                to: AdminRoutes.UserReport,
                module: "users",
            },
            {
                label: "Cnab",
                module: "cnab",
                to: AdminRoutes.Cnab,
            },
            {
                label: "Acompanhamento de boletos",
                module: "paymentSlipReports",
                to: AdminRoutes.paymentSlipReports,
            },
            {
                label: "Escrow",
                module: "escrow",
                to: AdminRoutes.Escrow,
            },
        ],
    },
    {
        label: i18n.t("authentication.accessControl"),
        to: "",
        items: [
            {
                label: i18n.t("authentication.users"),
                to: AdminRoutes.Admin.Users,
                module: "authentication",
            },
            {
                label: i18n.t("authentication.profiles"),
                to: AdminRoutes.Admin.Profiles,
                module: "authentication",
            },
        ],
    },
    {
        label: i18n.t("authentication.changePasswordText"),
        to: AdminRoutes.ChangePassword,
        isUserSubmenu: true,
    },
];
